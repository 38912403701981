<template>
    <div class="p-grid">
        <div class="p-col-4">
            <CustomInputText name="name" label="Nazwa" v-model="internalValue.name"/>
        </div>
        <div class="p-col-4">
            <CustomSelectBoolean name="agreementForDataSharing" label="Zgoda na publikację"
                                 v-model="internalValue.agreementForDataSharing"></CustomSelectBoolean>
        </div>
        <div class="p-col-4">
            <CustomSelectBoolean name="publishedForPublicAccess" label="Opublikowano na stronie"
                                 v-model="internalValue.publishedForPublicAccess"></CustomSelectBoolean>
        </div>
    </div>
</template>

<script>
    import CustomSelectBoolean from "@/views/user/components/CustomSelectBoolean";
    import CustomInputText from "../../../components/form/CustomInputText";

    export default {
        name: "UserSearchCriteria",

        props: {
            modelValue: null,
        },

        emits: ["update:modelValue"],

        computed: {
            internalValue: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },
            },
        },

        components: {CustomInputText, CustomSelectBoolean},
    };
</script>

<style scoped>

</style>
