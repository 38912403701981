<template>
    <div>
        <div v-if="quiz && elearningData">
            <h1 class="page-header">
                <i class="pi pi-desktop header-icon"></i>Quiz do E-Learningu "{{elearningData.name}}"
            </h1>

            <div v-if="showResults">
                <div v-if="result>=75">
                    <h2>Gratulacje! Zaliczyłeś quiz</h2>
                </div>
                <div v-else>
                    <h2>Niestety nie udało ci się zaliczyć quizu</h2>
                </div>
                <h2>Twój wynik to {{result}}%</h2>
                <br/>
                <br/>
            </div>
            <div>
                <h2>{{quiz.title}}</h2>
                <br/>
                <div class="quiz-questions">
                    <div v-for="(question, questionIndex) in quiz.questions" :key="question.id"
                         class="quiz-question-box" :class="answerClass(question)">
                        <div class="quiz-title">
                            <b>{{questionIndex+ 1}}. </b><span>{{question.questionText}}</span>
                        </div>
                        <div v-for="(answer, index) in question.answers" :key="answer.id" class="quiz-answear">
                            <RadioButton :id="answer.id" name="category"
                                         :value="answer.id" v-model="question.answerId"
                            />
                            <label :for="answer.id" class="quiz-answear-label">
                                <b>{{String.fromCharCode(97 + index)}}. </b>{{answer.answerText}}
                            </label>

                        </div>
                    </div>
                </div>
                <div class="quiz-buttons">
                    <Button @click="submitAnswers()" v-if="!showResults">Wyślij</Button>
                    <!--                    TODO: powrót do Elearning'u-->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {finishELearningUsingPOST, getElearningUsingGET, saveQuizResultsUsingPOST} from "@/swagger/vue-api-client";
    import RadioButton from "primevue/radiobutton";
    import Button from "primevue/button";

    export default {
        name: "ElearningQuizView",

        components: {
            RadioButton, Button,
        },

        data() {
            return {
                elearningData: null,
                quiz: null,
                answers: [],
                result: null,
                showResults: false,
            };
        },

        methods: {
            finishElearningAndFetchData() {
                finishELearningUsingPOST({eLearningId: this.$route.params.id}).then((response) => {
                    this.quiz = response.data;
                    response.data.questions.forEach(
                        (question) => {
                            question.answerId = null;
                        },
                    );
                }).catch(() => {
                    this.$toast.add({
                        severity: "error",
                        summary: "Błąd",
                        detail: "Wystąpił nieoczekiwany błąd",
                        life: 3000,
                    });
                });
            },
            getElearningData() {
                getElearningUsingGET({id: this.$route.params.id}).then((response) => {
                    this.elearningData = response.data;
                }).catch(() => {
                    this.$toast.add({
                        severity: "error",
                        summary: "Błąd",
                        detail: "Wystąpił nieoczekiwany błąd",
                        life: 3000,
                    });
                });
            },
            submitAnswers() {
                window.console.log("subbmitting answers");
                this.$confirm.require({
                    header: "Czy na pewno chcesz wysłać swoje odpowiedzi?",
                    message: "Po wysłaniu odpowiedzi nie będzie możliwości ich zmiany",
                    icon: "pi pi-exclamation-triangle",
                    acceptLabel: "Tak",
                    rejectLabel: "Nie",
                    accept: () => {
                        // Ustawianie odpowiedzi
                        this.quiz.questions.forEach((question) => {
                            question.answers.forEach((answer) => {
                                answer.chosen = (answer.id === question.answerId);
                            });
                        });
                        saveQuizResultsUsingPOST({eLearningId: this.$route.params.id, quizDto: this.quiz})
                            .then((response) => {
                                this.showResults = true;
                                this.result = response.data;
                            });
                    },
                });
            },
            answerClass(question) {
                if (!this.showResults) {
                    return "";
                }
                return (question.answers.find((ans) => ans.correct).id === question.answerId)
                    ? "correct" : "not-correct";
            },
        },

        mounted() {
            this.finishElearningAndFetchData();
            this.getElearningData();
        },

    };
</script>

<style scoped lang="less">
    @import "../../assets/theme/variable.less";

    .quiz-question-box {
        max-width: 800px;
        background-color: rgb(244, 244, 244);
        margin: auto;
        padding: 10px;
        margin-top: 20px;
        text-align: left;
        .quiz-title {
            span {
                font-size: 20px;
            }
            margin-bottom: 10px;
        }
        .quiz-answear {
            padding: 5px;
            .quiz-answear-label{
                margin-left: 10px;
            }
        }
    }
    .correct {
        background-color:  #d9f2e6;
    }
    .not-correct {
        background-color: #ffcccc;
    }

    .quiz-buttons {
        margin-top: 50px;
    }
</style>
