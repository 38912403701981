<template>
    <div class="p-fluid custom-input-text">
        <span class="p-float-label" :class="icon ? 'p-input-icon-right' : ''">
            <i v-if="icon" :class="icon" />
            <TextArea :id="name" type="text" v-model="internalValue" :class="computedClasses"
                      :rows="rows" :cols="cols" :maxlength="maxlength"
                      @input="handleChange" @blur="handleBlur"/>
            <label :for="name">{{ label }}</label>
            <span v-show="errorMessage" class="p-invalid">{{ errorMessage }}</span>
        </span>
    </div>
</template>

<script>
    import TextArea from "primevue/textarea";
    import {useField} from "vee-validate";

    export default {
        name: "CustomInputTextArea",

        props: {
            name: {
                type: String,
            },
            label: {
                type: String,
            },
            modelValue: null,
            class: {
                type: String,
            },
            rows: {
                type: Number,
                default: 3,
                required: false,
            },
            cols: {
                type: Number,
                default: 30,
                required: false,
            },
            maxlength: {
                type: Number,
                default: undefined,
                required: false,
            },
            icon: {
                type: String,
                default: null,
                required: false,
            },
            rules: undefined,
        },
        emits: ["update:modelValue"],

        watch: {
            modelValue(val) {
                this.handleChange(val);
            },
        },

        computed: {
            internalValue: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },

            },

            computedClasses() {
                const classes = this.class ? this.class : "";
                return classes + (this.errorMessage ? "p-invalid" : "");
            },

        },

        components: {TextArea},

        setup(props) {
            const {
                value: inputValue,
                errorMessage,
                handleBlur,
                handleChange,
                meta,
            } = useField(props.name, props.rules, {
                initialValue: props.modelValue,
            });

            return {
                handleChange,
                handleBlur,
                errorMessage,
                inputValue,
                meta,
            };
        },
    };
</script>

<style scoped>
    .custom-input-text {
        margin-bottom: 20px;
    }
</style>
