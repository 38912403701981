<template>
    <div>
        <DataTable ref="userTable" :value="users" :lazy="true" :paginator="true" v-model:rows="searchParams.page.limit"
                   removableSort :totalRecords="totalRecords" :loading="loading" @page="onPage"
                   @sort="onPage" :rowsPerPageOptions="[5,10,20,50]">
            <Column field="firstName" header="Imię" :sortable="true"></Column>
            <Column field="lastName" header="Nazwisko" :sortable="true"></Column>
            <Column field="email" header="e-mail" :sortable="true"></Column>
            <Column field="systemRole" header="Rola" headerStyle="width: 200px" style="text-align: center">
                <template #body="slotProps">
                    <CustomBadge label="Administrator" color="#05112f"
                                 v-show="slotProps.data.systemRole === 'ADMIN'"/>
                    <CustomBadge label="Pracodawca" color="#247ba0"
                                 v-show="slotProps.data.systemRole === 'EMPLOYER'"/>
                    <CustomBadge label="Pracownik" color="#ff6161"
                                 v-show="slotProps.data.systemRole === 'WORKER'"/>
                </template>
            </Column>
            <Column headerStyle="width: 200px">
                <template #body="slotProps" v-if="Role.isAdmin()">
                    <router-link :to="{name: 'userEdit', params: {'id': slotProps.data.id}}">
                        <Button type="button" icon="pi pi-pencil"
                                class="p-button-rounded p-button-outlined"
                                style="color: #247ba0"
                                v-tooltip.top="'Edytuj użytkownika'"/>
                    </router-link>
                    <Button v-if="slotProps.data.systemRole === 'EMPLOYER' && slotProps.data.active === false"
                            type="button" icon="pi pi-check"
                            class="p-ml-2 p-button-rounded p-button-outlined"
                            style="color: #ff6161"
                            @click="activateEmployerAccount(slotProps.data)"
                            v-tooltip.top="'Aktywuj użytkownika'">
                    </Button>
                </template>
            </Column>
            <template #empty>
                Nie znaleziono użytkowników.
            </template>
        </DataTable>
    </div>
</template>

<script>
    import {
        activateEmployerAccountUsingPOST as activateEmployerAccount,
        searchUserCountUsingPOST as searchUserCount,
        searchUserUsingPOST as searchUser,
    } from "@/swagger/vue-api-client";
    import {SystemRole} from "@/utils/SystemRole";
    import Button from "primevue/button";
    import DataTable from "primevue/datatable";
    import Column from "primevue/column";
    import Tooltip from "primevue/tooltip";
    import CustomBadge from "../../../components/CustomBadge";

    export default {
        name: "UserTable",
        components: {
            // eslint-disable-next-line vue/no-unused-components
            CustomBadge, Button, Column, DataTable, Tooltip,
        },

        props: {
            searchCriteria: {
                type: Object,
            },
        },

        emits: ["update:searchCriteria"],

        directives: {
            tooltip: Tooltip,
        },

        data() {
            return {
                loading: false,
                totalRecords: 0,
                users: [],
                Role: SystemRole,
                searchParams: this.searchCriteria,
            };
        },

        mounted() {
            this.updateTotalRecords();
            this.onPage(this.getFirstPage());
        },

        methods: {
            search() {
                this.onPage(this.getFirstPage());
                this.updateTotalRecords();
            },

            onPage(event) {
                this.loading = true;

                this.searchParams.page.offset = event.first;
                this.searchParams.page.limit = event.rows;
                this.searchParams.page.sortField = event.sortField;
                this.searchParams.page.sortOrder = event.sortOrder;

                searchUser({searchCriteria: this.searchCriteria}).then((response) => {
                    this.users = response.data;
                    this.loading = false;
                });
            },

            updateTotalRecords() {
                searchUserCount({searchCriteria: this.searchCriteria}).then((response) => {
                    this.totalRecords = response.data;
                });
            },

            getFirstPage() {
                return {
                    first: this.searchCriteria.page.offset,
                    rows: this.searchCriteria.page.limit,
                };
            },

            activateEmployerAccount(user) {
                activateEmployerAccount({userIdRequest: {id: user.id}}).then(() => {
                    user.active = true;
                    this.$toast.add({
                        severity: "success",
                        summary: "Sukces",
                        detail: "Użytkownik został aktywowany",
                        life: 3000,
                    });
                }).catch(() => {
                    this.$toast.add({
                        severity: "error",
                        summary: "Błąd",
                        detail: "Nastąpił nieoczekiwany błąd",
                        life: 3000,
                    });
                });
            },
        },

    };
</script>

<style scoped>

</style>
