<template>
    <div>
        <div class="login-panel-header" style="align-items: center;">
            <img src="@/assets/logo_full.png" width="300" height="300">
        </div>
        <div class="change-password-panel-content">
            <div class="p-grid">
                <div class="p-col-12">
                    <h2>Zmiana hasła</h2>
                    <div v-show="!afterChange">
                        Podaj nowe hasło. Hasło zawierać musi co najmniej 8 znaków.
                    </div>
                </div>
                <div v-show="!afterChange" class="p-col-12" @keyup.enter="change">
                    <CustomPassword class="input-password" name="newPassword"
                                    v-model="changePasswordForm.newPassword" label="Nowe hasło"/>
                    <CustomPassword name="newPassword" v-model="repeatPassword" label="Powtórz hasło"/>

                    <div v-show="!passwordEqual" class="valid-error">
                        Wpisane hasła nie są takie same
                    </div>
                </div>
                <div v-show="!afterChange" class="p-col-12">
                    <Button v-if="validPassword" label="Zmień hasło" @click="change"/>
                    <Button v-else disabled label="Zmień hasło"/>
                </div>
                <div v-show="afterChange" class="p-col-12">
                    <div v-if="success">
                        Hasło zostało zmienione. <router-link :to="{name: 'login'}">Zaloguj się</router-link>
                    </div>
                    <div v-else>
                        Nie udało się zmienić hasła.
                        <router-link :to="{name: 'remindPassword'}">Spróbuj ponownie</router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {changePasswordUsingPOST as changePassword} from "@/swagger/vue-api-client";
    import Button from "primevue/button";
    import CustomPassword from "../components/form/CustomPassword";

    export default {
        name: "ChangePasswordView",
        components: {CustomPassword, Button},

        computed: {
            passwordEqual() {
                return this.repeatPassword === this.changePasswordForm.newPassword;
            },

            validPassword() {
                return this.passwordEqual && this.repeatPassword.length > 7;
            },
        },

        data() {
            return {
                changePasswordForm: {
                    resetPasswordToken: "",
                    newPassword: "",
                },
                repeatPassword: "",
                afterChange: false,
                success: false,
            };
        },

        mounted() {
            this.changePasswordForm.resetPasswordToken = this.$route.query.resetPasswordToken;
        },

        methods: {
            change() {
                changePassword({passwordChangeRequest: this.changePasswordForm}).then((response) => {
                    this.success = response.data;
                    this.afterChange = true;
                });
            },
        },
    };
</script>

<style lang="less" scoped>
    @import "assets/less/change-password.less";
</style>
