<template>
    <div class="layout-topbar">
        <button class="p-link layout-menu-button" @click="onMenuToggle">
            <span class="pi pi-bars"></span>
        </button>
        <div class="environment-description">
            {{ envDesc }}
        </div>
        <div class="layout-topbar-icons">
            <div class="user-info">
                <div>
                    <i class="pi pi-user" />
                    {{ fullName }}
                </div>
                <div class="logout" @click="logout"><i class="pi pi-sign-out" /> Wyloguj</div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                envDesc: process.env.VUE_APP_ENV_DESC,
                fullName: localStorage.getItem("fullName"),
            };
        },
        methods: {
            onMenuToggle(event) {
                this.$emit("menu-toggle", event);
            },
            logout() {
                localStorage.removeItem("token");
                localStorage.removeItem("role");
                localStorage.removeItem("fullName");
                this.$router.push({name: "login"});
            },
        },
    };
</script>

<style lang="less">
    @import "src/components/assets/less/app-top-bar";
</style>
