<template>
    <div>
        <div class="top-panel">
            <div class="code">
                500
            </div>
        </div>
        <div class="bottom-panel">
            <div class="icon">
                <i class="pi pi-times"/>
            </div>
            <div class="content">
                <h3>Błąd</h3>
                <span>Prosimy skontaktować się z administratorem systemu</span>
                <br><br>
                <div class="p-lg-12 p-col-12" style="align-items: center">
                    <Button class="p-lg-12 p-col-12"
                            icon="pi pi-undo"
                            label="Powrót na stronę główną"
                            @click="redirectToLogin"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Button from "primevue/button";

    export default {
        name: "ErrorView",
        components: {Button},

        methods: {
            redirectToLogin() {
                this.$router.push({name: "login"});
            },
        },
    };
</script>

<style lang="less" scoped>
    @import url("@/views/assets/less/status-page.less");

    .top-panel {
        background-color: orangered;
        .code {
            color: #ffffff;
        }
    }

    .bottom-panel {
        .icon {
            background-color: orangered;
        }
    }
</style>
