<template>
    <div class="login-panel">
        <div class="login-panel-header" style="align-items: center;">
            <img src="@/assets/logo_full.png" width="300" height="300">
        </div>
        <div class="login-panel-content">
            <div class="p-grid">
                <div class="p-col-12">
                    <h2>{{ $t('message.login.title') }}</h2>
                </div>
                <div class="p-col-12" @keyup.enter="login">
                    <CustomInputText name="login" v-model="loginForm.login" :label="$t('message.login.login')"/>
                    <CustomPassword name="password" v-model="loginForm.password" :label="$t('message.login.password')"
                                    toggle-mask/>
                    <span class="p-invalid">{{wrongPasswordMessage}}</span>
                </div>
                <div class="p-col-12">
                    <Button :label="$t('message.login.action.login')" @click="login"/>
                </div>
                <div class="p-col-12">
                    <Button class="p-button-help" label="Zarejestruj się" @click="goToRegistrationView"/>
                </div>
                <div class="p-col-12">
                    <Button class="p-button-danger" label="Przypomnij hasło" @click="goToPasswordRemindView"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Button from "primevue/button";
    import {loginUsingPOST as loginRequest} from "@/swagger/vue-api-client";
    import {SystemRole} from "@/utils/SystemRole";
    import CustomPassword from "../components/form/CustomPassword";
    import CustomInputText from "../components/form/CustomInputText";

    export default {
        name: "LoginView",
        components: {CustomInputText, CustomPassword, Button},

        data() {
            return {
                loginForm: {
                    login: "",
                    password: "",
                },
                wrongPasswordMessage: null,
            };
        },

        methods: {
            login() {
                loginRequest({authenticationRequest: this.loginForm})
                    .then((response) => {
                        localStorage.setItem("token", response.data.token);
                        localStorage.setItem("role", response.data.role);
                        localStorage.setItem("fullName", response.data.fullName);
                        if (this.$route.query.next) {
                            this.$router.push(this.$route.query.next);
                        } else if (SystemRole.isAdmin()) {
                            this.$router.push({name: "userConfirmList"});
                        } else {
                            this.$router.push({name: "myEvaluations"});
                        }
                    })
                    .catch((error) => {
                        if (error.response.data) {
                            this.wrongPasswordMessage = error.response.data;
                        }
                    });
            },

            goToPasswordRemindView() {
                this.$router.push({name: "remindPassword"});
            },

            goToRegistrationView() {
                this.$router.push({name: "register-company"});
            },
        },
    };
</script>

<style lang="less" scoped>
    @import "assets/less/login.less";
</style>
