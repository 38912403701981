<template>
    <div>
        <CustomSelectOne :disabled="disabled"
                         :name="name" :items="items" :empty-value="emptyValue"
                         v-model="internalValue"
                         item-label="label" item-value="value" :label="label" v-if="loading"/>
    </div>
</template>

<script>
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne";

    export default {
        name: "CustomSelectBoolean",
        props: {
            name: {
                type: String,
            },
            label: {
                type: String,
            },
            emptyValue: {
                type: Boolean,
                default: true,
            },
            modelValue: null,
            disabled: {
                type: Boolean,
                default: false,
                required: false,

            },
        },

        emits: ["update:modelValue"],

        computed: {
            internalValue: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },
            },
        },

        data() {
            return {
                items: [
                    {label: "Tak", value: true},
                    {label: "Nie", value: false},
                ],
                loading: false,
            };
        },

        beforeMount() {
            this.loading = true;
        },

        components: {CustomSelectOne},
    };
</script>

<style scoped>

</style>
