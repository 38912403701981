<template>
    <div style="margin: auto;">
        <div>
            <h1 class="page-header" v-if="currentDepartment">
                <i class="pi pi-percentage header-icon"></i>
                Wynik analizy finansowej działu {{currentDepartment.name}}</h1>
        </div>

        <div v-if="loaded" style="margin: auto;">

            <div style="padding-bottom: 50px; margin: auto; width: 1300px; ">
                <h5>Koszty wdrożenia ścieżki reskillingu</h5>
                <Chart type="horizontalBar" :data="chartData" :options="chartOptions"
                       :width="1300" :height="650"/>
            </div>

            <table class="tableizer-table" style="margin-bottom: 20px; margin: auto;">
                <thead>
                    <tr>
                        <th>
                            Imię i nazwisko pracownika
                        </th>
                        <th>
                        </th>
                        <th>
                            Liczba wdrożeń
                        </th>
                        <th>
                            suma
                        </th>
                        <th>
                            Wartość z uwzgl. efekty synergii
                        </th>
                    </tr>
                </thead>

                <tbody>
                    <tr v-for="(row, index) in rows" :key="index">
                        <td>
                            {{ row.mainName }}
                        </td>
                        <td>
                            {{ row.subName }}
                        </td>
                        <td>
                            {{ row.numberOfImplementations }}
                        </td>
                        <td>
                            {{ formatAsMoneyString(row.total) }}
                        </td>
                        <td>
                            {{ formatAsMoneyString(row.totalWithSynergyEffect) }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div v-else>
            <ProgressSpinner/>
        </div>

        <div style="padding-bottom: 20px; padding-top: 20px;">
            <span class="no-print">
                <Button @click="goBack()" class="p-button"
                        style="padding: 20px; margin-right: 40px;">
                    <i class="pi pi-angle-double-left" style="padding-right: 10px;"></i>Powrót do poprzedniej strony
                </Button>
                <Button @click="print()" class="p-button-danger"
                        style="padding: 20px; background-color: #ff6161;">
                    <i class="pi pi-print" style="padding-right: 10px;"></i>Drukuj</Button>
            </span>
        </div>
    </div>
</template>

<script>
    import {Departments} from "@/utils/Departments";
    import ProgressSpinner from "primevue/progressspinner";
    import {getFinancialAnalysisDataUsingGET as getFinancialAnalysis} from "@/swagger/vue-api-client";
    import Chart from "primevue/chart";
    import Button from "primevue/button";

    export default {
        name: "PrintEfficiencyResultsStepOne",
        components: {
            // eslint-disable-next-line vue/no-unused-components
            ProgressSpinner, Chart, Button,
        },
        data() {
            return {
                loaded: false,
                loadedForPrint: false,
                currentDepartment: null,
                rows: [],
                dynamicColumns: [],
                showWorkers: false,
                showRows: true,
                chartData: {
                    labels: [],
                    datasets: [
                        {
                            label: "WARTOŚĆ Z UWZGLĘDNIENIEM EFEKTU SYNERGII",
                            backgroundColor: "#2E9FA2",
                            data: [],
                        },
                        {
                            label: "SUMA",
                            backgroundColor: "#84DADC",
                            data: [],
                        },
                    ],
                },
                chartOptions: {
                    responsive: false,
                },
            };
        },

        beforeMount() {
            if (this.$route.params.department && Departments.getDepartmentByUrlParam(this.$route.params.department)) {
                this.setCurrentDepartment();
                this.getAnalysis();
            } else {
                this.$router.push({name: "notFound"});
            }
        },

        watch: {
            "$route.params.department": function (newVal, oldVal) {
                if (newVal !== oldVal) {
                    this.setCurrentDepartment();
                    this.getAnalysis();
                }
            },
        },

        methods: {
            setCurrentDepartment() {
                this.currentDepartment = Departments.getDepartmentByUrlParam(this.$route.params.department);
            },

            getAnalysis() {
                getFinancialAnalysis({department: this.currentDepartment.discriminator}).then((response) => {
                    this.rows = response.data.rows;
                    this.dynamicColumns = response.data.dynamicColumns;
                    // eslint-disable-next-line prefer-destructuring
                    this.chartData.datasets[0].data = response.data.chartData.data[0];
                    // eslint-disable-next-line prefer-destructuring
                    this.chartData.datasets[1].data = response.data.chartData.data[1];
                    this.chartData.labels = response.data.chartData.labels;
                    this.loaded = true;
                }).catch(() => {
                    this.loaded = true;
                });
            },

            formatAsMoneyString(val) {
                if (!val) {
                    return "";
                }
                return parseFloat(val).toFixed(2) + " zł";
            },

            goBack() {
                window.history.back();
            },

            print() {
                window.print();
            },
        },

        computed: {
            filteredRows() {
                return this.rows.filter((r) => r.mainName === r.mainName.toUpperCase());
            },
        },
    };
</script>

<style scoped>
@media print {
    .no-print, .no-print *
    {
        display: none !important;
    }

    .tableizer-table th {
        border: 1px solid black !important;
    }
    .tableizer-table td {
        border-bottom: 1px solid black !important;
        border-left: 1px dashed black !important;
        border-right: 1px dashed black !important;
    }
}
    table.tableizer-table {
        font-size: 1rem;
    }
    .tableizer-table td {
        padding: 0.5rem 0.8rem;
        margin: 10px;
        border-bottom: 1px solid #CCC;
        border-left: 1px dashed #CCC;
        border-right: 1px dashed #CCC;
    }
    .tableizer-table th {
        background-color: #D9D9D9;
        color: #495057;
        padding: 1rem;
        font-weight: bold;
        border: 1px solid #ffffff;
    }
</style>
