<template>
    <div>
        <DataTable ref="competenceTable" :value="competences" :lazy="true">
            <template #empty>
                Nie znaleziono kompetencji
            </template>
            <template #loading>
                Trwa wczytywanie kompetencji. Proszę czekać
            </template>
            <Column field="competences" header="Kompetencje" style="text-align: center"></Column>
            <Column field="competencyLevel" style="text-align: center; width: 57%">
                <template #header>
                    <div style="white-space:nowrap; align-content: stretch;">
                        <div v-tooltip="'Pracownik znajduje się na podstawowym poziomie  badanej kompetencji.' +
                            ' Potrzebuje wsparcia w zakresie wykorzystywania jej podczas realizacji większości' +
                            ' obowiązków zawodowych. \n W takiej sytuacji zalecane jest korzystanie z wiedzy' +
                            ' i doświadczenia pozostałych współpracowników, u których wspomniana kompetencja' +
                            ' jest lepiej rozwinięta i którzy skutecznie wykorzystują ją w codziennej pracy.' +
                            ' Działania reskillingowe, poza poszerzaniem wiedzy i umiejętności pracownika,' +
                            ' powinny zmierzać do zwiększania jego motywacji \n w zakresie rozwijania badanej' +
                            ' umiejętności.'" style="width: 25%; display: inline-block;">
                            Debiutant ⓘ
                        </div>
                        <div v-tooltip="'Pracownik jest na dobrej drodze do stopniowego osiągania' +
                            ' samodzielności w zakresie badanej kompetencji. Niekiedy potrafi skutecznie' +
                            ' wykorzystywać ją podczas realizacji zadań. W dalszym ciągu wskazane jest' +
                            ' rozwijanie umiejętności np. dzięki obserwowaniu pracy współpracowników,' +
                            ' którzy wspomnianą kompetencję mają lepiej rozwiniętą, bądź wykorzystują' +
                            ' ją w bardziej złożony sposób. Stwarzanie okazji do wymiany doświadczeń' +
                            ' jest w tej sytuacji niezbędne do pomyślnego przejścia przez proces reskillingu.' +
                            ' Pracownik potrzebuje także wzmocnienia ' +
                            'motywacji do rozwoju.'" style="width: 25%; display: inline-block;">
                            Asystent ⓘ
                        </div>
                        <div v-tooltip="'Pracownik przejawia wysoki poziom w zakresie badanej kompetencji.' +
                            ' Zwykle potrafi skutecznie wykorzystywać ją w ramach wykonywania obowiązków zawodowych.' +
                            ' Niekiedy może potrzebować wsparcia ze strony bardziej doświadczonych współpracowników.' +
                            ' Stwarzanie okazji do uczestnictwa w rekomendowanych poniżej działaniach rozwojowych,' +
                            ' może znacząco przyczynić się do wzmocnienia kompetencji i osiągnięcia pełnej' +
                            ' biegłości w posługiwaniu się nią. Wpłynie to na pomyślne przejście procesu' +
                            ' reskillingu i zwiększenie samodzielności' +
                            ' w wykonywaniu zadań zawodowych.'" style="width: 25%; display: inline-block;">
                            Specjalista ⓘ
                        </div>
                        <div v-tooltip="'Pracownik znajduje się na najwyższym poziomie rozwoju w zakresie' +
                            ' badanej kompetencji. Potrafi biegle wykorzystywać ją podczas realizowania' +
                            ' obowiązków zawodowych. Najprawdopodobniej z powodzeniem wykorzysta ją podczas' +
                            ' wdrażania procesu reskillingu w organizacji. Należy zaznaczyć, iż może on pełnić' +
                            ' rolę lidera zmiany i być aktywnie włączany w działania kształtujące tę' +
                            ' umiejętność u pozostałych członków zespołu. Kluczowe dla dalszego rozwoju' +
                            ' badanej kompetencji jest dla niego utrwalenie posiadanych zasobów oraz' +
                            ' wzbogacanie ich w oparciu o ' +
                            'najnowsze trendy branżowe i rozwojowe.'" style="width: 25%; display: inline-block;">
                            Profesjonalista ⓘ
                        </div>
                    </div>
                </template>
                <template #body="{data}">
                    <ProgressBar :value="data.competencyLevel"
                                 v-bind:class="isCompetencyLevel(data)"
                                 style="height: 40px">
                    </ProgressBar>
                </template>
            </Column>
            <Column field="competencyLevel" header="Poziom kompetencji"
                    style="text-align: center; border-left-width: 3px">
                <template #body="{data}">
                    {{data.competencyLevel}}%
                    <ProgressBar :value="data.competencyLevel"></ProgressBar>
                </template>
            </Column>
            <Column field="competencyLevelGap" header="Poziom luki kompetencyjnej" style="text-align: center">
                <template #body="{data}">
                    {{data.competencyLevelGap}}%
                    <ProgressBar :value="data.competencyLevelGap"></ProgressBar>
                </template>
            </Column>
        </DataTable>
    </div>
</template>

<script>
    import DataTable from "primevue/datatable";
    import Column from "primevue/column";
    import ProgressBar from "primevue/progressbar";
    import {
        getMyCompetencesUsingGET as getOwnCompetence,
        getWorkerCompetenceUsingGET as getWorkerCompetence,
    } from "@/swagger/vue-api-client";
    import Tooltip from "primevue/tooltip";

    export default {
        name: "CompetenceTable",
        components: {
            DataTable, Column, ProgressBar,
        },

        directives: {
            tooltip: Tooltip,
        },

        data() {
            return {
                competences: [],
                workerId: this.$route.params.id,
            };
        },

        props: {
            own: {
                type: Boolean,
                required: false,
                default: false,
            },
        },

        methods: {
            getWorkerCompetences() {
                if (this.own) {
                    getOwnCompetence().then((response) => {
                        this.competences = response.data;
                    });
                } else {
                    getWorkerCompetence({workerId: this.workerId}).then((response) => {
                        this.competences = response.data;
                    });
                }
            },
            getCompetenceBooleanClass(boolean) {
                if (boolean === "TAK") {
                    return "competence-yes";
                }
                return "competence-no";
            },
            isCompetencyLevel(competency) {
                if (competency.competencyLevel >= 80) {
                    return "p-80 competence-progress-bar";
                } if (competency.competencyLevel >= 50) {
                    return "p-50 competence-progress-bar";
                } if (competency.competencyLevel >= 30) {
                    return "p-30 competence-progress-bar";
                } return "p-0 competence-progress-bar";
            },
        },

        mounted() {
            this.getWorkerCompetences();
        },
    };
</script>

<style lang="less" scoped>
    @import "src/views/assets/less/competence-view.less";
</style>
