<template>
    <div>
        <PanelMenu :model="items"/>
    </div>
</template>

<script>
    // eslint-disable-next-line max-len
    import {getCurrentAccountSelfEvaluationDataUsingGET as getCurrentAccountSelfEvaluationData} from "@/swagger/vue-api-client";
    import PanelMenu from "primevue/panelmenu";
    import {SystemRole} from "@/utils/SystemRole";
    import {Departments} from "@/utils/Departments";

    export default {
        name: "AppMenu",

        components: {PanelMenu},

        data() {
            return {
                currentAccountSelfEvaluationId: "",
                currentAccountSelfEvaluationCode: "",
                items: [
                    {
                        label: this.$t("message.menu.users"),
                        icon: "pi pi-users",
                        command: () => this.$router.push({name: "userList"}),
                        visible: () => SystemRole.isAdmin(),
                    },
                    {
                        label: this.$t("message.menu.notConfirmedUsers"),
                        icon: "pi pi-check-square",
                        command: () => this.$router.push({name: "userConfirmList"}),
                        visible: () => SystemRole.isAdmin(),
                    },
                    {
                        label: this.$t("message.menu.workers"),
                        icon: "pi pi-users",
                        visible: () => SystemRole.isEmployer(),
                        items: Departments.departments.map(this.departmentEntryToMenuEntry),
                    },
                    {
                        label: this.$t("message.menu.survey"),
                        icon: "pi pi-pencil",
                        command: () => {
                            this.getCurrentAccountSelfEvaluationData();
                            setTimeout(() => this.$router.push({
                                name: "survey",
                                params: {id: this.currentAccountSelfEvaluationId},
                                query: {code: this.currentAccountSelfEvaluationCode},
                            }), 500);
                        },
                        visible: () => SystemRole.isWorker(),
                    },
                    {
                        label: this.$t("message.menu.sentSurveys"),
                        icon: "pi pi-envelope",
                        command: () => this.$router.push({name: "surveyList"}),
                        visible: () => SystemRole.isEmployer(),
                    },
                    {
                        label: this.$t("message.menu.eLearning"),
                        icon: "pi pi-file",
                        command: () => this.$router.push({name: "eLearningList"}),
                        visible: () => SystemRole.isEmployer() || SystemRole.isWorker(),
                    },
                    {
                        label: this.$t("message.menu.competencesDepartment"),
                        icon: "pi pi-chart-bar",
                        visible: () => SystemRole.isEmployer(),
                        items: Departments.departments.map(this.departmentEntryToMenuEntryCompetences),
                    },
                    {
                        label: this.$t("message.menu.reskillingPath"),
                        icon: "pi pi-compass",
                        visible: () => SystemRole.isEmployer(),
                        items: Departments.departments.map(this.departmentEntryToMenuEntryReskillingPath),
                    },
                    {
                        label: this.$t("message.menu.efficiencyCalculations"),
                        icon: "pi pi-percentage",
                        visible: () => SystemRole.isEmployer(),
                        items: Departments.departments.map(this.departmentEntryToMenuEntryEfficiencyCalculations),
                    },
                    {
                        label: this.$t("message.menu.ownCompetence"),
                        icon: "pi pi-chart-bar",
                        visible: () => SystemRole.isWorker(),
                        command: () => this.$router.push({name: "ownCompetences"}),
                    },
                    {
                        label: this.$t("message.menu.companies"),
                        icon: "pi pi-chart-line",
                        command: () => this.$router.push({name: "companyList"}),
                        visible: () => SystemRole.isAdmin(),
                    },
                    {
                        label: this.$t("message.menu.myEvaluations"),
                        icon: "pi pi-copy",
                        visible: () => SystemRole.isWorker() || SystemRole.isEmployer(),
                        command: () => this.$router.push({name: "myEvaluations"}),
                    },
                    {
                        label: this.$t("message.menu.forum"),
                        icon: "pi pi-comments",
                        command: () => this.$router.push({name: "forum"}),
                    },
                    {
                        label: this.$t("message.menu.trends"),
                        icon: "pi pi-list",
                        command: () => this.$router.push({name: "trends"}),
                    },
                    {
                        label: this.$t("message.menu.myElearning"),
                        icon: "pi pi-desktop",
                        visible: () => SystemRole.isWorker() || SystemRole.isEmployer(),
                        command: () => this.$router.push({name: "myElearning"}),
                    },
                ],
            };
        },
        methods: {
            departmentEntryToMenuEntry(entry) {
                return {
                    label: entry.name,
                    to: "/workers/" + entry.url,
                    visible: () => SystemRole.isEmployer(),
                    icon: "pi pi-id-card",
                    style: "text-align: left",
                };
            },
            departmentEntryToMenuEntryCompetences(entry) {
                return {
                    label: entry.name,
                    to: "/competences/department/" + entry.url,
                    visible: () => SystemRole.isEmployer(),
                    icon: "pi pi-id-card",
                    style: "text-align: left",
                };
            },
            departmentEntryToMenuEntryReskillingPath(entry) {
                return {
                    label: entry.name,
                    to: "/reskilling-path/department/" + entry.url,
                    visible: () => SystemRole.isEmployer(),
                    icon: "pi pi-id-card",
                    style: "text-align: left",
                };
            },
            departmentEntryToMenuEntryEfficiencyCalculations(entry) {
                return {
                    label: entry.name,
                    to: "/efficiency/department/" + entry.url,
                    visible: () => SystemRole.isEmployer(),
                    icon: "pi pi-id-card",
                    style: "text-align: left",
                };
            },
            getCurrentAccountSelfEvaluationData() {
                getCurrentAccountSelfEvaluationData({}).then((response) => {
                    const index = response.data.indexOf(";");
                    this.currentAccountSelfEvaluationId = response.data.substr(0, index);
                    this.currentAccountSelfEvaluationCode = response.data.substr(index + 1);
                }).catch(() => {
                    this.$router.push({name: "surveyNotAssigned"});
                });
            },
        },
    };
</script>

<style scoped>

</style>
