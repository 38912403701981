<template>
    <Dialog :header="getHeaderLabel" v-model:visible="internalVisible"
            :breakpoints="{'1200px': '60vw', '960px': '75vw', '640px': '100vw'}"
            :style="{width: '40vw'}">
        <Form @submit="addPost" v-slot="{ isSubmitting }">
            <div class="p-grid form-wrapper">
                <div class="p-col-12" v-if="currentStep==1">
                    <CustomInputText name="keyword" v-model="trendQuiz.keyword"
                                     label="Wpisz słowo klucz"
                                     icon="pi pi-pencil"
                                     rules="required"/>
                    <CustomSelectOneDepartment name="department" label="Dział" v-model="trendQuiz.department"/>
                </div>
                <div class="adding-post-completed" v-if="currentStep==2">
                    <div class="center success-message">
                    </div>
                </div>
            </div>
            <div class="button-div">
                <Button v-if="currentStep === 1" label="Anuluj" icon="pi pi-times" class="p-button-text"
                        @click="closeDialog()" :disabled="isSubmitting"/>
                <Button v-if="currentStep === 1" label="Dodaj" icon="pi pi-chevron-right"
                        type="submit" :disabled="isSubmitting" />
                <Button v-if="currentStep === 2" label="Zamknij" icon="pi pi-times" class="p-button-text"
                        @click="closeDialog(), resetPostData()" />
            </div>

        </Form>
    </Dialog>
</template>

<script>
    import Dialog from "primevue/dialog";
    import Button from "primevue/button";
    import CustomInputText from "@/components/form/CustomInputText";
    import {Form} from "vee-validate";
    import {createQuizFromTemplateUsingPOST as createQuiz} from "@/swagger/vue-api-client";
    import CustomSelectOneDepartment from "../../../components/form/CustomSelectOneDepartment";

    export default {
        name: "AddNewQuizDialog",
        components: {
            Dialog, Button, CustomInputText, Form, CustomSelectOneDepartment,
        },
        emits: ["update:visible", "new-post-added"],

        props: {
            visible: {
                type: Boolean,
                required: true,
            },
        },

        data() {
            return {
                trendQuiz: {
                    keyword: "",
                    department: null,
                },
                currentStep: 1,
            };
        },

        computed: {
            internalVisible: {
                get() {
                    return this.visible;
                },
                set(value) {
                    this.$emit("update:visible", value);
                },

            },
            getHeaderLabel() {
                return this.currentStep === 2 ? "Dodano nową ankietę" : "Dodaj nową ankietę";
            },
        },

        methods: {
            async addPost() {
                await Promise.resolve(createQuiz({trendQuiz: this.trendQuiz}))
                    .then(() => {
                        this.currentStep = 2;
                        this.$emit("new-post-added");
                    }).catch((error) => {
                        if (error.response.status === 403) {
                            this.$toast.add({
                                severity: "error",
                                summary: "Błąd",
                                detail: "Nie masz wystarczających uprawnień",
                                life: 3000,
                            });
                        } else {
                            this.$toast.add({
                                severity: "error",
                                summary: "Błąd",
                                detail: "Wystąpił nieoczekiwany błąd",
                                life: 3000,
                            });
                        }
                    });
            },

            closeDialog() {
                this.internalVisible = false;
                this.currentStep = 1;
            },

            getEmptyPost() {
                return {
                    title: "",
                    content: "",
                };
            },

            resetPostData() {
                this.postData = this.getEmptyPost();
            },
        },
    };
</script>

<style lang="less" scoped>
@import "src/views/assets/less/new-post-dialog.less";
</style>
