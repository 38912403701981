<template>
    <div class="custom-input custom-select">
        <div class="d-flex mb-1"><label :for="name" class="custom-input-label">{{ label }}</label></div>
        <MultiSelect :id="name" :options="items" :optionLabel="itemLabel" :optionValue="itemValue" :disabled="disabled"
                     v-model="internalValue" :virtualScrollerOptions="{ itemSize: 38 }" :filter="false"
                     :class="disabled ? 'disabled' : ''" :emptyMessage="emptyMessage" />
    </div>
</template>

<script>
    import MultiSelect from "primevue/multiselect";

    export default {
        name: "CustomMultiSelect",
        props: {
            name: {
                type: String,
            },
            label: {
                type: String,
            },
            itemLabel: {
                type: String,
            },
            itemValue: {
                type: String,
            },
            placeholder: {
                type: String,
            },
            items: {
                type: Array,
            },
            emptyMessage: {
                type: String,
                default: null,
            },
            modelValue: null,
            disabled: {
                type: Boolean,
                default: false,
            },
            width: {
                type: String,
                default: "",
            },
        },

        emits: ["update:modelValue"],

        computed: {
            internalValue: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },
            },
        },

        components: {MultiSelect},
    };
</script>

<style scoped>
    .p-multiselect {
        width: 200px;
    }
    .disabled {
        background-color: #d3d3d3;
    }
    .custom-select {
        width: 200px;
    }
</style>
