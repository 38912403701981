<template>
    <Dialog :header="'Wyszukaj ilość wystąpień słowa'" v-model:visible="internalVisible"
            :breakpoints="{'1200px': '60vw', '960px': '75vw', '640px': '100vw'}"
            :style="{width: '40vw'}">
        <Form @submit="searchPages" v-slot="{ isSubmitting }">
            <div class="p-grid form-wrapper">
                <div class="p-col-12">
                    <CustomInputText name="word" v-model="word.polishContent"
                                     label="Słowo"
                                     icon="pi pi-pencil"
                                     rules="required"/>
                    <CustomInputText name="foreignWord" v-model="word.foreignContent"
                                     label="Słowo zagraniczne"
                                     icon="pi pi-pencil"/>
                    <div v-if="currentStep === 2">
                        <div style="text-align: center">
                            <h5>RAPORT Z ANALIZY TRENDÓW W BRANŻY MEBLOWEJ – analiza portali branżowych</h5>
                            <h6>Dane dotyczące analizy</h6>
                        </div>
                        <div>
                            Okres przeprowadzenia analizy: <b>{{currentDate}}</b><br>
                            Liczba przeszukanych portali: <b>{{countPages}}</b><br>
                            Analizowana fraza: <b>{{word.polishContent}}</b>
                            <span v-if="word.foreignContent !== ''"><br> Analizowana zagraniczna fraza:
                                <b>{{word.foreignContent}}</b> </span>
                        </div>
                        <div></div>
                        <div style="text-align: center; padding-top: 30px">
                            <h6>Uzyskane wyniki</h6>
                            Podsumowanie:<br>
                            Na <b>{{percentageAppearancesOnPolishPages.zero.toFixed(2)}}%</b> stron prowadzonych
                            w języku polskim w ogóle nie znaleziono szukanej frazy,
                            na <b>{{percentageAppearancesOnPolishPages.one.toFixed(2)}}%</b> stron fraza wystąpiła
                            jednokrotnie, a na <b>{{percentageAppearancesOnPolishPages.many.toFixed(2)}}%</b>
                            stron fraza wystąpiła wielokrotnie.
                        </div>
                        <div class="box">
                            <Chart class="p-col-6" type="doughnut" :data="searchPolishPagesChartData"
                                   :options="searchPolishPagesChartOptions"
                                   :height="400" :width="400" style="padding: 20px; margin: auto"></Chart>
                        </div>
                    </div>
                    <div v-if="word.foreignContent !== '' && currentStep === 2">
                        <div style="text-align: center; padding-top: 30px">
                            Podsumowanie:<br>
                            Na <b>{{percentageAppearancesOnForeignPages.zero.toFixed(2)}}%</b> stron prowadzonych
                            w języku angielskim w ogóle nie znaleziono szukanej frazy,
                            na <b>{{percentageAppearancesOnForeignPages.one.toFixed(2)}}%</b> stron fraza
                            wystąpiła jednokrotnie, a na <b>{{percentageAppearancesOnForeignPages.many.toFixed(2)}}%</b>
                            stron fraza wystąpiła wielokrotnie.
                        </div>
                        <div class="box">
                            <Chart class="p-col-6" type="doughnut" :data="searchForeignPagesChartData"
                                   :options="searchForeignPagesChartOptions"
                                   :height="400" :width="400" style="padding: 20px; margin: auto"></Chart>
                        </div>
                        <div class="box">
                            <Chart class="p-col-6" type="bar" :data="searchComparisonPagesChartData"
                                   :options="searchComparisonPagesChartOptions"
                                   :height="400" :width="800" style="padding: 20px; margin: auto"></Chart>
                        </div>
                        <div style="text-align: center; padding: 30px">
                            {{comparisonSummary}}
                        </div>
                    </div>
                    <ResultTable v-if="currentStep === 2" ref="resultTable" v-model:result="result"/>
                </div>
            </div>
            <div class="button-div">
                <Button label="Zamknij" icon="pi pi-times" class="p-button-text"
                        @click="closeDialog()" :disabled="isSubmitting"/>
                <Button label="Szukaj" icon="pi pi-chevron-right"
                        type="submit" :disabled="isSubmitting" />
            </div>

        </Form>
    </Dialog>
</template>

<script>
    import Dialog from "primevue/dialog";
    import Button from "primevue/button";
    import CustomInputText from "@/components/form/CustomInputText";
    import {Form} from "vee-validate";
    import ResultTable from "@/views/forum/components/ResultTable";
    import Chart from "primevue/chart";
    import {searchPagesUsingPOST as searchPages} from "../../../swagger/vue-api-client";

    export default {
        name: "SearchPagesDialog",
        components: {
            ResultTable, Dialog, Button, CustomInputText, Form, Chart,
        },
        emits: ["update:visible", "new-post-added"],

        props: {
            visible: {
                type: Boolean,
                required: true,
            },
        },

        data() {
            return {
                word: {
                    polishContent: "",
                    foreignContent: "",
                },
                result: [],
                currentStep: 1,
                currentDate: null,
                countPages: 0,
                comparisonSummary: "",
                percentageAppearancesOnPolishPages: {
                    zero: 0,
                    one: 0,
                    many: 0,
                },
                percentageAppearancesOnForeignPages: {
                    zero: 0,
                    one: 0,
                    many: 0,
                },
                searchPolishPagesChartData: {
                    labels: [],
                    datasets: [
                        {
                            backgroundColor: ["#247ba0", "#ff6161", "#05112f"],
                            data: [],
                        },
                    ],
                },
                searchPolishPagesChartOptions: {
                    maintainAspectRatio: false,
                    title: {
                        display: true,
                        text: null,
                    },
                    plugins: {
                        legend: {
                            labels: {
                                color: "#495057",
                            },
                        },
                    },
                    tooltips: {
                        callbacks: {
                            label(tooltipItems, data) {
                                let total = 0;
                                for (let i = 0; i < data.datasets[0].data.length; i += 1) {
                                    if (data.datasets[0].data[i] !== 0) {
                                        total += data.datasets[0].data[i];
                                    }
                                }
                                return " " + data.labels[tooltipItems.index] + ": "
                                    + Math.trunc((data.datasets[0].data[tooltipItems.index] * 100) / total) + "%";
                            },
                        },
                    },
                },
                searchForeignPagesChartData: {
                    labels: [],
                    datasets: [
                        {
                            backgroundColor: ["#247ba0", "#ff6161", "#05112f"],
                            data: [],
                        },
                    ],
                },
                searchForeignPagesChartOptions: {
                    maintainAspectRatio: false,
                    title: {
                        display: true,
                        text: null,
                    },
                    plugins: {
                        legend: {
                            labels: {
                                color: "#495057",
                            },
                        },
                    },
                    tooltips: {
                        callbacks: {
                            label(tooltipItems, data) {
                                let total = 0;
                                for (let i = 0; i < data.datasets[0].data.length; i += 1) {
                                    if (data.datasets[0].data[i] !== 0) {
                                        total += data.datasets[0].data[i];
                                    }
                                }
                                return " " + data.labels[tooltipItems.index] + ": "
                                    + Math.trunc((data.datasets[0].data[tooltipItems.index] * 100) / total) + "%";
                            },
                        },
                    },
                },
                searchComparisonPagesChartData: {
                    labels: [],
                    datasets: [
                        {
                            backgroundColor: ["#247ba0", "#247ba0", "#247ba0"],
                            label: "Strony polskie",
                            data: [],
                        },
                        {
                            backgroundColor: ["#ff6161", "#ff6161", "#ff6161"],
                            label: "Strony zagraniczne",
                            data: [],
                        },
                    ],
                },
                searchComparisonPagesChartOptions: {
                    maintainAspectRatio: false,
                    title: {
                        display: true,
                        text: null,
                    },
                    tooltips: {
                        callbacks: {
                            label(tooltipItems, data) {
                                if (tooltipItems.datasetIndex === 0) {
                                    let total = 0;
                                    for (let i = 0; i < data.datasets[0].data.length; i += 1) {
                                        if (data.datasets[0].data[i] !== 0) {
                                            total += data.datasets[0].data[i];
                                        }
                                    }
                                    return " " + data.labels[tooltipItems.index] + ": "
                                        + Math.trunc((data.datasets[0].data[tooltipItems.index] * 100) / total) + "%";
                                }
                                let total = 0;
                                for (let i = 0; i < data.datasets[1].data.length; i += 1) {
                                    if (data.datasets[1].data[i] !== 0) {
                                        total += data.datasets[1].data[i];
                                    }
                                }
                                return " " + data.labels[tooltipItems.index] + ": "
                                    + Math.trunc((data.datasets[1].data[tooltipItems.index] * 100) / total) + "%";
                            },
                        },
                    },
                },
            };
        },

        computed: {
            internalVisible: {
                get() {
                    return this.visible;
                },
                set(value) {
                    this.$emit("update:visible", value);
                },

            },
            getHeaderLabel() {
                return this.currentStep === 2 ? "Dodano nowy temat" : "Dodaj nowy temat";
            },
        },

        methods: {
            async searchPages() {
                this.currentStep = 1;
                await Promise.resolve(searchPages({word: this.word}))
                    .then((response) => {
                        this.currentStep = 2;
                        this.currentDate = this.getNow();
                        this.result = response.data;
                        this.countPages = this.result.length;
                        this.getPolishStatisticData();
                        if (this.word.foreignContent !== "") {
                            this.getForeignStatisticData();
                        }
                    }).catch((error) => {
                        if (error.response.status === 403) {
                            this.$toast.add({
                                severity: "error",
                                summary: "Błąd",
                                detail: "Nie masz wystarczających uprawnień",
                                life: 3000,
                            });
                        } else {
                            this.$toast.add({
                                severity: "error",
                                summary: "Błąd",
                                detail: "Wystąpił nieoczekiwany błąd",
                                life: 3000,
                            });
                        }
                    });
            },

            closeDialog() {
                this.internalVisible = false;
                this.currentStep = 1;
            },

            getEmptyPost() {
                return {
                    title: "",
                    content: "",
                };
            },

            resetPostData() {
                this.postData = this.getEmptyPost();
            },

            getNow() {
                // eslint-disable-next-line no-extend-native
                Number.prototype.padLeft = function (base, chr) {
                    const len = (String(base || 10).length - String(this).length) + 1;
                    return len > 0 ? new Array(len).join(chr || "0") + this : this;
                };
                const today = new Date();
                const date = today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
                // eslint-disable-next-line max-len
                const time = today.getHours().padLeft() + ":" + today.getMinutes().padLeft() + ":" + today.getSeconds().padLeft();
                return date + " " + time;
            },

            getPolishStatisticData() {
                this.searchPolishPagesChartData.labels = [];
                this.searchPolishPagesChartData.datasets[0].data = [];
                let countPolishPages = 0;
                let countZeroAppearances = 0;
                let countOneAppearances = 0;
                let countManyAppearances = 0;
                // eslint-disable-next-line no-restricted-syntax
                for (const page of this.result) {
                    if (page.country === "polska") {
                        countPolishPages += 1;
                        if (page.appearances === 0) {
                            countZeroAppearances += 1;
                        } else if (page.appearances === 1) {
                            countOneAppearances += 1;
                        } else if (page.appearances > 1) {
                            countManyAppearances += 1;
                        }
                    }
                }
                this.percentageAppearancesOnPolishPages.zero = (countZeroAppearances * 100) / countPolishPages;
                this.percentageAppearancesOnPolishPages.one = (countOneAppearances * 100) / countPolishPages;
                this.percentageAppearancesOnPolishPages.many = (countManyAppearances * 100) / countPolishPages;

                // eslint-disable-next-line max-len
                this.searchPolishPagesChartOptions.title.text = "Wystąpienia frazy '" + this.word.polishContent + "' na polskich stronach";
                this.searchPolishPagesChartData.labels.push("Zero wystąpień");
                // eslint-disable-next-line max-len
                this.searchPolishPagesChartData.datasets[0].data.push(this.percentageAppearancesOnPolishPages.zero);
                this.searchPolishPagesChartData.labels.push("Jedno wystąpienie");
                // eslint-disable-next-line max-len
                this.searchPolishPagesChartData.datasets[0].data.push(this.percentageAppearancesOnPolishPages.one);
                this.searchPolishPagesChartData.labels.push("Wiele wystąpień");
                // eslint-disable-next-line max-len
                this.searchPolishPagesChartData.datasets[0].data.push(this.percentageAppearancesOnPolishPages.many);
            },

            getForeignStatisticData() {
                this.searchForeignPagesChartData.labels = [];
                this.searchForeignPagesChartData.datasets[0].data = [];
                this.searchComparisonPagesChartData.labels = [];
                this.searchComparisonPagesChartData.datasets[0].data = [];
                this.searchComparisonPagesChartData.datasets[1].data = [];
                let countForeignPages = 0;
                let countZeroAppearances = 0;
                let countOneAppearances = 0;
                let countManyAppearances = 0;
                // eslint-disable-next-line no-restricted-syntax
                for (const page of this.result) {
                    if (page.country === "zagranica") {
                        countForeignPages += 1;
                        if (page.appearances === 0) {
                            countZeroAppearances += 1;
                        } else if (page.appearances === 1) {
                            countOneAppearances += 1;
                        } else if (page.appearances > 1) {
                            countManyAppearances += 1;
                        }
                    }
                }
                this.percentageAppearancesOnForeignPages.zero = (countZeroAppearances * 100) / countForeignPages;
                this.percentageAppearancesOnForeignPages.one = (countOneAppearances * 100) / countForeignPages;
                this.percentageAppearancesOnForeignPages.many = (countManyAppearances * 100) / countForeignPages;

                // eslint-disable-next-line max-len
                this.searchForeignPagesChartOptions.title.text = "Wystąpienia frazy '" + this.word.foreignContent + "' na zagranicznych stronach";
                this.searchForeignPagesChartData.labels.push("Zero wystąpień");
                // eslint-disable-next-line max-len
                this.searchForeignPagesChartData.datasets[0].data.push(this.percentageAppearancesOnForeignPages.zero);
                this.searchForeignPagesChartData.labels.push("Jedno wystąpienie");
                // eslint-disable-next-line max-len
                this.searchForeignPagesChartData.datasets[0].data.push(this.percentageAppearancesOnForeignPages.one);
                this.searchForeignPagesChartData.labels.push("Wiele wystąpień");
                // eslint-disable-next-line max-len
                this.searchForeignPagesChartData.datasets[0].data.push(this.percentageAppearancesOnForeignPages.many);

                // eslint-disable-next-line max-len
                this.searchComparisonPagesChartOptions.title.text = "Porównanie przeszukiwania stron w języku polskim i angielskim";
                this.searchComparisonPagesChartData.labels.push("Zero wystąpień");
                this.searchComparisonPagesChartData.labels.push("Jedno wystąpienie");
                this.searchComparisonPagesChartData.labels.push("Wiele wystąpień");

                this.searchComparisonPagesChartData.datasets[0].data.push(this.percentageAppearancesOnPolishPages.zero);
                this.searchComparisonPagesChartData.datasets[0].data.push(this.percentageAppearancesOnPolishPages.one);
                this.searchComparisonPagesChartData.datasets[0].data.push(this.percentageAppearancesOnPolishPages.many);

                // eslint-disable-next-line max-len
                this.searchComparisonPagesChartData.datasets[1].data.push(this.percentageAppearancesOnForeignPages.zero);
                this.searchComparisonPagesChartData.datasets[1].data.push(this.percentageAppearancesOnForeignPages.one);
                // eslint-disable-next-line max-len
                this.searchComparisonPagesChartData.datasets[1].data.push(this.percentageAppearancesOnForeignPages.many);

                // eslint-disable-next-line max-len
                const sumOneAndManyPolish = this.percentageAppearancesOnPolishPages.one + this.percentageAppearancesOnPolishPages.many;
                // eslint-disable-next-line max-len
                const sumOneAndManyForeign = this.percentageAppearancesOnForeignPages.one + this.percentageAppearancesOnForeignPages.many;

                if (sumOneAndManyPolish > sumOneAndManyForeign) {
                    this.comparisonSummary = "Należy bliżej przyjrzeć się trendowi, możliwe, że jest to"
                        + " rozwiązanie typowe i ważne dla rynku polskiego.";
                } else {
                    this.comparisonSummary = "Należy bliżej przyjrzeć się trendowi, istnieje szansa na"
                        + " uzyskanie przewagi czasowej w jego wdrożeniu na rynku polskim.";
                }
            },
        },
    };
</script>

<style lang="less" scoped>
@import "src/views/assets/less/new-post-dialog.less";
</style>
