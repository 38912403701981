<template>
    <div class="registration-layout-wrapper">
        <header>
            <img src="@/assets/ue.png" alt="ue-logo" class="eu-logo">
        </header>
        <router-view/>
    </div>
</template>

<script>
    export default {
        name: "LayoutRegistration",

    };
</script>

<style lang="less">
    @import "../assets/less/registration.less";
</style>
