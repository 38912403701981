<template>
    <h1 class="page-header"><i class="pi pi-desktop header-icon"></i>Moje e-learningi</h1>

    <h4>
        Wszystkie E-Learningi
    </h4>

    <!--    TODO: komunikat gdy nie ma e-learningu-->
    <div v-if="isEmployer">
        <div class="p-grid">
            <div v-for="elearning in publicElearnings" :key="elearning" class="p-col-12 p-md-6 p-xl-4">
                <div class="box">
                    <div style="text-align: left; display: flex; justify-content:space-between">
                        <Button @click="redirectToElearning(elearning)">
                            {{ elearning.name }}
                        </Button>
                    </div>
                    <div style="display: flex; flex-direction: row; justify-content: space-between">
                        <div style="text-align: right; padding-right: 5px; padding-top: 10px;">
                            <span style="font-size:15px">{{elearning.competence}}</span>
                        </div>
                        <div v-if="elearning.department === 'PRODUCTION'"
                             style="text-align: right; padding-right: 5px; padding-top: 10px;">
                            <Badge value="Produkcja" style="background-color: #ff6161;"/>
                        </div>
                        <div v-else-if="elearning.department === 'TRANSPORT_LOGISTICS'"
                             style="text-align: right; padding-right: 5px; padding-top: 10px;">
                            <Badge value="Transport / logistyka" style="background-color: #ff6161;"/>
                        </div>
                        <div v-else-if="elearning.department === 'MARKETING_SALES'"
                             style="text-align: right; padding-right: 5px; padding-top: 10px;">
                            <Badge value="Marketing / sprzedaż" style="background-color: #ff6161;"/>
                        </div>
                        <div v-else-if="elearning.department === 'ADMINISTRATION'"
                             style="text-align: right; padding-right: 5px; padding-top: 10px;">
                            <Badge value="Administracja" style="background-color: #ff6161;"/>
                        </div>
                        <div v-else-if="elearning.department === 'CONSTRUCTION_DESIGNING'"
                             style="text-align: right; padding-right: 5px; padding-top: 10px;">
                            <Badge value="Dział konstrukcyjny / projektowy" style="background-color: #ff6161;"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div v-if="isWorker">
        <div class="p-grid">
            <div v-for="elearning in assignedElearnings" :key="elearning" class="p-col-12 p-md-6 p-xl-4">
                <div class="box">
                    <div style="text-align: left; display: flex; justify-content:space-between">
                        <Button @click="redirectToElearning(elearning)">
                            {{ elearning.name }}
                        </Button>
                        <Button v-if="elearning.quizScore >= 75" icon="pi pi-file"
                                class="p-button-rounded p-button-sm"
                                style="margin-right:5px" v-tooltip.top="'Pobierz dyplom'"
                                @click="downloadDiploma(elearning.id)"/>
                    </div>
                    <div style="display: flex; flex-direction: row; justify-content: space-between">
                        <div style="text-align: left; padding-right: 5px; padding-top: 10px;">
                            <span style="font-size:15px">{{elearning.competence}}</span>
                        </div>
                        <div v-if="elearning.quizId !== null">
                            <div v-if="elearning.quizScore >= 75"
                                 style="text-align: right; padding-right: 5px; padding-top: 10px;">
                                <Badge value="Zaliczono" icon="pi pi-check" severity="success"/>
                            </div>
                            <div v-else
                                 style="text-align: right; padding-right: 5px; padding-top: 10px;">
                                <Badge value="Do zaliczenia" severity="warning"/>
                            </div>
                            <div v-if="elearning.quizScore"
                                 style="text-align: right; padding-right: 5px; font-size: small; padding-top: 20px">
                                <span style="font-size:15px">Wynik: {{elearning.quizScore}} %</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <br/>

    <h4 v-if="isWorker">
        Udostępnione dla mnie
    </h4>

    <div v-if="isWorker">
        <div class="p-grid">
            <div v-for="elearning in assignedElearnings" :key="elearning" class="p-col-12 p-md-6 p-xl-4">
                <div class="box">
                    <div style="text-align: left; display: flex; justify-content:space-between">
                        <Button @click="redirectToElearning(elearning)">
                            {{ elearning.name }}
                        </Button>
                        <Button v-if="elearning.quizScore >= 75" icon="pi pi-file"
                                class="p-button-rounded p-button-sm"
                                style="margin-right:5px" v-tooltip.top="'Pobierz dyplom'"
                                @click="downloadDiploma(elearning.id)"/>
                    </div>
                    <div style="display: flex; flex-direction: row; justify-content: space-between">
                        <div style="text-align: left; padding-right: 5px; padding-top: 10px;">
                            <span style="font-size:15px">{{elearning.competence}}</span>
                        </div>
                        <div v-if="elearning.quizId !== null">
                            <div v-if="elearning.quizScore >= 75"
                                 style="text-align: right; padding-right: 5px; padding-top: 10px;">
                                <Badge value="Zaliczono" icon="pi pi-check" severity="success"/>
                            </div>
                            <div v-else
                                 style="text-align: right; padding-right: 5px; padding-top: 10px;">
                                <Badge value="Do zaliczenia" severity="warning"/>
                            </div>
                            <div v-if="elearning.quizScore"
                                 style="text-align: right; padding-right: 5px; font-size: small; padding-top: 20px">
                                <span style="font-size:15px">Wynik: {{elearning.quizScore}} %</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import Button from "primevue/button";
    import Badge from "primevue/badge";
    import Tooltip from "primevue/tooltip";
    import {getDiplomaForUserUsingGETURL, publicELearningsUsingGET, assignedELearningsUsingGET}
        from "@/swagger/vue-api-client";
    import {FileUtils} from "@/utils/FileUtils";
    import {SystemRole} from "@/utils/SystemRole";

    export default {
        name: "MyElearningMainView",

        directives: {
            tooltip: Tooltip,
        },
        mixins: [FileUtils, SystemRole],

        data() {
            return {
                publicElearnings: {},
                assignedElearnings: {},
            };
        },

        components: {
            Button, Badge,
        },

        methods: {
            redirectToElearning(data) {
                this.$router.push({
                    name: "ElearningStep",
                    params: {id: data.id, stepId: data.step ? data.step : 1},
                });
            },

            async downloadDiploma(id) {
                await this.downloadFileAsBlob(this, getDiplomaForUserUsingGETURL({elearningId: id}), "dyplom.pdf");
            },

            getPublicElearnings() {
                publicELearningsUsingGET().then((response) => {
                    this.publicElearnings = response.data;
                });
            },

            getAssignedElearnings() {
                assignedELearningsUsingGET().then((response) => {
                    this.assignedElearnings = response.data;
                });
            },
        },

        mounted() {
            //    TODO: pobieranie wszystkich
            if (this.isEmployer) {
                this.getPublicElearnings();
            } else if (this.isWorker) {
                this.getAssignedElearnings();
            }
        },

        competenceStyle(val) {
            switch (val) {
                case "PRODUCTION":
                    return "red";
                default:
                    return null;
            }
        },

        computed: {
            isWorker() {
                return SystemRole.isWorker();
            },
            isEmployer() {
                return SystemRole.isEmployer();
            },
        },
    };
</script>

<style lang="less" scoped>

@import "../../assets/theme/variable.less";

.box {
    background-color:@lighter-gray;
    padding: 1rem;
    border-radius: 4px;
    margin: 20px;
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 1px 3px 0 rgba(0, 0, 0, .12);
    min-height: 120px;
}

</style>
