/* eslint-disable */
export const FileUtils = {

    methods: {
        async fileToBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
            });
        },
        async downloadFileAsBlob(ctx, url, fileName) {
            await ctx.$http.get(url, {responseType: 'arraybuffer'}).then(
                response => {
                    let blob = new Blob([response.data], {type: response.headers['content-type']});
                    if (!!window.MSInputMethodContext && !!document.documentMode) {
                        window.navigator.msSaveOrOpenBlob(blob, fileName);
                    } else {
                        let link = document.createElement('a');
                        link.href = window.URL.createObjectURL(blob);
                        link.download = fileName;
                        link.click();
                    }
                },
                error => {
                    if (error.status === 403) ctx.$router.push("/forbidden");
                }
            );
        },
    }
};
