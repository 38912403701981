<template>
    <div style="margin: auto;">
        <div>
            <h1 class="page-header">
                <i class="pi pi-percentage header-icon"></i>
                Dane podmiotu niezbędne do kalkulacji kosztów i
                korzyści wdrożenia procesu reskillingu
                działu {{currentDepartment.name}}</h1>

        </div>
        <div v-if="!loaded">
            <ProgressSpinner/>
        </div>

        <div v-else style="margin: auto;">
            <div class="p-grid" style="margin: auto; width: 1000px">
                <div class="p-col">
                    <div class="box" style="padding:20px">
                        <Chart type="horizontalBar" :data="firstChartData"
                               :options="firstChartOptions"  width="1000" height="500"/>
                    </div>
                </div>
                <div class="p-col" style="margin: auto; width: 1000px">
                    <div class="box" style="padding:20px">
                        <Chart type="pie" :data="secondChartData"
                               :options="secondChartOptions"  width="1000" height="500"/>
                    </div>
                </div>
                <div class="p-col" style="margin: auto; width: 1000px">
                    <div class="box" style="padding:20px">
                        <Chart type="line" :data="thirdChartData"
                               :options="thirdChartOptions"  width="1000" height="500"/>
                    </div>
                </div>
            </div>

            <table class="tableizer-table" style="margin: auto;">
                <thead>
                    <tr style="height: 70px">
                        <th colspan="4" scope="colgroup"></th>
                        <th colspan="10" scope="colgroup" style="background-color: #92D050">
                            {{ results.columnGroupOneHeader }}
                        </th>
                        <th colspan="2" scope="colgroup" style="background-color: #F4B183">
                            {{ results.columnGroupTwoHeader }}
                        </th>
                    </tr>
                    <tr>
                        <th v-for="(column, index) in results.columns.slice(0,16)"
                            :key="index" :style="headerStyle(index)">
                            {{ column }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(row, index) in results.rows" :key="index">
                        <td v-for="(cell, index) in row.slice(0,16)" :key="index">
                            <span>{{ formatAsMoneyString(cell, index) }}</span>
                        </td>
                    </tr>
                </tbody>

            </table>
        </div>

        <div style="padding-bottom: 20px; padding-top: 20px;">
            <span class="no-print">
                <Button @click="goBack()" class="p-button"
                        style="padding: 20px; margin-right: 40px;">
                    <i class="pi pi-angle-double-left" style="padding-right: 10px;"></i>Powrót do poprzedniej strony
                </Button>
                <Button @click="print()" class="p-button-danger"
                        style="padding: 20px">
                    <i class="pi pi-print" style="padding-right: 10px;"></i>Drukuj</Button>
            </span>
        </div>
    </div>
</template>

<script>
    import {Departments} from "@/utils/Departments";
    import ProgressSpinner from "primevue/progressspinner";
    import {
        getEfficiencyCalculationsResultsUsingGET as getEfficiencyCalculationsResults,
    } from "@/swagger/vue-api-client";
    import Button from "primevue/button";
    import Tooltip from "primevue/tooltip";
    import ToggleButton from "primevue/togglebutton";
    import Chart from "primevue/chart";

    export default {
        name: "EfficiencyDepartmentMainResultsView",
        components: {
            // eslint-disable-next-line vue/no-unused-components
            ProgressSpinner, Button, Tooltip, ToggleButton, Chart,
        },

        data() {
            return {
                loaded: false,
                currentDepartment: null,
                notReady: false,
                inProgress: false,
                showWorkers: false,
                showRows: true,
                searchCriteria: this.getClearSearchCriteria(),
                results: {},
                firstChartData: {
                    labels: [],
                    datasets: [
                        {
                            backgroundColor: "#3D6FC9",
                            data: [],
                        },
                    ],
                },
                firstChartOptions: {
                    legend: {
                        display: false,
                    },
                    responsive: false,
                    scales: {
                        xAxes: [{
                            /* For changing color of x-axis coordinates */
                            ticks: {
                                fontSize: 16,
                                fontColor: "#495057",
                            },
                        }],
                        yAxes: [{
                            /* For changing color of x-axis coordinates */
                            ticks: {
                                fontSize: 16,
                                fontColor: "#495057",
                            },
                        }],
                    },
                },
                secondChartData: {
                    labels: [],
                    datasets: [
                        {
                            data: [],
                            backgroundColor: ["#3D6FC9", "#A4A4A4", "#549ADA", "#21427C", "#636363"],
                            hoverBackgroundColor: ["#6082CA", "#AEAEAE", "#71A6DA", "#506088", "#767676"],
                        },
                    ],
                },
                secondChartOptions: {
                    responsive: false,
                    legend: {
                        labels: {
                            fontSize: 16,
                        },
                    },
                },
                thirdChartData: {
                    labels: [],
                    datasets: [
                        {
                            label: "WYNIK",
                            fill: false,
                            borderColor: "#3D6FC9",
                            data: [],
                        },
                        {
                            label: "WYNIK (z uwzg. efektu synergii)",
                            fill: false,
                            borderColor: "#A4A4A4",
                            data: [],
                        },
                        {
                            label: "SUMA korzyści",
                            fill: false,
                            borderColor: "#549ADA",
                            data: [],
                        },
                        {
                            label: "SUMA kosztów",
                            fill: false,
                            borderColor: "#21427C",
                            data: [],
                        },
                        {
                            label: "SUMA kosztów (z uwzg. efektu synergii)",
                            fill: false,
                            borderColor: "#636363",
                            data: [],
                        },
                    ],
                },
                thirdChartOptions: {
                    responsive: false,
                    legend: {
                        labels: {
                            fontSize: 16,
                        },
                    },
                    scales: {
                        xAxes: [{
                            /* For changing color of x-axis coordinates */
                            ticks: {
                                fontSize: 16,
                                fontColor: "#495057",
                            },
                        }],
                        yAxes: [{
                            /* For changing color of x-axis coordinates */
                            ticks: {
                                fontSize: 16,
                                fontColor: "#495057",
                            },
                        }],
                    },
                },
            };
        },

        directives: {
            tooltip: Tooltip,
        },

        mounted() {
            this.getResults();
        },

        beforeMount() {
            if (this.$route.params.department && Departments.getDepartmentByUrlParam(this.$route.params.department)) {
                this.setCurrentDepartment();
            } else {
                this.$router.push({name: "notFound"});
            }
        },

        watch: {
            "$route.params.department": function (newVal, oldVal) {
                if (newVal !== oldVal) {
                    this.setCurrentDepartment();
                    this.getResults();
                }
            },
        },

        methods: {

            setCurrentDepartment() {
                this.currentDepartment = Departments.getDepartmentByUrlParam(this.$route.params.department);
            },

            getClearSearchCriteria() {
                return {
                    department: Departments.getDepartmentDiscriminatorByUrlParam(this.$route.params.department),
                };
            },

            async getResults() {
                // eslint-disable-next-line max-len
                await getEfficiencyCalculationsResults({department: this.currentDepartment.discriminator}).then((response) => {
                    this.results = response.data;
                    this.firstChartData.labels = response.data.charts[0].labels;
                    // eslint-disable-next-line prefer-destructuring
                    this.firstChartData.datasets[0].data = response.data.charts[0].data[0];

                    this.secondChartData.labels = response.data.charts[1].labels;
                    // eslint-disable-next-line prefer-destructuring
                    this.secondChartData.datasets[0].data = response.data.charts[1].data[0];

                    this.thirdChartData.labels = response.data.charts[2].labels;
                    // eslint-disable-next-line prefer-destructuring
                    this.thirdChartData.datasets[0].data = response.data.charts[2].data[0];
                    // eslint-disable-next-line prefer-destructuring
                    this.thirdChartData.datasets[1].data = response.data.charts[2].data[1];
                    // eslint-disable-next-line prefer-destructuring
                    this.thirdChartData.datasets[2].data = response.data.charts[2].data[2];
                    // eslint-disable-next-line prefer-destructuring
                    this.thirdChartData.datasets[3].data = response.data.charts[2].data[3];
                    // eslint-disable-next-line prefer-destructuring
                    this.thirdChartData.datasets[4].data = response.data.charts[2].data[4];
                    this.loaded = true;
                });
            },

            headerStyle(val) {
                if (val <= 1) {
                    return "background-color: #D9D9D9";
                }
                if (val <= 3) {
                    return "background-color: #9DC3E6";
                }
                if (val <= 5) {
                    return "background-color: #A9D18E";
                }
                if (val <= 8) {
                    return "background-color: #D9D9D9";
                }
                if (val === 9) {
                    return "background-color: #A9D18E";
                }
                if (val <= 13) {
                    return "background-color: #D9D9D9";
                }
                if (val <= 15) {
                    return "background-color: #F4B183";
                } return "background-color: #D9D9D9";
            },

            formatAsMoneyString(val, index) {
                if (!val) {
                    return "";
                } if (index <= 1) {
                    return val;
                }
                // eslint-disable-next-line max-len
                return parseFloat(val).toLocaleString("pl-PL", {style: "currency", currency: "PLN", minimumFractionDigits: 2});
            },

            goBack() {
                window.history.back();
            },

            print() {
                window.print();
            },
        },

        computed: {
            filteredRows() {
                return this.results.rows.filter((r) => r[0] === r[0].toUpperCase());
            },
        },
    };
</script>
<style scoped>
@media print {
    .no-print, .no-print *
    {
        display: none !important;
    }

    .tableizer-table th {
        border: 1px solid black !important;
    }
    .tableizer-table td {
        border-bottom: 1px solid black !important;
        border-left: 1px dashed black !important;
        border-right: 1px dashed black !important;
    }
}
    table.tableizer-table {
        font-size: 1rem;
        padding-right: 30px;
    }
    .tableizer-table td {
        padding: 0.5rem 0.8rem;
        margin: 10px;
        border-bottom: 1px solid #CCC;
        border-left: 1px dashed #CCC;
        border-right: 1px dashed #CCC;
    }
    .tableizer-table th {
        color: #495057;
        padding: 1rem;
        font-weight: bold;
        border: 1px solid #ffffff;
    }
</style>
