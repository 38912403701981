<template>
    <div>
        <h1 class="page-header"><i class="pi pi-copy header-icon"></i>Moje oceny </h1>

        <div>
            <DataTable ref="userTable" :value="users" :lazy="true" :paginator="true"
                       v-model:rows="searchParams.page.limit" :rowClass="rowClass"
                       removableSort :totalRecords="totalRecords" :loading="loading" @page="onPage"
                       @sort="onPage" :rowsPerPageOptions="[5,10,20,50]">
                <Column field="workerFullName" header="Oceniany pracownik" headerStyle="text-align: left"/>
                <Column field="department" header="Dział" headerStyle="text-align: left">
                    <template #body="{data}">
                        {{getDepartmentName(data.department)}}
                    </template>
                </Column>
                <Column field="evaluationType" header="Typ oceny" style="text-align: center">
                    <template #body="{data}">
                        <EvaluationTypeBadge :type="data.evaluationType"/>
                    </template>
                </Column>
                <Column field="evaluationStatus" header="Status oceny" style="text-align: center">
                    <template #body="{data}">
                        <EvaluationStatusBadge :status="data.evaluationStatus"/>
                    </template>
                </Column>
                <Column header="Akcje" style="text-align: center"  headerStyle="width: 60px; text-align: center">
                    <template #body="{data}">
                        <span>
                            <Button v-if="data.evaluationStatus === 'SENT'"
                                    type="button"
                                    icon="pi pi-eye"
                                    class="p-button-danger p-ml-2 p-button-rounded p-button-outlined"
                                    @click="redirectToSurvey(data.id, data.evaluationCode)"
                                    v-tooltip.top="'Zobacz ankietę'">
                            </Button>
                            <Button v-else
                                    type="button"
                                    icon="pi pi-pencil"
                                    class="p-button-help p-ml-2 p-button-rounded p-button-outlined"
                                    @click="redirectToSurvey(data.id, data.evaluationCode)"
                                    v-tooltip.top="'Wypełnij ankietę'">
                            </Button>
                        </span>
                    </template>
                </Column>
                <template #empty>
                    Nie masz żadnych ocen do uzupełnienia
                </template>
            </DataTable>
        </div>
    </div>
</template>

<script>
    import {SystemRole} from "@/utils/SystemRole";
    import {Departments} from "@/utils/Departments";
    import {
        searchMyEvaluationsCountUsingPOST as countEvaluations,
        searchMyEvaluationsUsingPOST as searchEvaluations,
    } from "@/swagger/vue-api-client";
    import DataTable from "primevue/datatable";
    import Column from "primevue/column";
    import {Evaluations} from "@/utils/Evaluations";
    import Button from "primevue/button";
    import Tooltip from "primevue/tooltip";
    import EvaluationStatusBadge from "@/components/EvaluationStatusBadge";
    import EvaluationTypeBadge from "@/components/EvaluationTypeBadge";

    export default {
        name: "MyEvaluationsView",

        components: {
            EvaluationTypeBadge,
            EvaluationStatusBadge,
            DataTable,
            Column,
            Button,
        },

        directives: {
            tooltip: Tooltip,
        },

        data() {
            return {
                loading: false,
                totalRecords: 0,
                users: [],
                Role: SystemRole,
                searchParams: {
                    page: {},
                },
            };
        },

        methods: {
            search() {
                this.onPage(this.getFirstPage());
                this.updateTotalRecords();
            },

            onPage(event) {
                this.loading = true;

                this.searchParams.page.offset = event.first;
                this.searchParams.page.limit = event.rows;
                this.searchParams.page.sortField = "creationDate";
                this.searchParams.page.sortOrder = -1;

                searchEvaluations({searchCriteria: this.searchParams}).then((response) => {
                    this.users = response.data;
                    this.loading = false;
                });
            },

            updateTotalRecords() {
                countEvaluations({searchCriteria: this.searchParams}).then((response) => {
                    this.totalRecords = response.data;
                });
            },

            getFirstPage() {
                return {
                    first: 0,
                    rows: 10,
                };
            },

            getDepartmentName(discriminator) {
                return Departments.getDepartmentByDiscriminator(discriminator).name;
            },

            getEvaluationTypeText(type) {
                return Evaluations.getEvaluationNameByDiscriminator(type);
            },

            getEvaluationTypeClass(type) {
                return Evaluations.getEvaluationTypeClass(type);
            },

            redirectToSurvey(id, evaluationCode) {
                this.$router.push({
                    name: "survey",
                    params: {id},
                    query: {code: evaluationCode},
                });
            },

            rowClass(data) {
                return data.evaluationStatus !== "SENT" ? "not-sent" : null;
            },

        },

        mounted() {
            this.updateTotalRecords();
            this.onPage(this.getFirstPage());
        },

    };
</script>

<style lang="less" scoped>
    @import "src/views/assets/less/surveys-view.less";
</style>
