<template>
    <div class="p-fluid custom-input">
        <Button @click="$refs.file.click()" icon="pi pi-file"
                :class="computedClasses" :name="name" :id="name"
                :label="getLabel">
        </Button>
        <span v-show="errorMessage" class="p-invalid">{{ errorMessage }}</span>
        <input type="file" ref="file" v-show="false" v-on:change="handleFile">
    </div>
</template>

<script>
    import {useField} from "vee-validate";
    import Button from "primevue/button";

    export default {
        name: "CustomFileUpload",
        props: {
            name: {
                type: String,
            },
            label: {
                type: String,
            },
            modelValue: null,
            class: {
                type: String,
            },
            icon: {
                type: String,
                default: null,
                required: false,
            },
            rules: undefined,
        },
        emits: ["update:modelValue"],

        watch: {
            modelValue(val) {
                this.handleChange(val);
            },
        },

        computed: {
            internalValue: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },

            },

            getLabel() {
                if (this.internalValue) {
                    if (this.internalValue.name) {
                        return this.internalValue.name;
                    }
                    return this.internalValue;
                }
                return this.$t("message.components.chooseFile");
            },

            computedClasses() {
                const classes = this.class ? this.class : "";
                return classes + (this.errorMessage ? "p-invalid" : "");
            },

        },

        methods: {
            async handleFile() {
                const file = this.$refs.file.files[0];
                if (file !== undefined) {
                    // eslint-disable-next-line prefer-destructuring
                    this.internalValue = file;
                    this.handleChange(file);
                } else {
                    this.internalValue = undefined;
                    this.handleChange(undefined);
                }
            },
        },

        components: {Button},

        setup(props) {
            const {
                value: inputValue,
                errorMessage,
                handleBlur,
                handleChange,
                meta,
            } = useField(props.name, props.rules, {
                initialValue: props.modelValue,
            });

            return {
                handleChange,
                handleBlur,
                errorMessage,
                inputValue,
                meta,
            };
        },
    };
</script>

<style scoped>
.custom-input {
    margin-bottom: 20px;
}
</style>
