<template>
    <Dialog :header="editing ? 'Edytuj materiał' : 'Dodaj materiał'" v-model:visible="internalVisible"
            :breakpoints="{'1200px': '60vw', '960px': '75vw', '640px': '100vw'}"
            :style="{width: '40vw'}">
        <Form @submit="addOrEditFile" v-slot="{ isSubmitting }">
            <div class="p-grid form-wrapper">
                <div class="p-col-12">
                    <CustomInputText name="filename" v-model="fileData.name"
                                     :label="$t('message.global.filename')"
                                     icon="pi pi-book"
                                     rules="required"/>
                    <CustomInputTextArea name="description" v-model="fileData.description"
                                         :label="$t('message.global.description')"
                                         rules="required"/>
                    <CustomFileUpload name="file" v-model="file" :rules="editing? '' : 'requiredFile'"
                                      :label="$t('message.global.file')"/>
                    <div v-if="editing" class="p-mb-3">Aby nie zmieniać pliku nie dodawaj nowego.</div>
                </div>
            </div>
            <div class="button-div">
                <Button label="Anuluj" icon="pi pi-times" class="p-button-text"
                        @click="closeDialog" :disabled="isSubmitting"/>
                <Button label="Zapisz" icon="pi pi-chevron-right"
                        type="submit" :disabled="isSubmitting" />
            </div>

        </Form>
    </Dialog>
</template>

<script>
    import Dialog from "primevue/dialog";
    import Button from "primevue/button";
    import CustomInputText from "@/components/form/CustomInputText";
    import {Form} from "vee-validate";
    import {
        updateEmployerFileUsingPUT as updateFile,
        getEmployerFileInfoUsingGET as getFileInfo,
        uploadEmployerFileUsingPOST as uploadFile,
    } from "@/swagger/vue-api-client";
    import CustomInputTextArea from "@/components/form/CustomInputTextArea";
    import CustomFileUpload from "@/components/form/CustomFileUpload";
    import {FileUtils} from "@/utils/FileUtils";

    export default {
        name: "FileFormDialog",
        components: {
            CustomFileUpload,
            CustomInputTextArea,
            Dialog,
            Button,
            CustomInputText,
            Form,
        },
        mixins: [FileUtils],
        emits: ["update:visible", "file-added"],

        props: {
            visible: {
                type: Boolean,
                required: true,
            },
            editing: {
                type: Boolean,
                required: true,
            },
            editedId: {
                type: Number,
                required: false,
            },
        },

        data() {
            return {
                file: undefined,
                fileData: this.getEmptyFile(),
            };
        },

        watch: {
            editedId() {
                this.getFileInfo();
            },
        },

        computed: {
            internalVisible: {
                get() {
                    return this.visible;
                },
                set(value) {
                    this.$emit("update:visible", value);
                },

            },
        },

        methods: {
            getFileInfo() {
                getFileInfo({id: this.editedId}).then((result) => {
                    this.fileData = result.data;
                    this.file = result.data.filename;
                }).catch((error) => {
                    if (error.response.status === 403) {
                        this.$toast.add({
                            severity: "error",
                            summary: "Błąd",
                            detail: "Nie masz wystarczających uprawnień",
                            life: 3000,
                        });
                    } else {
                        this.$toast.add({
                            severity: "error",
                            summary: "Błąd",
                            detail: "Wystąpił nieoczekiwany błąd",
                            life: 3000,
                        });
                    }
                });
            },

            getEmptyFile() {
                return {
                    name: "",
                    filename: "",
                    description: "",
                    encodedData: "",
                };
            },
            async addOrEditFile() {
                if (this.editing) {
                    await this.editFile();
                } else {
                    await this.addFile();
                }
            },

            async editFile() {
                // jeśli this.file posiada atrybut name to znaczy, że ktoś podmienił plik
                if (this.file.name) {
                    this.fileData.filename = this.file.name;
                    this.fileData.encodedData = await this.fileToBase64(this.file);
                }
                await Promise.resolve(updateFile({fileDto: this.fileData})).then(() => {
                    this.$emit("file-added");
                    this.internalVisible = false;
                    this.file = undefined;
                    this.fileData = this.getEmptyFile();
                }).catch((error) => {
                    if (error.response.status === 403) {
                        this.$toast.add({
                            severity: "error",
                            summary: "Błąd",
                            detail: "Nie masz wystarczających uprawnień",
                            life: 3000,
                        });
                    } else {
                        this.$toast.add({
                            severity: "error",
                            summary: "Błąd",
                            detail: "Wystąpił nieoczekiwany błąd",
                            life: 3000,
                        });
                    }
                });
            },

            async addFile() {
                this.fileData.filename = this.file.name;
                this.fileData.encodedData = await this.fileToBase64(this.file);
                await Promise.resolve(uploadFile({fileDto: this.fileData})).then(() => {
                    this.$emit("file-added");
                    this.internalVisible = false;
                    this.file = undefined;
                    this.fileData = this.getEmptyFile();
                }).catch((error) => {
                    if (error.response.status === 403) {
                        this.$toast.add({
                            severity: "error",
                            summary: "Błąd",
                            detail: "Nie masz wystarczających uprawnień",
                            life: 3000,
                        });
                    } else {
                        this.$toast.add({
                            severity: "error",
                            summary: "Błąd",
                            detail: "Wystąpił nieoczekiwany błąd",
                            life: 3000,
                        });
                    }
                });
            },

            closeDialog() {
                this.internalVisible = false;
                this.file = undefined;
                this.fileData = this.getEmptyFile();
            },

        },
    };
</script>

<style lang="less" scoped>
@import "src/views/assets/less/new-worker-dialog.less";
</style>
