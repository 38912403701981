<template>
    <Dialog :header="header" v-model:visible="internalVisible"
            :breakpoints="{'1200px': '60vw', '960px': '75vw', '640px': '100vw'}"
            :style="{width: '30vw'}">
        <Form @submit="selectWorker">
            <Listbox v-model="selectedWorker" :options="workers" :optionLabel="getWorkerFullName"
                     :filter="true" listStyle="max-height:250px" emptyFilterMessage="Nie znaleziono pracowników"/>
            <div class="button-div">
                <Button label="Potwierdź" icon="pi pi-check" type="submit" style="margin-top:20px; float:right"/>
            </div>
        </Form>
    </Dialog>
</template>

<script>
    import Listbox from "primevue/listbox";
    import Dialog from "primevue/dialog";
    import Button from "primevue/button";
    import {Form} from "vee-validate";

    export default {
        name: "AddAssessorDialog",
        components: {
            Listbox, Dialog, Button, Form,
        },
        props: {
            visible: {
                type: Boolean,
                required: true,
            },
            workers: null,
        },
        emits: ["update:visible", "assessor-added"],
        computed: {
            internalVisible: {
                get() {
                    return this.visible;
                },
                set(value) {
                    this.$emit("update:visible", value);
                },

            },
        },

        data() {
            return {
                selectedWorker: null,
                header: "Wybierz współpracownika",
            };
        },
        methods: {
            getWorkerFullName(worker) {
                return worker.firstName + " " + worker.lastName;
            },
            selectWorker() {
                this.$emit("assessor-added", this.selectedWorker.id);
                this.internalVisible = false;
                this.selectedWorker = null;
            },
        },
    };
</script>

<style scoped>

</style>
