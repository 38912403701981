<template>
    <div class="card">
        <h4>Poziom luk kompetencyjnych</h4>
        <Chart type="horizontalBar" :data="stackedChartData" :options="stackedChartOptions"
               :key="stackedChartData.labels" :height="100" style="font-style: italic"/>
    </div>
</template>

<script>
    import Chart from "primevue/chart";
    import {
        getCompetenceDepartmentsUsingGET as getCompetenceDepartments,
    } from "@/swagger/vue-api-client";
    import {Departments} from "@/utils/Departments";

    export default {
        name: "CompetenceDepartmentChart",
        components: {
            Chart,
        },

        data() {
            return {
                competences: [],
                department: Departments.getDepartmentDiscriminatorByUrlParam(this.$route.params.department),
                stackedChartData: {
                    labels: [],
                    datasets: [{
                        type: "horizontalBar",
                        label: "Średni poziom kompetencji",
                        backgroundColor: "#2E9FA2",
                        data: [],
                    }, {
                        type: "horizontalBar",
                        label: "Średni poziom luki kompetencyjnej",
                        backgroundColor: "#84DADC",
                        data: [],
                    }],
                },
                stackedChartOptions: {
                    tooltips: {
                        mode: "index",
                        intersect: false,
                        bodyFontSize: 16,
                        titleFontSize: 16,
                    },
                    legend: {
                        labels: {
                            fontSize: 16,
                        },
                    },
                    responsive: true,
                    scales: {
                        xAxes: [{
                            stacked: true,
                            ticks: {
                                fontSize: 16,
                            },
                        }],
                        yAxes: [{
                            stacked: true,
                            ticks: {
                                fontSize: 16,
                            },
                        }],
                    },
                },
            };
        },

        methods: {
            getCompetenceDepartments() {
                getCompetenceDepartments({department: this.department}).then((response) => {
                    this.competences = response.data;
                    this.getDataForCharts();
                });
            },
            getDataForCharts() {
                this.competences.forEach((entry) => {
                    // eslint-disable-next-line max-len
                    this.stackedChartData.datasets[0].data = this.stackedChartData.datasets[0].data.concat(entry.averageCompetencyLevel / 100);
                    // eslint-disable-next-line max-len
                    this.stackedChartData.datasets[1].data = this.stackedChartData.datasets[1].data.concat(entry.averageCompetencyLevelGap / 100);
                    this.stackedChartData.labels = this.stackedChartData.labels.concat(entry.competence);
                });
            },
        },

        mounted() {
            this.getCompetenceDepartments();
        },
    };
</script>

<style scoped>

</style>
