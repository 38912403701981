<template>
    <div>
        <DataTable ref="surveysTable" :value="surveys" :lazy="true" :paginator="true"
                   v-model:rows="searchParams.page.limit"
                   removableSort :totalRecords="totalRecords" :loading="loading" @page="onPage"
                   @sort="onPage" :rowsPerPageOptions="[5,10,20,50]" :autoLayout="true"
                   :rowHover="true">
            <template #empty>
                Nie znaleziono ankiet
            </template>
            <template #loading>
                Trwa wczytywanie ankiet. Proszę czekać
            </template>
            <Column field="workerFullName" header="Pracownik" :sortable="true"></Column>
            <Column field="workerEmail" header="Email"></Column>
            <Column field="department" header="Dział" :sortable="true">
                <template #body="{data}">
                    {{getDepartmentNameText(data.department)}}
                </template>
            </Column>
            <Column field="evaluationType" header="Typ oceny"
                    style="width: 220px; text-align: center" :sortable="true">
                <template #body="{data}">
                    <EvaluationTypeBadge :type="data.evaluationType"/>
                </template>
            </Column>
            <Column field="lastModificationDate" header="Data przesłania ankiety"
                    style="text-align: center" :sortable="true">
                <template #body="{data}">
                    {{prepareDateAndTime(data.lastModificationDate)}}
                </template>
            </Column>
            <Column header= "Akcje" headerStyle="width: 130px" style="text-align: center">
                <template #body="{data}">
                    <span>
                        <Button
                            type="button"
                            icon="pi pi-eye" class="p-button-danger p-ml-2 p-button-rounded p-button-outlined"
                            @click="redirectToSurvey(data.id, data.evaluationCode)"
                            v-tooltip.top="'Zobacz ankietę'">
                        </Button>
                    </span>
                </template>
            </Column>
        </DataTable>
    </div>
</template>

<script>
    import DataTable from "primevue/datatable";
    import Column from "primevue/column";
    import Button from "primevue/button";
    import Tooltip from "primevue/tooltip";
    import {Evaluations} from "@/utils/Evaluations";
    import {Departments} from "@/utils/Departments";
    import {
        searchEvaluationCountUsingPOST as searchEvaluationCount,
        searchEvaluationUsingPOST as searchEvaluation,
    } from "@/swagger/vue-api-client";
    import EvaluationTypeBadge from "@/components/EvaluationTypeBadge";

    export default {
        name: "SurveysTable",
        components: {
            EvaluationTypeBadge,
            DataTable,
            Column,
            Button,
        },

        directives: {
            tooltip: Tooltip,
        },

        props: {
            searchCriteria: {
                type: Object,
            },
        },

        data() {
            return {
                searchParams: this.searchCriteria,
                loading: false,
                totalRecords: 0,
                surveys: [],
            };
        },

        methods: {
            onPage(event) {
                this.loading = true;

                this.searchParams.page.offset = event.first;
                this.searchParams.page.limit = event.rows;
                this.searchParams.page.sortField = event.sortField;
                this.searchParams.page.sortOrder = event.sortOrder;

                this.search();
            },

            search() {
                searchEvaluation({searchCriteria: this.searchCriteria}).then((response) => {
                    this.surveys = response.data;
                    this.loading = false;
                });
            },

            updateTotalRecords() {
                searchEvaluationCount({searchCriteria: this.searchCriteria}).then((response) => {
                    this.totalRecords = response.data;
                });
            },

            getFirstPage() {
                return {
                    first: this.searchCriteria.page.offset,
                    rows: this.searchCriteria.page.limit,
                    sortField: this.searchCriteria.page.sortField,
                };
            },

            getEvaluationTypeText(type) {
                return Evaluations.getEvaluationNameByDiscriminator(type);
            },

            getEvaluationTypeClass(type) {
                return Evaluations.getEvaluationTypeClass(type);
            },

            getDepartmentNameText(discriminator) {
                return Departments.getDepartmentNameByDiscriminator(discriminator);
            },

            prepareDateAndTime(lastModificationDate) {
                lastModificationDate = lastModificationDate.replace("T", " ");
                const index = lastModificationDate.lastIndexOf(".");
                return lastModificationDate.substr(0, index);
            },

            redirectToSurvey(id, evaluationCode) {
                this.$router.push({
                    name: "survey",
                    params: {id},
                    query: {code: evaluationCode},
                });
            },
        },

        mounted() {
            this.updateTotalRecords();
            this.onPage(this.getFirstPage());
        },
    };
</script>

<style scoped>
</style>
