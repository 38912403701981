<template>
    <div v-if="data" class="background">
        <div>
            <h2 style="border-bottom: 1px solid #eee; margin-bottom: 30px; padding-bottom: 12px; text-align: right;">
                {{ data.title }}
            </h2>

            <div v-if="data.contentText" style="max-width: 840px; margin:auto; margin-bottom: 50px"
                 v-html="data.contentText"/>

            <div v-else-if="data.file">
                <Button :label="data.file.filename" icon="pi pi-download"
                        style="margin-bottom: 50px"
                        @click="downloadFile(data.file)"/>
            </div>

            <div v-if="data.description !== null"
                 style="text-align: center; display: inline-block;">
                <div class="description wrap-spaces">
                    {{ data.description }}
                </div>
            </div>

        </div>
        <div class="nav-buttons">
            <div
                style="margin: auto; display: flex; justify-content: space-between; max-width: 500px">
                <div>
                    <Button v-if="data.stepNumber > 1" icon="pi pi-backward" iconPos="left"
                            label="Wstecz" @click="goPrevious"/>
                </div>
                <div>
                    <Button v-if="data.lastStep === false" icon="pi pi-forward" iconPos="right"
                            label="Dalej" @click="goForward"/>
                    <Button v-else-if="data.lastStep === true && isWorker && data.quizId !== null" icon="pi pi-forward"
                            iconPos="right"
                            label="Przejdź do quizu" @click="goToQuiz"/>
                    <Button v-else-if="(data.lastStep === true && isEmployer) ||
                                data.quizId === null" icon="pi pi-desktop"
                            iconPos="right"
                            label="Wróć do listy e-learningów" @click="goToList"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Button from "primevue/button";
    import {downloadElearningFileUsingGETURL} from "@/swagger/vue-api-client";
    import {FileUtils} from "@/utils/FileUtils";
    import {SystemRole} from "@/utils/SystemRole";

    export default {
        name: "ElearingStepView",

        mixins: [FileUtils, SystemRole],

        components: {
            Button,
        },

        emits: [
            "prev", "next",
        ],

        props: {
            data: {},
        },

        methods: {
            goForward() {
                const nextPageNumber = this.data.stepNumber + 1;
                this.$emit("next", nextPageNumber);
            },

            goPrevious() {
                const prevPageNumber = this.data.stepNumber - 1;
                this.$emit("prev", prevPageNumber);
            },

            goToQuiz() {
                this.$router.push({
                    name: "MyElearningQuiz",
                    params: {id: this.$route.params.id},
                });
            },

            goToList() {
                this.$router.push({
                    name: "myElearning",
                });
            },
            async downloadFile(file) {
                await this.downloadFileAsBlob(this,
                                              downloadElearningFileUsingGETURL({id: file.id}), file.filename);
            },
        },
        computed: {
            isEmployer() {
                return SystemRole.isEmployer();
            },
            isWorker() {
                return SystemRole.isWorker();
            },
        },
    };
</script>

<style lang="less" scoped>
.background {
    background: #f4f4f4;
    padding: 5px;

    .nav-buttons {
        padding: 1em;
    }
}

.wrap-spaces {
    white-space: pre-wrap;
}

.description {
    max-width: 840px;
    border: 5px solid #a7a5a5;
    padding: 40px;
}
</style>
