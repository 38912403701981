<template>
    <div :class="containerClass">
        <AppTopBar @menu-toggle="onMenuToggle"/>
        <div class="sidebar">

            <transition name="layout-sidebar">
                <div class="layout-sidebar" @click="onSidebarClick"  v-show="isSidebarVisible()">
                    <div>
                        <!--TODO: link na stronę główną-->
                        <img src="@/assets/logo_full.png" height="250" width="250">
                        <AppMenu />
                    </div>
                </div>
            </transition>
        </div>

        <div class="layout-main">
            <Toast/>
            <ConfirmDialog/>
            <div class="content">
                <router-view/>
            </div>

            <footer>

            </footer>
        </div>
    </div>
</template>

<script>
    import Toast from "primevue/toast";
    import ConfirmDialog from "primevue/confirmdialog";
    import AppTopBar from "@/components/AppTopBar";
    import AppMenu from "../../components/AppMenu";

    export default {
        name: "LayoutDashboard",
        components: {
            AppMenu, Toast, AppTopBar, ConfirmDialog,
        },

        mounted() {
            this.currentYear = new Date().getFullYear();
        },

        data() {
            return {
                layoutMode: "static",
                staticMenuInactive: false,
                overlayMenuActive: false,
                mobileMenuActive: false,
                windowWidth: null,
                currentYear: null,
            };
        },

        methods: {
            onWrapperClick() {
                if (!this.menuClick) {
                    this.overlayMenuActive = false;
                    this.mobileMenuActive = false;
                }

                this.menuClick = false;
            },
            onMenuToggle() {
                this.menuClick = true;

                if (this.isDesktop()) {
                    if (this.layoutMode === "overlay") {
                        if (this.mobileMenuActive === true) {
                            this.overlayMenuActive = true;
                        }

                        this.overlayMenuActive = !this.overlayMenuActive;
                        this.mobileMenuActive = false;
                    } else if (this.layoutMode === "static") {
                        this.staticMenuInactive = !this.staticMenuInactive;
                    }
                } else {
                    this.mobileMenuActive = !this.mobileMenuActive;
                }
            },
            onSidebarClick() {
                this.menuClick = true;
            },
            onMenuItemClick(event) {
                if (event.item && !event.item.items) {
                    this.overlayMenuActive = false;
                    this.mobileMenuActive = false;
                }
            },
            onLayoutChange(layoutMode) {
                this.layoutMode = layoutMode;
            },
            removeClass(element, className) {
                if (element.classList) {
                    element.classList.remove(className);
                } else {
                    element.className = element.className.replace(
                        new RegExp("(^|\\b)" + className.split(" ").join("|") + "(\\b|$)", "gi"), " ",
                    );
                }
            },
            isDesktop() {
                return window.innerWidth > 1024;
            },
            isSidebarVisible() {
                if (this.isDesktop()) {
                    if (this.layoutMode === "static") return !this.staticMenuInactive;
                    if (this.layoutMode === "overlay") return this.overlayMenuActive;
                    return true;
                }

                return true;
            },

            goToSite() {
                window.open("https://vavatech.pl/");
            },
        },
        computed: {
            containerClass() {
                return ["layout-wrapper", {
                    "layout-overlay": this.layoutMode === "overlay",
                    "layout-static": this.layoutMode === "static",
                    "layout-static-sidebar-inactive": this.staticMenuInactive && this.layoutMode === "static",
                    "layout-overlay-sidebar-active": this.overlayMenuActive && this.layoutMode === "overlay",
                    "layout-mobile-sidebar-active": this.mobileMenuActive,
                }];
            },
        },
    };
</script>

<style lang="less">
    @import "../assets/less/layout-dasboard";
    @import "../assets/less/responsive";
    @import "../assets/less/sidebar";
</style>
