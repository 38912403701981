<template>
    <div>
        <h1 class="page-header" v-if="currentDepartment">
            <i class="pi pi-percentage header-icon"></i>
            Wynik analizy finansowej działu {{currentDepartment.name}}</h1>
    </div>

    <div v-if="loaded">

        <div class="card" style="padding-bottom: 50px;">
            <h5>Koszty wdrożenia ścieżki reskillingu</h5>
            <Chart type="horizontalBar" :data="chartData" :options="chartOptions" />
        </div>

        <table class="tableizer-table">
            <thead>
                <tr>
                    <th>
                        Imię i nazwisko pracownika
                    </th>
                    <th>
                    </th>
                    <th>
                        Liczba wdrożeń
                    </th>
                    <th>
                        suma
                    </th>
                    <th>
                        Wartość z uwzgl. efekty synergii
                    </th>
                    <template v-if="showWorkers">
                        <th v-for="(column, index) in dynamicColumns" :index="index" :key="index">
                            {{ column.name }}
                        </th>
                    </template>
                </tr>
            </thead>

            <tbody v-if="showRows">
                <tr v-for="(row, index) in rows" :key="index">
                    <td>
                        {{ row.mainName }}
                    </td>
                    <td>
                        {{ row.subName }}
                    </td>
                    <td>
                        {{ row.numberOfImplementations }}
                    </td>
                    <td>
                        {{ formatAsMoneyString(row.total) }}
                    </td>
                    <td>
                        {{ formatAsMoneyString(row.totalWithSynergyEffect) }}
                    </td>
                    <template v-if="showWorkers">
                        <td v-for="(totalForWorkers, index) in row.totalForWorkers" :key="index">
                            {{ formatAsMoneyString(row.totalForWorkers[index]) }}
                        </td>
                    </template>
                </tr>
            </tbody>

            <tbody v-else>
                <tr v-for="(row, index) in filteredRows" :key="index">
                    <td>
                        {{ row.mainName }}
                    </td>
                    <td>
                        {{ row.subName }}
                    </td>
                    <td>
                        {{ row.numberOfImplementations }}
                    </td>
                    <td>
                        {{ formatAsMoneyString(row.total) }}
                    </td>
                    <td>
                        {{ formatAsMoneyString(row.totalWithSynergyEffect) }}
                    </td>
                    <template v-if="showWorkers">
                        <td v-for="(totalForWorkers, index) in row.totalForWorkers" :key="index">
                            {{ formatAsMoneyString(row.totalForWorkers[index]) }}
                        </td>
                    </template>
                </tr>
            </tbody>
        </table>

        <div style="padding-right: 20px; padding-top: 20px; text-align: left;">
            <ToggleButton v-model="showWorkers"
                          offLabel="Pokaż pracowników" onLabel="Ukryj pracowników"
                          style="padding: 20px; width: 250px"
                          offIcon="pi pi-user-plus" onIcon="pi pi-user-minus">
            </ToggleButton>

            <ToggleButton v-model="showRows"
                          offLabel="Pokaż ukryte wiersze" onLabel="Ukryj wybrane wiersze"
                          style="padding: 20px; margin-left: 50px; width: 250px"
                          offIcon="pi pi-window-maximize" onIcon="pi pi-window-minimize">
            </ToggleButton>

            <Button label="Przejdź do widoku wydruku" class="p-button-danger" @click="redirectToPrintView()"
                    style="padding: 20px; margin-left: 50px;" icon="pi pi-print">
            </Button>

            <Button @click="goBack()" class="p-button"
                    style="padding: 20px; margin-left: 50px;">
                <i class="pi pi-angle-double-left" style="padding-right: 10px;"></i>
                Powrót do poprzedniej strony
            </Button>
        </div>

        <div style="max-width: 1300px; margin:auto; font-size: 20px; padding:30px">
            Zgodnie ze skalkulowanymi kosztami działań wskaż możliwe do wdrożenia formy wsparcia w
            zakładce KOMPETENCJE DZIAŁÓW a następnie przejdź do pełnych wyników analizy kosztów i
            korzyści klikając ZOBACZ DALSZE WYNIKI
        </div>

        <Button @click="navigateToStepTwo" label="Zobacz dalsze wyniki" style="margin-top:20px"
                icon="pi pi-angle-double-right" iconPos="right" class="p-button-lg"/>

    </div>

    <div v-else>
        <ProgressSpinner/>
    </div>
</template>

<script>
    import {Departments} from "@/utils/Departments";
    import ProgressSpinner from "primevue/progressspinner";
    import {getFinancialAnalysisDataUsingGET as getFinancialAnalysis} from "@/swagger/vue-api-client";
    import ToggleButton from "primevue/togglebutton";
    import Chart from "primevue/chart";
    import Button from "primevue/button";

    export default {
        name: "EfficiencyDepartmentResultsOne",
        components: {
            ProgressSpinner, ToggleButton, Chart, Button,
        },
        data() {
            return {
                loaded: false,
                currentDepartment: null,
                rows: [],
                dynamicColumns: [],
                showWorkers: true,
                showRows: true,
                chartData: {
                    labels: [],
                    datasets: [
                        {
                            label: "WARTOŚĆ Z UWZGLĘDNIENIEM EFEKTU SYNERGII",
                            backgroundColor: "#2E9FA2",
                            data: [],
                        },
                        {
                            label: "SUMA",
                            backgroundColor: "#84DADC",
                            data: [],
                        },
                    ],
                },
                chartOptions: null,
            };
        },

        beforeMount() {
            if (this.$route.params.department && Departments.getDepartmentByUrlParam(this.$route.params.department)) {
                this.setCurrentDepartment();
                this.getAnalysis();
            } else {
                this.$router.push({name: "notFound"});
            }
        },

        watch: {
            "$route.params.department": function (newVal, oldVal) {
                if (newVal !== oldVal) {
                    this.setCurrentDepartment();
                    this.getAnalysis();
                }
            },
        },

        methods: {
            setCurrentDepartment() {
                this.currentDepartment = Departments.getDepartmentByUrlParam(this.$route.params.department);
            },

            navigateToStepTwo() {
                // eslint-disable-next-line max-len
                this.$router.push({name: "efficiencyDepartmentCalculationsResults", params: {department: this.$route.params.department}});
            },

            getAnalysis() {
                getFinancialAnalysis({department: this.currentDepartment.discriminator}).then((response) => {
                    this.loaded = true;
                    this.rows = response.data.rows;
                    this.dynamicColumns = response.data.dynamicColumns;
                    // eslint-disable-next-line prefer-destructuring
                    this.chartData.datasets[0].data = response.data.chartData.data[0];
                    // eslint-disable-next-line prefer-destructuring
                    this.chartData.datasets[1].data = response.data.chartData.data[1];
                    this.chartData.labels = response.data.chartData.labels;
                }).catch(() => {
                    this.loaded = true;
                });
            },

            formatAsMoneyString(val) {
                if (!val) {
                    return "";
                }
                // eslint-disable-next-line max-len
                return parseFloat(val).toLocaleString("pl-PL", {style: "currency", currency: "PLN", minimumFractionDigits: 2});
            },

            redirectToPrintView() {
                this.$router.push({name: "printEfficiencyResultsStepOne"});
            },

            goBack() {
                window.history.back();
            },
        },

        computed: {
            filteredRows() {
                return this.rows.filter((r) => r.mainName === r.mainName.toUpperCase());
            },
        },
    };
</script>

<style scoped>
    table.tableizer-table {
        font-size: 1rem;
    }
    .tableizer-table td {
        padding: 0.5rem 0.8rem;
        margin: 10px;
        border-bottom: 1px solid #CCC;
        border-left: 1px dashed #CCC;
        border-right: 1px dashed #CCC;
    }
    .tableizer-table th {
        background-color: #D9D9D9;
        color: #495057;
        padding: 1rem;
        font-weight: bold;
        border: 1px solid #ffffff;
    }
</style>
