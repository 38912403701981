<template>
    <Dialog :header="getHeaderLabel" v-model:visible="internalVisible"
            :breakpoints="{'1200px': '60vw', '960px': '75vw', '640px': '100vw'}"
            :style="{width: '40vw'}">
        <Form @submit="addTrendComment" v-slot="{ isSubmitting }">
            <div class="p-grid form-wrapper">
                <div class="p-col-12" v-if="currentStep==1">
                    <CustomInputTextArea name="content" v-model="commentData.content"
                                         label="Treść" :rows="10"
                                         rules="required"/>
                </div>
                <div class="adding-comment-completed" v-if="currentStep==2">
                    <div class="center success-message">
                        Twój post został dodany!
                    </div>
                </div>
            </div>
            <div class="button-div">
                <Button v-if="currentStep === 1" label="Anuluj" icon="pi pi-times" class="p-button-text"
                        @click="closeDialog()" :disabled="isSubmitting"/>
                <Button v-if="currentStep === 1" label="Dalej" icon="pi pi-chevron-right"
                        type="submit" :disabled="isSubmitting" />
                <Button v-if="currentStep === 2" label="Zamknij" icon="pi pi-times" class="p-button-text"
                        @click="closeDialog(), resetCommentData()" />
            </div>

        </Form>
    </Dialog>
</template>

<script>
    import Dialog from "primevue/dialog";
    import Button from "primevue/button";
    import CustomInputTextArea from "@/components/form/CustomInputTextArea";
    import {Form} from "vee-validate";
    import {commentTrendPostUsingPOST} from "@/swagger/vue-api-client";

    export default {
        name: "AddNewTrendCommentDialog",
        components: {
            Dialog, Button, Form, CustomInputTextArea,
        },
        emits: ["update:visible", "new-comment-added"],

        props: {
            visible: {
                type: Boolean,
                required: true,
            },
        },

        data() {
            return {
                commentData: {
                    content: "",
                    postId: this.$route.params.id,
                },
                currentStep: 1,
            };
        },

        computed: {
            internalVisible: {
                get() {
                    return this.visible;
                },
                set(value) {
                    this.$emit("update:visible", value);
                },

            },
            getHeaderLabel() {
                return this.currentStep === 2 ? "Dodano post" : "Dodaj nowy post";
            },
        },

        methods: {
            async addTrendComment() {
                await Promise.resolve(commentTrendPostUsingPOST({commentDto: this.commentData}))
                    .then(() => {
                        this.currentStep = 2;
                        this.$emit("new-comment-added");
                    }).catch((error) => {
                        if (error.response.status === 403) {
                            this.$toast.add({
                                severity: "error",
                                summary: "Błąd",
                                detail: "Nie masz wystarczających uprawnień",
                                life: 3000,
                            });
                        } else {
                            this.$toast.add({
                                severity: "error",
                                summary: "Błąd",
                                detail: "Wystąpił nieoczekiwany błąd",
                                life: 3000,
                            });
                        }
                    });
            },

            closeDialog() {
                this.internalVisible = false;
                this.currentStep = 1;
            },

            getEmptyComment() {
                return {
                    content: "",
                    postId: this.$route.params.id,
                };
            },

            resetCommentData() {
                this.commentData = this.getEmptyComment();
            },
        },
    };
</script>

<style lang="less" scoped>
@import "src/views/assets/less/new-comment-dialog.less";
</style>
