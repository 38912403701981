<template>
    <div>
        <div class="p-lg-9" style="margin: auto">
            <div>
                <h3 style="margin-bottom: 40px">
                    Wycena planowanych działań
                </h3>
                <div style="margin-bottom: 40px">
                    <CustomSelectBoolean v-if="analysisVariant === 'dane mieszane'"
                                         class="p-lg-9" style="margin: auto"
                                         name="useStatisticalData"
                                         label="Chcę skorzystać z danych statystycznych?"
                                         v-model="internalValue.useStatisticalData">
                    </CustomSelectBoolean>
                    <CustomSelectBoolean class="p-lg-9" style="margin: auto"
                                         :disabled="realizationMethodDisabled
                                             && !internalValue.realizedAsBuyAService"
                                         name="realizedAsBuyAService"
                                         label="Wszystkie działania realizowane będą jako 'zakup usługi'"
                                         v-model="internalValue.realizedAsBuyAService">
                    </CustomSelectBoolean>
                    <CustomSelectBoolean class="p-lg-9" style="margin: auto"
                                         :disabled="realizationMethodDisabled
                                             && !internalValue.realizedWithExternalResources"
                                         name="realizedWithExternalResources"
                                         label="Wszystkie działania realizowane będą z wykorzystaniem
                                         wewnętrznych zasobów, w tym własnej kadry w roli trenerów/coachów/doradców"
                                         v-model="internalValue.realizedWithExternalResources">
                    </CustomSelectBoolean>
                    <CustomSelectBoolean class="p-lg-9" style="margin: auto"
                                         :disabled="realizationMethodDisabled
                                             && !internalValue.realizedWithMixedSource"
                                         name="realizedWithMixedSource"
                                         label="Część działań realizowana będzie jako 'zakup usługi',
                                          a część z wykorzystaniem wewnętrznych zasobów"
                                         v-model="internalValue.realizedWithMixedSource">
                    </CustomSelectBoolean>
                </div>

                <div style="margin-bottom: 40px">

                    <h4 style="margin-bottom: 20px;">Planowany coaching / doradztwo / mentoring</h4>

                    <div style="margin: auto" class="p-lg-9">
                        <table class="tableizer-table">
                            <thead>
                                <tr class="tableizer-firstrow">
                                    <th></th>
                                    <th>Jednostkowy koszt brutto - koszt 1 godziny wsparcia</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <CustomSelectOne :disabled=
                                                             "!(internalValue.realizedWithMixedSource === true)"
                                                         v-model="internalValue.coachingPurchase"
                                                         :items="purchaseOptions"
                                                         item-label="label"
                                                         item-value="value"
                                                         label="Wybór typu realizacji"/>
                                    </td>
                                    <td>
                                        <CustomInputNumber
                                            :disabled="!isCoachingHourCostActive"
                                            name="coachingHourCost"
                                            v-model="internalValue.coachingHourCost"
                                            mode="currency" currency="PLN" locale="pl-PL"/>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div>

                <div style="margin-bottom: 40px;">

                    <div style="margin: auto; margin-top: 60px;">

                        <h4 style="margin-bottom: 20px;">Planowane szkolenia</h4>

                    </div>

                    <DataTable :value="internalValue.trainings"
                               responsiveLayout="scroll">

                        <Column field="name" style="width:25%"
                                headerStyle="font-size: larger">
                            <template #body="slotProps">
                                {{slotProps.data.name}}
                            </template>
                        </Column>

                        <Column field="trainingPurchaseOption"
                                header="Forma"
                                style="width:25%">
                            <template #body="slotProps">
                                <CustomSelectOne :disabled="!(internalValue.realizedWithMixedSource === true)"
                                                 v-model="slotProps.data.trainingPurchaseOption"
                                                 :items="purchaseOptions"
                                                 item-label="label"
                                                 item-value="value"
                                                 label="Wybór typu realizacji"/>
                            </template>
                        </Column>

                        <Column field="trainingCost"
                                header="Jednostkowy koszt szkolenia brutto na osobę (nie dotyczy kosztów wewnętrznych)"
                                style="width:25%">
                            <template #body="slotProps">
                                <CustomInputNumber
                                    :disabled="!isCostInputActive()"
                                    name="trainingCost"
                                    v-model="slotProps.data.trainingCost"
                                    mode="currency" currency="PLN" locale="pl-PL"/>
                            </template>
                        </Column>

                        <Column field="trainingDurationInHours"
                                header="Wymiar godzinowy szkolenia"
                                style="width:25%">
                            <template #body="slotProps">
                                <CustomInputNumber
                                    :disabled="isTrainingDurationInHoursDisabled(slotProps.data.name)"
                                    name="trainingDurationInHours"
                                    v-model="slotProps.data.trainingDurationInHours"/>
                            </template>
                        </Column>

                    </DataTable>
                </div>

                <div style="margin-bottom: 40px">

                    <div style="margin: auto; margin-top: 60px;">

                        <h4 style="margin-bottom: 20px;">Planowane wizyty studyjne/praktyki</h4>

                        <CustomSelectOne :disabled="!internalValue.realizedWithMixedSource"
                                         v-model="internalValue.studyVisitsPurchase"
                                         :items="purchaseOptions"
                                         item-label="label"
                                         item-value="value"
                                         label="Wybór typu realizacji"/>

                        <CustomSelectOne :disabled="isRegionDisabled
                                             (internalValue.studyVisitsPurchase)"
                                         v-model="internalValue.region"
                                         :items="regions"
                                         item-label="label"
                                         item-value="value"
                                         label="Wybierz region"/>

                        <CustomSelectOne :disabled="isEuropeRegionDisabled
                                             (internalValue.region)"
                                         v-model="internalValue.regionEurope"
                                         :items="regionsEurope"
                                         item-label="label"
                                         item-value="value"
                                         label="Wybierz kraj"/>

                    </div>

                    <DataTable :value="internalValue.studyVisits"
                               responsiveLayout="scroll" style="margin: auto;">

                        <Column field="name" style="width:50%"
                                headerStyle="font-size: larger">
                            <template #body="slotProps">
                                {{slotProps.data.name}}
                            </template>
                        </Column>

                        <Column field="hourCost"
                                header="Jednostkowy koszt brutto - koszt na 1 pracownika"
                                style="width:50%">
                            <template #body="slotProps">
                                <CustomInputNumber
                                    :disabled="isCostInputDisabled()"
                                    name="visitCost"
                                    v-model="slotProps.data.hourCost"
                                    mode="currency" currency="PLN" locale="pl-PL"/>
                            </template>
                        </Column>

                    </DataTable>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne";
    import CustomSelectBoolean from "@/views/user/components/CustomSelectBoolean";
    import CustomInputNumber from "@/components/form/CustomInputNumber";
    import Column from "primevue/column";
    import DataTable from "primevue/datatable";

    export default {
        name: "EfficiencyFinancialData",

        components: {
            CustomSelectOne,
            CustomInputNumber,
            CustomSelectBoolean,
            Column,
            DataTable,
        },

        watch: {
            "internalValue.realizedAsBuyAService": function (val) {
                if (val) {
                    this.internalValue.realizedWithExternalResources = false;
                    this.internalValue.realizedWithMixedSource = false;
                }
            },

            "internalValue.realizedWithExternalResources": function (val) {
                if (val) {
                    this.internalValue.realizedAsBuyAService = false;
                    this.internalValue.realizedWithMixedSource = false;
                }
            },

            "internalValue.realizedWithMixedSource": function (val) {
                if (val) {
                    this.internalValue.realizedAsBuyAService = false;
                    this.internalValue.realizedWithExternalResources = false;
                }
            },
        },

        data() {
            return {
                priceInputOptions: [
                    {
                        label: "chcę podać wartość samodzielnie",
                        value: "chcę podać wartość samodzielnie",
                    },
                    {
                        label: "chcę skorzystać z danych statystycznych",
                        value: "chcę skorzystać z danych statystycznych",
                    },
                ],

                regions: [
                    {label: "województwo", value: "województwo"},
                    {label: "region", value: "region"},
                    {label: "Polska", value: "Polska"},
                    {label: "Europa", value: "Europa"},
                ],

                regionsEurope: [
                    {label: "Holandia", value: "Holandia"},
                    {label: "Dania", value: "Dania"},
                    {label: "Francja", value: "Francja"},
                    {label: "Norwegia", value: "Norwegia"},
                    {label: "Finlandia", value: "Finlandia"},
                    {label: "Szwecja", value: "Szwecja"},
                    {label: "Hiszpania", value: "Hiszpania"},
                    {label: "Grecja", value: "Grecja"},
                    {label: "Włochy", value: "Włochy"},
                    {label: "Austria", value: "Austria"},
                    {label: "Portugalia", value: "Portugalia"},
                    {label: "Niemcy", value: "Niemcy"},
                    {label: "Wegry", value: "Wegry"},
                    {label: "Czechy", value: "Czechy"},
                ],

                purchaseOptions: [
                    {label: "zakup usługi", value: "zakup usługi"},
                    {
                        label: "realizacja z wykorzystaniem zasobów własnych",
                        value: "realizacja z wykorzystaniem zasobów własnych",
                    },
                ],

            };
        },
        props: {
            modelValue: null,
            analysisVariant: {
                type: String,
                default: null,
            },
        },

        emits: ["update:modelValue"],

        computed: {
            internalValue: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },
            },
            realizationMethodDisabled() {
                if (this.analysisVariant === "dane statystyczne") {
                    return true;
                }
                if (this.analysisVariant === "dane mieszane" && this.internalValue.useStatisticalData === true) {
                    return true;
                }
                return false;
            },
            isCoachingHourCostActive() {
                if (this.analysisVariant === "dane mieszane" && !this.internalValue.useStatisticalData) {
                    window.console.log("tu wchodzi 1");
                    if (this.internalValue.realizedAsBuyAService) {
                        // B55=TAK
                        return true;
                    } if (this.internalValue.realizedWithMixedSource
                        && this.internalValue.coachingPurchase === "zakup usługi") {
                        // B57=TAK AND B60='zakup usługi'
                        return true;
                    }
                }
                if (this.analysisVariant === "dane własne") {
                    if (this.internalValue.realizedAsBuyAService) {
                        // B55=TAK
                        return true;
                    } if (this.internalValue.realizedWithMixedSource
                        && this.internalValue.coachingPurchase === "zakup usługi") {
                        // B57=TAK && B60='zakup usługi'
                        return true;
                    }
                }
                return false;
            },
        },

        methods: {
            isCoachingHourCostDisabled(val) {
                return !(this.internalValue.useStatisticalData !== true
                    && ((val === "zakup usługi" || this.internalValue.realizedAsBuyAService === true)
                        && ((this.analysisVariant === "dane własne" || this.analysisVariant === "dane mieszane"))));
            },

            isPricingOptionDisabled(val) {
                return !(val === "zakup usługi");
            },

            isTrainingDurationInHoursDisabled(name) {
                return !name.includes("szkolenie zawodowe");
            },

            isCostInputDisabled() {
                return !(this.analysisVariant === "dane własne"
                    || (this.analysisVariant === "dane mieszane" && !this.internalValue.useStatisticalData));
            },

            isCostInputActive() {
                if (this.analysisVariant === "dane mieszane" && !this.internalValue.useStatisticalData) {
                    if (this.internalValue.realizedAsBuyAService) { // B55
                        return true;
                    }
                    if (this.internalValue.realizedWithMixedSource
                        && this.internalValue.coachingPurchase === "zakup usługi") { // B57 AND B63='zakup usługi'
                        return true;
                    }
                }
                if (this.analysisVariant === "dane własne") {
                    if (this.internalValue.realizedAsBuyAService) {
                        return true;
                    }
                    if (this.internalValue.realizedWithMixedSource
                        && this.internalValue.coachingPurchase === "zakup usługi") { // B57 AND B63='zakup usługi'
                        return true;
                    }
                }
                return false;
            },

            isInnerCostInputDisabled(val) {
                return val !== "realizacja z wykorzystaniem zasobów własnych";
            },

            isOuterCostInputDisabled(val) {
                return val !== "zakup usługi";
            },
            isRegionDisabled(val) {
                return val !== "zakup usługi";
            },
            isEuropeRegionDisabled(val) {
                return val !== "Europa";
            },

            realizationMethodSelected() {
                return this.internalValue.realizedAsBuyAService
                    || this.internalValue.realizedWithExternalResources
                    || this.internalValue.realizedWithMixedSource;
            },
        },
    };
</script>

<style scoped>
    table.tableizer-table {
        font-size: 1rem;
    }
    .tableizer-table td {
        padding: 0.3rem 0.5rem;
        margin: 10px;
        border-top: 1px solid #CCC;
        text-align: left;
    }
    .tableizer-table th {
        color: #495057;
        padding: 1rem;
        font-weight: bold;
    }
</style>
