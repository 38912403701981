<template>
    <div>
        <h1 class="page-header">
            <i class="pi pi-comments header-icon"></i>Forum</h1>

        <Toolbar class="actions">
            <template v-slot:right>
                <Button label="Dodaj nowy post" icon="pi pi-plus" @click="showNewCommentDialog = true"/>
            </template>
        </Toolbar>
        <AddNewCommentDialog v-model:visible ="showNewCommentDialog"
                             @new-comment-added="updateSearch"></AddNewCommentDialog>
        <ForumPostDataView ref="forumPostDataView" v-model:search-criteria="searchCriteria"></ForumPostDataView>
    </div>
</template>

<script>
    import ForumPostDataView from "@/views/forum/ForumPostDataView";
    import AddNewCommentDialog from "@/views/forum/components/AddNewCommentDialog";
    import Button from "primevue/button";
    import Toolbar from "primevue/toolbar";

    export default {
        name: "ForumPostPage",
        components: {
            ForumPostDataView, AddNewCommentDialog, Toolbar, Button,
        },

        data() {
            return {
                searchCriteria: this.getClearSearchCriteria(),
                showNewCommentDialog: false,
            };
        },

        methods: {
            updateSearch() {
                this.$refs.forumPostDataView.refreshResults();
            },

            getClearSearchCriteria() {
                return {
                    page: {
                        limit: 10,
                        offset: 0,
                        sortField: null,
                        sortOrder: null,
                    },
                };
            },
        },
    };
</script>

<style scoped>

</style>
