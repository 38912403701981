<template>
    <div>
        <h1 class="page-header">
            <i class="pi pi-compass header-icon"></i>
            Ścieżka reskillingu działu {{currentDepartment.name}}</h1>

    </div>
    <div v-if="!loaded">
        <ProgressSpinner/>
    </div>

    <div v-else>
        <div v-if="chartsExists">
            <div v-if="!isUpdated" class="p-grid">

                <div class="p-col">
                    <Chart type="horizontalBar" :data="legendChartData"
                           :options="legendChartOptions" :width="1440" :height="150"
                           style="padding-left: 20px;"/>
                </div>

                <div class="p-col" v-for="(row, index) in chartList" :key="index">
                    <Chart type="horizontalBar" :data="row"
                           :options="chartOptions" :width="1000" :height="500"
                           style="padding-left: 20px; padding-bottom: 70px;"/>
                </div>

            </div>

            <div v-else class="p-grid">

                <div class="p-col">
                    <Chart type="horizontalBar" :data="legendUpdatedChartData"
                           :options="legendUpdatedChartOptions"
                           :width="1440" :height="200"
                           style="padding-left: 20px;"/>
                </div>

                <div class="p-col" v-for="(row, index) in updatedChartList" :key="index">
                    <Chart type="horizontalBar" :data="row"
                           :options="updatedChartOptions" :width="1000" :height="500"
                           style="padding-left: 20px; padding-bottom: 70px;"/>
                </div>

            </div>
        </div>
        <div v-else>
            <h3>Ścieżka reskilingu będzie dostępna dopiero po scaleniu wyników działu</h3>
        </div>
    </div>
</template>

<script>
    import {Departments} from "@/utils/Departments";
    import ProgressSpinner from "primevue/progressspinner";
    import {
        // eslint-disable-next-line no-unused-vars
        getReskillingPathDataUsingGET as getReskillingPath,
        getReskillingUpdatedPathDataUsingGET as getReskillingUpdatedPath,
        getUpdatedStatusForReskillingPathUsingGET as getUpdatedStatus,
    } from "@/swagger/vue-api-client";
    import Button from "primevue/button";
    import Tooltip from "primevue/tooltip";
    import ToggleButton from "primevue/togglebutton";
    import Chart from "primevue/chart";

    export default {
        name: "ReskillingDepartmentPathChartView",
        components: {
            // eslint-disable-next-line vue/no-unused-components
            ProgressSpinner, Button, Tooltip, ToggleButton, Chart,
        },

        data() {
            return {
                loaded: false,
                currentDepartment: null,
                notReady: false,
                inProgress: false,
                searchCriteria: this.getClearSearchCriteria(),
                chartsExists: false,
                isUpdated: false,

                legendChartData: {
                    labels: [],
                    datasets: [
                        {
                            label: "punkt wyjścia",
                            backgroundColor: "#CFF0F1",
                        },
                        {
                            label: "punkt docelowy po wprowadzeniu planowanych działań",
                            backgroundColor: "#64CFD2",
                        },
                        {
                            label: "średni wynik dla działu - punkt wyjścia",
                            backgroundColor: "#26A293",
                        },
                        {
                            label: "średni wynik dla działu - punkt docelowy po wprowadzeniu planowanych działań",
                            backgroundColor: "#1D6365",
                        },
                    ],
                },
                legendChartOptions: {
                    responsive: false,
                    maintainAspectRatio: false,
                    layout: {
                        padding: {
                            left: 330,
                        },
                    },
                    legend: {
                        labels: {
                            fontSize: 20,
                            boxWidth: 300,
                            fontStyle: "bolder",

                        },
                        position: "left",
                        padding: 50,
                    },
                    plugins: {
                        legend: {
                            labels: {
                                fontSize: 20,
                            },
                        },
                    },
                    scales: {
                        xAxes: [{
                            display: false,
                        }],
                        yAxes: [{
                            display: false,
                        }],
                    },
                },

                legendUpdatedChartData: {
                    labels: [],
                    datasets: [
                        {
                            label: "punkt wyjścia",
                            backgroundColor: "#DBF4F5",
                        },
                        {
                            label: "bieżący punkt (po wdrożeniu działań)",
                            backgroundColor: "#A5E3E5",
                        },
                        {
                            label: "punkt docelowy po wprowadzeniu planowanych działań",
                            backgroundColor: "#64CFD2",
                        },
                        {
                            label: "średni wynik dla działu - punkt wyjścia",
                            backgroundColor: "#2DC1AF",
                        },
                        {
                            label: "średnik wynik dla działu - punkt bieżący (po wdrożeniu działań)",
                            backgroundColor: "#26A293",
                        },
                        {
                            label: "średni wynik dla działu - punkt docelowy po wprowadzeniu planowanych działań",
                            backgroundColor: "#1D6365",
                        },
                    ],
                },
                legendUpdatedChartOptions: {
                    responsive: false,
                    maintainAspectRatio: false,
                    layout: {
                        padding: {
                            left: 330,
                        },
                    },
                    legend: {
                        labels: {
                            fontSize: 20,
                            boxWidth: 300,
                            fontStyle: "bolder",

                        },
                        position: "left",
                        padding: 50,
                    },
                    plugins: {
                        legend: {
                            labels: {
                                fontSize: 20,
                            },
                        },
                    },
                    scales: {
                        xAxes: [{
                            display: false,
                        }],
                        yAxes: [{
                            display: false,
                        }],
                    },
                },

                chartList: [],
                updatedChartList: [],

                chartOptions: {
                    responsive: true,
                    legend: {
                        display: false,
                    },
                    maintainAspectRatio: false,
                    interaction: {
                        intersect: false,
                    },
                    layout: {
                        padding: {
                            left: 330,
                        },
                    },
                    plugins: {
                        legend: {
                            labels: {
                                fontSize: 20,
                            },
                        },
                    },
                    tooltips: {
                        callbacks: {
                            label(tooltipItems, data) {
                                if (tooltipItems.xLabel !== 0) {
                                    let total = 0;
                                    for (let i = 0; i < data.datasets.length; i += 1) {
                                        total += data.datasets[i].data[tooltipItems.index];
                                    }
                                    if (total > 1) {
                                        total = 1;
                                    }
                                    if (tooltipItems.datasetIndex === 1 || tooltipItems.datasetIndex === 3) {
                                        return data.datasets[tooltipItems.datasetIndex].label + ": "
                                            + Math.trunc(total * 100) + " %";
                                    }
                                    return data.datasets[tooltipItems.datasetIndex].label + ": "
                                        + Math.trunc(tooltipItems.xLabel * 100) + " %";
                                } return "";
                            },
                        },
                    },
                    scales: {
                        xAxes: [{
                            stacked: true,
                            ticks: {
                                fontSize: 16,
                                fontColor: "#495057",
                                fontStyle: "bolder",
                                stepSize: 0.1,
                                // eslint-disable-next-line no-unused-vars
                                callback(value, index, values) {
                                    return (value * 100) + "%";
                                },
                                max: 1,
                            },
                        }],
                        yAxes: [{
                            stacked: true,
                            offset: true,
                            gridLines: {
                                color: "rgba(0, 0, 0, 0)",
                            },
                            ticks: {
                                count: 20,
                                fontSize: 16,
                                fontColor: "#495057",
                                fontStyle: "bolder",
                                mirror: true,
                                padding: 330,
                                callback(label) {
                                    if (/\s/.test(label) && label.length > 30) {
                                        const tempLabel = label.split(" ");
                                        const finalLabel = [];
                                        let string = "";
                                        let i = 0;
                                        while (i < tempLabel.length) {
                                            string = string.concat(tempLabel[i], " ");
                                            i += 1;
                                            // eslint-disable-next-line max-len
                                            if (string.concat(tempLabel[i], " ").length > 30 || i === tempLabel.length) {
                                                finalLabel.push(string);
                                                string = "";
                                            }
                                        }
                                        return finalLabel;
                                    }
                                    return label;
                                },
                            },
                            barPercentage: 1.5,
                        }],
                    },
                },

                updatedChartOptions: {
                    responsive: true,
                    legend: {
                        display: false,
                    },
                    maintainAspectRatio: false,
                    interaction: {
                        intersect: false,
                    },
                    layout: {
                        padding: {
                            left: 330,
                        },
                    },
                    plugins: {
                        legend: {
                            labels: {
                                fontSize: 20,
                            },
                        },
                    },
                    tooltips: {
                        callbacks: {
                            label(tooltipItems, data) {
                                if (tooltipItems.xLabel !== 0) {
                                    let total = 0;
                                    if (tooltipItems.datasetIndex === 1) {
                                        total = data.datasets[0].data[tooltipItems.index]
                                            + data.datasets[1].data[tooltipItems.index];
                                        if (total > 1) {
                                            total = 1;
                                        }
                                        return data.datasets[tooltipItems.datasetIndex].label + ": "
                                            + Math.trunc(total * 100) + " %";
                                    }
                                    if (tooltipItems.datasetIndex === 4) {
                                        total = data.datasets[3].data[tooltipItems.index]
                                            + data.datasets[4].data[tooltipItems.index];
                                        if (total > 1) {
                                            total = 1;
                                        }
                                        return data.datasets[tooltipItems.datasetIndex].label + ": "
                                            + Math.trunc(total * 100) + " %";
                                    }
                                    let tooltipLabel = tooltipItems.xLabel;
                                    if (tooltipLabel > 1) {
                                        tooltipLabel = 1;
                                    }
                                    return data.datasets[tooltipItems.datasetIndex].label + ": "
                                        + Math.trunc(tooltipLabel * 100) + " %";
                                } return "";
                            },
                        },
                    },
                    scales: {
                        xAxes: [{
                            stacked: true,
                            ticks: {
                                fontSize: 16,
                                fontColor: "#495057",
                                fontStyle: "bolder",
                                stepSize: 0.1,
                                // eslint-disable-next-line no-unused-vars
                                callback(value, index, values) {
                                    return (value * 100) + "%";
                                },
                                max: 1,
                            },
                        }],
                        yAxes: [{
                            stacked: true,
                            offset: true,
                            gridLines: {
                                color: "rgba(0, 0, 0, 0)",
                            },
                            ticks: {
                                count: 20,
                                fontSize: 16,
                                fontColor: "#495057",
                                fontStyle: "bolder",
                                mirror: true,
                                padding: 330,
                                callback(label) {
                                    if (/\s/.test(label) && label.length > 30) {
                                        const tempLabel = label.split(" ");
                                        const finalLabel = [];
                                        let string = "";
                                        let i = 0;
                                        while (i < tempLabel.length) {
                                            string = string.concat(tempLabel[i], " ");
                                            i += 1;
                                            // eslint-disable-next-line max-len
                                            if (string.concat(tempLabel[i], " ").length > 30 || i === tempLabel.length) {
                                                finalLabel.push(string);
                                                string = "";
                                            }
                                        }
                                        return finalLabel;
                                    }
                                    return label;
                                },
                            },
                            barPercentage: 1.5,
                        }],
                    },
                },
            };
        },

        directives: {
            tooltip: Tooltip,
        },

        beforeMount() {
            if (this.$route.params.department && Departments.getDepartmentByUrlParam(this.$route.params.department)) {
                this.setCurrentDepartment();
            } else {
                this.$router.push({name: "notFound"});
            }
        },

        mounted() {
            getUpdatedStatus({department: this.currentDepartment.discriminator}).then((response) => {
                this.isUpdated = response.data;
            });
            this.chartsExists = false;
            this.loaded = false;
            setTimeout(() => {
                if (!this.isUpdated) {
                    this.getResults();
                } else {
                    this.getUpdatedResults();
                }
            }, 1000);
        },

        watch: {
            "$route.params.department": function (newVal, oldVal) {
                if (newVal !== oldVal && this.$route.params.department) {
                    this.setCurrentDepartment();
                    getUpdatedStatus({department: this.currentDepartment.discriminator}).then((response) => {
                        this.isUpdated = response.data;
                    });
                    this.chartsExists = false;
                    this.loaded = false;
                    setTimeout(() => {
                        if (!this.isUpdated) {
                            this.getResults();
                        } else {
                            this.getUpdatedResults();
                        }
                    }, 1000);
                }
            },
        },

        methods: {
            getEmptyUpdatedChartData() {
                const chartData = {
                    labels: [],
                    datasets: [
                        {
                            label: "punkt wyjścia",
                            backgroundColor: "#DBF4F5",
                            type: "horizontalBar",
                            data: [],
                            stack: "Stack 0",
                        },
                        {
                            label: "punkt docelowy po wprowadzeniu planowanych działań",
                            backgroundColor: "#64CFD2",
                            type: "horizontalBar",
                            data: [],
                            stack: "Stack 0",
                        },
                        {
                            label: "bieżący punkt (po wdrożeniu działań)",
                            backgroundColor: "#A5E3E5",
                            type: "horizontalBar",
                            data: [],
                            stack: "Stack 1",
                        },
                        {
                            label: "średni wynik dla działu - punkt wyjścia",
                            backgroundColor: "#2DC1AF",
                            type: "horizontalBar",
                            data: [],
                            stack: "Stack 2",
                        },
                        {
                            label: "średni wynik dla działu - punkt docelowy po wprowadzeniu planowanych działań",
                            backgroundColor: "#1D6365",
                            type: "horizontalBar",
                            data: [],
                            stack: "Stack 2",
                        },
                        {
                            label: "średni wynik dla działu - punkt bieżący (po wdrożeniu działań)",
                            backgroundColor: "#26A293",
                            type: "horizontalBar",
                            data: [],
                            stack: "Stack 1",
                        },
                    ],
                };
                return chartData;
            },
            getEmptyChartData() {
                const chartData = {
                    labels: null,
                    datasets: [
                        {
                            label: "punkt wyjścia",
                            type: "horizontalBar",
                            backgroundColor: "#CFF0F1",
                            data: [],
                            stack: "Stack 0",
                        },
                        {
                            label: "punkt docelowy po wprowadzeniu planowanych działań",
                            type: "horizontalBar",
                            backgroundColor: "#64CFD2",
                            data: [],
                            stack: "Stack 0",
                        },
                        {
                            label: "średni wynik dla działu - punkt wyjścia",
                            type: "horizontalBar",
                            backgroundColor: "#26A293",
                            data: [],
                            stack: "Stack 1",
                        },
                        {
                            label: "średni wynik dla działu - punkt docelowy po wprowadzeniu planowanych działań",
                            type: "horizontalBar",
                            backgroundColor: "#1D6365",
                            data: [],
                            stack: "Stack 1",
                        },
                    ],
                };
                return chartData;
            },

            setCurrentDepartment() {
                this.currentDepartment = Departments.getDepartmentByUrlParam(this.$route.params.department);
            },

            getClearSearchCriteria() {
                return {
                    department: Departments.getDepartmentDiscriminatorByUrlParam(this.$route.params.department),
                };
            },

            async getResults() {
                this.chartsExists = false;
                this.loaded = false;
                await getReskillingPath({department: this.currentDepartment.discriminator}).then((response) => {
                    this.chartList = [];
                    // eslint-disable-next-line no-restricted-syntax
                    for (const element of response.data) {
                        const chartData = this.getEmptyChartData();
                        chartData.labels = element.labels;
                        element.data.forEach((el, index) => {
                            chartData.datasets[index].data = el;
                        });
                        this.chartList.push(chartData);
                    }

                    this.chartsExists = true;
                    this.loaded = true;
                }).catch((error) => {
                    this.loaded = true;
                    if (error.response.status === 409) {
                        this.chartsExists = false;
                    } else {
                        this.$toast.add({
                            severity: "error",
                            summary: "Błąd",
                            detail: "Wystąpił nieoczekiwany błąd",
                            life: 3000,
                        });
                    }
                });
            },

            async getUpdatedResults() {
                this.chartsExists = false;
                this.loaded = false;

                // eslint-disable-next-line max-len
                await getReskillingUpdatedPath({department: this.currentDepartment.discriminator}).then((response) => {
                    this.chartList = [];
                    // eslint-disable-next-line no-restricted-syntax
                    for (const element of response.data) {
                        const chartData = this.getEmptyUpdatedChartData();
                        chartData.labels = element.labels;
                        element.data.forEach((el, index) => {
                            chartData.datasets[index].data = el;
                        });

                        this.updatedChartList.push(chartData);
                    }

                    this.chartsExists = true;
                    this.loaded = true;
                }).catch((error) => {
                    this.loaded = true;
                    if (error.response.status === 409) {
                        this.chartsExists = false;
                    } else {
                        this.$toast.add({
                            severity: "error",
                            summary: "Błąd",
                            detail: "Wystąpił nieoczekiwany błąd",
                            life: 3000,
                        });
                    }
                });
            },
        },
    };
</script>
<style scoped>
</style>
