<template>
    <div>

        <h1 class="page-header"><i class="pi pi-chart-line header-icon"></i>Edycja firmy</h1>

        <div class="p-grid">
            <div class="p-col-4">
                <div>
                    <div>
                        <CustomInputText :disabled="true" name="email" label="Adres e-mail"
                                         v-model="company.email"/>
                        <CustomInputText :disabled="true" name="name" label="Nazwa" v-model="company.name"/>
                        <CustomInputText :disabled="true" name="phoneNumber"
                                         label="Numer telefonu" v-model="company.phoneNumber"/>
                        <CustomInputTextArea name="description" label="Opis" v-model="company.description"/>
                        <CustomFileUpload label="logo firmy" name="logoFile" v-model="file" @change="onFileChange"/>
                        <CustomSelectBoolean :disabled="true" name="agreementForDataSharing"
                                             label="Zgoda na publikację"
                                             v-model="company.agreementForDataSharing"></CustomSelectBoolean>
                        <CustomSelectBoolean :disabled="isPublicationDisabled()" name="publishedForPublicAccess"
                                             label="Opublikowano na stronie"
                                             v-model="company.publishedForPublicAccess"></CustomSelectBoolean>
                    </div>
                </div>
            </div>

            <div class="p-col">

                <div v-if="company.logoUrl">
                    <h3 style="text-align: center">Podgląd oryginalnego logo</h3>
                    <div>
                        <img v-if="company.logoUrl" :src="company.logoUrl" height="225"/>
                    </div>
                </div>

                <div v-if="imgUploadedUrl" style="margin-top: 20px">
                    <h3 style="text-align: center">Podgląd nowego logo</h3>
                    <div id="preview">
                        <img v-if="imgUploadedUrl" :src="imgUploadedUrl" height="225"/>
                    </div>
                </div>
            </div>
        </div>

        <Toolbar class="actions">
            <template v-slot:left>
                <Button label="Zapisz" icon="pi pi-save" @click="saveCompany"/>
            </template>
        </Toolbar>
    </div>
</template>

<script>
    import {
        editCompanyUsingPUT as editCompany,
        getCompanyUsingGET as getCompany,
    } from "@/swagger/vue-api-client";
    import Button from "primevue/button";
    import Toolbar from "primevue/toolbar";
    import CustomSelectBoolean from "@/views/user/components/CustomSelectBoolean";
    import CustomInputTextArea from "@/components/form/CustomInputTextArea";
    import CustomFileUpload from "@/components/form/CustomFileUpload";
    import {FileUtils} from "@/utils/FileUtils";
    import CustomInputText from "../../components/form/CustomInputText";

    export default {
        name: "EditCompanyView",
        mixins: [FileUtils],

        components: {
            Button, Toolbar, CustomInputText, CustomSelectBoolean, CustomInputTextArea, CustomFileUpload,
        },

        props: {
            value: {},
        },

        data() {
            return {
                company: {
                    id: null,
                    email: null,
                    phoneNumber: null,
                    description: null,
                    logoFile: {},
                    agreementForDataSharing: null,
                    publishedForPublicAccess: null,
                    logoUrl: null,
                },
                file: undefined,
                fileData: this.getEmptyFile(),
                imgUploadedUrl: undefined,
            };
        },

        beforeMount() {
            if (this.$route.params.id) {
                getCompany({id: this.$route.params.id}).then((response) => {
                    this.company = response.data;
                    this.file = response.data.logoFile.filename;
                });
            }
        },

        methods: {
            async saveCompany() {
                if (this.file) {
                    if (this.company.logoFile === null) {
                        this.company.logoFile = this.getEmptyFile();
                    }
                    this.company.logoFile.filename = this.file.name;
                    this.company.logoFile.encodedData = await this.fileToBase64(this.file);
                }
                editCompany({companyDto: this.company}).then(() => {
                    this.$toast.add({
                        severity: "success",
                        summary: "Sukces",
                        detail: "Zapisano dane firmy",
                        life: 3000,
                    });
                });
            },

            isPublicationDisabled() {
                return !this.company.agreementForDataSharing;
            },

            getEmptyFile() {
                return {
                    name: "",
                    filename: "",
                    description: "",
                    encodedData: "",
                };
            },

            onFileChange(e) {
                const file = e.target.files[0];
                this.imgUploadedUrl = URL.createObjectURL(file);
            },
        },
    };
</script>

<style scoped>

</style>
