/* eslint-disable */
export const Evaluations = {
    evaluations: [
        {name: "Ocena przełożonego", discriminator: "SUPERVISOR_EVALUATION"},
        {name: "Samoocena", discriminator: "SELF_EVALUATION"},
        {name: "Ocena współpracownika", discriminator: "COWORKER_EVALUATION"},

    ],

    getEvaluationByDiscriminator(discriminator) {
        if (discriminator) {
            return this.evaluations.find(entry => entry.discriminator === discriminator)
        }
        return null;
    },

    getEvaluationNameByDiscriminator(discriminator) {
        let evaluation = this.getEvaluationByDiscriminator(discriminator);
        if (evaluation) {
            return evaluation.name;
        }
        return "";
    },

    getEvaluationTypeClass(type) {
        const evaluationClass = "evaluation-";
        if (type) {
            return evaluationClass + type.toLowerCase();
        }
        return evaluationClass + "undefined";
    },

    getEvaluationStatusText(status) {
        switch (status) {
            case "ASSIGNED":
                return "Przypisana";
            case "STARTED":
                return "Rozpoczęta";
            case "SENT":
                return "Wysłana";
            default:
                return "Nieprzypisana";
        }
    },

    getEvaluationStatusClass(status) {
        const evaluationClass = "evaluation-";
        if (status) {
            return evaluationClass + status.toLowerCase();
        }
        return evaluationClass + "undefined";
    },

}
