<template>
    <Dialog :header="getHeaderLabel" v-model:visible="internalVisible"
            :breakpoints="{'1200px': '60vw', '960px': '75vw', '640px': '100vw'}"
            :style="{width: '40vw'}">
        <Form @submit="addUser" v-slot="{ isSubmitting }">
            <div class="p-grid form-wrapper">
                <div class="p-col-12" v-if="currentStep==1">
                    <CustomInputText name="userName" v-model="workerData.name"
                                     :label="$t('message.global.userName')"
                                     icon="pi pi-user"
                                     rules="required"/>
                    <CustomInputText name="userSurname" v-model="workerData.surname"
                                     :label="$t('message.global.userSurname')"
                                     icon="pi pi-user"
                                     rules="required"/>
                    <CustomInputText name="userEmail" v-model="workerData.email"
                                     :label="$t('message.global.email')"
                                     icon="pi pi-envelope"
                                     rules="required|email"/>
                </div>
                <div class="worker-registration-completed" v-if="currentStep==2">
                    <div class="center success-message">
                        Konto dla pracownika {{workerData.name}} {{workerData.surname}} zostało utworzone.
                        <br>
                        Na adres {{workerData.email}} został wysłany email rejestracyjny.
                    </div>
                </div>
            </div>
            <div class="button-div">
                <Button v-if="currentStep === 1" label="Anuluj" icon="pi pi-times" class="p-button-text"
                        @click="closeDialog()" :disabled="isSubmitting"/>
                <Button v-if="currentStep === 1" label="Dalej" icon="pi pi-chevron-right"
                        type="submit" :disabled="isSubmitting" />
                <Button v-if="currentStep === 2" label="Zamknij" icon="pi pi-times" class="p-button-text"
                        @click="closeDialog(); resetWorkerData()" />
                <Button v-if="currentStep === 2" label="Dodaj następnego pracownika" icon="pi pi-check"
                        @click="addAnotherWorker()"/>
            </div>

        </Form>
    </Dialog>
</template>

<script>
    import Dialog from "primevue/dialog";
    import Button from "primevue/button";
    import CustomInputText from "@/components/form/CustomInputText";
    import {Form} from "vee-validate";
    import {emailExistUsingGET, registerWorkerUsingPOST} from "@/swagger/vue-api-client";

    export default {
        name: "NewWorkerDialog",
        components: {
            Dialog, Button, CustomInputText, Form,
        },
        emits: ["update:visible", "new-worker-added"],

        props: {
            visible: {
                type: Boolean,
                required: true,
            },
            department: {
                type: String,
                required: true,
            },
        },

        data() {
            return {
                workerData: this.getEmptyUser(),
                currentStep: 1,
            };
        },

        watch: {
            department(newVal) {
                if (this.workerData) {
                    this.workerData.department = newVal;
                }
            },
        },

        computed: {
            internalVisible: {
                get() {
                    return this.visible;
                },
                set(value) {
                    this.$emit("update:visible", value);
                },

            },
            getHeaderLabel() {
                return this.currentStep === 2 ? "Dodano pracownika" : "Dodaj pracownika";
            },
        },

        methods: {
            // TODO: po dodaniu pracownika trzeba przeliczyć jeszcze arkusze
            async addUser(values, actions) {
                let valid = false;
                await Promise.resolve(emailExistUsingGET({email: this.workerData.email})).then((response) => {
                    if (response.data.exist === true) {
                        actions.setFieldError("userEmail", "Istnieje już użytkownik o takim adresie email");
                    } else {
                        this.currentStep = 1;
                        valid = true;
                    }
                }).catch(() => {
                });

                if (!valid) {
                    return;
                }

                await Promise.resolve(registerWorkerUsingPOST({workerRegistrationDto: this.workerData}))
                    .then(() => {
                        this.currentStep = 2;
                        this.$emit("new-worker-added");
                    }).catch((error) => {
                        if (error.response.status === 403) {
                            this.$toast.add({
                                severity: "error",
                                summary: "Błąd",
                                detail: "Nie masz wystarczających uprawnień",
                                life: 3000,
                            });
                        } else if (error.response.status === 409) {
                            this.$toast.add({
                                severity: "error",
                                summary: "Błąd",
                                detail: error.response.data,
                                life: 3000,
                            });
                        } else {
                            this.$toast.add({
                                severity: "error",
                                summary: "Błąd",
                                detail: "Wystąpił nieoczekiwany błąd",
                                life: 3000,
                            });
                        }
                    });
            },

            getEmptyUser() {
                return {
                    name: "",
                    surname: "",
                    email: "",
                    department: this.department,
                };
            },

            resetWorkerData() {
                this.workerData = this.getEmptyUser();
            },

            closeDialog() {
                this.internalVisible = false;
                this.currentStep = 1;
            },

            addAnotherWorker() {
                this.currentStep = 1;
                this.resetWorkerData();
            },

        },
    };
</script>

<style lang="less" scoped>
    @import "src/views/assets/less/new-worker-dialog.less";
</style>
