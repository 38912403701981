<template>
    <div>
        <h1 class="page-header"><i class="pi pi-file-excel header-icon"></i>Pliki excel firmy</h1>

        <div style="width: 100%; display: flex; align-items: flex-start; margin-top: 30px">
            <Button label="Pobierz wszystkie pliki" @click="downloadAllExcelFiles"
                    style="max-height: 40px; margin-right: 50px"
                    icon="pi pi-download" iconPos="left"/>
        </div>
        <CompanyExcelFilesTable ref="filesTable" v-model:search-criteria="searchCriteria"/>
    </div>
</template>

<script>

    import Button from "primevue/button";
    import {
        downloadAllCompanyExcelFilesUsingGETURL as downloadAllFilesUrl,
    } from "@/swagger/vue-api-client";
    import {FileUtils} from "@/utils/FileUtils";
    import CompanyExcelFilesTable from "./components/CompanyExcelFilesTable";

    export default {
        name: "CompanyExcelFilesMainView",

        components: {CompanyExcelFilesTable, Button},

        mixins: [FileUtils],

        data() {
            return {
                searchCriteria: this.getClearSearchCriteria(),
                companyId: this.$route.params.id,
            };
        },

        methods: {
            async downloadAllExcelFiles() {
                await this.downloadFileAsBlob(this, downloadAllFilesUrl({companyId: this.companyId}),
                                              this.companyId + ".zip");
            },

            updateSearch() {
                this.$refs.filesTable.refreshResults();
            },

            getClearSearchCriteria() {
                return {
                    page: {
                        limit: 10,
                        offset: 0,
                        sortField: null,
                        sortOrder: null,
                    },
                };
            },
        },
    };
</script>
