<template>
    <div>
        <h1 class="page-header"><i class="pi pi-check-square header-icon"></i>Konta do akceptacji</h1>

        <UserTable ref="userTable" :search-criteria="searchCriteria" />
    </div>
</template>

<script>
    import {SystemRole} from "../../utils/SystemRole";
    import {SearchCriteria} from "../../utils/SearchCriteria";
    import UserTable from "./components/UserTable";

    export default {
        name: "UserConfirmListView",
        components: {
            UserTable,
        },

        data() {
            return {
                searchCriteria: this.getSearchCriteria(),
                Role: SystemRole,
            };
        },

        beforeMount() {
            SearchCriteria.loadCriteria(this);
        },

        methods: {
            getSearchCriteria() {
                return {
                    firstName: "",
                    lastName: "",
                    email: "",
                    systemRole: "EMPLOYER",
                    active: false,
                    page: {
                        limit: 10,
                        offset: 0,
                        sortField: null,
                        sortOrder: null,
                    },
                };
            },
        },
    };
</script>

<style scoped>

</style>
