<template>
    <div>
        <h1 class="page-header">
            <i class="pi pi-pencil header-icon"></i>Ankieta</h1>

        <div v-show="isLoading">
            <progress-spinner/>
        </div>

        <div v-show="!isLoading">

            <h2> {{surveyTitle}}</h2>
            <h3> {{surveySubtitle}}
                <span v-if="surveyTitle != 'Samoocena'">
                    <br> {{workerFullName}}
                </span>
            </h3>
            <br/>

            <div v-if="!editable" style="padding-top: 15px; margin-right: 10px" class="p-grid">

                <div class="p-col p-md-6 p-lg-3">
                    <div  class="box">
                        <i class="pi pi-star"/> - Zdecydowanie się nie zgadzam</div>
                </div>

                <div class="p-col p-md-6 p-lg-2">
                    <div class="box">
                        <i class="pi pi-star"/><i class="pi pi-star"/> - Raczej się nie zgadzam</div>
                </div>

                <div class="p-col p-md-6 p-lg-2">
                    <div class="box">
                        <i class="pi pi-star"/><i class="pi pi-star"/>
                        <i class="pi pi-star"/> - Trudno powiedzieć</div>
                </div>

                <div class="p-col p-md-6 p-lg-2">
                    <div class="box">
                        <i class="pi pi-star"/><i class="pi pi-star"/><i class="pi pi-star"/>
                        <i class="pi pi-star"/> - Raczej się zgadzam</div>
                </div>

                <div class="p-col p-md-6 p-lg-3">
                    <div class="box">
                        <i class="pi pi-star"/><i class="pi pi-star"/><i class="pi pi-star"/><i class="pi pi-star"/>
                        <i class="pi pi-star"/><i class="pi pi-star"/> - Zdecydowanie się zgadzam</div>
                </div>
            </div>

            <ul id="array-rendering" style="list-style: none">
                <li v-for="item in surveyForm.questionList" v-bind:key="item"
                    class="question-list-item" style="text-align: left;">
                    <div class="p-grid">
                        <div class="p-col-12 p-md-6 p-lg-9">
                            <span style="display: inline-block; font-size: 20px;" class="box">
                                <strong>{{ item.questionIndex }}</strong>. {{ item.name }}
                            </span>
                        </div>
                        <div class="p-col-12 p-md-6 p-lg-3" style="float: right">
                            <Rating v-model="item.answer" :stars="5" :cancel="false" class="box"
                                    style="display: inline-block; float: right"
                                    :readonly="!editable" @change="showValid = false"/>
                        </div>
                    </div>
                </li>
            </ul>

            <div v-if="competencesLoaded">
                <h3>Kompetencje</h3>
                <ul id="array-rendering" style="list-style: none">
                    <div v-for="(competence, index) in surveyForm.competenceList" v-bind:key="index">
                        <div style="margin-top: 20px">
                            <h4> {{ competence.name }}</h4>
                        </div>

                        <li v-for="(question, index) in competence.competenceQuestionAnswers" v-bind:key="index"
                            class="question-list-item" style="text-align: left;">
                            <div class="p-grid">
                                <div class="p-col-12 p-md-6 p-lg-9">
                                    <span style="display: inline-block; font-size: 20px;" class="box">
                                        <strong>{{ question.index }}</strong>. {{ question.name }}
                                    </span>
                                </div>
                                <div class="p-col-12 p-md-6 p-lg-3" style="float: right">
                                    <Rating v-model="question.answer" :stars="5" :cancel="false" class="box"
                                            style="display: inline-block; float: right"
                                            :readonly="!editable" @change="showValid = false"/>
                                </div>
                            </div>
                        </li>
                    </div>
                </ul>
            </div>

            <div class="survey-buttons" v-if="editable">

                <div style="padding-top: 15px; margin-right: 10px" class="p-grid">

                    <div class="p-col p-md-6 p-lg-3">
                        <div  class="box">
                            <i class="pi pi-star"/> - Zdecydowanie się nie zgadzam</div>
                    </div>

                    <div class="p-col p-md-6 p-lg-2">
                        <div class="box">
                            <i class="pi pi-star"/><i class="pi pi-star"/> - Raczej się nie zgadzam</div>
                    </div>

                    <div class="p-col p-md-6 p-lg-2">
                        <div class="box">
                            <i class="pi pi-star"/><i class="pi pi-star"/>
                            <i class="pi pi-star"/> - Trudno powiedzieć</div>
                    </div>

                    <div class="p-col p-md-6 p-lg-2">
                        <div class="box">
                            <i class="pi pi-star"/><i class="pi pi-star"/><i class="pi pi-star"/>
                            <i class="pi pi-star"/> - Raczej się zgadzam</div>
                    </div>

                    <div class="p-col p-md-6 p-lg-3">
                        <div class="box">
                            <i class="pi pi-star"/><i class="pi pi-star"/><i class="pi pi-star"/><i class="pi pi-star"/>
                            <i class="pi pi-star"/><i class="pi pi-star"/> - Zdecydowanie się zgadzam</div>
                    </div>
                </div>

                <span v-if="showValid" class="p-invalid" style="font-size: 20px">
                    Przed wysłaniem ankiety należy uzupełnić wszystkie odpowiedzi
                </span>
                <div v-else class="p-mt-3">
                    <Button label="Zapisz" icon="pi pi-file"
                            style="margin-right: 10px"
                            @click="saveSurvey(false)" class="p-button-lg"/>
                    <Button label="Wyślij" icon="pi pi-cloud-upload"
                            style="margin-left: 10px" class="p-button-lg"
                            @click="saveSurvey(true)"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Button from "primevue/button";
    import {
        getEvaluationStatusUsingGET as getEvaluationStatus,
        prepareSurveySubtitleUsingGET as prepareSurveySubtitle,
        prepareSurveyTitleUsingGET as prepareSurveyTitle,
        readExcelUsingGET as readExcel,
        readExcelCompetencesUsingGET as readExcelCompetences,
        saveSurveyUsingPOST as saveSurvey,
    } from "@/swagger/vue-api-client";
    import Rating from "primevue/rating";
    import ProgressSpinner from "primevue/progressspinner";

    export default {
        name: "SurveyView",
        components: {
            Button,
            Rating,
            ProgressSpinner,
        },
        data() {
            return {
                surveyForm: {
                    questionList: [],
                    competenceList: [],
                    evaluationID: this.getIDFromURL(),
                    sent: false,
                },
                showValid: false,
                evaluationCode: this.$route.query.code,
                surveyTitle: "",
                surveySubtitle: "",
                workerFullName: "",
                editable: true,
                notLoaded: true,
                competencesLoaded: false,
            };
        },
        beforeMount() {
            this.importExcel();
            this.notLoaded = false;
        },
        methods: {
            importExcel() {
                readExcel({evaluationCode: this.evaluationCode}).then((response) => {
                    if (!Object.keys(response.data).length) {
                        this.$router.push({name: "surveyCompleted"});
                    } else {
                        this.surveyForm.questionList = response.data;
                        this.success = response.data;
                    }
                }).catch(() => {
                    this.$router.push({name: "notFound"});
                });
                readExcelCompetences({evaluationCode: this.evaluationCode}).then((response) => {
                    if (response.data && response.data.length > 0) {
                        this.surveyForm.competenceList = response.data;
                        this.competencesLoaded = true;
                    }
                }).catch((error) => {
                    console.log(error);
                });
                prepareSurveyTitle({evaluationCode: this.evaluationCode}).then((response) => {
                    this.surveyTitle = response.data;
                });
                prepareSurveySubtitle({evaluationCode: this.evaluationCode}).then((response) => {
                    this.surveySubtitle = response.data;
                    const index = this.surveySubtitle.lastIndexOf(":");
                    this.surveySubtitle = this.surveySubtitle.substr(0, index + 1);
                    this.workerFullName = response.data.substr(index + 1);
                });
                getEvaluationStatus({evaluationCode: this.evaluationCode}).then((response) => {
                    if (response.data === "SENT") {
                        this.editable = false;
                    }
                });
            },

            validateSurveyBeforeSend() {
                let valid = true;
                this.surveyForm.questionList.forEach(
                    (question) => {
                        if (!question || question.answer < 1 || question.answer > 5) {
                            valid = false;
                            this.showValid = true;
                        }
                    },
                );
                this.surveyForm.competenceList.forEach(
                    (question) => {
                        question.competenceQuestionAnswers.forEach((ans) => {
                            if (!ans || ans.answer < 1 || ans.answer > 5) {
                                valid = false;
                                this.showValid = true;
                            }
                        });
                    },
                );
                return valid;
            },

            saveSurvey(sent) {
                this.surveyForm.sent = sent;
                if (sent && !this.validateSurveyBeforeSend()) {
                    this.showValid = true;
                    return;
                }

                if (sent) {
                    this.$confirm.require({
                        header: "Czy na pewno chcesz wysłać ankietę",
                        message: "Po wysłaniu ankiety nie ma możliwości zmian odpowiedzi",
                        icon: "pi pi-exclamation-triangle",
                        acceptLabel: "Tak",
                        rejectLabel: "Nie",
                        accept: () => {
                            this.sendSaveRequest(sent);
                        },
                        reject: () => {
                            // callback to execute when user rejects the action
                        },
                    });
                } else {
                    this.sendSaveRequest(sent);
                }
            },

            sendSaveRequest(sent) {
                saveSurvey({surveyRequest: this.surveyForm}).then((response) => {
                    this.success = response.data;
                    if (sent) {
                        this.$router.push({name: "surveyCompleted"});
                    } else {
                        this.$toast.add({
                            severity: "success",
                            summary: "Sukces",
                            detail: "Zapisano dane ankiety.",
                            life: 3000,
                        });
                    }
                }).catch(() => {
                    this.$toast.add({
                        severity: "error",
                        summary: "Błąd",
                        detail: "Nie udało się zapisać ankiety.",
                        life: 3000,
                    });
                });
            },

            getIDFromURL() {
                return window.location.pathname.split("/")[2];
            },
        },

        computed: {
            isLoading() {
                return this.notLoaded;
            },
        },
    };

</script>

<style scoped>
    .question-list-item {
        background: #f4f4f4;
        padding: 10px;
        margin-top: 20px;
    }

    #array-rendering {
        margin-bottom: 30px;
    }

    .survey-buttons {
        background: #f4f4f4;
        position: sticky;
        bottom: 1px;
        width:100%;
        z-index: 102;
        padding-top: 5px;
        padding-bottom: 20px;
    }
</style>
