import {defineRule} from "vee-validate";

defineRule("required", (value) => {
    if (!value || !value.length) {
        return "To pole jest wymagane";
    }

    return true;
});

defineRule("requiredFile", (value) => {
    if (!value) {
        return "Plik jest wymagany";
    }
    return true;
});

defineRule("email", (value) => {
    // Field is empty, should pass
    if (!value || !value.length) {
        return true;
    }

    // Check if email
    if (!/^[\w+-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value)) {
        return "W tym polu należy podać poprawny adres email";
    }

    return true;
});

/*
 * Sprawdza czy hasło spełnia warunki:
 * Minimum jedna duża litera
 * Minimum jedna małą litera
 * Minimum jedna cyfra
 * Minimum 8 znaków
 */
defineRule("validPassword", (value) => {
    if (!value || !value.length) {
        return "To pole jest wymagane";
    }

    if (!/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/.test(value)) {
        return "Hasło powinno zawierać minimum 8 znaków, duże i małe litery oraz cyfrę";
    }

    return true;
});

defineRule("confirmed", (value, [other, label]) => {
    if (value !== other) {
        return `${label} nie są takie same`;
    }

    return true;
});
