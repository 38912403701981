<template>
    <div style="margin-bottom: 20px">
        <h3>
            Dane podstawowe
        </h3>

        <div class="p-lg-9" style="margin: auto">
            <CustomSelectOneVoivodeship name="voivodeship" label="Województwo"
                                        v-model="internalValue.voivodeship"/>

            <CustomSelectOne name="businessEntityType" label="Kategoria podmiotu"
                             :items="businessEntityCategory"
                             v-model="internalValue.businessEntityType"
                             item-label="label" item-value="value"/>

            <CustomInputNumber name="workersNumber" v-model="internalValue.workersNumber"
                               label="Liczba zatrudnionych pracowników"/>

            <CustomInputNumber name="keyClients" v-model="internalValue.keyClients"
                               label="Liczba (kluczowych/strategicznych) klientów"/>

            <CustomSelectOne name="analysisTimePerspective" label="Perspektywa czasowa analizy"
                             :items="analysisYearsPerspective"
                             v-model="internalValue.analysisTimePerspectiveYears"
                             item-label="label" item-value="value"/>
            <CustomSelectOne name="analysisVariant" label="Wariant analizy"
                             :items="analysisVariantItems"
                             v-model="internalValue.analysisVariant"
                             item-label="label" item-value="value"/>
        </div>
    </div>
</template>

<script>
    import CustomSelectOneVoivodeship from "@/components/form/CustomSelectOneVoivodeship";
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne";
    import CustomInputNumber from "@/components/form/CustomInputNumber";

    export default {
        name: "EfficiencyBasicData",

        components: {
            CustomSelectOneVoivodeship,
            CustomSelectOne,
            CustomInputNumber,
        },

        data() {
            return {
                businessEntityCategory: [
                    {label: "mikroprzedsiębiorstwo", value: "mikroprzedsiębiorstwo"},
                    {label: "małe przedsiębiorstwo", value: "małe przedsiębiorstwo"},
                    {label: "średnie przedsiębiorstwo", value: "średnie przedsiębiorstwo"},
                    {label: "duże przedsiębiorstwo", value: "duże przedsiębiorstwo"},
                ],

                analysisYearsPerspective: [
                    {label: "rok", value: "rok"},
                    {label: "2 lata", value: "2 lata"},
                    {label: "3 lata", value: "3 lata"},
                    {label: "5 lat", value: "5 lat"},
                ],
                analysisVariantItems: [
                    {label: "dane własne", value: "dane własne"},
                    {label: "dane mieszane", value: "dane mieszane"},
                    {label: "dane statystyczne", value: "dane statystyczne"},
                ],
            };
        },
        props: {
            modelValue: null,
        },

        emits: ["update:modelValue"],

        computed: {
            internalValue: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },
            },
        },
    };
</script>

<style scoped>

</style>
