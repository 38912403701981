<template>
    <div>
        <h1 class="page-header" v-if="currentDepartment">
            <i class="pi pi-percentage header-icon"></i>
            Dane podmiotu niezbędne do kalkulacji kosztów i
            korzyści wdrożenia procesu reskillingu
            działu {{currentDepartment.name}}</h1>

        <div v-if="loaded">
            <div v-if="notReady">
                <br>
                <br>
                <br>
                <h4>
                    Analiza finansowa dla działu {{currentDepartment.name}} będzie dostępna po
                    przesłaniu wszystkich ankiet przez pracowników działu.
                </h4>
            </div>
            <div v-else-if="inProgress">
                <br/>
                <br/>
                <br/>
                <h4>
                    Trwa generowanie analizy finansowej. Analiza będzie dostępna w ciągu kilku minut.
                </h4>
                <Button label="Odśwież" icon="pi pi-refresh" @click="getEfficiency()"/>
            </div>
            <div v-else>
                <EfficiencyBasicData v-model="efficiencyMainDTO.basicDataDTO"/>
                <EfficiencyFinancialData v-model="efficiencyMainDTO.financialDataDTO"
                                         :department="currentDepartment"
                                         :analysisVariant="efficiencyMainDTO.basicDataDTO.analysisVariant"/>
                <EfficiencyMotivationData v-model="efficiencyMainDTO.motivationDataDTO"
                                          :analysisVariant="efficiencyMainDTO.basicDataDTO.analysisVariant"/>
                <EfficiencyActivityPricingData v-model="efficiencyMainDTO.activityPricingDTO"
                                               :analysisVariant="efficiencyMainDTO.basicDataDTO.analysisVariant"/>
                <EfficiencyPlannedTrendEffectsTable v-model="efficiencyMainDTO.trendEffectsDTO"
                                                    :analysisVariant="efficiencyMainDTO.basicDataDTO.analysisVariant"/>
                <Button label="Skalkuluj koszty" icon="pi pi-percentage" type="submit"
                        style="margin-top: 40px; margin-bottom: 40px; width: auto; background-color: #247ba0;"
                        @click="saveData"
                        class="p-button-lg">
                </Button>
                <Button label="Przejdź do ostatniej wersji wyników etapu pierwszego"
                        icon="pi pi-play"
                        style="margin-top: 40px;
                        margin-bottom: 40px;
                        width: auto;
                        margin-left: 50px;
                        background-color: #ff6161;"
                        @click="goToStepOne"
                        class="p-button-lg p-button-danger">
                </Button>
                <Button label="Przejdź do ostatniej wersji wyników etapu drugiego"
                        icon="pi pi-forward"
                        style="margin-top: 40px;
                        margin-bottom: 40px;
                        width: auto;
                        margin-left: 50px;
                        background-color: #ff6161;"
                        @click="goToStepTwo"
                        class="p-button-lg p-button-danger">
                </Button>
            </div>
        </div>
        <div v-else>
            <progress-spinner/>
        </div>
    </div>
</template>

<script>
    import {Departments} from "@/utils/Departments";
    import EfficiencyBasicData from "@/views/efficiency/components/EfficiencyBasicData";
    import EfficiencyFinancialData from "@/views/efficiency/components/EfficiencyFinancialData";
    import EfficiencyMotivationData from "@/views/efficiency/components/EfficiencyMotivationData";
    import EfficiencyPlannedTrendEffectsTable from "@/views/efficiency/components/EfficiencyPlannedTrendEffectsData";
    import EfficiencyActivityPricingData from "@/views/efficiency/components/EfficiencyActivityPricingData";
    import Button from "primevue/button";
    import ProgressSpinner from "primevue/progressspinner";
    import {
        getEfficiencyCalculationsUsingGET as getEfficiencyCalculations,
        setEfficiencyCalculationsUsingPOST as setEfficiencyCalculations,
    } from "@/swagger/vue-api-client";

    export default {
        name: "EfficiencyDepartmentMainView",
        components: {
            EfficiencyMotivationData,
            EfficiencyBasicData,
            EfficiencyFinancialData,
            EfficiencyPlannedTrendEffectsTable,
            EfficiencyActivityPricingData,
            Button,
            ProgressSpinner,
        },
        data() {
            return {
                loaded: false,
                currentDepartment: null,
                notReady: false,
                inProgress: false,
                efficiencyMainDTO: {
                },
            };
        },

        beforeMount() {
            if (this.$route.params.department && Departments.getDepartmentByUrlParam(this.$route.params.department)) {
                this.setCurrentDepartment();
                this.getEfficiency();
            } else {
                this.$router.push({name: "notFound"});
            }
        },

        watch: {
            "$route.params.department": function (newVal, oldVal) {
                if (newVal !== oldVal) {
                    this.setCurrentDepartment();
                    this.getEfficiency();
                }
            },
        },

        methods: {
            setCurrentDepartment() {
                this.currentDepartment = Departments.getDepartmentByUrlParam(this.$route.params.department);
            },

            async getEfficiency() {
                this.loaded = false;
                this.notReady = false;
                this.inProgress = false;
                await Promise.resolve(getEfficiencyCalculations(
                    {department: this.currentDepartment.discriminator},
                )).then((response) => {
                    this.loaded = true;
                    this.efficiencyMainDTO = response.data;
                }).catch((error) => {
                    this.loaded = true;
                    if (error.response.status === 409) {
                        if (error.response.data === "NOT_READY") {
                            this.notReady = true;
                        } else if (error.response.data === "IN_PROGRESS") {
                            this.inProgress = true;
                        }
                    }
                });
            },

            goToStepOne() {
                // eslint-disable-next-line max-len
                this.$router.push({name: "efficiencyDepartmentResultsStepOne", params: {department: this.currentDepartment.url}});
            },

            goToStepTwo() {
                // eslint-disable-next-line max-len
                this.$router.push({name: "efficiencyDepartmentCalculationsResults", params: {department: this.currentDepartment.url}});
            },

            async saveData() {
                this.loaded = false;
                this.$toast.add({
                    severity: "info",
                    summary: "Przetwarzanie",
                    detail: "Trwa kalkulacja kosztów",
                    life: 5000,
                });

                await Promise.resolve(setEfficiencyCalculations({efficiencyMainDto: this.efficiencyMainDTO}))
                    .then(() => {
                        window.console.log("pushing to results");
                        this.loaded = true;
                        // eslint-disable-next-line max-len
                        this.$router.push({name: "efficiencyDepartmentResultsStepOne", params: {department: this.currentDepartment.url}});
                        this.$toast.add({
                            severity: "success",
                            summary: "Sukces",
                            detail: "Zapisano obliczenia efektywności w pliku KiK",
                            life: 3000,
                        });
                        // eslint-disable-next-line max-len
                        window.console.log("going to result");
                    }).catch((error) => {
                        if (error.response.status === 403) {
                            this.$toast.add({
                                severity: "error",
                                summary: "Błąd",
                                detail: "Nie masz wystarczających uprawnień",
                                life: 3000,
                            });
                        } else {
                            this.$toast.add({
                                severity: "error",
                                summary: "Błąd",
                                detail: "Wystąpił nieoczekiwany błąd",
                                life: 3000,
                            });
                        }
                    });
            },
        },
    };
</script>
