<template>
    <Toast/>
    <div class="activate-worker-account">
        <div v-if="showPage === 'REGISTER'">
            <div class="p-grid p-col-12 p-md-8 p-xl-4 p-align-center" style="margin: auto">
                <h1>Dodaj hasło aby aktywować konto</h1>
                <div class="p-grid" style="width: 100%">
                    <div class="p-col-12">
                        <Form @submit="registerUser" v-slot="{ isSubmitting }">
                            <CustomPassword name="newPassword"
                                            v-model="userData.password"
                                            :label="$t('message.global.password')"
                                            :toggle-mask="true"
                                            rules="required|validPassword"/>
                            <CustomPassword name="newPasswordRepeat"
                                            v-model="userData.repeatPassword"
                                            :toggle-mask="true"
                                            :label="$t('message.global.repeatPassword')"
                                            rules="required|confirmed:@newPassword,Hasła"/>
                            <Button label="Ustaw hasło i aktywuj konto" icon="pi pi-check" type="submit"
                                    style="margin-top: 40px; width: auto;"
                                    class="p-button" :disabled="isSubmitting">
                            </Button>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="showPage === 'ALREADY_ACTIVATED'">
            <h1>To konto zostało już aktywowane</h1>
            <br/>
            <router-link :to="{name: 'login'}">Zaloguj się</router-link>
        </div>
        <div v-if="showPage === 'LOADING'">
            <h1>Proszę czekać</h1>
            <br>
            <progress-spinner/>
        </div>
        <div v-if="showPage === 'ACTIVATED'">
            <h1>Twoje konto zostało aktywowane</h1>
            <br/>
            <router-link :to="{name: 'login'}">Zaloguj się</router-link>
        </div>
    </div>
</template>

<script>
    import CustomPassword from "@/components/form/CustomPassword";
    import ProgressSpinner from "primevue/progressspinner";
    import Button from "primevue/button";
    import Toast from "primevue/toast";
    import {Form} from "vee-validate";
    import {
        verifyWorkersEmailAndSetPasswordUsingPOST as activateAccount,
        isWorkerVerifiedByEmailVerificationTokenUsingGET as isWorkerActive,
    } from "@/swagger/vue-api-client";

    export default {
        name: "ActivateWorkerAccount",
        components: {
            CustomPassword, Form, Button, ProgressSpinner, Toast,
        },

        data() {
            return {
                userData: {
                    password: null,
                    repeatPassword: null,
                },
                token: this.$route.query.token,
                showPage: "LOADING",
            };
        },

        mounted() {
            if (!this.token) {
                this.redirectToErrorPage();
            }
            isWorkerActive({token: this.token})
                .then((response) => {
                    if (response.data.active) {
                        this.showPage = "ALREADY_ACTIVATED";
                    } else {
                        this.showPage = "REGISTER";
                    }
                })
                .catch(() => {
                    this.redirectToErrorPage();
                });
        },

        methods: {
            registerUser() {
                const passwordRequestData = {
                    resetPasswordToken: this.token,
                    newPassword: this.userData.password,
                };

                activateAccount({passwordRequest: passwordRequestData})
                    .then(() => {
                        this.showPage = "ACTIVATED";
                    })
                    .catch(() => {
                        this.$toast.add({
                            severity: "error",
                            summary: "Błąd",
                            detail: "Nastąpił nieoczekiwany błąd",
                            life: 3000,
                        });
                    });
            },
            redirectToErrorPage() {
                this.$router.push({name: "error"});
            },
        },

    };
</script>

<style lang="less">
    @import "../assets/less/registration.less";
</style>
