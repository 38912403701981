<template>
    <div id="app">
        <router-view/>
    </div>
</template>

<style lang="less">
    #app {
        font-family: "Avenir", Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #495057;
    }

    body {
        margin: 0;
    }

</style>
