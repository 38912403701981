<template>
    <div style="margin-bottom: 40px">
        <h3>
            Dane finansowe
        </h3>

        <div class="p-lg-9" style="margin: auto">
            <div>
                <h5 style="margin-bottom: 40px">
                    Proszę podać wartości w poniższych kategoriach dotyczące okresu 2 poprzednich lat obrotowych
                </h5>

                <div class="p-lg-9" style="margin: auto">
                    <table class="tableizer-table">
                        <thead>
                            <tr class="tableizer-firstrow">
                                <th>Kategoria</th>
                                <th>{{internalValue.financialYears[0].year}}</th>
                                <th>{{internalValue.financialYears[1].year}}</th>
                            </tr>
                        </thead>

                        <tbody style="text-align: left">
                            <tr>
                                <td>Przychody (ogółem)</td>
                                <td>
                                    <CustomInputNumber  name="yearOneValue"
                                                        v-model="internalValue.financialYears[0].totalIncome"
                                                        mode="currency"
                                                        locale="pl-PL"
                                                        currency="PLN"/>
                                </td>
                                <td>
                                    <CustomInputNumber  name="yearOneValue"
                                                        v-model="internalValue.financialYears[1].totalIncome"
                                                        mode="currency"
                                                        locale="pl-PL"
                                                        currency="PLN"/>
                                </td>
                            </tr>
                            <tr>
                                <td>Koszty (ogółem), w tym:</td>
                                <td>
                                    <CustomInputNumber  name="yearOneValue"
                                                        v-model="internalValue.financialYears[0].totalCosts"
                                                        mode="currency"
                                                        locale="pl-PL"
                                                        currency="PLN"/>
                                </td>
                                <td>
                                    <CustomInputNumber  name="yearOneValue"
                                                        v-model="internalValue.financialYears[1].totalCosts"
                                                        mode="currency"
                                                        locale="pl-PL"
                                                        currency="PLN"/>
                                </td>
                            </tr>
                        </tbody></table>
                </div>

                <h5 style="margin-bottom: 40px; margin-top:60px;">
                    Proszę podać wartości aktualnego wynagrodzenia brutto pracowników
                    zaangażowanych w proces reskillingu w podmiocie bądź skorzystać z danych statystycznych
                </h5>

                <div style="margin-bottom: 40px">
                    <DataTable :value="internalValue.workersWagesAndPositions"
                               responsiveLayout="scroll">
                        <Column field="fullName" header="Pracownik" style="width:25%">
                            <template #body="slotProps">
                                {{ slotProps.data.fullName }}
                            </template>
                        </Column>

                        <Column field="wageCalculationChoice" style="width:25%">
                            <template #body="slotProps">
                                <CustomSelectOne v-if="analysisVariant==='dane mieszane'"
                                                 v-model="slotProps.data.wageCalculationChoice"
                                                 :items="workerSalaryChoices"
                                                 item-label="label"
                                                 item-value="value"
                                                 label="Wybierz sposób wyliczenia wynagrodzenia"/>
                            </template>
                        </Column>

                        <Column field="workerPosition" header="Pole wyboru stanowiska" style="width:25%">
                            <template #body="slotProps">
                                <CustomSelectOne
                                    :disabled="
                                        isWorkerPositionDisabled(slotProps.data.wageCalculationChoice)"
                                    v-model="slotProps.data.positionChoice"
                                    :items="workerPositionsForDepartment(department.discriminator)"
                                    item-label="value"
                                    item-value="value" label="Pole wyboru stanowiska"/>
                            </template>
                        </Column>

                        <Column field="grossWage" header="Wartość brutto wynagrodzenia" style="width:25%">
                            <template #body="slotProps">
                                <CustomInputNumber
                                    :disabled="
                                        isGrossWageDisabled(slotProps.data.wageCalculationChoice)"
                                    name="grossWage"
                                    v-model="slotProps.data.grossWage"
                                    mode="currency" currency="PLN" locale="pl-PL"/>
                            </template>
                        </Column>

                        <template #footer>
                            <div style="display: flex; " v-if="internalValue.implementingWorker">
                                <div style="width:25%">wynagrodzenie pracownika odpowiedzialnego za wdrożenie
                                    rozwiązania w podmiocie / średnie wynagrodzenie pracowników
                                    zaangażowanych w proces wdrożenia reskillingu </div>
                                <div style="width:25%">
                                    <CustomSelectOne v-if="analysisVariant==='dane mieszane'"
                                                     v-model="internalValue.implementingWorker.wageCalculationChoice"
                                                     :items="workerSalaryChoices"
                                                     item-label="label"
                                                     item-value="value"
                                                     label="Wybierz sposób wyliczenia wynagrodzenia"/>
                                </div>

                                <div style="width:25%">
                                    <CustomSelectOne
                                        :disabled="isWorkerPositionDisabled(
                                            internalValue.implementingWorker.wageCalculationChoice)"
                                        v-model="internalValue.implementingWorker.positionChoice"
                                        :items="workerPositionsForDepartment(department.discriminator)"
                                        label="Pole wyboru stanowiska"
                                        item-label="value" item-value="value"/>
                                </div>

                                <div style="width:25%">
                                    <CustomInputNumber
                                        :disabled="
                                            isGrossWageDisabled(internalValue.implementingWorker.wageCalculationChoice)"
                                        name="grossWage"
                                        v-model="internalValue.implementingWorker.grossWage"
                                        mode="currency" currency="PLN" locale="pl-PL"/>
                                </div>
                            </div>
                        </template>
                    </DataTable>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne";
    import CustomInputNumber from "@/components/form/CustomInputNumber";
    import Column from "primevue/column";
    import DataTable from "primevue/datatable";

    export default {
        name: "EfficiencyFinancialData",

        components: {
            CustomSelectOne,
            CustomInputNumber,
            Column,
            DataTable,
        },

        data() {
            return {
                workerSalaryChoices: [
                    {
                        label: "chcę podać wartość samodzielnie",
                        value: "chcę podać wartość samodzielnie",
                    },
                    {
                        label: "chcę skorzystać z danych statystycznych",
                        value: "chcę skorzystać z danych statystycznych",
                    },
                ],

                workersPositions: [
                    // PRODUCTION
                    {value: "dyrektor do spraw produkcji", departament: "PRODUCTION"},
                    {value: "kierownik produkcji", departament: "PRODUCTION"},
                    {value: "główny technolog produkcji", departament: "PRODUCTION"},
                    {value: "inżynier procesu produkcji", departament: "PRODUCTION"},
                    {value: "mistrz zmiany w dziale produkcji", departament: "PRODUCTION"},
                    {value: "brygadzista (produkcja)", departament: "PRODUCTION"},
                    {value: "kontroler produkcji", departament: "PRODUCTION"},
                    {value: "asystent w dziale produkcji", departament: "PRODUCTION"},
                    {value: "operator linii produkcyjnej", departament: "PRODUCTION"},
                    {value: "operator maszyn i urządzeń produkcyjnych", departament: "PRODUCTION"},
                    {value: "planista produkcyjny", departament: "PRODUCTION"},
                    {value: "pracownik produkcji", departament: "PRODUCTION"},
                    {value: "specjalista ds. planowania produkcji", departament: "PRODUCTION"},
                    {value: "specjalista ds. przygotowania produkcji", departament: "PRODUCTION"},
                    // TRANSPORT_LOGISTICS
                    {value: "dyrektor do spraw logistyki i/lub transportu", departament: "TRANSPORT_LOGISTICS"},
                    {value: "kierownik do spraw logistyki", departament: "TRANSPORT_LOGISTICS"},
                    {value: "kierownik do spraw spedycji i transportu", departament: "TRANSPORT_LOGISTICS"},
                    {value: "specjalista do spraw logistyki i/lub transportu", departament: "TRANSPORT_LOGISTICS"},
                    {value: "brygadzista w dziale logistyki", departament: "TRANSPORT_LOGISTICS"},
                    {value: "asystent do spraw logistyki i/lub transportu", departament: "TRANSPORT_LOGISTICS"},
                    {value: "pracownik działu logistyki i/lub transportu", departament: "TRANSPORT_LOGISTICS"},
                    {value: "dyspozytor transportu", departament: "TRANSPORT_LOGISTICS"},
                    {value: "kierowca samochodu dostawczego", departament: "TRANSPORT_LOGISTICS"},
                    // MARKETING_SALES
                    {value: "dyrektor do spraw sprzedaży i marketingu", departament: "MARKETING_SALES"},
                    {value: "dyrektor do spraw sprzedaży", departament: "MARKETING_SALES"},
                    {value: "dyrektor do spraw marketingu", departament: "MARKETING_SALES"},
                    {value: "kierownik do spraw sprzedaży", departament: "MARKETING_SALES"},
                    {value: "kierownik do spraw marketingu", departament: "MARKETING_SALES"},
                    {value: "specjalista do spraw spraw sprzedaży i marketingu", departament: "MARKETING_SALES"},
                    {value: "specjalista do spraw sprzedaży", departament: "MARKETING_SALES"},
                    {value: "specjalista do spraw marketingu", departament: "MARKETING_SALES"},
                    {value: "asystent do spraw sprzedaży", departament: "MARKETING_SALES"},
                    {value: "asystent do spraw marketingu", departament: "MARKETING_SALES"},
                    // ADMINISTRATION
                    {value: "dyrektor do spraw administracyjnych", departament: "ADMINISTRATION"},
                    {value: "główny księgowy", departament: "ADMINISTRATION"},
                    {value: "kierownik do spraw administracyjnych", departament: "ADMINISTRATION"},
                    {value: "kierownik do spraw księgowości", departament: "ADMINISTRATION"},
                    {value: "kierownik działu kadr i płac", departament: "ADMINISTRATION"},
                    {value: "specjalista do spraw administracji", departament: "ADMINISTRATION"},
                    {value: "samodzielny księgowy", departament: "ADMINISTRATION"},
                    {value: "specjalista do spraw kadr i płac", departament: "ADMINISTRATION"},
                    {value: "asystent do spraw administracyjnych", departament: "ADMINISTRATION"},
                    {value: "asystent do spraw księgowości", departament: "ADMINISTRATION"},
                    {value: "pracownik administracyjno-biurowy", departament: "ADMINISTRATION"},
                    // CONSTRUCTION_DESIGNING
                    {value: "projektant mebli", departament: "CONSTRUCTION_DESIGNING"},
                    {value: "inżynier konstruktor", departament: "CONSTRUCTION_DESIGNING"},
                    {value: "kierownik działu konstrukcyjnego", departament: "CONSTRUCTION_DESIGNING"},
                    {value: "konstruktor", departament: "CONSTRUCTION_DESIGNING"},
                    {value: "technolog konstruktor", departament: "CONSTRUCTION_DESIGNING"},
                ],
            };
        },

        props: {
            modelValue: null,
            department: null,
            analysisVariant: {
                type: String,
                default: null,
            },
        },

        emits: ["update:modelValue"],

        computed: {
            internalValue: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },
            },
        },

        methods: {
            isGrossWageDisabled(val) {
                if (this.analysisVariant === "dane własne") {
                    return false;
                } if (this.analysisVariant === "dane mieszane") {
                    return val !== "chcę podać wartość samodzielnie";
                }
                return true;
            },

            isWorkerPositionDisabled(val) {
                if (this.analysisVariant === "dane statystyczne") {
                    return false;
                } if (this.analysisVariant === "dane mieszane") {
                    return val !== "chcę skorzystać z danych statystycznych";
                }
                return true;
            },

            workerPositionsForDepartment(discriminator) {
                return this.workersPositions.filter((position) => position.departament === discriminator);
            },
        },
    };
</script>

<style scoped>
    table.tableizer-table {
        font-size: 1rem;
    }
    .tableizer-table td {
        padding: 0.3rem 0.5rem;
        margin: 10px;
        border-top: 1px solid #CCC;
    }
    .tableizer-table th {
        color: #495057;
        padding: 1rem;
        font-weight: bold;
    }
</style>
