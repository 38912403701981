<template>
    <div>
        <DataTable ref="companyExcelFilesTable" :value="files" :lazy="true" :paginator="true"
                   v-model:rows="searchParams.page.limit"
                   removableSort :totalRecords="totalRecords" :loading="loading" @page="onPage"
                   @sort="onPage" :rowsPerPageOptions="[5,10,20,50]" :autoLayout="true"
                   :rowHover="true">
            <template #empty>
                Nie znaleziono plików excel
            </template>
            <template #loading>
                Trwa wczytywanie plików excel. Proszę czekać
            </template>
            <Column field="name" header="Nazwa"></Column>
            <Column field="filename" header="Nazwa pliku"></Column>
            <Column field="description" header="Opis pliku"></Column>
            <Column header="Akcje" headerStyle="width: 200px; text-align: center">
                <template #body="{data}">
                    <span style="display: flex; justify-content: center">
                        <Button
                            type="button"
                            icon="pi pi-download"
                            class="p-button-success p-ml-2 p-button-rounded p-button-outlined"
                            @click="downloadExcelFile(data)"
                            v-tooltip.top="'Pobierz plik excel'">
                        </Button>
                    </span>
                </template>
            </Column>
        </DataTable>
    </div>
</template>

<script>
    import DataTable from "primevue/datatable";
    import Column from "primevue/column";
    import Button from "primevue/button";
    import Tooltip from "primevue/tooltip";
    import {
        searchCompanyExcelFilesUsingPOST as searchCompanyExcelFiles,
        downloadExcelFileUsingGETURL as downloadFileUrl,
        searchCompanyExcelFilesCountUsingPOST as searchCompanyExcelFilesCount,
    } from "@/swagger/vue-api-client";
    import {FileUtils} from "@/utils/FileUtils";

    export default {
        name: "CompanyExcelFilesTable",
        components: {
            DataTable,
            Column,
            Button,
        },
        mixins: [FileUtils],
        directives: {
            tooltip: Tooltip,
        },

        props: {
            searchCriteria: {
                type: Object,
            },
        },

        data() {
            return {
                searchParams: this.searchCriteria,
                loading: false,
                totalRecords: 0,
                files: [],
            };
        },
        methods: {

            onPage(event) {
                this.loading = true;

                this.searchParams.page.offset = event.first;
                this.searchParams.page.limit = event.rows;
                this.searchParams.page.sortField = "name";
                this.searchParams.page.sortOrder = 1;

                this.search();
            },

            search() {
                searchCompanyExcelFiles({companyId: this.$route.params.id, searchCriteria: this.searchCriteria})
                    .then((response) => {
                        this.files = response.data;
                        this.loading = false;
                    });
            },

            updateTotalRecords() {
                searchCompanyExcelFilesCount({companyId: this.$route.params.id, searchCriteria: this.searchCriteria})
                    .then((response) => {
                        this.totalRecords = response.data;
                    });
            },

            getFirstPage() {
                return {
                    first: this.searchCriteria.page.offset,
                    rows: this.searchCriteria.page.limit,
                };
            },

            async downloadExcelFile(file) {
                await this.downloadFileAsBlob(this, downloadFileUrl({id: file.id}), file.filename);
            },
        },

        mounted() {
            this.updateTotalRecords();
            this.onPage(this.getFirstPage());
        },
    };
</script>

<style lang="less" scoped>
@import "src/views/assets/less/files-view.less";
</style>
