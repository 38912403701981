/* eslint-disable */
import axios from 'axios'
import qs from 'qs'
let domain = ''
export const getDomain = () => {
  return domain
}
export const setDomain = ($domain) => {
  domain = $domain
}
export const request = (method, url, body, queryParameters, form, config) => {
  method = method.toLowerCase()
  let keys = Object.keys(queryParameters)
  let queryUrl = url
  if (keys.length > 0) {
    queryUrl = url + '?' + qs.stringify(queryParameters)
  }
  // let queryUrl = url+(keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
  if (body) {
    return axios[method](queryUrl, body, config)
  } else if (method === 'get') {
    return axios[method](queryUrl, {
      params: form
    }, config)
  } else {
    return axios[method](queryUrl, qs.stringify(form), config)
  }
}
/*==========================================================
 *                    Api Documentation
 ==========================================================*/
/**
 * getAllCompanies
 * request: getAllCompaniesUsingGET
 * url: getAllCompaniesUsingGETURL
 * method: getAllCompaniesUsingGET_TYPE
 * raw_url: getAllCompaniesUsingGET_RAW_URL
 */
export const getAllCompaniesUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/company/all-existing-companies'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getAllCompaniesUsingGET_RAW_URL = function() {
  return '/company/all-existing-companies'
}
export const getAllCompaniesUsingGET_TYPE = function() {
  return 'get'
}
export const getAllCompaniesUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/company/all-existing-companies'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getAllPublishedCompanies
 * request: getAllPublishedCompaniesUsingGET
 * url: getAllPublishedCompaniesUsingGETURL
 * method: getAllPublishedCompaniesUsingGET_TYPE
 * raw_url: getAllPublishedCompaniesUsingGET_RAW_URL
 * @param onlyWithLogo - onlyWithLogo
 */
export const getAllPublishedCompaniesUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/company/all-public-companies'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['onlyWithLogo'] !== undefined) {
    queryParameters['onlyWithLogo'] = parameters['onlyWithLogo']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getAllPublishedCompaniesUsingGET_RAW_URL = function() {
  return '/company/all-public-companies'
}
export const getAllPublishedCompaniesUsingGET_TYPE = function() {
  return 'get'
}
export const getAllPublishedCompaniesUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/company/all-public-companies'
  if (parameters['onlyWithLogo'] !== undefined) {
    queryParameters['onlyWithLogo'] = parameters['onlyWithLogo']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * editCompany
 * request: editCompanyUsingPUT
 * url: editCompanyUsingPUTURL
 * method: editCompanyUsingPUT_TYPE
 * raw_url: editCompanyUsingPUT_RAW_URL
 * @param companyDto - companyDto
 */
export const editCompanyUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/company/edit'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['companyDto'] !== undefined) {
    body = parameters['companyDto']
  }
  if (parameters['companyDto'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: companyDto'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const editCompanyUsingPUT_RAW_URL = function() {
  return '/company/edit'
}
export const editCompanyUsingPUT_TYPE = function() {
  return 'put'
}
export const editCompanyUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/company/edit'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * companyExist
 * request: companyExistUsingGET
 * url: companyExistUsingGETURL
 * method: companyExistUsingGET_TYPE
 * raw_url: companyExistUsingGET_RAW_URL
 * @param companyName - companyName
 */
export const companyExistUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/company/exists'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['companyName'] !== undefined) {
    queryParameters['companyName'] = parameters['companyName']
  }
  if (parameters['companyName'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: companyName'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const companyExistUsingGET_RAW_URL = function() {
  return '/company/exists'
}
export const companyExistUsingGET_TYPE = function() {
  return 'get'
}
export const companyExistUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/company/exists'
  if (parameters['companyName'] !== undefined) {
    queryParameters['companyName'] = parameters['companyName']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * changePublicationStatus
 * request: changePublicationStatusUsingPOST
 * url: changePublicationStatusUsingPOSTURL
 * method: changePublicationStatusUsingPOST_TYPE
 * raw_url: changePublicationStatusUsingPOST_RAW_URL
 * @param companyIdRequest - companyIdRequest
 */
export const changePublicationStatusUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/company/publish'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['companyIdRequest'] !== undefined) {
    body = parameters['companyIdRequest']
  }
  if (parameters['companyIdRequest'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: companyIdRequest'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const changePublicationStatusUsingPOST_RAW_URL = function() {
  return '/company/publish'
}
export const changePublicationStatusUsingPOST_TYPE = function() {
  return 'post'
}
export const changePublicationStatusUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/company/publish'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * registerCompany
 * request: registerCompanyUsingPOST
 * url: registerCompanyUsingPOSTURL
 * method: registerCompanyUsingPOST_TYPE
 * raw_url: registerCompanyUsingPOST_RAW_URL
 * @param companyRegistrationDto - companyRegistrationDto
 */
export const registerCompanyUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/company/register'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['companyRegistrationDto'] !== undefined) {
    body = parameters['companyRegistrationDto']
  }
  if (parameters['companyRegistrationDto'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: companyRegistrationDto'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const registerCompanyUsingPOST_RAW_URL = function() {
  return '/company/register'
}
export const registerCompanyUsingPOST_TYPE = function() {
  return 'post'
}
export const registerCompanyUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/company/register'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchCompany
 * request: searchCompanyUsingPOST
 * url: searchCompanyUsingPOSTURL
 * method: searchCompanyUsingPOST_TYPE
 * raw_url: searchCompanyUsingPOST_RAW_URL
 * @param searchCriteria - searchCriteria
 */
export const searchCompanyUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/company/search'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['searchCriteria'] !== undefined) {
    body = parameters['searchCriteria']
  }
  if (parameters['searchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: searchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchCompanyUsingPOST_RAW_URL = function() {
  return '/company/search'
}
export const searchCompanyUsingPOST_TYPE = function() {
  return 'post'
}
export const searchCompanyUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/company/search'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchCompanyCount
 * request: searchCompanyCountUsingPOST
 * url: searchCompanyCountUsingPOSTURL
 * method: searchCompanyCountUsingPOST_TYPE
 * raw_url: searchCompanyCountUsingPOST_RAW_URL
 * @param searchCriteria - searchCriteria
 */
export const searchCompanyCountUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/company/search/count'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['searchCriteria'] !== undefined) {
    body = parameters['searchCriteria']
  }
  if (parameters['searchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: searchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchCompanyCountUsingPOST_RAW_URL = function() {
  return '/company/search/count'
}
export const searchCompanyCountUsingPOST_TYPE = function() {
  return 'post'
}
export const searchCompanyCountUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/company/search/count'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getCompany
 * request: getCompanyUsingGET
 * url: getCompanyUsingGETURL
 * method: getCompanyUsingGET_TYPE
 * raw_url: getCompanyUsingGET_RAW_URL
 * @param id - id
 */
export const getCompanyUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/company/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getCompanyUsingGET_RAW_URL = function() {
  return '/company/{id}'
}
export const getCompanyUsingGET_TYPE = function() {
  return 'get'
}
export const getCompanyUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/company/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getDiplomaForUser
 * request: getDiplomaForUserUsingGET
 * url: getDiplomaForUserUsingGETURL
 * method: getDiplomaForUserUsingGET_TYPE
 * raw_url: getDiplomaForUserUsingGET_RAW_URL
 * @param elearningId - elearningId
 */
export const getDiplomaForUserUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/diploma/{elearningId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{elearningId}', `${parameters['elearningId']}`)
  if (parameters['elearningId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: elearningId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getDiplomaForUserUsingGET_RAW_URL = function() {
  return '/diploma/{elearningId}'
}
export const getDiplomaForUserUsingGET_TYPE = function() {
  return 'get'
}
export const getDiplomaForUserUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/diploma/{elearningId}'
  path = path.replace('{elearningId}', `${parameters['elearningId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * publicELearnings
 * request: publicELearningsUsingGET
 * url: publicELearningsUsingGETURL
 * method: publicELearningsUsingGET_TYPE
 * raw_url: publicELearningsUsingGET_RAW_URL
 */
export const publicELearningsUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/elearning/all_public'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const publicELearningsUsingGET_RAW_URL = function() {
  return '/elearning/all_public'
}
export const publicELearningsUsingGET_TYPE = function() {
  return 'get'
}
export const publicELearningsUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/elearning/all_public'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * assignedELearnings
 * request: assignedELearningsUsingGET
 * url: assignedELearningsUsingGETURL
 * method: assignedELearningsUsingGET_TYPE
 * raw_url: assignedELearningsUsingGET_RAW_URL
 */
export const assignedELearningsUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/elearning/assigned'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const assignedELearningsUsingGET_RAW_URL = function() {
  return '/elearning/assigned'
}
export const assignedELearningsUsingGET_TYPE = function() {
  return 'get'
}
export const assignedELearningsUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/elearning/assigned'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * finishELearning
 * request: finishELearningUsingPOST
 * url: finishELearningUsingPOSTURL
 * method: finishELearningUsingPOST_TYPE
 * raw_url: finishELearningUsingPOST_RAW_URL
 * @param eLearningId - eLearningId
 */
export const finishELearningUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/elearning/finished/{eLearningId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{eLearningId}', `${parameters['eLearningId']}`)
  if (parameters['eLearningId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: eLearningId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const finishELearningUsingPOST_RAW_URL = function() {
  return '/elearning/finished/{eLearningId}'
}
export const finishELearningUsingPOST_TYPE = function() {
  return 'post'
}
export const finishELearningUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/elearning/finished/{eLearningId}'
  path = path.replace('{eLearningId}', `${parameters['eLearningId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * saveQuizResults
 * request: saveQuizResultsUsingPOST
 * url: saveQuizResultsUsingPOSTURL
 * method: saveQuizResultsUsingPOST_TYPE
 * raw_url: saveQuizResultsUsingPOST_RAW_URL
 * @param eLearningId - eLearningId
 * @param quizDto - quizDTO
 */
export const saveQuizResultsUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/elearning/quizResults/{eLearningId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{eLearningId}', `${parameters['eLearningId']}`)
  if (parameters['eLearningId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: eLearningId'))
  }
  if (parameters['quizDto'] !== undefined) {
    body = parameters['quizDto']
  }
  if (parameters['quizDto'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: quizDto'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const saveQuizResultsUsingPOST_RAW_URL = function() {
  return '/elearning/quizResults/{eLearningId}'
}
export const saveQuizResultsUsingPOST_TYPE = function() {
  return 'post'
}
export const saveQuizResultsUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/elearning/quizResults/{eLearningId}'
  path = path.replace('{eLearningId}', `${parameters['eLearningId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getELearningStep
 * request: getELearningStepUsingPUT
 * url: getELearningStepUsingPUTURL
 * method: getELearningStepUsingPUT_TYPE
 * raw_url: getELearningStepUsingPUT_RAW_URL
 * @param eLearningId - eLearningId
 * @param stepId - stepId
 */
export const getELearningStepUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/elearning/step/{eLearningId}/{stepId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{eLearningId}', `${parameters['eLearningId']}`)
  if (parameters['eLearningId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: eLearningId'))
  }
  path = path.replace('{stepId}', `${parameters['stepId']}`)
  if (parameters['stepId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: stepId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const getELearningStepUsingPUT_RAW_URL = function() {
  return '/elearning/step/{eLearningId}/{stepId}'
}
export const getELearningStepUsingPUT_TYPE = function() {
  return 'put'
}
export const getELearningStepUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/elearning/step/{eLearningId}/{stepId}'
  path = path.replace('{eLearningId}', `${parameters['eLearningId']}`)
  path = path.replace('{stepId}', `${parameters['stepId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getElearningStepsNumber
 * request: getElearningStepsNumberUsingGET
 * url: getElearningStepsNumberUsingGETURL
 * method: getElearningStepsNumberUsingGET_TYPE
 * raw_url: getElearningStepsNumberUsingGET_RAW_URL
 * @param eLearningId - eLearningId
 */
export const getElearningStepsNumberUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/elearning/steps/{eLearningId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{eLearningId}', `${parameters['eLearningId']}`)
  if (parameters['eLearningId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: eLearningId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getElearningStepsNumberUsingGET_RAW_URL = function() {
  return '/elearning/steps/{eLearningId}'
}
export const getElearningStepsNumberUsingGET_TYPE = function() {
  return 'get'
}
export const getElearningStepsNumberUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/elearning/steps/{eLearningId}'
  path = path.replace('{eLearningId}', `${parameters['eLearningId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getElearning
 * request: getElearningUsingGET
 * url: getElearningUsingGETURL
 * method: getElearningUsingGET_TYPE
 * raw_url: getElearningUsingGET_RAW_URL
 * @param id - id
 */
export const getElearningUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/elearning/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getElearningUsingGET_RAW_URL = function() {
  return '/elearning/{id}'
}
export const getElearningUsingGET_TYPE = function() {
  return 'get'
}
export const getElearningUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/elearning/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * emailExist
 * request: emailExistUsingGET
 * url: emailExistUsingGETURL
 * method: emailExistUsingGET_TYPE
 * raw_url: emailExistUsingGET_RAW_URL
 * @param email - email
 */
export const emailExistUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/email-exists'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['email'] !== undefined) {
    queryParameters['email'] = parameters['email']
  }
  if (parameters['email'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: email'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const emailExistUsingGET_RAW_URL = function() {
  return '/email-exists'
}
export const emailExistUsingGET_TYPE = function() {
  return 'get'
}
export const emailExistUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/email-exists'
  if (parameters['email'] !== undefined) {
    queryParameters['email'] = parameters['email']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * activateEmployerAccount
 * request: activateEmployerAccountUsingPOST
 * url: activateEmployerAccountUsingPOSTURL
 * method: activateEmployerAccountUsingPOST_TYPE
 * raw_url: activateEmployerAccountUsingPOST_RAW_URL
 * @param userIdRequest - userIdRequest
 */
export const activateEmployerAccountUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/employer/activate-account'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['userIdRequest'] !== undefined) {
    body = parameters['userIdRequest']
  }
  if (parameters['userIdRequest'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: userIdRequest'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const activateEmployerAccountUsingPOST_RAW_URL = function() {
  return '/employer/activate-account'
}
export const activateEmployerAccountUsingPOST_TYPE = function() {
  return 'post'
}
export const activateEmployerAccountUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/employer/activate-account'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchEmployer
 * request: searchEmployerUsingPOST
 * url: searchEmployerUsingPOSTURL
 * method: searchEmployerUsingPOST_TYPE
 * raw_url: searchEmployerUsingPOST_RAW_URL
 * @param searchCriteria - searchCriteria
 */
export const searchEmployerUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/employer/search/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['searchCriteria'] !== undefined) {
    body = parameters['searchCriteria']
  }
  if (parameters['searchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: searchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchEmployerUsingPOST_RAW_URL = function() {
  return '/employer/search/'
}
export const searchEmployerUsingPOST_TYPE = function() {
  return 'post'
}
export const searchEmployerUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/employer/search/'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * error
 * request: errorUsingGET
 * url: errorUsingGETURL
 * method: errorUsingGET_TYPE
 * raw_url: errorUsingGET_RAW_URL
 */
export const errorUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/error'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const errorUsingGET_RAW_URL = function() {
  return '/error'
}
export const errorUsingGET_TYPE = function() {
  return 'get'
}
export const errorUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/error'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * error
 * request: errorUsingHEAD
 * url: errorUsingHEADURL
 * method: errorUsingHEAD_TYPE
 * raw_url: errorUsingHEAD_RAW_URL
 */
export const errorUsingHEAD = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/error'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('head', domain + path, body, queryParameters, form, config)
}
export const errorUsingHEAD_RAW_URL = function() {
  return '/error'
}
export const errorUsingHEAD_TYPE = function() {
  return 'head'
}
export const errorUsingHEADURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/error'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * error
 * request: errorUsingPOST
 * url: errorUsingPOSTURL
 * method: errorUsingPOST_TYPE
 * raw_url: errorUsingPOST_RAW_URL
 */
export const errorUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/error'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const errorUsingPOST_RAW_URL = function() {
  return '/error'
}
export const errorUsingPOST_TYPE = function() {
  return 'post'
}
export const errorUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/error'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * error
 * request: errorUsingPUT
 * url: errorUsingPUTURL
 * method: errorUsingPUT_TYPE
 * raw_url: errorUsingPUT_RAW_URL
 */
export const errorUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/error'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const errorUsingPUT_RAW_URL = function() {
  return '/error'
}
export const errorUsingPUT_TYPE = function() {
  return 'put'
}
export const errorUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/error'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * error
 * request: errorUsingDELETE
 * url: errorUsingDELETEURL
 * method: errorUsingDELETE_TYPE
 * raw_url: errorUsingDELETE_RAW_URL
 */
export const errorUsingDELETE = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/error'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const errorUsingDELETE_RAW_URL = function() {
  return '/error'
}
export const errorUsingDELETE_TYPE = function() {
  return 'delete'
}
export const errorUsingDELETEURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/error'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * error
 * request: errorUsingOPTIONS
 * url: errorUsingOPTIONSURL
 * method: errorUsingOPTIONS_TYPE
 * raw_url: errorUsingOPTIONS_RAW_URL
 */
export const errorUsingOPTIONS = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/error'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('options', domain + path, body, queryParameters, form, config)
}
export const errorUsingOPTIONS_RAW_URL = function() {
  return '/error'
}
export const errorUsingOPTIONS_TYPE = function() {
  return 'options'
}
export const errorUsingOPTIONSURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/error'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * error
 * request: errorUsingPATCH
 * url: errorUsingPATCHURL
 * method: errorUsingPATCH_TYPE
 * raw_url: errorUsingPATCH_RAW_URL
 */
export const errorUsingPATCH = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/error'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('patch', domain + path, body, queryParameters, form, config)
}
export const errorUsingPATCH_RAW_URL = function() {
  return '/error'
}
export const errorUsingPATCH_TYPE = function() {
  return 'patch'
}
export const errorUsingPATCHURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/error'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * addCoworkerEvaluation
 * request: addCoworkerEvaluationUsingPOST
 * url: addCoworkerEvaluationUsingPOSTURL
 * method: addCoworkerEvaluationUsingPOST_TYPE
 * raw_url: addCoworkerEvaluationUsingPOST_RAW_URL
 * @param coworkerEvaluation - coworkerEvaluation
 */
export const addCoworkerEvaluationUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/evaluation/coworker'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['coworkerEvaluation'] !== undefined) {
    body = parameters['coworkerEvaluation']
  }
  if (parameters['coworkerEvaluation'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: coworkerEvaluation'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const addCoworkerEvaluationUsingPOST_RAW_URL = function() {
  return '/evaluation/coworker'
}
export const addCoworkerEvaluationUsingPOST_TYPE = function() {
  return 'post'
}
export const addCoworkerEvaluationUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/evaluation/coworker'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getCurrentAccountSelfEvaluationData
 * request: getCurrentAccountSelfEvaluationDataUsingGET
 * url: getCurrentAccountSelfEvaluationDataUsingGETURL
 * method: getCurrentAccountSelfEvaluationDataUsingGET_TYPE
 * raw_url: getCurrentAccountSelfEvaluationDataUsingGET_RAW_URL
 */
export const getCurrentAccountSelfEvaluationDataUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/evaluation/current-account-self-evaluation-data'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getCurrentAccountSelfEvaluationDataUsingGET_RAW_URL = function() {
  return '/evaluation/current-account-self-evaluation-data'
}
export const getCurrentAccountSelfEvaluationDataUsingGET_TYPE = function() {
  return 'get'
}
export const getCurrentAccountSelfEvaluationDataUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/evaluation/current-account-self-evaluation-data'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getAllDepartments
 * request: getAllDepartmentsUsingGET
 * url: getAllDepartmentsUsingGETURL
 * method: getAllDepartmentsUsingGET_TYPE
 * raw_url: getAllDepartmentsUsingGET_RAW_URL
 */
export const getAllDepartmentsUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/evaluation/departments'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getAllDepartmentsUsingGET_RAW_URL = function() {
  return '/evaluation/departments'
}
export const getAllDepartmentsUsingGET_TYPE = function() {
  return 'get'
}
export const getAllDepartmentsUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/evaluation/departments'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getAllEvaluationTypes
 * request: getAllEvaluationTypesUsingGET
 * url: getAllEvaluationTypesUsingGETURL
 * method: getAllEvaluationTypesUsingGET_TYPE
 * raw_url: getAllEvaluationTypesUsingGET_RAW_URL
 */
export const getAllEvaluationTypesUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/evaluation/evaluation/types'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getAllEvaluationTypesUsingGET_RAW_URL = function() {
  return '/evaluation/evaluation/types'
}
export const getAllEvaluationTypesUsingGET_TYPE = function() {
  return 'get'
}
export const getAllEvaluationTypesUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/evaluation/evaluation/types'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchMyEvaluations
 * request: searchMyEvaluationsUsingPOST
 * url: searchMyEvaluationsUsingPOSTURL
 * method: searchMyEvaluationsUsingPOST_TYPE
 * raw_url: searchMyEvaluationsUsingPOST_RAW_URL
 * @param searchCriteria - searchCriteria
 */
export const searchMyEvaluationsUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/evaluation/my/search'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['searchCriteria'] !== undefined) {
    body = parameters['searchCriteria']
  }
  if (parameters['searchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: searchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchMyEvaluationsUsingPOST_RAW_URL = function() {
  return '/evaluation/my/search'
}
export const searchMyEvaluationsUsingPOST_TYPE = function() {
  return 'post'
}
export const searchMyEvaluationsUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/evaluation/my/search'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchMyEvaluationsCount
 * request: searchMyEvaluationsCountUsingPOST
 * url: searchMyEvaluationsCountUsingPOSTURL
 * method: searchMyEvaluationsCountUsingPOST_TYPE
 * raw_url: searchMyEvaluationsCountUsingPOST_RAW_URL
 * @param searchCriteria - searchCriteria
 */
export const searchMyEvaluationsCountUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/evaluation/my/search/count'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['searchCriteria'] !== undefined) {
    body = parameters['searchCriteria']
  }
  if (parameters['searchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: searchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchMyEvaluationsCountUsingPOST_RAW_URL = function() {
  return '/evaluation/my/search/count'
}
export const searchMyEvaluationsCountUsingPOST_TYPE = function() {
  return 'post'
}
export const searchMyEvaluationsCountUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/evaluation/my/search/count'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchEvaluation
 * request: searchEvaluationUsingPOST
 * url: searchEvaluationUsingPOSTURL
 * method: searchEvaluationUsingPOST_TYPE
 * raw_url: searchEvaluationUsingPOST_RAW_URL
 * @param searchCriteria - searchCriteria
 */
export const searchEvaluationUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/evaluation/search/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['searchCriteria'] !== undefined) {
    body = parameters['searchCriteria']
  }
  if (parameters['searchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: searchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchEvaluationUsingPOST_RAW_URL = function() {
  return '/evaluation/search/'
}
export const searchEvaluationUsingPOST_TYPE = function() {
  return 'post'
}
export const searchEvaluationUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/evaluation/search/'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchEvaluationCount
 * request: searchEvaluationCountUsingPOST
 * url: searchEvaluationCountUsingPOSTURL
 * method: searchEvaluationCountUsingPOST_TYPE
 * raw_url: searchEvaluationCountUsingPOST_RAW_URL
 * @param searchCriteria - searchCriteria
 */
export const searchEvaluationCountUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/evaluation/search/count'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['searchCriteria'] !== undefined) {
    body = parameters['searchCriteria']
  }
  if (parameters['searchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: searchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchEvaluationCountUsingPOST_RAW_URL = function() {
  return '/evaluation/search/count'
}
export const searchEvaluationCountUsingPOST_TYPE = function() {
  return 'post'
}
export const searchEvaluationCountUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/evaluation/search/count'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * addSelfEvaluation
 * request: addSelfEvaluationUsingPOST
 * url: addSelfEvaluationUsingPOSTURL
 * method: addSelfEvaluationUsingPOST_TYPE
 * raw_url: addSelfEvaluationUsingPOST_RAW_URL
 * @param selfEvaluation - selfEvaluation
 */
export const addSelfEvaluationUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/evaluation/self-evaluation'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['selfEvaluation'] !== undefined) {
    body = parameters['selfEvaluation']
  }
  if (parameters['selfEvaluation'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: selfEvaluation'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const addSelfEvaluationUsingPOST_RAW_URL = function() {
  return '/evaluation/self-evaluation'
}
export const addSelfEvaluationUsingPOST_TYPE = function() {
  return 'post'
}
export const addSelfEvaluationUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/evaluation/self-evaluation'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getEvaluationStatsForDepartment
 * request: getEvaluationStatsForDepartmentUsingGET
 * url: getEvaluationStatsForDepartmentUsingGETURL
 * method: getEvaluationStatsForDepartmentUsingGET_TYPE
 * raw_url: getEvaluationStatsForDepartmentUsingGET_RAW_URL
 * @param department - department
 */
export const getEvaluationStatsForDepartmentUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/evaluation/stats'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['department'] !== undefined) {
    queryParameters['department'] = parameters['department']
  }
  if (parameters['department'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: department'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getEvaluationStatsForDepartmentUsingGET_RAW_URL = function() {
  return '/evaluation/stats'
}
export const getEvaluationStatsForDepartmentUsingGET_TYPE = function() {
  return 'get'
}
export const getEvaluationStatsForDepartmentUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/evaluation/stats'
  if (parameters['department'] !== undefined) {
    queryParameters['department'] = parameters['department']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * addSupervisorEvaluation
 * request: addSupervisorEvaluationUsingPOST
 * url: addSupervisorEvaluationUsingPOSTURL
 * method: addSupervisorEvaluationUsingPOST_TYPE
 * raw_url: addSupervisorEvaluationUsingPOST_RAW_URL
 * @param supervisorEvaluation - supervisorEvaluation
 */
export const addSupervisorEvaluationUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/evaluation/supervisor'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['supervisorEvaluation'] !== undefined) {
    body = parameters['supervisorEvaluation']
  }
  if (parameters['supervisorEvaluation'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: supervisorEvaluation'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const addSupervisorEvaluationUsingPOST_RAW_URL = function() {
  return '/evaluation/supervisor'
}
export const addSupervisorEvaluationUsingPOST_TYPE = function() {
  return 'post'
}
export const addSupervisorEvaluationUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/evaluation/supervisor'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchCompanyExcelFilesCount
 * request: searchCompanyExcelFilesCountUsingPOST
 * url: searchCompanyExcelFilesCountUsingPOSTURL
 * method: searchCompanyExcelFilesCountUsingPOST_TYPE
 * raw_url: searchCompanyExcelFilesCountUsingPOST_RAW_URL
 * @param companyId - companyId
 * @param searchCriteria - searchCriteria
 */
export const searchCompanyExcelFilesCountUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/file/company/files/count/{companyId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{companyId}', `${parameters['companyId']}`)
  if (parameters['companyId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: companyId'))
  }
  if (parameters['searchCriteria'] !== undefined) {
    body = parameters['searchCriteria']
  }
  if (parameters['searchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: searchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchCompanyExcelFilesCountUsingPOST_RAW_URL = function() {
  return '/file/company/files/count/{companyId}'
}
export const searchCompanyExcelFilesCountUsingPOST_TYPE = function() {
  return 'post'
}
export const searchCompanyExcelFilesCountUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/file/company/files/count/{companyId}'
  path = path.replace('{companyId}', `${parameters['companyId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * downloadAllCompanyExcelFiles
 * request: downloadAllCompanyExcelFilesUsingGET
 * url: downloadAllCompanyExcelFilesUsingGETURL
 * method: downloadAllCompanyExcelFilesUsingGET_TYPE
 * raw_url: downloadAllCompanyExcelFilesUsingGET_RAW_URL
 * @param companyId - companyId
 */
export const downloadAllCompanyExcelFilesUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/file/company/files/download/all/{companyId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{companyId}', `${parameters['companyId']}`)
  if (parameters['companyId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: companyId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const downloadAllCompanyExcelFilesUsingGET_RAW_URL = function() {
  return '/file/company/files/download/all/{companyId}'
}
export const downloadAllCompanyExcelFilesUsingGET_TYPE = function() {
  return 'get'
}
export const downloadAllCompanyExcelFilesUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/file/company/files/download/all/{companyId}'
  path = path.replace('{companyId}', `${parameters['companyId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * downloadExcelFile
 * request: downloadExcelFileUsingGET
 * url: downloadExcelFileUsingGETURL
 * method: downloadExcelFileUsingGET_TYPE
 * raw_url: downloadExcelFileUsingGET_RAW_URL
 * @param id - id
 */
export const downloadExcelFileUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/file/company/files/download/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const downloadExcelFileUsingGET_RAW_URL = function() {
  return '/file/company/files/download/{id}'
}
export const downloadExcelFileUsingGET_TYPE = function() {
  return 'get'
}
export const downloadExcelFileUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/file/company/files/download/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchCompanyExcelFiles
 * request: searchCompanyExcelFilesUsingPOST
 * url: searchCompanyExcelFilesUsingPOSTURL
 * method: searchCompanyExcelFilesUsingPOST_TYPE
 * raw_url: searchCompanyExcelFilesUsingPOST_RAW_URL
 * @param companyId - companyId
 * @param searchCriteria - searchCriteria
 */
export const searchCompanyExcelFilesUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/file/company/files/search/{companyId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{companyId}', `${parameters['companyId']}`)
  if (parameters['companyId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: companyId'))
  }
  if (parameters['searchCriteria'] !== undefined) {
    body = parameters['searchCriteria']
  }
  if (parameters['searchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: searchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchCompanyExcelFilesUsingPOST_RAW_URL = function() {
  return '/file/company/files/search/{companyId}'
}
export const searchCompanyExcelFilesUsingPOST_TYPE = function() {
  return 'post'
}
export const searchCompanyExcelFilesUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/file/company/files/search/{companyId}'
  path = path.replace('{companyId}', `${parameters['companyId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * downloadElearningFile
 * request: downloadElearningFileUsingGET
 * url: downloadElearningFileUsingGETURL
 * method: downloadElearningFileUsingGET_TYPE
 * raw_url: downloadElearningFileUsingGET_RAW_URL
 * @param id - id
 */
export const downloadElearningFileUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/file/elearning/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const downloadElearningFileUsingGET_RAW_URL = function() {
  return '/file/elearning/{id}'
}
export const downloadElearningFileUsingGET_TYPE = function() {
  return 'get'
}
export const downloadElearningFileUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/file/elearning/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchEmployerFilesCount
 * request: searchEmployerFilesCountUsingPOST
 * url: searchEmployerFilesCountUsingPOSTURL
 * method: searchEmployerFilesCountUsingPOST_TYPE
 * raw_url: searchEmployerFilesCountUsingPOST_RAW_URL
 * @param searchCriteria - searchCriteria
 */
export const searchEmployerFilesCountUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/file/employer/count'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['searchCriteria'] !== undefined) {
    body = parameters['searchCriteria']
  }
  if (parameters['searchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: searchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchEmployerFilesCountUsingPOST_RAW_URL = function() {
  return '/file/employer/count'
}
export const searchEmployerFilesCountUsingPOST_TYPE = function() {
  return 'post'
}
export const searchEmployerFilesCountUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/file/employer/count'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * downloadEmployerFile
 * request: downloadEmployerFileUsingGET
 * url: downloadEmployerFileUsingGETURL
 * method: downloadEmployerFileUsingGET_TYPE
 * raw_url: downloadEmployerFileUsingGET_RAW_URL
 * @param id - id
 */
export const downloadEmployerFileUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/file/employer/download/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const downloadEmployerFileUsingGET_RAW_URL = function() {
  return '/file/employer/download/{id}'
}
export const downloadEmployerFileUsingGET_TYPE = function() {
  return 'get'
}
export const downloadEmployerFileUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/file/employer/download/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchEmployerFiles
 * request: searchEmployerFilesUsingPOST
 * url: searchEmployerFilesUsingPOSTURL
 * method: searchEmployerFilesUsingPOST_TYPE
 * raw_url: searchEmployerFilesUsingPOST_RAW_URL
 * @param searchCriteria - searchCriteria
 */
export const searchEmployerFilesUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/file/employer/search'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['searchCriteria'] !== undefined) {
    body = parameters['searchCriteria']
  }
  if (parameters['searchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: searchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchEmployerFilesUsingPOST_RAW_URL = function() {
  return '/file/employer/search'
}
export const searchEmployerFilesUsingPOST_TYPE = function() {
  return 'post'
}
export const searchEmployerFilesUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/file/employer/search'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * updateEmployerFile
 * request: updateEmployerFileUsingPUT
 * url: updateEmployerFileUsingPUTURL
 * method: updateEmployerFileUsingPUT_TYPE
 * raw_url: updateEmployerFileUsingPUT_RAW_URL
 * @param fileDto - fileDTO
 */
export const updateEmployerFileUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/file/employer/update'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['fileDto'] !== undefined) {
    body = parameters['fileDto']
  }
  if (parameters['fileDto'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: fileDto'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updateEmployerFileUsingPUT_RAW_URL = function() {
  return '/file/employer/update'
}
export const updateEmployerFileUsingPUT_TYPE = function() {
  return 'put'
}
export const updateEmployerFileUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/file/employer/update'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * uploadEmployerFile
 * request: uploadEmployerFileUsingPOST
 * url: uploadEmployerFileUsingPOSTURL
 * method: uploadEmployerFileUsingPOST_TYPE
 * raw_url: uploadEmployerFileUsingPOST_RAW_URL
 * @param fileDto - fileDTO
 */
export const uploadEmployerFileUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/file/employer/upload'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['fileDto'] !== undefined) {
    body = parameters['fileDto']
  }
  if (parameters['fileDto'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: fileDto'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const uploadEmployerFileUsingPOST_RAW_URL = function() {
  return '/file/employer/upload'
}
export const uploadEmployerFileUsingPOST_TYPE = function() {
  return 'post'
}
export const uploadEmployerFileUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/file/employer/upload'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getEmployerFileInfo
 * request: getEmployerFileInfoUsingGET
 * url: getEmployerFileInfoUsingGETURL
 * method: getEmployerFileInfoUsingGET_TYPE
 * raw_url: getEmployerFileInfoUsingGET_RAW_URL
 * @param id - id
 */
export const getEmployerFileInfoUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/file/employer/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getEmployerFileInfoUsingGET_RAW_URL = function() {
  return '/file/employer/{id}'
}
export const getEmployerFileInfoUsingGET_TYPE = function() {
  return 'get'
}
export const getEmployerFileInfoUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/file/employer/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * deleteEmployerFile
 * request: deleteEmployerFileUsingDELETE
 * url: deleteEmployerFileUsingDELETEURL
 * method: deleteEmployerFileUsingDELETE_TYPE
 * raw_url: deleteEmployerFileUsingDELETE_RAW_URL
 * @param id - id
 */
export const deleteEmployerFileUsingDELETE = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/file/employer/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const deleteEmployerFileUsingDELETE_RAW_URL = function() {
  return '/file/employer/{id}'
}
export const deleteEmployerFileUsingDELETE_TYPE = function() {
  return 'delete'
}
export const deleteEmployerFileUsingDELETEURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/file/employer/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getFinancialAnalysisData
 * request: getFinancialAnalysisDataUsingGET
 * url: getFinancialAnalysisDataUsingGETURL
 * method: getFinancialAnalysisDataUsingGET_TYPE
 * raw_url: getFinancialAnalysisDataUsingGET_RAW_URL
 * @param department - department
 */
export const getFinancialAnalysisDataUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/financial-analysis-data'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['department'] !== undefined) {
    queryParameters['department'] = parameters['department']
  }
  if (parameters['department'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: department'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getFinancialAnalysisDataUsingGET_RAW_URL = function() {
  return '/financial-analysis-data'
}
export const getFinancialAnalysisDataUsingGET_TYPE = function() {
  return 'get'
}
export const getFinancialAnalysisDataUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/financial-analysis-data'
  if (parameters['department'] !== undefined) {
    queryParameters['department'] = parameters['department']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getCompetenceActions
 * request: getCompetenceActionsUsingGET
 * url: getCompetenceActionsUsingGETURL
 * method: getCompetenceActionsUsingGET_TYPE
 * raw_url: getCompetenceActionsUsingGET_RAW_URL
 * @param workerId - workerId
 */
export const getCompetenceActionsUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/get_competence_actions'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['workerId'] !== undefined) {
    queryParameters['workerId'] = parameters['workerId']
  }
  if (parameters['workerId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: workerId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getCompetenceActionsUsingGET_RAW_URL = function() {
  return '/get_competence_actions'
}
export const getCompetenceActionsUsingGET_TYPE = function() {
  return 'get'
}
export const getCompetenceActionsUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/get_competence_actions'
  if (parameters['workerId'] !== undefined) {
    queryParameters['workerId'] = parameters['workerId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getCompetenceDepartments
 * request: getCompetenceDepartmentsUsingGET
 * url: getCompetenceDepartmentsUsingGETURL
 * method: getCompetenceDepartmentsUsingGET_TYPE
 * raw_url: getCompetenceDepartmentsUsingGET_RAW_URL
 * @param department - department
 */
export const getCompetenceDepartmentsUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/get_competence_departments'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['department'] !== undefined) {
    queryParameters['department'] = parameters['department']
  }
  if (parameters['department'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: department'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getCompetenceDepartmentsUsingGET_RAW_URL = function() {
  return '/get_competence_departments'
}
export const getCompetenceDepartmentsUsingGET_TYPE = function() {
  return 'get'
}
export const getCompetenceDepartmentsUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/get_competence_departments'
  if (parameters['department'] !== undefined) {
    queryParameters['department'] = parameters['department']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getCompetenceDepartmentsActions
 * request: getCompetenceDepartmentsActionsUsingGET
 * url: getCompetenceDepartmentsActionsUsingGETURL
 * method: getCompetenceDepartmentsActionsUsingGET_TYPE
 * raw_url: getCompetenceDepartmentsActionsUsingGET_RAW_URL
 * @param department - department
 */
export const getCompetenceDepartmentsActionsUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/get_competence_departments_actions'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['department'] !== undefined) {
    queryParameters['department'] = parameters['department']
  }
  if (parameters['department'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: department'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getCompetenceDepartmentsActionsUsingGET_RAW_URL = function() {
  return '/get_competence_departments_actions'
}
export const getCompetenceDepartmentsActionsUsingGET_TYPE = function() {
  return 'get'
}
export const getCompetenceDepartmentsActionsUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/get_competence_departments_actions'
  if (parameters['department'] !== undefined) {
    queryParameters['department'] = parameters['department']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getEfficiencyCalculations
 * request: getEfficiencyCalculationsUsingGET
 * url: getEfficiencyCalculationsUsingGETURL
 * method: getEfficiencyCalculationsUsingGET_TYPE
 * raw_url: getEfficiencyCalculationsUsingGET_RAW_URL
 * @param department - department
 */
export const getEfficiencyCalculationsUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/get_efficiency'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['department'] !== undefined) {
    queryParameters['department'] = parameters['department']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getEfficiencyCalculationsUsingGET_RAW_URL = function() {
  return '/get_efficiency'
}
export const getEfficiencyCalculationsUsingGET_TYPE = function() {
  return 'get'
}
export const getEfficiencyCalculationsUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/get_efficiency'
  if (parameters['department'] !== undefined) {
    queryParameters['department'] = parameters['department']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getEfficiencyCalculationsResults
 * request: getEfficiencyCalculationsResultsUsingGET
 * url: getEfficiencyCalculationsResultsUsingGETURL
 * method: getEfficiencyCalculationsResultsUsingGET_TYPE
 * raw_url: getEfficiencyCalculationsResultsUsingGET_RAW_URL
 * @param department - department
 */
export const getEfficiencyCalculationsResultsUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/get_efficiency/results'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['department'] !== undefined) {
    queryParameters['department'] = parameters['department']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getEfficiencyCalculationsResultsUsingGET_RAW_URL = function() {
  return '/get_efficiency/results'
}
export const getEfficiencyCalculationsResultsUsingGET_TYPE = function() {
  return 'get'
}
export const getEfficiencyCalculationsResultsUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/get_efficiency/results'
  if (parameters['department'] !== undefined) {
    queryParameters['department'] = parameters['department']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getEvaluationStatus
 * request: getEvaluationStatusUsingGET
 * url: getEvaluationStatusUsingGETURL
 * method: getEvaluationStatusUsingGET_TYPE
 * raw_url: getEvaluationStatusUsingGET_RAW_URL
 * @param evaluationCode - evaluationCode
 */
export const getEvaluationStatusUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/get_evaluation_status'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['evaluationCode'] !== undefined) {
    queryParameters['evaluationCode'] = parameters['evaluationCode']
  }
  if (parameters['evaluationCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: evaluationCode'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getEvaluationStatusUsingGET_RAW_URL = function() {
  return '/get_evaluation_status'
}
export const getEvaluationStatusUsingGET_TYPE = function() {
  return 'get'
}
export const getEvaluationStatusUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/get_evaluation_status'
  if (parameters['evaluationCode'] !== undefined) {
    queryParameters['evaluationCode'] = parameters['evaluationCode']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getWorkerCompetence
 * request: getWorkerCompetenceUsingGET
 * url: getWorkerCompetenceUsingGETURL
 * method: getWorkerCompetenceUsingGET_TYPE
 * raw_url: getWorkerCompetenceUsingGET_RAW_URL
 * @param workerId - workerId
 */
export const getWorkerCompetenceUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/get_worker_competence'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['workerId'] !== undefined) {
    queryParameters['workerId'] = parameters['workerId']
  }
  if (parameters['workerId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: workerId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getWorkerCompetenceUsingGET_RAW_URL = function() {
  return '/get_worker_competence'
}
export const getWorkerCompetenceUsingGET_TYPE = function() {
  return 'get'
}
export const getWorkerCompetenceUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/get_worker_competence'
  if (parameters['workerId'] !== undefined) {
    queryParameters['workerId'] = parameters['workerId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getWorkerFullName
 * request: getWorkerFullNameUsingGET
 * url: getWorkerFullNameUsingGETURL
 * method: getWorkerFullNameUsingGET_TYPE
 * raw_url: getWorkerFullNameUsingGET_RAW_URL
 * @param workerId - workerId
 */
export const getWorkerFullNameUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/get_worker_full_name'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['workerId'] !== undefined) {
    queryParameters['workerId'] = parameters['workerId']
  }
  if (parameters['workerId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: workerId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getWorkerFullNameUsingGET_RAW_URL = function() {
  return '/get_worker_full_name'
}
export const getWorkerFullNameUsingGET_TYPE = function() {
  return 'get'
}
export const getWorkerFullNameUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/get_worker_full_name'
  if (parameters['workerId'] !== undefined) {
    queryParameters['workerId'] = parameters['workerId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * isTokenValid
 * request: isTokenValidUsingGET
 * url: isTokenValidUsingGETURL
 * method: isTokenValidUsingGET_TYPE
 * raw_url: isTokenValidUsingGET_RAW_URL
 */
export const isTokenValidUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/is-token-valid'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const isTokenValidUsingGET_RAW_URL = function() {
  return '/is-token-valid'
}
export const isTokenValidUsingGET_TYPE = function() {
  return 'get'
}
export const isTokenValidUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/is-token-valid'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * login
 * request: loginUsingPOST
 * url: loginUsingPOSTURL
 * method: loginUsingPOST_TYPE
 * raw_url: loginUsingPOST_RAW_URL
 * @param authenticationRequest - authenticationRequest
 */
export const loginUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/login'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['authenticationRequest'] !== undefined) {
    body = parameters['authenticationRequest']
  }
  if (parameters['authenticationRequest'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: authenticationRequest'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const loginUsingPOST_RAW_URL = function() {
  return '/login'
}
export const loginUsingPOST_TYPE = function() {
  return 'post'
}
export const loginUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/login'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getMyCompetenceActions
 * request: getMyCompetenceActionsUsingGET
 * url: getMyCompetenceActionsUsingGETURL
 * method: getMyCompetenceActionsUsingGET_TYPE
 * raw_url: getMyCompetenceActionsUsingGET_RAW_URL
 */
export const getMyCompetenceActionsUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/my-competence-actions'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getMyCompetenceActionsUsingGET_RAW_URL = function() {
  return '/my-competence-actions'
}
export const getMyCompetenceActionsUsingGET_TYPE = function() {
  return 'get'
}
export const getMyCompetenceActionsUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/my-competence-actions'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getMyCompetences
 * request: getMyCompetencesUsingGET
 * url: getMyCompetencesUsingGETURL
 * method: getMyCompetencesUsingGET_TYPE
 * raw_url: getMyCompetencesUsingGET_RAW_URL
 */
export const getMyCompetencesUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/my-competences'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getMyCompetencesUsingGET_RAW_URL = function() {
  return '/my-competences'
}
export const getMyCompetencesUsingGET_TYPE = function() {
  return 'get'
}
export const getMyCompetencesUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/my-competences'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchPages
 * request: searchPagesUsingPOST
 * url: searchPagesUsingPOSTURL
 * method: searchPagesUsingPOST_TYPE
 * raw_url: searchPagesUsingPOST_RAW_URL
 * @param word - word
 */
export const searchPagesUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/page/search'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['word'] !== undefined) {
    body = parameters['word']
  }
  if (parameters['word'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: word'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchPagesUsingPOST_RAW_URL = function() {
  return '/page/search'
}
export const searchPagesUsingPOST_TYPE = function() {
  return 'post'
}
export const searchPagesUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/page/search'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * commentPost
 * request: commentPostUsingPOST
 * url: commentPostUsingPOSTURL
 * method: commentPostUsingPOST_TYPE
 * raw_url: commentPostUsingPOST_RAW_URL
 * @param commentDto - commentDTO
 */
export const commentPostUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/post/comment'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['commentDto'] !== undefined) {
    body = parameters['commentDto']
  }
  if (parameters['commentDto'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: commentDto'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const commentPostUsingPOST_RAW_URL = function() {
  return '/post/comment'
}
export const commentPostUsingPOST_TYPE = function() {
  return 'post'
}
export const commentPostUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/post/comment'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * commentTrendPost
 * request: commentTrendPostUsingPOST
 * url: commentTrendPostUsingPOSTURL
 * method: commentTrendPostUsingPOST_TYPE
 * raw_url: commentTrendPostUsingPOST_RAW_URL
 * @param commentDto - commentDTO
 */
export const commentTrendPostUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/post/comment-trend'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['commentDto'] !== undefined) {
    body = parameters['commentDto']
  }
  if (parameters['commentDto'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: commentDto'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const commentTrendPostUsingPOST_RAW_URL = function() {
  return '/post/comment-trend'
}
export const commentTrendPostUsingPOST_TYPE = function() {
  return 'post'
}
export const commentTrendPostUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/post/comment-trend'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * editTrendComment
 * request: editTrendCommentUsingPOST
 * url: editTrendCommentUsingPOSTURL
 * method: editTrendCommentUsingPOST_TYPE
 * raw_url: editTrendCommentUsingPOST_RAW_URL
 * @param commentDto - commentDTO
 */
export const editTrendCommentUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/post/comment-trend/edit'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['commentDto'] !== undefined) {
    body = parameters['commentDto']
  }
  if (parameters['commentDto'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: commentDto'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const editTrendCommentUsingPOST_RAW_URL = function() {
  return '/post/comment-trend/edit'
}
export const editTrendCommentUsingPOST_TYPE = function() {
  return 'post'
}
export const editTrendCommentUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/post/comment-trend/edit'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * editComment
 * request: editCommentUsingPOST
 * url: editCommentUsingPOSTURL
 * method: editCommentUsingPOST_TYPE
 * raw_url: editCommentUsingPOST_RAW_URL
 * @param commentDto - commentDTO
 */
export const editCommentUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/post/comment/edit'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['commentDto'] !== undefined) {
    body = parameters['commentDto']
  }
  if (parameters['commentDto'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: commentDto'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const editCommentUsingPOST_RAW_URL = function() {
  return '/post/comment/edit'
}
export const editCommentUsingPOST_TYPE = function() {
  return 'post'
}
export const editCommentUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/post/comment/edit'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * createPost
 * request: createPostUsingPOST
 * url: createPostUsingPOSTURL
 * method: createPostUsingPOST_TYPE
 * raw_url: createPostUsingPOST_RAW_URL
 * @param postDto - postDTO
 */
export const createPostUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/post/create'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['postDto'] !== undefined) {
    body = parameters['postDto']
  }
  if (parameters['postDto'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: postDto'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createPostUsingPOST_RAW_URL = function() {
  return '/post/create'
}
export const createPostUsingPOST_TYPE = function() {
  return 'post'
}
export const createPostUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/post/create'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * createTrendPost
 * request: createTrendPostUsingPOST
 * url: createTrendPostUsingPOSTURL
 * method: createTrendPostUsingPOST_TYPE
 * raw_url: createTrendPostUsingPOST_RAW_URL
 * @param postDto - postDTO
 */
export const createTrendPostUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/post/create-trend-post'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['postDto'] !== undefined) {
    body = parameters['postDto']
  }
  if (parameters['postDto'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: postDto'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createTrendPostUsingPOST_RAW_URL = function() {
  return '/post/create-trend-post'
}
export const createTrendPostUsingPOST_TYPE = function() {
  return 'post'
}
export const createTrendPostUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/post/create-trend-post'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * editPost
 * request: editPostUsingPOST
 * url: editPostUsingPOSTURL
 * method: editPostUsingPOST_TYPE
 * raw_url: editPostUsingPOST_RAW_URL
 * @param postDto - postDTO
 */
export const editPostUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/post/edit'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['postDto'] !== undefined) {
    body = parameters['postDto']
  }
  if (parameters['postDto'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: postDto'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const editPostUsingPOST_RAW_URL = function() {
  return '/post/edit'
}
export const editPostUsingPOST_TYPE = function() {
  return 'post'
}
export const editPostUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/post/edit'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * editTrendPost
 * request: editTrendPostUsingPOST
 * url: editTrendPostUsingPOSTURL
 * method: editTrendPostUsingPOST_TYPE
 * raw_url: editTrendPostUsingPOST_RAW_URL
 * @param postDto - postDTO
 */
export const editTrendPostUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/post/edit-trend'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['postDto'] !== undefined) {
    body = parameters['postDto']
  }
  if (parameters['postDto'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: postDto'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const editTrendPostUsingPOST_RAW_URL = function() {
  return '/post/edit-trend'
}
export const editTrendPostUsingPOST_TYPE = function() {
  return 'post'
}
export const editTrendPostUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/post/edit-trend'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchPost
 * request: searchPostUsingPOST
 * url: searchPostUsingPOSTURL
 * method: searchPostUsingPOST_TYPE
 * raw_url: searchPostUsingPOST_RAW_URL
 * @param postSearchCriteria - postSearchCriteria
 */
export const searchPostUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/post/search'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['postSearchCriteria'] !== undefined) {
    body = parameters['postSearchCriteria']
  }
  if (parameters['postSearchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: postSearchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchPostUsingPOST_RAW_URL = function() {
  return '/post/search'
}
export const searchPostUsingPOST_TYPE = function() {
  return 'post'
}
export const searchPostUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/post/search'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchTrendPost
 * request: searchTrendPostUsingPOST
 * url: searchTrendPostUsingPOSTURL
 * method: searchTrendPostUsingPOST_TYPE
 * raw_url: searchTrendPostUsingPOST_RAW_URL
 * @param trendPostSearchCriteria - trendPostSearchCriteria
 */
export const searchTrendPostUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/post/search-trend'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['trendPostSearchCriteria'] !== undefined) {
    body = parameters['trendPostSearchCriteria']
  }
  if (parameters['trendPostSearchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: trendPostSearchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchTrendPostUsingPOST_RAW_URL = function() {
  return '/post/search-trend'
}
export const searchTrendPostUsingPOST_TYPE = function() {
  return 'post'
}
export const searchTrendPostUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/post/search-trend'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchPostCount
 * request: searchPostCountUsingPOST
 * url: searchPostCountUsingPOSTURL
 * method: searchPostCountUsingPOST_TYPE
 * raw_url: searchPostCountUsingPOST_RAW_URL
 * @param postSearchCriteria - postSearchCriteria
 */
export const searchPostCountUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/post/search/count'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['postSearchCriteria'] !== undefined) {
    body = parameters['postSearchCriteria']
  }
  if (parameters['postSearchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: postSearchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchPostCountUsingPOST_RAW_URL = function() {
  return '/post/search/count'
}
export const searchPostCountUsingPOST_TYPE = function() {
  return 'post'
}
export const searchPostCountUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/post/search/count'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * deletePost
 * request: deletePostUsingDELETE
 * url: deletePostUsingDELETEURL
 * method: deletePostUsingDELETE_TYPE
 * raw_url: deletePostUsingDELETE_RAW_URL
 * @param id - id
 */
export const deletePostUsingDELETE = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/post/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const deletePostUsingDELETE_RAW_URL = function() {
  return '/post/{id}'
}
export const deletePostUsingDELETE_TYPE = function() {
  return 'delete'
}
export const deletePostUsingDELETEURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/post/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * prepareSurveySubtitle
 * request: prepareSurveySubtitleUsingGET
 * url: prepareSurveySubtitleUsingGETURL
 * method: prepareSurveySubtitleUsingGET_TYPE
 * raw_url: prepareSurveySubtitleUsingGET_RAW_URL
 * @param evaluationCode - evaluationCode
 */
export const prepareSurveySubtitleUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/prepare_survey_subtitle'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['evaluationCode'] !== undefined) {
    queryParameters['evaluationCode'] = parameters['evaluationCode']
  }
  if (parameters['evaluationCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: evaluationCode'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const prepareSurveySubtitleUsingGET_RAW_URL = function() {
  return '/prepare_survey_subtitle'
}
export const prepareSurveySubtitleUsingGET_TYPE = function() {
  return 'get'
}
export const prepareSurveySubtitleUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/prepare_survey_subtitle'
  if (parameters['evaluationCode'] !== undefined) {
    queryParameters['evaluationCode'] = parameters['evaluationCode']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * prepareSurveyTitle
 * request: prepareSurveyTitleUsingGET
 * url: prepareSurveyTitleUsingGETURL
 * method: prepareSurveyTitleUsingGET_TYPE
 * raw_url: prepareSurveyTitleUsingGET_RAW_URL
 * @param evaluationCode - evaluationCode
 */
export const prepareSurveyTitleUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/prepare_survey_title'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['evaluationCode'] !== undefined) {
    queryParameters['evaluationCode'] = parameters['evaluationCode']
  }
  if (parameters['evaluationCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: evaluationCode'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const prepareSurveyTitleUsingGET_RAW_URL = function() {
  return '/prepare_survey_title'
}
export const prepareSurveyTitleUsingGET_TYPE = function() {
  return 'get'
}
export const prepareSurveyTitleUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/prepare_survey_title'
  if (parameters['evaluationCode'] !== undefined) {
    queryParameters['evaluationCode'] = parameters['evaluationCode']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * readExcel
 * request: readExcelUsingGET
 * url: readExcelUsingGETURL
 * method: readExcelUsingGET_TYPE
 * raw_url: readExcelUsingGET_RAW_URL
 * @param evaluationCode - evaluationCode
 */
export const readExcelUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/read_excel'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['evaluationCode'] !== undefined) {
    queryParameters['evaluationCode'] = parameters['evaluationCode']
  }
  if (parameters['evaluationCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: evaluationCode'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const readExcelUsingGET_RAW_URL = function() {
  return '/read_excel'
}
export const readExcelUsingGET_TYPE = function() {
  return 'get'
}
export const readExcelUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/read_excel'
  if (parameters['evaluationCode'] !== undefined) {
    queryParameters['evaluationCode'] = parameters['evaluationCode']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * readExcelCompetences
 * request: readExcelCompetencesUsingGET
 * url: readExcelCompetencesUsingGETURL
 * method: readExcelCompetencesUsingGET_TYPE
 * raw_url: readExcelCompetencesUsingGET_RAW_URL
 * @param evaluationCode - evaluationCode
 */
export const readExcelCompetencesUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/read_excel/competences'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['evaluationCode'] !== undefined) {
    queryParameters['evaluationCode'] = parameters['evaluationCode']
  }
  if (parameters['evaluationCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: evaluationCode'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const readExcelCompetencesUsingGET_RAW_URL = function() {
  return '/read_excel/competences'
}
export const readExcelCompetencesUsingGET_TYPE = function() {
  return 'get'
}
export const readExcelCompetencesUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/read_excel/competences'
  if (parameters['evaluationCode'] !== undefined) {
    queryParameters['evaluationCode'] = parameters['evaluationCode']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getReskillingPathData
 * request: getReskillingPathDataUsingGET
 * url: getReskillingPathDataUsingGETURL
 * method: getReskillingPathDataUsingGET_TYPE
 * raw_url: getReskillingPathDataUsingGET_RAW_URL
 * @param department - department
 */
export const getReskillingPathDataUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/reskilling-path'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['department'] !== undefined) {
    queryParameters['department'] = parameters['department']
  }
  if (parameters['department'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: department'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getReskillingPathDataUsingGET_RAW_URL = function() {
  return '/reskilling-path'
}
export const getReskillingPathDataUsingGET_TYPE = function() {
  return 'get'
}
export const getReskillingPathDataUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/reskilling-path'
  if (parameters['department'] !== undefined) {
    queryParameters['department'] = parameters['department']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * updateReskillingPathWorkersActions
 * request: updateReskillingPathWorkersActionsUsingPOST
 * url: updateReskillingPathWorkersActionsUsingPOSTURL
 * method: updateReskillingPathWorkersActionsUsingPOST_TYPE
 * raw_url: updateReskillingPathWorkersActionsUsingPOST_RAW_URL
 * @param department - department
 * @param reskillingPathWorkers - reskillingPathWorkers
 */
export const updateReskillingPathWorkersActionsUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/reskilling-path'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['department'] !== undefined) {
    queryParameters['department'] = parameters['department']
  }
  if (parameters['reskillingPathWorkers'] !== undefined) {
    body = parameters['reskillingPathWorkers']
  }
  if (parameters['reskillingPathWorkers'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: reskillingPathWorkers'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const updateReskillingPathWorkersActionsUsingPOST_RAW_URL = function() {
  return '/reskilling-path'
}
export const updateReskillingPathWorkersActionsUsingPOST_TYPE = function() {
  return 'post'
}
export const updateReskillingPathWorkersActionsUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/reskilling-path'
  if (parameters['department'] !== undefined) {
    queryParameters['department'] = parameters['department']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getUpdatedStatusForReskillingPath
 * request: getUpdatedStatusForReskillingPathUsingGET
 * url: getUpdatedStatusForReskillingPathUsingGETURL
 * method: getUpdatedStatusForReskillingPathUsingGET_TYPE
 * raw_url: getUpdatedStatusForReskillingPathUsingGET_RAW_URL
 * @param department - department
 */
export const getUpdatedStatusForReskillingPathUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/reskilling-path/is-updated'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['department'] !== undefined) {
    queryParameters['department'] = parameters['department']
  }
  if (parameters['department'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: department'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getUpdatedStatusForReskillingPathUsingGET_RAW_URL = function() {
  return '/reskilling-path/is-updated'
}
export const getUpdatedStatusForReskillingPathUsingGET_TYPE = function() {
  return 'get'
}
export const getUpdatedStatusForReskillingPathUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/reskilling-path/is-updated'
  if (parameters['department'] !== undefined) {
    queryParameters['department'] = parameters['department']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getReskillingUpdatedPathData
 * request: getReskillingUpdatedPathDataUsingGET
 * url: getReskillingUpdatedPathDataUsingGETURL
 * method: getReskillingUpdatedPathDataUsingGET_TYPE
 * raw_url: getReskillingUpdatedPathDataUsingGET_RAW_URL
 * @param department - department
 */
export const getReskillingUpdatedPathDataUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/reskilling-path/updated'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['department'] !== undefined) {
    queryParameters['department'] = parameters['department']
  }
  if (parameters['department'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: department'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getReskillingUpdatedPathDataUsingGET_RAW_URL = function() {
  return '/reskilling-path/updated'
}
export const getReskillingUpdatedPathDataUsingGET_TYPE = function() {
  return 'get'
}
export const getReskillingUpdatedPathDataUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/reskilling-path/updated'
  if (parameters['department'] !== undefined) {
    queryParameters['department'] = parameters['department']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * saveSurvey
 * request: saveSurveyUsingPOST
 * url: saveSurveyUsingPOSTURL
 * method: saveSurveyUsingPOST_TYPE
 * raw_url: saveSurveyUsingPOST_RAW_URL
 * @param surveyRequest - surveyRequest
 */
export const saveSurveyUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/save_survey'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['surveyRequest'] !== undefined) {
    body = parameters['surveyRequest']
  }
  if (parameters['surveyRequest'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: surveyRequest'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const saveSurveyUsingPOST_RAW_URL = function() {
  return '/save_survey'
}
export const saveSurveyUsingPOST_TYPE = function() {
  return 'post'
}
export const saveSurveyUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/save_survey'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchUser
 * request: searchUserUsingPOST
 * url: searchUserUsingPOSTURL
 * method: searchUserUsingPOST_TYPE
 * raw_url: searchUserUsingPOST_RAW_URL
 * @param searchCriteria - searchCriteria
 */
export const searchUserUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/search/user'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['searchCriteria'] !== undefined) {
    body = parameters['searchCriteria']
  }
  if (parameters['searchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: searchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchUserUsingPOST_RAW_URL = function() {
  return '/search/user'
}
export const searchUserUsingPOST_TYPE = function() {
  return 'post'
}
export const searchUserUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/search/user'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchUserCount
 * request: searchUserCountUsingPOST
 * url: searchUserCountUsingPOSTURL
 * method: searchUserCountUsingPOST_TYPE
 * raw_url: searchUserCountUsingPOST_RAW_URL
 * @param searchCriteria - searchCriteria
 */
export const searchUserCountUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/search/user/count'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['searchCriteria'] !== undefined) {
    body = parameters['searchCriteria']
  }
  if (parameters['searchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: searchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchUserCountUsingPOST_RAW_URL = function() {
  return '/search/user/count'
}
export const searchUserCountUsingPOST_TYPE = function() {
  return 'post'
}
export const searchUserCountUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/search/user/count'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * setEfficiencyCalculations
 * request: setEfficiencyCalculationsUsingPOST
 * url: setEfficiencyCalculationsUsingPOSTURL
 * method: setEfficiencyCalculationsUsingPOST_TYPE
 * raw_url: setEfficiencyCalculationsUsingPOST_RAW_URL
 * @param efficiencyMainDto - efficiencyMainDTO
 */
export const setEfficiencyCalculationsUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/set_efficiency'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['efficiencyMainDto'] !== undefined) {
    body = parameters['efficiencyMainDto']
  }
  if (parameters['efficiencyMainDto'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: efficiencyMainDto'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const setEfficiencyCalculationsUsingPOST_RAW_URL = function() {
  return '/set_efficiency'
}
export const setEfficiencyCalculationsUsingPOST_TYPE = function() {
  return 'post'
}
export const setEfficiencyCalculationsUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/set_efficiency'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getActiveTrendsQuiz
 * request: getActiveTrendsQuizUsingGET
 * url: getActiveTrendsQuizUsingGETURL
 * method: getActiveTrendsQuizUsingGET_TYPE
 * raw_url: getActiveTrendsQuizUsingGET_RAW_URL
 */
export const getActiveTrendsQuizUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/trends/active-trend-quizzes'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getActiveTrendsQuizUsingGET_RAW_URL = function() {
  return '/trends/active-trend-quizzes'
}
export const getActiveTrendsQuizUsingGET_TYPE = function() {
  return 'get'
}
export const getActiveTrendsQuizUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/trends/active-trend-quizzes'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * countTrendQuizResults
 * request: countTrendQuizResultsUsingGET
 * url: countTrendQuizResultsUsingGETURL
 * method: countTrendQuizResultsUsingGET_TYPE
 * raw_url: countTrendQuizResultsUsingGET_RAW_URL
 * @param trendQuizId - trendQuizId
 */
export const countTrendQuizResultsUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/trends/count-trend-quiz-results/{trendQuizId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{trendQuizId}', `${parameters['trendQuizId']}`)
  if (parameters['trendQuizId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: trendQuizId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const countTrendQuizResultsUsingGET_RAW_URL = function() {
  return '/trends/count-trend-quiz-results/{trendQuizId}'
}
export const countTrendQuizResultsUsingGET_TYPE = function() {
  return 'get'
}
export const countTrendQuizResultsUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/trends/count-trend-quiz-results/{trendQuizId}'
  path = path.replace('{trendQuizId}', `${parameters['trendQuizId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * createQuizFromTemplate
 * request: createQuizFromTemplateUsingPOST
 * url: createQuizFromTemplateUsingPOSTURL
 * method: createQuizFromTemplateUsingPOST_TYPE
 * raw_url: createQuizFromTemplateUsingPOST_RAW_URL
 * @param trendQuiz - trendQuiz
 */
export const createQuizFromTemplateUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/trends/create-trends-quiz-from-template'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['trendQuiz'] !== undefined) {
    body = parameters['trendQuiz']
  }
  if (parameters['trendQuiz'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: trendQuiz'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createQuizFromTemplateUsingPOST_RAW_URL = function() {
  return '/trends/create-trends-quiz-from-template'
}
export const createQuizFromTemplateUsingPOST_TYPE = function() {
  return 'post'
}
export const createQuizFromTemplateUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/trends/create-trends-quiz-from-template'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * submitTrendStudyResults
 * request: submitTrendStudyResultsUsingPOST
 * url: submitTrendStudyResultsUsingPOSTURL
 * method: submitTrendStudyResultsUsingPOST_TYPE
 * raw_url: submitTrendStudyResultsUsingPOST_RAW_URL
 * @param guestToken - guestToken
 * @param quizDto - quizDTO
 */
export const submitTrendStudyResultsUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/trends/trend-study-results'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['guestToken'] !== undefined) {
    queryParameters['guestToken'] = parameters['guestToken']
  }
  if (parameters['guestToken'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: guestToken'))
  }
  if (parameters['quizDto'] !== undefined) {
    body = parameters['quizDto']
  }
  if (parameters['quizDto'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: quizDto'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const submitTrendStudyResultsUsingPOST_RAW_URL = function() {
  return '/trends/trend-study-results'
}
export const submitTrendStudyResultsUsingPOST_TYPE = function() {
  return 'post'
}
export const submitTrendStudyResultsUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/trends/trend-study-results'
  if (parameters['guestToken'] !== undefined) {
    queryParameters['guestToken'] = parameters['guestToken']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getTrendStudyResults
 * request: getTrendStudyResultsUsingGET
 * url: getTrendStudyResultsUsingGETURL
 * method: getTrendStudyResultsUsingGET_TYPE
 * raw_url: getTrendStudyResultsUsingGET_RAW_URL
 * @param trendQuizId - trendQuizId
 */
export const getTrendStudyResultsUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/trends/trend-study-results/{trendQuizId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{trendQuizId}', `${parameters['trendQuizId']}`)
  if (parameters['trendQuizId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: trendQuizId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getTrendStudyResultsUsingGET_RAW_URL = function() {
  return '/trends/trend-study-results/{trendQuizId}'
}
export const getTrendStudyResultsUsingGET_TYPE = function() {
  return 'get'
}
export const getTrendStudyResultsUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/trends/trend-study-results/{trendQuizId}'
  path = path.replace('{trendQuizId}', `${parameters['trendQuizId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getTrendQuizById
 * request: getTrendQuizByIdUsingGET
 * url: getTrendQuizByIdUsingGETURL
 * method: getTrendQuizByIdUsingGET_TYPE
 * raw_url: getTrendQuizByIdUsingGET_RAW_URL
 * @param guestToken - guestToken
 * @param trendQuizId - trendQuizId
 */
export const getTrendQuizByIdUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/trends/{trendQuizId}'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['guestToken'] !== undefined) {
    queryParameters['guestToken'] = parameters['guestToken']
  }
  if (parameters['guestToken'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: guestToken'))
  }
  path = path.replace('{trendQuizId}', `${parameters['trendQuizId']}`)
  if (parameters['trendQuizId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: trendQuizId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getTrendQuizByIdUsingGET_RAW_URL = function() {
  return '/trends/{trendQuizId}'
}
export const getTrendQuizByIdUsingGET_TYPE = function() {
  return 'get'
}
export const getTrendQuizByIdUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/trends/{trendQuizId}'
  if (parameters['guestToken'] !== undefined) {
    queryParameters['guestToken'] = parameters['guestToken']
  }
  path = path.replace('{trendQuizId}', `${parameters['trendQuizId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * createOrUpdateUser
 * request: createOrUpdateUserUsingPOST
 * url: createOrUpdateUserUsingPOSTURL
 * method: createOrUpdateUserUsingPOST_TYPE
 * raw_url: createOrUpdateUserUsingPOST_RAW_URL
 * @param userDto - userDTO
 */
export const createOrUpdateUserUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/user'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['userDto'] !== undefined) {
    body = parameters['userDto']
  }
  if (parameters['userDto'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: userDto'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createOrUpdateUserUsingPOST_RAW_URL = function() {
  return '/user'
}
export const createOrUpdateUserUsingPOST_TYPE = function() {
  return 'post'
}
export const createOrUpdateUserUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/user'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * changePassword
 * request: changePasswordUsingPOST
 * url: changePasswordUsingPOSTURL
 * method: changePasswordUsingPOST_TYPE
 * raw_url: changePasswordUsingPOST_RAW_URL
 * @param passwordChangeRequest - passwordChangeRequest
 */
export const changePasswordUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/user/password/change'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['passwordChangeRequest'] !== undefined) {
    body = parameters['passwordChangeRequest']
  }
  if (parameters['passwordChangeRequest'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: passwordChangeRequest'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const changePasswordUsingPOST_RAW_URL = function() {
  return '/user/password/change'
}
export const changePasswordUsingPOST_TYPE = function() {
  return 'post'
}
export const changePasswordUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/user/password/change'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * remindPassword
 * request: remindPasswordUsingPOST
 * url: remindPasswordUsingPOSTURL
 * method: remindPasswordUsingPOST_TYPE
 * raw_url: remindPasswordUsingPOST_RAW_URL
 * @param passwordRemindRequest - passwordRemindRequest
 */
export const remindPasswordUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/user/password/remind'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['passwordRemindRequest'] !== undefined) {
    body = parameters['passwordRemindRequest']
  }
  if (parameters['passwordRemindRequest'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: passwordRemindRequest'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const remindPasswordUsingPOST_RAW_URL = function() {
  return '/user/password/remind'
}
export const remindPasswordUsingPOST_TYPE = function() {
  return 'post'
}
export const remindPasswordUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/user/password/remind'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getUserRoles
 * request: getUserRolesUsingGET
 * url: getUserRolesUsingGETURL
 * method: getUserRolesUsingGET_TYPE
 * raw_url: getUserRolesUsingGET_RAW_URL
 */
export const getUserRolesUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/user/roles'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getUserRolesUsingGET_RAW_URL = function() {
  return '/user/roles'
}
export const getUserRolesUsingGET_TYPE = function() {
  return 'get'
}
export const getUserRolesUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/user/roles'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * verifyEmail
 * request: verifyEmailUsingPOST
 * url: verifyEmailUsingPOSTURL
 * method: verifyEmailUsingPOST_TYPE
 * raw_url: verifyEmailUsingPOST_RAW_URL
 * @param emailVerificationRequest - emailVerificationRequest
 */
export const verifyEmailUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/user/verify-email'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['emailVerificationRequest'] !== undefined) {
    body = parameters['emailVerificationRequest']
  }
  if (parameters['emailVerificationRequest'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: emailVerificationRequest'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const verifyEmailUsingPOST_RAW_URL = function() {
  return '/user/verify-email'
}
export const verifyEmailUsingPOST_TYPE = function() {
  return 'post'
}
export const verifyEmailUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/user/verify-email'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getUser
 * request: getUserUsingGET
 * url: getUserUsingGETURL
 * method: getUserUsingGET_TYPE
 * raw_url: getUserUsingGET_RAW_URL
 * @param id - id
 */
export const getUserUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/user/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getUserUsingGET_RAW_URL = function() {
  return '/user/{id}'
}
export const getUserUsingGET_TYPE = function() {
  return 'get'
}
export const getUserUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/user/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * recalculateCompetences
 * request: recalculateCompetencesUsingPOST
 * url: recalculateCompetencesUsingPOSTURL
 * method: recalculateCompetencesUsingPOST_TYPE
 * raw_url: recalculateCompetencesUsingPOST_RAW_URL
 * @param workerId - workerId
 */
export const recalculateCompetencesUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/worker/competences/recalculate'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['workerId'] !== undefined) {
    body = parameters['workerId']
  }
  if (parameters['workerId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: workerId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const recalculateCompetencesUsingPOST_RAW_URL = function() {
  return '/worker/competences/recalculate'
}
export const recalculateCompetencesUsingPOST_TYPE = function() {
  return 'post'
}
export const recalculateCompetencesUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/worker/competences/recalculate'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * registerWorker
 * request: registerWorkerUsingPOST
 * url: registerWorkerUsingPOSTURL
 * method: registerWorkerUsingPOST_TYPE
 * raw_url: registerWorkerUsingPOST_RAW_URL
 * @param workerRegistrationDto - workerRegistrationDto
 */
export const registerWorkerUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/worker/create'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['workerRegistrationDto'] !== undefined) {
    body = parameters['workerRegistrationDto']
  }
  if (parameters['workerRegistrationDto'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: workerRegistrationDto'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const registerWorkerUsingPOST_RAW_URL = function() {
  return '/worker/create'
}
export const registerWorkerUsingPOST_TYPE = function() {
  return 'post'
}
export const registerWorkerUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/worker/create'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getCurrentAccountId
 * request: getCurrentAccountIdUsingGET
 * url: getCurrentAccountIdUsingGETURL
 * method: getCurrentAccountIdUsingGET_TYPE
 * raw_url: getCurrentAccountIdUsingGET_RAW_URL
 */
export const getCurrentAccountIdUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/worker/current-account-id'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getCurrentAccountIdUsingGET_RAW_URL = function() {
  return '/worker/current-account-id'
}
export const getCurrentAccountIdUsingGET_TYPE = function() {
  return 'get'
}
export const getCurrentAccountIdUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/worker/current-account-id'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * isWorkerVerifiedByEmailVerificationToken
 * request: isWorkerVerifiedByEmailVerificationTokenUsingGET
 * url: isWorkerVerifiedByEmailVerificationTokenUsingGETURL
 * method: isWorkerVerifiedByEmailVerificationTokenUsingGET_TYPE
 * raw_url: isWorkerVerifiedByEmailVerificationTokenUsingGET_RAW_URL
 * @param token - token
 */
export const isWorkerVerifiedByEmailVerificationTokenUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/worker/is-email-verified'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['token'] !== undefined) {
    queryParameters['token'] = parameters['token']
  }
  if (parameters['token'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: token'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const isWorkerVerifiedByEmailVerificationTokenUsingGET_RAW_URL = function() {
  return '/worker/is-email-verified'
}
export const isWorkerVerifiedByEmailVerificationTokenUsingGET_TYPE = function() {
  return 'get'
}
export const isWorkerVerifiedByEmailVerificationTokenUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/worker/is-email-verified'
  if (parameters['token'] !== undefined) {
    queryParameters['token'] = parameters['token']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getAllWorkersFromCompany
 * request: getAllWorkersFromCompanyUsingGET
 * url: getAllWorkersFromCompanyUsingGETURL
 * method: getAllWorkersFromCompanyUsingGET_TYPE
 * raw_url: getAllWorkersFromCompanyUsingGET_RAW_URL
 */
export const getAllWorkersFromCompanyUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/worker/list/company'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getAllWorkersFromCompanyUsingGET_RAW_URL = function() {
  return '/worker/list/company'
}
export const getAllWorkersFromCompanyUsingGET_TYPE = function() {
  return 'get'
}
export const getAllWorkersFromCompanyUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/worker/list/company'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchWorker
 * request: searchWorkerUsingPOST
 * url: searchWorkerUsingPOSTURL
 * method: searchWorkerUsingPOST_TYPE
 * raw_url: searchWorkerUsingPOST_RAW_URL
 * @param searchCriteria - searchCriteria
 */
export const searchWorkerUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/worker/search/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['searchCriteria'] !== undefined) {
    body = parameters['searchCriteria']
  }
  if (parameters['searchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: searchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchWorkerUsingPOST_RAW_URL = function() {
  return '/worker/search/'
}
export const searchWorkerUsingPOST_TYPE = function() {
  return 'post'
}
export const searchWorkerUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/worker/search/'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchWorkerCount
 * request: searchWorkerCountUsingPOST
 * url: searchWorkerCountUsingPOSTURL
 * method: searchWorkerCountUsingPOST_TYPE
 * raw_url: searchWorkerCountUsingPOST_RAW_URL
 * @param searchCriteria - searchCriteria
 */
export const searchWorkerCountUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/worker/search/count'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['searchCriteria'] !== undefined) {
    body = parameters['searchCriteria']
  }
  if (parameters['searchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: searchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchWorkerCountUsingPOST_RAW_URL = function() {
  return '/worker/search/count'
}
export const searchWorkerCountUsingPOST_TYPE = function() {
  return 'post'
}
export const searchWorkerCountUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/worker/search/count'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * sendVerificationEmail
 * request: sendVerificationEmailUsingPOST
 * url: sendVerificationEmailUsingPOSTURL
 * method: sendVerificationEmailUsingPOST_TYPE
 * raw_url: sendVerificationEmailUsingPOST_RAW_URL
 * @param id - id
 */
export const sendVerificationEmailUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/worker/send-verification-email/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const sendVerificationEmailUsingPOST_RAW_URL = function() {
  return '/worker/send-verification-email/{id}'
}
export const sendVerificationEmailUsingPOST_TYPE = function() {
  return 'post'
}
export const sendVerificationEmailUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/worker/send-verification-email/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * saveCompetenceListForDepartment
 * request: saveCompetenceListForDepartmentUsingPOST
 * url: saveCompetenceListForDepartmentUsingPOSTURL
 * method: saveCompetenceListForDepartmentUsingPOST_TYPE
 * raw_url: saveCompetenceListForDepartmentUsingPOST_RAW_URL
 * @param competenceListDto - competenceListDTO
 */
export const saveCompetenceListForDepartmentUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/worker/trends/competences/department/save'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['competenceListDto'] !== undefined) {
    body = parameters['competenceListDto']
  }
  if (parameters['competenceListDto'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: competenceListDto'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const saveCompetenceListForDepartmentUsingPOST_RAW_URL = function() {
  return '/worker/trends/competences/department/save'
}
export const saveCompetenceListForDepartmentUsingPOST_TYPE = function() {
  return 'post'
}
export const saveCompetenceListForDepartmentUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/worker/trends/competences/department/save'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getCompetenceListForDepartment
 * request: getCompetenceListForDepartmentUsingGET
 * url: getCompetenceListForDepartmentUsingGETURL
 * method: getCompetenceListForDepartmentUsingGET_TYPE
 * raw_url: getCompetenceListForDepartmentUsingGET_RAW_URL
 * @param department - department
 */
export const getCompetenceListForDepartmentUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/worker/trends/competences/list'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['department'] !== undefined) {
    queryParameters['department'] = parameters['department']
  }
  if (parameters['department'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: department'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getCompetenceListForDepartmentUsingGET_RAW_URL = function() {
  return '/worker/trends/competences/list'
}
export const getCompetenceListForDepartmentUsingGET_TYPE = function() {
  return 'get'
}
export const getCompetenceListForDepartmentUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/worker/trends/competences/list'
  if (parameters['department'] !== undefined) {
    queryParameters['department'] = parameters['department']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getCompetenceMapForWorkers
 * request: getCompetenceMapForWorkersUsingGET
 * url: getCompetenceMapForWorkersUsingGETURL
 * method: getCompetenceMapForWorkersUsingGET_TYPE
 * raw_url: getCompetenceMapForWorkersUsingGET_RAW_URL
 * @param department - department
 */
export const getCompetenceMapForWorkersUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/worker/trends/competences/worker'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['department'] !== undefined) {
    queryParameters['department'] = parameters['department']
  }
  if (parameters['department'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: department'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getCompetenceMapForWorkersUsingGET_RAW_URL = function() {
  return '/worker/trends/competences/worker'
}
export const getCompetenceMapForWorkersUsingGET_TYPE = function() {
  return 'get'
}
export const getCompetenceMapForWorkersUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/worker/trends/competences/worker'
  if (parameters['department'] !== undefined) {
    queryParameters['department'] = parameters['department']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * saveCompetenceMapForWorkers
 * request: saveCompetenceMapForWorkersUsingPOST
 * url: saveCompetenceMapForWorkersUsingPOSTURL
 * method: saveCompetenceMapForWorkersUsingPOST_TYPE
 * raw_url: saveCompetenceMapForWorkersUsingPOST_RAW_URL
 * @param department - department
 * @param selectedCompetenceMap - selectedCompetenceMap
 */
export const saveCompetenceMapForWorkersUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/worker/trends/competences/worker/save'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['department'] !== undefined) {
    queryParameters['department'] = parameters['department']
  }
  if (parameters['department'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: department'))
  }
  if (parameters['selectedCompetenceMap'] !== undefined) {
    body = parameters['selectedCompetenceMap']
  }
  if (parameters['selectedCompetenceMap'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: selectedCompetenceMap'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const saveCompetenceMapForWorkersUsingPOST_RAW_URL = function() {
  return '/worker/trends/competences/worker/save'
}
export const saveCompetenceMapForWorkersUsingPOST_TYPE = function() {
  return 'post'
}
export const saveCompetenceMapForWorkersUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/worker/trends/competences/worker/save'
  if (parameters['department'] !== undefined) {
    queryParameters['department'] = parameters['department']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * verifyWorkersEmailAndSetPassword
 * request: verifyWorkersEmailAndSetPasswordUsingPOST
 * url: verifyWorkersEmailAndSetPasswordUsingPOSTURL
 * method: verifyWorkersEmailAndSetPasswordUsingPOST_TYPE
 * raw_url: verifyWorkersEmailAndSetPasswordUsingPOST_RAW_URL
 * @param passwordRequest - passwordRequest
 */
export const verifyWorkersEmailAndSetPasswordUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/worker/verify-email'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['passwordRequest'] !== undefined) {
    body = parameters['passwordRequest']
  }
  if (parameters['passwordRequest'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: passwordRequest'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const verifyWorkersEmailAndSetPasswordUsingPOST_RAW_URL = function() {
  return '/worker/verify-email'
}
export const verifyWorkersEmailAndSetPasswordUsingPOST_TYPE = function() {
  return 'post'
}
export const verifyWorkersEmailAndSetPasswordUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/worker/verify-email'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * deleteWorker
 * request: deleteWorkerUsingDELETE
 * url: deleteWorkerUsingDELETEURL
 * method: deleteWorkerUsingDELETE_TYPE
 * raw_url: deleteWorkerUsingDELETE_RAW_URL
 * @param id - id
 */
export const deleteWorkerUsingDELETE = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/worker/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const deleteWorkerUsingDELETE_RAW_URL = function() {
  return '/worker/{id}'
}
export const deleteWorkerUsingDELETE_TYPE = function() {
  return 'delete'
}
export const deleteWorkerUsingDELETEURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/worker/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}