<template>
    <div class="p-fluid custom-input-text">
        <span class="p-float-label">
            <i v-if="icon" :class="icon" />
            <Password v-model="internalValue" :feedback="false" :class="computedClasses"
                      @input="handleChange" @blur="handleBlur" :toggleMask="toggleMask"/>
            <label :for="name">{{ label }}</label>
            <span v-show="errorMessage" class="p-invalid">{{ errorMessage }}</span>
        </span>
    </div>
</template>

<script>
    import Password from "primevue/password";
    import {useField} from "vee-validate";

    export default {
        name: "CustomPassword",
        components: {Password},

        props: {
            name: {
                type: String,
            },
            label: {
                type: String,
            },
            modelValue: null,
            class: {
                type: String,
            },
            rules: undefined,
            icon: {
                type: String,
                default: null,
                required: false,
            },
            toggleMask: {
                type: Boolean,
                default: false,
                required: false,
            },
        },
        emits: ["update:modelValue"],

        computed: {
            internalValue: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },

            },

            computedClasses() {
                const classes = this.class ? this.class : "";
                return classes + (this.errorMessage ? "p-invalid" : "");
            },

        },

        setup(props) {
            const {
                value: inputValue,
                errorMessage,
                handleBlur,
                handleChange,
                meta,
            } = useField(props.name, props.rules, {
                initialValue: props.modelValue,
            });

            return {
                handleChange,
                handleBlur,
                errorMessage,
                inputValue,
                meta,
            };
        },
    };
</script>

<style scoped>
    .custom-input-text {
        margin-bottom: 20px;
    }
</style>
