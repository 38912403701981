<template>
    <h4>
        Kompetencje działu
    </h4>
    <Toolbar v-if="editing" class="actions">
        <template v-slot:left>
            <Button label="Dodaj kompetencję" :disabled="actionsDisabled || (value != null && value.length >= 5)"
                    icon="pi pi-plus" @click="newCompetence"/>
        </template>
    </Toolbar>
    <DataTable v-if="editing" :value="value" class="editable-cells-table mb-4" responsiveLayout="scroll"
               @rowReorder="onRowReorder" editMode="cell" >
        <template #empty>
            Brak kompetencji
        </template>
        <Column :rowReorder="true" headerStyle="width: 3rem" :reorderableColumn="false" />
        <Column field="competence" header="Kompetencja" bodyClass="align-cell-baseline">
            <template #editor="slotProps">
                <CustomInputText :disabled="actionsDisabled"
                                 v-model="slotProps.data.competence"  style="width: 100%"
                                 rules="required"/>
            </template>
        </Column>
        <Column headerStyle="width: 5rem" header="Usuń">
            <template #body="{ index }">
                <Button type="button" :disabled="actionsDisabled" icon="pi pi-trash" @click="deleteCompetence(index)"
                        class="p-button-rounded p-button-text p-button-plain"/>
            </template>
        </Column>
    </DataTable>
    <DataTable v-else :value="value" class="editable-cells-table mb-4" responsiveLayout="scroll">
        <template #empty>
            Brak kompetencji
        </template>
        <Column field="competence" header="Kompetencja" bodyClass="align-cell-baseline">
            <template #editor="slotProps">
                <CustomInputText disabled
                                 v-model="slotProps.data.competence"  style="width: 100%"
                                 rules="required"/>
            </template>
        </Column>
    </DataTable>
    <Toolbar  v-if="editing" class="actions">
        <template v-slot:left>
            <Button label="Zapisz kompetencje i odśwież listy"
                    :disabled="actionsDisabled"
                    icon="pi pi-save" @click="saveCompetences"/>
        </template>
    </Toolbar>
</template>

<script>

    import DataTable from "primevue/datatable";
    import Button from "primevue/button";
    import Column from "primevue/column";
    import CustomInputText from "@/components/form/CustomInputText";
    import Toolbar from "primevue/toolbar";
    import {saveCompetenceListForDepartmentUsingPOST as saveCompetenceList} from "@/swagger/vue-api-client";

    export default {
        name: "CompetenceList",
        components: {
            DataTable,
            Button,
            Column,
            CustomInputText,
            Toolbar,
        },
        props: {
            modelValue: Array,
            department: {
                type: Object,
            },
            editing: {
                type: Boolean,
                default: true,
            },
        },
        emits: ["update:modelValue", "saving-started", "competences-updated"],
        computed: {
            value: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },
            },
        },
        data() {
            return {
                actionsDisabled: false,
            };
        },
        methods: {
            onRowReorder(event) {
                this.value = event.value;
            },
            deleteCompetence(i) {
                this.value = this.value.filter((el, index) => index !== i);
            },
            newCompetence() {
                this.value = [...this.value, {competence: ""}];
            },
            async saveCompetences() {
                this.$emit("saving-started");
                this.actionsDisabled = true;
                const competenceList = this.value;
                const competenceListDTO = {
                    department: this.department.discriminator,
                    competences: competenceList,
                };
                await saveCompetenceList({competenceListDto: competenceListDTO}).then((response) => {
                    this.$emit("competences-updated", response.data);
                }).catch(() => {
                    this.$toast.add({
                        severity: "error",
                        summary: "Błąd",
                        detail: "Nastąpił nieoczekiwany błąd",
                        life: 3000,
                    });
                });
                this.actionsDisabled = false;
            },
        },
    };
</script>

<style lang="less">
@import "../../../assets/theme/variable.less";
.competence-list {
  color: @quaternary-color;
  .align-cell-baseline {
    vertical-align: baseline;
  }
}
</style>
