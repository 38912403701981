/* eslint-disable */
export const Departments = {
    departments: [
        {name: "Produkcja", url: "production", discriminator: "PRODUCTION"},
        {name: "Transport/logistyka", url: "transport-logistics", discriminator: "TRANSPORT_LOGISTICS"},
        {name: "Marketing/sprzedaż", url: "marketing-sales", discriminator: "MARKETING_SALES"},
        {name: "Administracja", url: "administration", discriminator: "ADMINISTRATION"},
        {name: "Dział konstrukcyjny / projektowy", url: "construction-design", discriminator: "CONSTRUCTION_DESIGNING"},
    ],

    getDepartmentByUrlParam(urlParam) {
        return this.departments.find((entry) => entry.url === urlParam);
    },

    getDepartmentDiscriminatorByUrlParam(urlParam) {
        let department = this.getDepartmentByUrlParam(urlParam);
        if (department) {
            return department.discriminator;
        }
        return null;
    },

    getDepartmentByDiscriminator(discriminator) {
        if (discriminator) {
            return this.departments.find(entry => entry.discriminator === discriminator);
        }
        return null;
    },

    getDepartmentNameByDiscriminator(discriminator) {
        let department = this.getDepartmentByDiscriminator(discriminator);
        if (department) {
            return department.name;
        }
        return "";
    }
};
