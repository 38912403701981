<template>
    <div class="center">
        <div style="display: flex; flex-direction: column">
            <span class="message"> Twoja ankieta została przesłana, dziękujemy. </span>
            <br/>
            <router-link class="redirect-button" tag="div" :to="{name: 'home'}">
                <Button icon="pi pi-undo" label="Powrót na stronę główną" />
            </router-link>
        </div>
    </div>
</template>

<script>
    import Button from "primevue/button";

    export default {
        name: "SurveyCompletedView",
        components: {Button},
    };
</script>

<style scoped>
    .center {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 80vh;
    }
    .message {
        font-size: 40px;
    }
</style>
