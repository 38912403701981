<template>
    <span class="evaluation-badge" :class="getEvaluationTypeClass(type)">
        {{getEvaluationTypeText(type)}}
    </span>
</template>

<script>
    import {Evaluations} from "@/utils/Evaluations";

    export default {
        name: "EvaluationTypeBadge",
        props: {
            type: {
                type: String,
                required: true,
            },
        },

        methods: {
            getEvaluationTypeText(type) {
                return Evaluations.getEvaluationNameByDiscriminator(type);
            },

            getEvaluationTypeClass(type) {
                return Evaluations.getEvaluationTypeClass(type);
            },
        },
    };
</script>

<style scoped>
    .evaluation-self_evaluation {
        background-color: #ff6161;
        color: #ffffff;
    }
    .evaluation-coworker_evaluation {
        background-color: #247ba0;
        color: #ffffff;
    }
    .evaluation-supervisor_evaluation {
        background-color: #05112f;
        color: #ffffff;
    }

    .evaluation-badge {
        border-radius: 2px;
        padding: .25em .5rem;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 12px;
        letter-spacing: .3px;
        display: inline-block;
    }
</style>
