<template>
    <div>
        <DataTable ref="competenceDepartmentActionsTable" :value="competencesOrActions" :lazy="true" :loading="loading"
                   :rowClass="rowClass" :scrollable="true" scrollHeight="1000px">
            <template #empty>
                Nie znaleziono kompetencji/akcji
            </template>
            <template #loading>
                Trwa wczytywanie kompetencji/akcji. Proszę czekać
            </template>
            <Column field="competenceOrAction" header="Kompetencje wymagające uzupełnienia / działania"
                    style="text-align: center">
                <template #body="slotProps">
                    <div v-if="slotProps.data.elearningIndex !== null">
                        <!--eslint-disable-next-line max-len-->
                        <a v-on:click="redirectToElearning(slotProps.data.elearningIndex)" style="cursor: pointer;">
                            {{ slotProps.data.competenceOrAction }}</a>
                    </div>
                    <div v-else>
                        {{ slotProps.data.competenceOrAction }}
                    </div>
                </template>
            </Column>
            <Column field="description" header="Opis działania" style="text-align: center"></Column>
            <Column field="actionType" header="Typ działania" style="text-align: center"></Column>
            <Column field="numberOfWorkersRecommendedToTakeAction"
                    header="Liczba pracowników rekomendowanych do objęcia działaniem"
                    style="text-align: center"></Column>
            <Column field="listOfWorkersRecommendedToTakeAction"
                    header="Lista pracowników rekomendowanych do objęcia działaniem"
                    style="text-align: center">

                <template #body="slotProps">
                    <div v-if="slotProps.data.listOfWorkersRecommendedToTakeAction.length !== 0">
                        <Button
                            icon="pi pi-eye"
                            v-if="slotProps.data.showList === false"
                            type="button"
                            @click="showHideList(slotProps.data)"
                            class="p-button-help p-ml-2 p-button-rounded p-button-outlined"
                            v-tooltip.top="'Wyświetl listę'">
                        </Button>

                        <div>
                            <Button icon="pi pi-eye-slash"
                                    v-if="slotProps.data.showList === true"
                                    type="button"
                                    @click="showHideList(slotProps.data)"
                                    class="p-button-danger p-ml-2 p-button-rounded p-button-outlined"
                                    v-tooltip.top="'Ukryj listę'">
                            </Button>
                        </div>

                        <div v-if="slotProps.data.showList === true" style="text-align: left;">
                            <br>
                            <li v-for="worker in slotProps.data.listOfWorkersRecommendedToTakeAction"
                                :key="worker" style="list-style-type:none">
                                <br>
                                {{worker}}
                            </li>
                        </div>
                    </div>
                </template>
            </Column>
            <Column field="implementedActions"
                    header="Działania zrealizowane"
                    style="text-align: left;">
                <template #body="slotProps">
                    <div v-if="slotProps.data.showList === false">
                        <CustomCheckbox v-if="slotProps.data.listOfWorkersRecommendedToTakeAction.length !== 0"
                                        v-model="slotProps.data.implementedActionForAllWorkers"
                                        :disabled=
                                            "slotProps.data.isImplementedActionForAllWorkersDisabled"
                                        label="Wybierz dla wszystkich"></CustomCheckbox>
                    </div>
                    <div v-else-if="slotProps.data.implementedActionForAllWorkers && slotProps.data.showList">
                        <CustomCheckbox v-if="slotProps.data.listOfWorkersRecommendedToTakeAction.length !== 0"
                                        v-model="slotProps.data.implementedActionForAllWorkers"
                                        :disabled=
                                            "slotProps.data.isImplementedActionForAllWorkersDisabled"
                                        label="Wybierz dla wszystkich">
                        </CustomCheckbox>
                    </div>
                    <div v-else>
                        <br><br><br><br><br><br>
                        <CustomCheckbox v-for="(action, index) in slotProps.data.implementedActionsForWorkers"
                                        :key="index"
                                        v-model="slotProps.data.implementedActionsForWorkers[index]"
                                        :disabled=
                                            "slotProps.data.isImplementedActionForWorkerDisabled[index]"
                                        label="Wybierz">
                        </CustomCheckbox>
                    </div>
                </template>
            </Column>
        </DataTable>
    </div>
    <div>
        <Button label="Zapisz" class="p-button"
                style="margin-top: 20px;" icon="pi pi-save" @click="confirmSave($event)"></Button>
    </div>
    <BlockUI :blocked="blockUI" :fullScreen="true"/>
    <PopupProgressSpinner v-if="blockUI" label="Zapisywanie wyników..."/>
</template>

<script>
    import BlockUI from "primevue/blockui";
    import DataTable from "primevue/datatable";
    import Column from "primevue/column";
    import {Departments} from "@/utils/Departments";
    import {
        getCompetenceDepartmentsActionsUsingGET as getCompetenceDepartmentsActions,
        updateReskillingPathWorkersActionsUsingPOST as updateReskillingPathWorkers,
    } from "@/swagger/vue-api-client";
    import Tooltip from "primevue/tooltip";
    import Button from "primevue/button";
    import CustomCheckbox from "@/components/form/CustomCheckbox";
    import PopupProgressSpinner from "@/components/PopupProgressSpinner";

    export default {
        name: "CompetenceDepartmentTable",
        components: {
            PopupProgressSpinner,
            DataTable,
            Column,
            Button,
            CustomCheckbox,
            BlockUI,
        },

        emits: ["loading-finished, merging-not-completed"],

        directives: {
            tooltip: Tooltip,
        },

        data() {
            return {
                competencesOrActions: [],
                department: Departments.getDepartmentDiscriminatorByUrlParam(this.$route.params.department),
                loading: false,
                blockUI: false,
            };
        },

        methods: {
            getCompetenceDepartmentsActions() {
                getCompetenceDepartmentsActions({department: this.department}).then((response) => {
                    this.competencesOrActions = response.data;
                    this.competencesOrActions.forEach((competence) => { competence.showList = false; });
                    this.$emit("loading-finished");
                    this.loading = false;
                }).catch((error) => {
                    if (error.response.status === 409) {
                        this.$emit("merging-not-completed");
                    }
                    this.$emit("loading-finished");
                    this.loading = false;
                });
            },

            rowClass(data) {
                if (data.competenceOrAction === data.competenceOrAction.toUpperCase()) {
                    return "row-class";
                } return null;
            },

            showHideList(data) {
                data.showList = !data.showList;
            },

            loadData() {
                this.loading = true;
                this.getCompetenceDepartmentsActions();
            },

            confirmSave(event) {
                this.$confirm.require({
                    target: event.currentTarget,
                    header: "Potwierdź poprawność danych",
                    message:
                        "Po zapisaniu twierdzących odpowiedzi nie będzie możliwości zmiany. Czy chcesz kontynuować?",
                    icon: "pi pi-exclamation-triangle",
                    acceptLabel: "Tak",
                    rejectLabel: "Nie",
                    accept: () => {
                        this.blockUI = true;
                        // eslint-disable-next-line max-len
                        updateReskillingPathWorkers({reskillingPathWorkers: this.competencesOrActions, department: this.department}).then(() => {
                            this.$toast.add({
                                severity: "info",
                                summary: "Sukces. Zapisano dane",
                                detail: "",
                                life: 3000,
                            });
                            this.loaded = false;
                            this.blockUI = false;
                            this.loadData();
                        }).catch(() => {
                            this.$toast.add({
                                severity: "info",
                                summary: "Nastąpił nieoczekiwany błąd",
                                detail: "",
                                life: 3000,
                            });
                            this.loaded = false;
                            this.blockUI = false;
                        });
                    },
                    reject: () => {
                        this.$toast.add({
                            severity: "error", summary: "Odmowa", detail: "Dane nie zostały zapisane.", life: 3000,
                        });
                    },
                });
            },

            redirectToElearning(elearningIndex) {
                this.$router.push({
                    name: "ElearningStep",
                    params: {id: elearningIndex, stepId: 1},
                });
            },

        },

        mounted() {
            this.loadData();
        },
    };
</script>

<style lang="less" scoped>
    @import "src/views/assets/less/competence-view.less";
</style>
