<template>
    <div>
        <router-view />
    </div>
</template>

<script>
    export default {
        name: "LayoutPrintable",

    };
</script>

<style>
</style>
