<template>
    <div class="registration-anchor">
        <div v-if="!registrationCompleted" class="p-grid p-col-12 p-md-6 p-lg-3 p-align-center" style="margin: auto">
            <div class="login-panel-header p-col-12" style="align-items: center">
                <img src="@/assets/logo_full.png" width="300" height="300">
            </div>
            <div style="align-items: center" class="p-col-12">
                <h1>
                    Rejestracja konta
                </h1>
            </div>
            <Form @submit="registerUser" v-slot="{ isSubmitting }">
                <div class="p-grid" style="margin-top: 10px">
                    <div class="p-col-12">
                        <h4>
                            Dane firmy
                        </h4>
                    </div>
                    <div class="p-col-12">
                        <CustomInputText name="companyName" v-model="userData.companyName"
                                         :label="$t('message.global.companyName')"
                                         icon="pi pi-globe"
                                         rules="required"/>
                        <CustomInputText name="phoneNumber" v-model="userData.phoneNumber"
                                         :label="$t('message.global.phoneNumber')"
                                         :maxlength="20"
                                         icon="pi pi-phone"
                                         rules="required"/>
                        <CustomInputText name="companyEmail" v-model="userData.companyEmail"
                                         label="Adres email firmy"
                                         icon="pi pi-envelope"
                                         rules="required|email"/>
                        <CustomInputTextArea name="description" v-model="userData.description"
                                             :label="$t('message.global.companyDescription')"
                                             :rows="10"
                                             :maxlength="400"
                                             icon="pi pi-id-card"
                                             :rules="this.rules"/>
                        <CustomFileUpload label="logo firmy" name="logoFile" v-model="file"/>
                        <CustomCheckbox name="checkbox" v-model="userData.agreementForDataSharing"
                                        label="Chcę aby dane firmy wyświetlały się w bazie podmiotów"
                                        @change="onCheckboxValueChange($event)"/>
                    </div>
                    <div class="p-col-12">
                        <h4>
                            Twoje dane
                        </h4>
                    </div>
                    <div class="p-col-12">
                        <CustomInputText name="userName" v-model="userData.userName"
                                         :label="$t('message.global.userName')"
                                         icon="pi pi-user"
                                         rules="required"/>
                        <CustomInputText name="userSurname" v-model="userData.userSurname"
                                         :label="$t('message.global.userSurname')"
                                         icon="pi pi-user"
                                         rules="required"/>
                        <CustomInputText name="userEmail" v-model="userData.userEmail"
                                         :label="$t('message.global.email')"
                                         icon="pi pi-envelope"
                                         rules="required|email"/>
                        <CustomPassword name="newPassword"
                                        v-model="userData.password" :label="$t('message.global.password')"
                                        :toggle-mask="true"
                                        rules="required|validPassword"/>
                        <CustomPassword name="newPasswordRepeat"
                                        v-model="userData.repeatPassword"
                                        :toggle-mask="true"
                                        :label="$t('message.global.repeatPassword')"
                                        rules="required|confirmed:@newPassword,Hasła"/>
                        <Button label="Utwórz konto" icon="pi pi-check" type="submit"
                                style="margin-top: 40px; width: auto;" :disabled="isSubmitting"
                                class="p-button">
                        </Button>
                    </div>
                </div>
            </Form>
        </div>
        <div v-else class="center">
            Dziękujemy za rejestrację.
            <br/>
            <br/>
            Na wskazany adres email zostanie przesłany email rejestracyjny.
        </div>
    </div>
</template>

<script>
    import CustomInputText from "@/components/form/CustomInputText";
    import CustomPassword from "@/components/form/CustomPassword";
    import CustomInputTextArea from "@/components/form/CustomInputTextArea";
    import {companyExistUsingGET, emailExistUsingGET, registerCompanyUsingPOST} from "@/swagger/vue-api-client";
    import Button from "primevue/button";
    import {Form} from "vee-validate";
    import {FileUtils} from "@/utils/FileUtils";
    import CustomCheckbox from "@/components/form/CustomCheckbox";
    import CustomFileUpload from "@/components/form/CustomFileUpload";

    export default {
        name: "RegistrationView",
        components: {
            CustomFileUpload,
            CustomInputTextArea,
            CustomCheckbox,
            CustomPassword,
            CustomInputText,
            Button,
            Form,
        },
        mixins: [FileUtils],
        data() {
            return {
                userData: {
                    companyName: "",
                    phoneNumber: "",
                    description: "",
                    logoFile: {},
                    agreementForDataSharing: false,
                    userName: "",
                    userSurname: "",
                    companyEmail: "",
                    userEmail: "",
                    password: "",
                    repeatPassword: "",
                },
                file: undefined,
                registrationCompleted: false,
                rules: "",
            };
        },

        methods: {
            async registerUser(values, actions) {
                let valid = true;

                await Promise.resolve(companyExistUsingGET({companyName: this.userData.companyName}))
                    .then((response) => {
                        if (response.data.exist === true) {
                            actions.setFieldError("companyName", "Istnieje już firma o takiej nazwie");
                            valid = false;
                        }
                    }).catch(() => {
                        valid = false;
                    });

                if (!valid) {
                    return;
                }

                await Promise.resolve(emailExistUsingGET({email: this.userData.userEmail})).then((response) => {
                    if (response.data.exist === true) {
                        actions.setFieldError("userEmail", "Istnieje już użytkownik o takim adresie email");
                        valid = false;
                    }
                }).catch(() => {
                    valid = false;
                });

                if (!valid) {
                    return;
                }

                if (this.file) {
                    this.userData.logoFile.filename = this.file.name;
                    this.userData.logoFile.encodedData = await this.fileToBase64(this.file);
                }

                registerCompanyUsingPOST({companyRegistrationDto: this.userData})
                    .then(() => {
                        this.registrationCompleted = true;
                    })
                    .catch(() => {
                    });
            },
            async fileToBase64(file) {
                return new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => resolve(reader.result);
                    reader.onerror = (error) => reject(error);
                });
            },
            onCheckboxValueChange(event) {
                if (event.target.checked) {
                    this.rules = "required";
                } else {
                    this.rules = "";
                }
            },
        },
    };
</script>

<style lang="less">
    @import "../assets/less/registration-view";
</style>
