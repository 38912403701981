import {createRouter, createWebHistory} from "vue-router";
import RegistrationLayout from "@/views/layout/LayoutRegistration";
import RegistrationView from "@/views/registration/RegistrationView";
import ActivateWorkerAccount from "@/views/registration/ActivateWorkerAccount";
import ConfirmEmailView from "@/views/ConfirmEmailView";
import WorkersMainView from "@/views/workers/WorkersMainView";
import ElearningMainView from "@/views/elearning/ElearningMainView";
import ForumPostPage from "@/views/forum/ForumPostPage";
import CompetenceDepartmentMainView from "@/views/competence/CompetenceDepartmentMainView";
import ForumPage from "@/views/forum/ForumPage";
import TrendsPage from "@/views/trends/TrendsPage";
import TrendsSurveyPage from "@/views/trends/TrendsSurveyPage";
import CompanyListView from "@/views/company/CompanyListView";
import EditCompanyView from "@/views/company/EditCompanyView";
import OwnCompetencesView from "@/views/competence/OwnCompetencesView";
import EfficiencyDepartmentMainView from "@/views/efficiency/EfficiencyDepartmentMainView";
import EfficiencyDepartmentResultsOne from "@/views/efficiency/EfficiencyDepartmentResultsOne";
import MyEvaluationsView from "@/views/evaluation/MyEvaluationsView";
import EfficiencyDepartmentMainResultsView
    from "@/views/efficiency/EfficiencyDepartmentMainResultsView";
import MyElearningMainView from "@/views/elearning/MyElearningMainView";
import MyElearningTemplateView from "@/views/elearning/MyElearningTemplateView";
import ElearningQuizView from "@/views/elearning/ElearningQuizView";
import ElearingStepView from "@/views/elearning/ElearingStepView";
import ReskillingDepartmentPathChartView
    from "@/views/reskilling-path/ReskillingDepartmentPathChartView";
import LayoutPrintable from "@/views/layout/LayoutPrintable";
import PrintEfficiencyResultsStepTwo from "@/views/print/PrintEfficiencyResultsStepTwo";
import PrintEfficiencyResultsStepOne from "@/views/print/PrintEfficiencyResultsStepOne";
import LayoutSimpleLogo from "./views/layout/LayoutSimpleLogo";
import LayoutDashboard from "./views/layout/LayoutDashboard";
import LoginView from "./views/LoginView";
import UserListView from "./views/user/UserListView";
import UserFormView from "./views/user/UserFormView";
import {SystemRole} from "./utils/SystemRole";
import LayoutSimple from "./views/layout/LayoutSimple";
import ForbiddenView from "./views/ForbiddenView";
import NotFoundView from "./views/NotFoundView";
import ErrorView from "./views/ErrorView";
import PasswordRemindView from "./views/PasswordRemindView";
import ChangePasswordView from "./views/ChangePasswordView";
import SurveyView from "./views/survey/SurveyView";
import SurveyCompletedView from "./views/survey/SurveyCompletedView";
import UserConfirmListView from "./views/user/UserConfirmListView";
import SurveyListView from "./views/survey/SurveyListView";
import SurveyNotAssigned from "./views/survey/SurveyNotAssigned";
import CompetenceMainView from "./views/competence/CompetenceMainView";
import TrendsQuizView from "./views/trends/TrendsQuizView";
import PrintTrendsSurveyData from "./views/print/PrintTrendsSurveyData";
import CompanyExcelFilesMainView from "./views/company/CompanyExcelFilesMainView";

const routes = [
        {
            path: "/home",
            name: "home",
            component: LayoutDashboard,
            meta: {requiresAuth: true},
            children: [
                {
                    path: "/workers/:department",
                    name: "workers",
                    component: WorkersMainView,
                    meta: {roles: [SystemRole.EMPLOYER]},
                },
                {
                    path: "/elearning",
                    name: "eLearningList",
                    component: ElearningMainView,
                    meta: {roles: [SystemRole.EMPLOYER, SystemRole.WORKER]},
                },
                {
                    path: "/user/list",
                    name: "userList",
                    component: UserListView,
                    meta: {roles: [SystemRole.ADMIN]},
                },
                {
                    path: "/user/confirm-list",
                    name: "userConfirmList",
                    component: UserConfirmListView,
                    meta: {roles: [SystemRole.ADMIN]},
                },
                {
                    path: "/user/create",
                    name: "userCreate",
                    component: UserFormView,
                    meta: {roles: [SystemRole.ADMIN]},
                },
                {
                    path: "/user/edit/:id",
                    name: "userEdit",
                    component: UserFormView,
                    meta: {roles: [SystemRole.ADMIN]},
                },
                {
                    path: "/survey/:id/",
                    name: "survey",
                    component: SurveyView,
                    meta: {roles: [SystemRole.EMPLOYER, SystemRole.WORKER]},
                },
                {
                    path: "/survey-completed",
                    name: "surveyCompleted",
                    component: SurveyCompletedView,
                    meta: {requiresAuth: true},
                },
                {
                    path: "/survey-list",
                    name: "surveyList",
                    component: SurveyListView,
                    meta: {roles: [SystemRole.EMPLOYER, SystemRole.ADMIN]},
                },
                {
                    path: "/survey-not-assigned",
                    name: "surveyNotAssigned",
                    component: SurveyNotAssigned,
                    meta: {roles: [SystemRole.WORKER]},
                },
                {
                    path: "/competences/:id",
                    name: "competences",
                    component: CompetenceMainView,
                    meta: {roles: [SystemRole.EMPLOYER]},
                },
                {
                    path: "/own-competences",
                    name: "ownCompetences",
                    component: OwnCompetencesView,
                    meta: {roles: [SystemRole.WORKER]},
                },
                {
                    path: "/competences/department/:department",
                    name: "competencesDepartment",
                    component: CompetenceDepartmentMainView,
                    meta: {roles: [SystemRole.EMPLOYER]},
                },
                {
                    path: "/company/list",
                    name: "companyList",
                    component: CompanyListView,
                    meta: {roles: [SystemRole.ADMIN]},
                },
                {
                    path: "/company/edit/:id",
                    name: "companyEdit",
                    component: EditCompanyView,
                    meta: {roles: [SystemRole.ADMIN]},
                },
                {
                    path: "/company/files/:id",
                    name: "companyExcelFiles",
                    component: CompanyExcelFilesMainView,
                    meta: {roles: [SystemRole.ADMIN]},
                },
                {
                    path: "/forum",
                    name: "forum",
                    component: ForumPage,
                    meta: {roles: [SystemRole.ADMIN, SystemRole.EMPLOYER, SystemRole.WORKER]},
                },
                {
                    path: "/forum/:id",
                    name: "forumPostPage",
                    component: ForumPostPage,
                    meta: {roles: [SystemRole.ADMIN, SystemRole.EMPLOYER, SystemRole.WORKER]},
                },
                {
                    path: "/efficiency/department/:department",
                    name: "efficiencyDepartmentCalculations",
                    component: EfficiencyDepartmentMainView,
                    meta: {roles: [SystemRole.EMPLOYER]},
                },
                {
                    path: "/efficiency/department/:department/results/",
                    name: "efficiencyDepartmentCalculationsResults",
                    component: EfficiencyDepartmentMainResultsView,
                    meta: {roles: [SystemRole.EMPLOYER]},
                },
                {
                    path: "/efficiency-results/department/:department",
                    name: "efficiencyDepartmentResultsStepOne",
                    component: EfficiencyDepartmentResultsOne,
                    meta: {roles: [SystemRole.EMPLOYER]},
                },
                {
                    path: "/reskilling-path/department/:department",
                    name: "reskillingDepartmentPathCharts",
                    component: ReskillingDepartmentPathChartView,
                    meta: {roles: [SystemRole.EMPLOYER]},
                },
                {
                    path: "/my-evaluations",
                    name: "myEvaluations",
                    component: MyEvaluationsView,
                    meta: {roles: [SystemRole.EMPLOYER, SystemRole.WORKER]},
                },
                {
                    path: "/my-elearning",
                    name: "myElearning",
                    component: MyElearningMainView,
                    meta: {roles: [SystemRole.WORKER, SystemRole.EMPLOYER]},
                },
                {
                    path: "/my-elearning/:id/:stepId",
                    name: "myElearningTemplate",
                    component: MyElearningTemplateView,
                    meta: {roles: [SystemRole.WORKER, SystemRole.EMPLOYER]},
                    children: [
                        {
                            path: "",
                            name: "ElearningStep",
                            component: ElearingStepView,
                        },
                    ],
                },
                {
                    path: "/my-elearning/:id/quiz",
                    name: "MyElearningQuiz",
                    component: ElearningQuizView,
                    meta: {roles: [SystemRole.WORKER]},
                },
                {
                    path: "/trends",
                    name: "trends",
                    component: TrendsPage,
                    meta: {roles: [SystemRole.ADMIN, SystemRole.EMPLOYER, SystemRole.WORKER]},
                },
                {
                    path: "/trends/:id",
                    name: "trendsSurveyPage",
                    component: TrendsSurveyPage,
                    meta: {roles: [SystemRole.ADMIN, SystemRole.EMPLOYER, SystemRole.WORKER]},
                },
            ],
        },
        {
            component: RegistrationLayout,
            path: "/register",
            children: [
                {
                    path: "",
                    name: "register-company",
                    component: RegistrationView,
                },
                {
                    path: "worker-activate",
                    name: "activate-worker-account",
                    component: ActivateWorkerAccount,
                },
            ],
        },
        {
            name: "layoutSimple",
            component: LayoutSimple,
            path: "/",
            children: [
                {
                    path: "/",
                    name: "login",
                    component: LoginView,
                },
                {
                    path: "/remind",
                    name: "remindPassword",
                    component: PasswordRemindView,
                },
                {
                    path: "/change-password",
                    name: "changePassword",
                    component: ChangePasswordView,
                },
                {
                    path: "/confirm-email",
                    name: "confirmEmail",
                    component: ConfirmEmailView,
                },
                {
                    path: "/forbidden",
                    name: "forbidden",
                    component: ForbiddenView,
                },
                {
                    path: "/error",
                    name: "error",
                    component: ErrorView,
                },
                {
                    path: "/:pathMatch(.*)*",
                    name: "notFound",
                    component: NotFoundView,
                },
            ],
        },
        {
            name: "layoutSimpleLogo",
            component: LayoutSimpleLogo,
            path: "/",
            children: [
                {
                    path: "/trend-quiz/:id/:guestToken",
                    name: "trendsQuizView",
                    component: TrendsQuizView,
                },
            ],
        },
        {
            path: "/print",
            name: "layoutPrintable",
            component: LayoutPrintable,
            meta: {requiresAuth: true},
            children: [
                {
                    path: "/print/efficiency-results/step-one/:department",
                    name: "printEfficiencyResultsStepOne",
                    component: PrintEfficiencyResultsStepOne,
                    meta: {roles: [SystemRole.EMPLOYER]},
                },
                {
                    path: "/print/efficiency-results/step-two/:department",
                    name: "printEfficiencyResultsStepTwo",
                    component: PrintEfficiencyResultsStepTwo,
                    meta: {roles: [SystemRole.EMPLOYER]},
                },
                {
                    path: "/print/trends/:id",
                    name: "printTrendsSurveyData",
                    component: PrintTrendsSurveyData,
                    meta: {roles: [SystemRole.ADMIN, SystemRole.EMPLOYER]},
                },
            ],
        },
    ];
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;
