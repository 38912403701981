<template>
    <Dialog :header="getHeaderLabel" v-model:visible="internalVisible"
            :breakpoints="{'1200px': '60vw', '960px': '75vw', '640px': '100vw'}"
            :style="{width: '40vw'}">
        <Form @submit="moderateComment" v-slot="{ isSubmitting }">
            <div class="p-grid form-wrapper">
                <div class="p-col-12" v-if="currentStep==1">
                    <CustomInputTextArea name="content"
                                         v-model="commentData.content" label="Treść"
                                         rules="required" :rows="10"/>
                </div>
                <div class="comment-edition-completed" v-if="currentStep==2">
                    <div class="center success-message">
                    </div>
                </div>
            </div>
            <div class="button-div">
                <Button v-if="currentStep === 1" label="Anuluj" icon="pi pi-times" class="p-button-text"
                        @click="closeDialog()" :disabled="isSubmitting"/>
                <Button v-if="currentStep === 1" label="Zakończ" icon="pi pi-chevron-right"
                        type="submit" :disabled="isSubmitting" />
                <Button v-if="currentStep === 2" label="Zamknij" icon="pi pi-times" class="p-button-text"
                        @click="closeDialog(), resetCommentData()" />
            </div>

        </Form>
    </Dialog>
</template>

<script>
    import Dialog from "primevue/dialog";
    import Button from "primevue/button";
    import CustomInputTextArea from "@/components/form/CustomInputTextArea";
    import {Form} from "vee-validate";
    import {editCommentUsingPOST as editComment} from "@/swagger/vue-api-client";

    export default {
        name: "EditCommentDialog",
        components: {
            Dialog, Button, Form, CustomInputTextArea,
        },
        emits: ["update:visible", "comment-edited", "update:content"],

        props: {
            visible: {
                type: Boolean,
                required: true,
            },
            commentId: {
                type: Number,
                required: true,
            },
            content: {
                type: Text,
                required: true,
            },
        },

        data() {
            return {
                commentData: {
                    id: this.commentId,
                    content: this.content,
                    postId: this.$route.params.id,
                },
                currentStep: 1,
            };
        },

        created() {
            this.commentData = this.setCommentData();
        },

        watch: {
            visible(val) {
                if (val === true) {
                    this.commentData = this.setCommentData();
                }
            },
        },

        computed: {
            internalVisible: {
                get() {
                    return this.visible;
                },
                set(value) {
                    this.$emit("update:visible", value);
                },

            },
            getHeaderLabel() {
                return this.currentStep === 2 ? "Zmoderowano post" : "Moderuj post";
            },
        },

        methods: {
            async moderateComment() {
                await Promise.resolve(editComment({commentDto: this.commentData}))
                    .then(() => {
                        this.currentStep = 2;
                        this.$emit("comment-edited");
                    }).catch((error) => {
                        if (error.response.status === 403) {
                            this.$toast.add({
                                severity: "error",
                                summary: "Błąd",
                                detail: "Nie masz wystarczających uprawnień",
                                life: 3000,
                            });
                        } else {
                            this.$toast.add({
                                severity: "error",
                                summary: "Błąd",
                                detail: "Wystąpił nieoczekiwany błąd",
                                life: 3000,
                            });
                        }
                    });
            },

            closeDialog() {
                this.internalVisible = false;
                this.currentStep = 1;
            },

            getEmptyComment() {
                return {
                    content: "",
                    postId: this.$route.params.id,
                };
            },

            resetCommentData() {
                this.commentData = this.getEmptyComment();
            },

            setCommentData() {
                return {
                    id: this.commentId,
                    content: this.content,
                    postId: this.$route.params.id,
                };
            },
        },
    };
</script>

<style lang="less" scoped>
@import "src/views/assets/less/edit-comment-dialog.less";
</style>
