import moment from "moment";

/* eslint-disable */
export const DateUtils = {
    defaultDateFormat: "YYYY-MM-DD",
    defaultDateTimeFormat: "YYYY-MM-DD HH:mm:ss",
    timestampFormat: "YYYY-MM-DD-HH-mm-ss",
    dateFormat: "YYYY-MM-DD",

    datesToStrings(dates, format = this.defaultDateTimeFormat) {
        return dates.map((d) => moment(d).format(format));
    },

    displayDateTime(dateString) {
        return moment(dateString).format(this.defaultDateTimeFormat);
    },

    displayDate(dateString) {
        return moment(dateString).format(this.dateFormat);
    },

    isInPast(dateString) {
        return moment().isSameOrAfter(dateString);
    },

    isInFuture(dateString) {
        return moment().isSameOrBefore(dateString);
    },

    dateAsString(date) {
        // return moment().format('DD.MM.YYYY HH:mm');
        return moment().format(this.dateFormat);
    }

};
