<template>
    <div>
        <h1 class="page-header">
            <i class="pi pi-list header-icon"></i>Trendy</h1>

        <Toolbar class="actions">
            <template v-slot:right>
                <Button v-if="isAdmin || isEmployer" label="Przejdź do wyszukiwarki"
                        icon="pi pi-search" @click="showSearchPagesDialog = true"/>
                <Button label="Dodaj nową ankietę" icon="pi pi-plus" @click="showNewPostDialog = true"
                        v-if="isAdmin || isEmployer"/>
            </template>
        </Toolbar>
        <AddNewQuizDialog v-model:visible ="showNewPostDialog" @new-post-added="updateSearch"/>
        <SearchPagesDialog v-model:visible ="showSearchPagesDialog"/>
        <TrendsTable ref="trendsTable" v-model:search-criteria="searchCriteria"></TrendsTable>
    </div>
</template>

<script>
    import TrendsTable from "@/views/trends/TrendsTable";
    import Toolbar from "primevue/toolbar";
    import Button from "primevue/button";
    import AddNewQuizDialog from "@/views/trends/components/AddNewQuizDialog";
    import SearchPagesDialog from "@/views/forum/components/SearchPagesDialog";
    import {SystemRole} from "../../utils/SystemRole";

    export default {
        name: "TrendsPage",
        components: {
            SearchPagesDialog, TrendsTable, Toolbar, Button, AddNewQuizDialog,
        },

        data() {
            return {
                searchCriteria: this.getClearSearchCriteria(),
                showNewPostDialog: false,
                showSearchPagesDialog: false,
                isAdmin: SystemRole.isAdmin(),
                isEmployer: SystemRole.isEmployer(),
            };
        },
        methods: {

            getClearSearchCriteria() {
                return {
                    page: {
                        limit: 10,
                        offset: 0,
                        sortField: null,
                        sortOrder: null,
                    },
                };
            },

            updateSearch() {
                this.$refs.trendsTable.refreshResults();
            },
        },
    };
</script>

<style scoped>

</style>
