<template>
    <div>
        <h1 class="page-header">
            <i class="pi pi-chart-bar header-icon"></i>Kompetencje pracowników działu {{currentDepartment.name}}</h1>

        <div v-show="isLoading">
            <progress-spinner/>
        </div>
        <div v-show="!isLoading">
            <div v-show="mergingResultExists">
                <CompetenceDepartmentTable ref="competenceDepartmentTable"
                                           :key="currentDepartment.discriminator"
                                           @loading-finished="loadingCompetences = false"
                                           @merging-not-completed="mergingResultExists = false"/>
                <br>
                <CompetenceDepartmentChart :key="currentDepartment.discriminator"></CompetenceDepartmentChart>
                <br>
                <div>
                    <Button label="Uzupełnij wynik o diagnozę jakościową" @click="prepareInterviewPdf"
                            style="max-height: 40px; margin-right: 50px"/>
                </div>
                <br>
                <CompetenceDepartmentActionsTable ref="competenceDepartmentActionsTable"
                                                  :key="currentDepartment.discriminator"
                                                  @loading-finished="loadingActions = false"
                                                  @merging-not-completed="mergingResultExists = false"/>
            </div>
            <div v-show="!mergingResultExists">
                <br>
                <br>
                <br>
                <h4>Kompetencje dla tego działu nie zostały jeszcze obliczone</h4>
                <h4>Wyniki pojawią się po przesłaniu wszystkich ankiet przez pracowników działu</h4>
                <div style="display: flex; justify-content: center">
                    <div class="summary">
                        <div class="title">
                            <span>Liczba wysłanych ankiet</span>
                        </div>
                        <span class="count total">{{numberOfSentEvaluations}}/{{numberOfAllEvaluations}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Button from "primevue/button";
    import {getEvaluationStatsForDepartmentUsingGET} from "@/swagger/vue-api-client";
    import {Departments} from "@/utils/Departments";
    import ProgressSpinner from "primevue/progressspinner";
    import CompetenceDepartmentTable from "./CompetenceDepartmentTable";
    import CompetenceDepartmentChart from "./CompetenceDepartmentChart";
    import CompetenceDepartmentActionsTable from "./CompetenceDepartmentActionsTable";

    export default {
        name: "CompetenceDepartmentMainView",
        components: {
            CompetenceDepartmentChart,
            CompetenceDepartmentTable,
            CompetenceDepartmentActionsTable,
            ProgressSpinner,
            Button,
        },
        data() {
            return {
                loadingCompetences: true,
                loadingActions: true,
                mergingResultExists: true,
                currentDepartment: null,
                numberOfSentEvaluations: 0,
                numberOfAllEvaluations: 0,
                pdf: null,
            };
        },

        beforeMount() {
            if (this.$route.params.department && Departments.getDepartmentByUrlParam(this.$route.params.department)) {
                this.setCurrentDepartment();
            } else {
                this.$router.push({name: "notFound"});
            }
        },

        mounted() {
            this.loadStats();
        },

        watch: {
            "$route.params.department": function (val) {
                if (val) {
                    this.setCurrentDepartment();
                    this.reloadResults();
                }
            },
        },

        methods: {
            reloadResults() {
                this.loadStats();
                this.mergingResultExists = true;
                this.loadingActions = true;
                this.loadingCompetences = true;
            },

            loadStats() {
                getEvaluationStatsForDepartmentUsingGET(
                    {department: this.currentDepartment.discriminator},
                ).then((response) => {
                    this.numberOfAllEvaluations = response.data.reduce((a, b) => a + b.totalCount, 0);
                    this.numberOfSentEvaluations = response.data
                        // eslint-disable-next-line no-return-assign
                        .filter((el) => el.evaluationStatus === "SENT")
                        .reduce((a, b) => a + b.totalCount, 0);
                });
            },

            setCurrentDepartment() {
                this.currentDepartment = Departments.getDepartmentByUrlParam(this.$route.params.department);
            },

            prepareInterviewPdf() {
                switch (this.currentDepartment.discriminator) {
                    case "PRODUCTION":
                        this.pdf = "/Wywiad pogłębiony_produkcja.pdf";
                        break;
                    case "TRANSPORT_LOGISTICS":
                        this.pdf = "/Wywiad pogłębiony_transport.pdf";
                        break;
                    case "MARKETING_SALES":
                        this.pdf = "/Wywiad pogłębiony_sprzedaż.pdf";
                        break;
                    case "ADMINISTRATION":
                        this.pdf = "/Wywiad pogłębiony_administracja.pdf";
                        break;
                    case "CONSTRUCTION_DESIGNING":
                        this.pdf = "/Wywiad pogłębiony_projektowy.pdf";
                        break;
                    default:
                        this.pdf = "";
                }
                window.open(this.pdf);
            },

        },

        computed: {
            isLoading() {
                return this.loadingActions || this.loadingCompetences;
            },
        },
    };
</script>

<style lang="less" scoped>
    @import url("@/views/assets/less/competence-deartment-main-view.less");
</style>
