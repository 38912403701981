<template>
    <div>
        <h1 class="page-header"><i class="pi pi-user header-icon"></i>{{header}}</h1>

        <Form @submit="addOrEditUser" v-slot="{ isSubmitting }">
            <div class="p-grid" style="margin-top: 10px">
                <div class="p-col-4">
                    <CustomInputText name="firstName" v-model="user.firstName"
                                     :label="$t('message.global.userName')"
                                     icon="pi pi-user"
                                     rules="required"/>
                    <CustomInputText name="lastName" v-model="user.lastName"
                                     :label="$t('message.global.userSurname')"
                                     icon="pi pi-user"
                                     rules="required"/>
                    <CustomInputText name="email" v-model="user.email"
                                     :label="$t('message.global.email')"
                                     icon="pi pi-envelope"
                                     rules="required|email"/>
                    <CustomPassword name="newPassword"
                                    v-model="user.password" :label="$t('message.global.password')"
                                    :toggle-mask="true"
                                    rules="required|validPassword"/>
                    <CustomPassword name="newPasswordRepeat"
                                    v-model="user.repeatPassword"
                                    :toggle-mask="true"
                                    :label="$t('message.global.repeatPassword')"
                                    rules="required|confirmed:@newPassword,Hasła"/>
                </div>
            </div>
            <Toolbar class="actions">
                <template v-slot:left>
                    <Button label="Zapisz" icon="pi pi-save" class="p-button" type="submit"
                            style="width: auto;" :disabled="isSubmitting"/>
                </template>
            </Toolbar>
        </Form>
    </div>
</template>

<script>
    import {
        createOrUpdateUserUsingPOST as createOrUpdateUser,
        emailExistUsingGET,
        getUserUsingGET as getUser,
    } from "@/swagger/vue-api-client";
    import Button from "primevue/button";
    import Toolbar from "primevue/toolbar";
    import {SystemRole} from "@/utils/SystemRole";
    import {Form} from "vee-validate";
    import CustomInputText from "../../components/form/CustomInputText";
    import CustomPassword from "../../components/form/CustomPassword";

    export default {
        name: "UserFormView",

        components: {
            Button, Toolbar, CustomInputText, CustomPassword, Form,
        },

        props: {
            value: {},
        },

        data() {
            return {
                user: {
                    id: null,
                    firstName: null,
                    lastName: null,
                    email: null,
                    password: null,
                    systemRole: null,
                },
                repeatPassword: null,
                header: null,
            };
        },

        beforeMount() {
            if (this.$route.params.id) {
                getUser({id: this.$route.params.id}).then((response) => {
                    this.user = response.data;
                });
                this.header = "Edytuj użytkownika";
            } else {
                this.header = "Dodaj użytkownika";
                this.user.systemRole = SystemRole.ADMIN;
            }
        },

        methods: {
            async addOrEditUser(values, actions) {
                if (this.header === "Dodaj użytkownika") {
                    let valid = true;
                    await Promise.resolve(emailExistUsingGET({email: this.user.email}))
                        .then((response) => {
                            if (response.data.exist === true) {
                                actions.setFieldError("email", "Istnieje już użytkownik o takim adresie email");
                                valid = false;
                            }
                        }).catch(() => {
                            valid = false;
                        });

                    if (!valid) {
                        return;
                    }
                }

                await Promise.resolve(createOrUpdateUser({userDto: this.user}))
                    .then(() => {
                        this.$toast.add({
                            severity: "success",
                            summary: "Sukces",
                            detail: "Zapisano dane użytkownika",
                            life: 3000,
                        });
                    }).catch((error) => {
                        if (error.response.status === 403) {
                            this.$toast.add({
                                severity: "error",
                                summary: "Błąd",
                                detail: "Nie masz wystarczających uprawnień",
                                life: 3000,
                            });
                        } else {
                            this.$toast.add({
                                severity: "error",
                                summary: "Błąd",
                                detail: "Wystąpił nieoczekiwany błąd",
                                life: 3000,
                            });
                        }
                    });
            },
        },
    };
</script>

<style scoped>

</style>
