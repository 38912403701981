<template>
    <div>
        <h1 class="page-header">
            <i class="pi pi-chart-line header-icon"></i>Lista firm</h1>

        <div class="search-criteria" @keyup.enter="search">
            <CompanySearchCriteria ref="companySearchCriteria" v-model="searchCriteria"/>

            <Toolbar>
                <template v-slot:left>
                    <Button label="Szukaj" icon="pi pi-search" @click="search"/>
                    <Button label="Wyczyść" icon="pi pi-times" @click="clear"/>
                </template>
            </Toolbar>
        </div>

        <CompanyTable ref="companyTable" :search-criteria="searchCriteria"></CompanyTable>
    </div>
</template>

<script>
    import CompanyTable from "@/views/company/components/CompanyTable";
    import CompanySearchCriteria from "@/views/company/components/CompanySearchCriteria";
    import {SystemRole} from "@/utils/SystemRole";
    import {SearchCriteria} from "@/utils/SearchCriteria";
    import Toolbar from "primevue/toolbar";
    import Button from "primevue/button";

    export default {
        name: "CompanyListView",
        components: {
            CompanyTable, CompanySearchCriteria, Toolbar, Button,
        },

        data() {
            return {
                searchCriteria: this.getClearSearchCriteria(),
                Role: SystemRole,
            };
        },

        beforeMount() {
            SearchCriteria.loadCriteria(this);
        },

        methods: {
            search() {
                SearchCriteria.updateUrl(this);

                this.$refs.companyTable.search();
            },

            clear() {
                this.searchCriteria = this.getClearSearchCriteria();
            },

            getClearSearchCriteria() {
                return {
                    name: "",
                    agreementForDataSharing: null,
                    publishedForPublicAccess: null,
                    page: {
                        limit: 10,
                        offset: 0,
                        sortField: null,
                        sortOrder: null,
                    },
                };
            },
        },
    };
</script>

<style scoped>

</style>
