<template>
    <div>
        <h1 class="page-header">
            <i class="pi pi-envelope header-icon"></i>Lista przesłanych ankiet</h1>

        <div class="search-criteria" @keyup.enter="search">
            <SurveySearchCriteria ref="surveySearchCriteria" v-model="searchCriteria"/>

            <Toolbar>
                <template v-slot:left>
                    <Button label="Szukaj" icon="pi pi-search" @click="search"/>
                    <Button label="Wyczyść" icon="pi pi-times" @click="clear"/>
                </template>
            </Toolbar>
        </div>
        <SurveysTable ref="surveysTable" v-model:search-criteria="searchCriteria" />
    </div>
</template>

<script>
    import SurveySearchCriteria from "@/views/survey/components/SurveySearchCriteria";
    import Button from "primevue/button";
    import Toolbar from "primevue/toolbar";
    import {SearchCriteria} from "@/utils/SearchCriteria";
    import SurveysTable from "./SurveysTable";

    export default {
        name: "SurveyListView",
        components: {
            SurveysTable, SurveySearchCriteria, Button, Toolbar,
        },
        data() {
            return {
                searchCriteria: this.getClearSearchCriteria(),
            };
        },

        beforeMount() {
            SearchCriteria.loadCriteria(this);
        },
        methods: {
            getClearSearchCriteria() {
                return {
                    evaluationStatus: "SENT",
                    workerId: null,
                    department: null,
                    evaluationType: null,
                    dates: [],
                    id: null,
                    page: {
                        limit: 10,
                        offset: 0,
                        sortField: null,
                        sortOrder: null,
                    },
                };
            },
            search() {
                SearchCriteria.updateUrl(this);

                this.$refs.surveysTable.search();
            },
            clear() {
                this.searchCriteria = this.getClearSearchCriteria();
            },

        },
    };
</script>

<style scoped>

</style>
