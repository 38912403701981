/* eslint-disable */
export const SystemRole = {
    ADMIN: "ADMIN",
    EMPLOYER: "EMPLOYER",
    WORKER: "WORKER",

    isAdmin() {
        return localStorage.getItem("role") === this.ADMIN;
    },

    isEmployer() {
        return localStorage.getItem("role") === this.EMPLOYER;
    },

    isWorker() {
        return localStorage.getItem("role") === this.WORKER;
    },

    getUserRole() {
        return localStorage.getItem("role");
    },

};
