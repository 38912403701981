<template>
    <div>
        <h1 class="page-header">
            <i class="pi pi-user header-icon"></i>Kompetencje pracownika: {{workerFullName}}</h1>

        <div style="  display: flex; flex-wrap: wrap; align-content: center;">
            <Button label="Przelicz kompetencje" @click="recalculateCompetences"
                    icon="pi pi-refresh" iconPos="left"/>
        </div>
        <CompetenceTable ref="competenceTable"/>
        <div style="width: 100%; display: flex; align-items: flex-start; margin-top: 30px">
            <h3 style="display: inline;">Wymagane działania do dalszego rozwoju</h3>
        </div>
        <CompetenceActionsTable ref="competenceActionsTable"/>
    </div>
</template>

<script>
    import {
        getWorkerFullNameUsingGET as getWorkerFullName,
        recalculateCompetencesUsingPOST as recalculateCompetences,
    } from "@/swagger/vue-api-client";
    import Button from "primevue/button";
    import CompetenceTable from "./CompetenceTable";
    import CompetenceActionsTable from "./CompetenceActionsTable";

    export default {
        name: "CompetenceMainView",
        components: {CompetenceActionsTable, CompetenceTable, Button},

        data() {
            return {
                workerId: this.$route.params.id,
                workerFullName: "",
            };
        },

        methods: {
            getWorkerFullName() {
                getWorkerFullName({workerId: this.workerId}).then((response) => {
                    this.workerFullName = response.data;
                });
            },
            recalculateCompetences() {
                recalculateCompetences({workerId: {id: this.workerId}}).then(() => {
                    this.$toast.add({
                        severity: "success",
                        summary: "Sukces",
                        detail: "Przeliczono kompetencje",
                        life: 3000,
                    });
                }).catch(() => {
                    this.$toast.add({
                        severity: "error",
                        summary: "Błąd",
                        detail: "Wystąpił nieoczekiwany błąd",
                        life: 3000,
                    });
                });
            },
        },

        mounted() {
            this.getWorkerFullName();
        },
    };
</script>
