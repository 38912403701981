<template>
    <div v-if="!verificationCompleted" class="center">
        Proszę czekać...
    </div>
    <div v-else style="height: 100%" class="center">
        <div v-if="!requiresActivation && !emailAlreadyVerified">
            <div class="thankYouRegistration">Dziękujemy za rejestrację</div>
            <br/>
            <router-link :to="{name: 'login'}">Zaloguj się</router-link>
        </div>
        <div v-else-if="requiresActivation && !emailAlreadyVerified">
            <div class="thankYouRegistration">Dziękujemy za rejestrację</div>
            <br/>
            Trwa weryfikacja Twoich danych
        </div>
        <div v-if="emailAlreadyVerified" >
            <div class="thankYouRegistration">To konto zostało już aktywowane</div>
            <br/>
            <router-link :to="{name: 'login'}">Zaloguj się</router-link>
        </div>
    </div>
</template>

<script>
    import {verifyEmailUsingPOST} from "@/swagger/vue-api-client";

    export default {
        name: "ConfirmEmailView",
        data() {
            return {
                token: this.$route.query.verificationToken,
                verificationCompleted: false,
                message: null,
                requiresActivation: false,
                emailAlreadyVerified: false,
            };
        },
        mounted() {
            verifyEmailUsingPOST({emailVerificationRequest: {emailVerificationToken: this.token}}).then((response) => {
                this.verificationCompleted = true;
                this.requiresActivation = response.data.requiresActivation;
                this.emailAlreadyVerified = response.data.emailAlreadyVerified;
            }).catch(() => {
                this.$router.push({name: "error"});
            });
        },
    };
</script>

<style lang="less" scoped>
    .center {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
    }
    .thankYouRegistration {
        font-size: 40px;
    }
</style>
