/* eslint-disable */
export const messagesPL = {
    login: {
        title: "Logowanie",
        login: "Login",
        password: "Hasło",
        action: {
            login: "Zaloguj się",
            remindPassword: "Przypomnij hasło",
        },
    },
    menu: {
        users: "Użytkownicy",
        workers: "Pracownicy",
        notConfirmedUsers: "Konta do akceptacji",
        forum: "Forum",
        myEvaluations: "Moje oceny",
        survey: "Ankieta",
        sentSurveys: "Przesłane ankiety",
        competencesDepartment: "Kompetencje działów",
        reskillingPath: "Ścieżka reskillingu",
        efficiencyCalculations: "Obliczenia efektywności",
        ownCompetence: "Moje kompetencje",
        eLearning: "Baza materiałów",
        companies: "Firmy",
        myElearning: "Moje e-learningi",
        trends: "Trendy",
    },
    global: {
        companyName: "Nazwa firmy",
        phoneNumber: "Numer telefonu",
        companyDescription: "Opis",
        userName: "Imię",
        userSurname: "Nazwisko",
        email: "Adres email",
        password: "Hasło",
        repeatPassword: "Powtórz hasło",
        filename: "Nazwa pliku",
        description: "Opis",
        file: "Plik",
    },
    components: {
        chooseFile: "Wybierz plik",
    }
};
