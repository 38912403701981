<template>
    <div v-if="loaded" >
        <h1 class="page-header">
            <i class="pi pi-users header-icon"></i>Lista pracowników działu {{currentDepartment.name}}</h1>

        <div style="width: 100%; display: flex; align-items: flex-start; margin-top: 30px">
            <Button :disabled="isDisabled(totalWorkers)"
                    label="Dodaj pracownika" @click="showNewWorkerDialog = true"
                    style="max-height: 40px; margin-right: 50px"
                    icon="pi pi-user-plus" iconPos="left"/>
        </div>
        <NewWorkerDialog v-model:visible ="showNewWorkerDialog"
                         v-model:department="currentDepartment.discriminator"
                         @new-worker-added="updateSearch"/>
        <WorkersTable v-if="tableLoaded" :key="currentDepartment.discriminator" ref="workersTable"
                      v-model:search-criteria="searchCriteria"
                      :options-competence-map="competences"
                      :selected-competence-map="workerSelectedCompetenceMap"
                      @total-records-changed="updateTotalRecords($event)" :disable-controls="disableControls"/>
        <CompetenceList :key="currentDepartment.discriminator" v-model="competences" :department="currentDepartment"
                        :editing="!competencesDisabled" @saving-started="setDisableControls"
                        @competences-updated="refreshData"/>
    </div>
</template>

<script>
    import Button from "primevue/button";
    import NewWorkerDialog from "@/views/workers/components/NewWorkerDialog";
    import {Departments} from "@/utils/Departments";
    import WorkersTable from "@/views/workers/components/WorkersTable";
    import CompetenceList from "@/views/workers/components/CompetenceList";
    import {
        getCompetenceListForDepartmentUsingGET as getCompetencesFromApi,
        getCompetenceMapForWorkersUsingGET as getWorkerCompetences,
    } from "@/swagger/vue-api-client";
    import {nextTick} from "vue";

    export default {
        name: "WorkersMainView",
        components: {
            WorkersTable, Button, NewWorkerDialog, CompetenceList,
        },
        data() {
            return {
                currentDepartment: null,
                showNewWorkerDialog: false,
                searchCriteria: this.getClearSearchCriteria(),
                totalWorkers: 0,
                competences: [],
                competencesAsObjects: [],
                workerOptionsCompetenceMap: {},
                workerSelectedCompetenceMap: {},
                loaded: false,
                tableLoaded: false,
                workersTableKey: 0,
                competencesDisabled: false,
                disableControls: false,
            };
        },

        beforeMount() {
            if (this.$route.params.department && Departments.getDepartmentByUrlParam(this.$route.params.department)) {
                this.setCurrentDepartment();
            } else {
                this.$router.push({name: "notFound"});
            }
            this.getCompetences();
            this.getWorkerCompetences();
            this.loaded = true;
            this.tableLoaded = true;
        },

        watch: {
            // eslint-disable-next-line func-names
            "$route.params.department": function () {
                this.setCurrentDepartment();
                this.searchCriteria = this.getClearSearchCriteria();
            },
        },

        methods: {
            setDisableControls() {
                this.disableControls = true;
            },
            refreshData() {
                window.console.log("update called");
                this.loaded = false;
                this.getCompetences();
                this.getWorkerCompetences();
                this.loaded = true;
                this.disableControls = false;
            },
            updateTotalRecords(val) {
                this.totalWorkers = val;
            },

            isDisabled(val) {
                if (val >= 20) {
                    return true;
                }
                return false;
            },

            updateSearch() {
                window.console.log("update called");
                this.$refs.workersTable.refreshResults();
            },

            setCurrentDepartment() {
                this.currentDepartment = Departments.getDepartmentByUrlParam(this.$route.params.department);
                if (this.currentDepartment && this.currentDepartment.discriminator) {
                    this.searchCriteria.department = this.currentDepartment.discriminator;
                }
                this.getCompetences();
                this.getWorkerCompetences();
            },

            getClearSearchCriteria() {
                return {
                    department: Departments.getDepartmentDiscriminatorByUrlParam(this.$route.params.department),
                    page: {
                        limit: 10,
                        offset: 0,
                        sortField: null,
                        sortOrder: null,
                    },
                };
            },

            getWorkerCompetences() {
                getWorkerCompetences(
                    {
                        department: Departments.getDepartmentByUrlParam(
                            this.$route.params.department,
                        ).discriminator,
                    },
                ).then(async (response) => {
                    this.workerOptionsCompetenceMap = response.data.optionsCompetenceMap;
                    this.workerSelectedCompetenceMap = response.data.selectedCompetenceMap;
                    this.tableLoaded = false;
                    await nextTick();
                    this.tableLoaded = true;
                });
            },

            getCompetences() {
                getCompetencesFromApi(
                    {department: this.searchCriteria.department},
                ).then((response) => {
                    this.competences = response.data;
                }).catch(() => {
                    this.$toast.add({
                        severity: "error",
                        summary: "Błąd",
                        detail: "Nastąpił nieoczekiwany błąd",
                        life: 3000,
                    });
                });
            },
        },
    };
</script>
