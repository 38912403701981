<template>
    <div class="center">
        <div style="display: flex; flex-direction: column">
            <span class="message"> Ankieta nie została jeszcze przypisana do konta użytkownika. </span>
        </div>
    </div>
</template>

<script>
    export default {
        name: "SurveyNotAssigned",
    };
</script>

<style scoped>

</style>
