<template>
    <span class="custom-badge" :style="'background-color:' + internalColor">{{ label }}</span>
</template>

<script>

    export default {
        name: "CustomBadge",
        props: {
            color: {
                type: String,
            },
            label: {
                type: String,
            },
        },

        data() {
            return {
                internalColor: "primary",
            };
        },

        mounted() {
            switch (this.color) {
                case "primary":
                    this.internalColor = "#a7a5a5";
                    break;
                case "secondary":
                    this.internalColor = "#247ba0";
                    break;
                case "tertiary":
                    this.internalColor = "#A3C340";
                    break;
                default:
                    this.internalColor = this.color;
            }
        },
    };
</script>

<style scoped>
    .custom-badge {
        color: #ffffff;
        border-radius: 2px;
        padding: .25em .5rem;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 12px;
        letter-spacing: .3px;
    }
</style>
