<template>
    <span class="evaluation-badge" :class="getEvaluationStatusClass(status)">
        {{getStatusText(status)}}
    </span>
</template>

<script>
    import {Evaluations} from "@/utils/Evaluations";

    export default {
        name: "EvaluationStatusBadge",

        props: {
            status: {
                type: String,
                required: true,
            },
        },

        methods: {
            getEvaluationStatusClass(status) {
                return Evaluations.getEvaluationStatusClass(status);
            },
            getStatusText(status) {
                return Evaluations.getEvaluationStatusText(status);
            },
        },
    };
</script>

<style scoped lang="less">
    @import "src/views/assets/less/evaluations-status-badge.less";
</style>
