<template>
    <div>
        <DataTable ref="trendsTable" :value="posts" v-model:rows="searchParams.page.limit" removableSort
                   :rows="9" :rowHover="true"  :rowsPerPageOptions="[5,10,20,50]"
                   @row-click="redirectToTrendsPostPage"
                   :rowClass="rowClass" @page="onPage" @sort="onPage" :paginator="true"
                   :totalRecords="totalRecords" :lazy="true">
            <Column field="title" header="Temat" style="width: 500px" :sortable="true"
                    class="first">
                <template #body="{data}">
                    <div style="width: 100%" class="first">
                        <b>{{data.title}}</b>
                        <br>{{data.content}}
                    </div>
                </template>
            </Column>
            <Column field="author" header="Autor" style="width: 300px" :sortable="true">
                <template #body="{data}">
                    <div style="width: 100%">
                        {{data.author.firstName}} {{data.author.lastName}}
                    </div>
                </template>
            </Column>
            <Column field="creationDate" header="Data" style="width: 200px"
                    :sortable="true">
                <template #body="{data}">
                    <div style="width: 100%">
                        {{parseDate(data.creationDate)}}
                    </div>
                </template>
            </Column>
            <Column field="comments" header="Komentarze" style="width: 200px"
                    class="last">
                <template #body="{data}">
                    <div style="width: 100%; text-align: center">
                        {{data.comments.length}}
                    </div>
                </template>
            </Column>
        </DataTable>
    </div>
</template>

<script>
    import DataTable from "primevue/datatable";
    import Column from "primevue/column";
    import {
        searchTrendPostUsingPOST as searchTrendPost,
        searchPostCountUsingPOST as searchPostCount,
    } from "@/swagger/vue-api-client";
    // eslint-disable-next-line import/named,no-unused-vars
    import {DateUtils} from "@/utils/DateUtils";

    export default {
        name: "TrendsTable",
        components: {
            DataTable, Column,
        },

        props: {
            searchCriteria: {
                type: Object,
            },
        },

        emits: ["update:searchCriteria"],

        watch: {
            totalRecords(newVal) {
                this.$emit("total-records-changed", newVal);
            },
        },

        data() {
            return {
                loading: false,
                searchParams: this.searchCriteria,
                posts: [],
                totalRecords: 0,
            };
        },

        mounted() {
            this.onPage(this.getFirstPage());
            this.updateTotalRecords();
        },

        methods: {
            redirectToTrendsPostPage(event) {
                this.$router.push({
                    name: "trendsSurveyPage",
                    params: {id: event.data.id},
                });
            },
            rowClass() {
                return "row-class";
            },

            onPage(event) {
                this.loading = true;

                this.searchParams.page.offset = event.first;
                this.searchParams.page.limit = event.rows;
                this.searchParams.page.sortField = event.sortField;
                this.searchParams.page.sortOrder = event.sortOrder;

                this.search();
            },

            search() {
                searchTrendPost({trendPostSearchCriteria: this.searchCriteria}).then((response) => {
                    this.posts = response.data;
                    this.loading = false;
                });
            },

            getFirstPage() {
                return {
                    first: this.searchCriteria.page.offset,
                    rows: this.searchCriteria.page.limit,
                };
            },

            parseDate(date) {
                return DateUtils.displayDateTime(date);
            },

            refreshResults() {
                this.search();
                this.updateTotalRecords();
            },

            updateTotalRecords() {
                searchPostCount({postSearchCriteria: this.searchCriteria}).then((response) => {
                    this.totalRecords = response.data;
                });
            },

        },

    };
</script>

<style scoped>

</style>
