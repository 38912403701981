<template>
    <div style="margin-bottom: 40px">

        <h3 style="margin-bottom: 40px">
            Struktura organizacyjna i system motywacyjny
        </h3>
        <div>
            <CustomSelectBoolean class="p-lg-9" style="margin: auto"
                                 name="plannedMotivationChanges"
                                 label="Czy są planowane zmiany w systemie
                                     motywacyjnym w związku z realizacją reskillingu?"
                                 v-model="internalValue.plannedMotivationChanges">

            </CustomSelectBoolean>
            <CustomSelectBoolean class="p-lg-9" style="margin: auto"
                                 v-if="analysisVariant === 'dane mieszane'"
                                 name="useStatisticalData"
                                 label="Chcę skorzystać z danych statystycznych?"
                                 v-model="internalValue.useStatisticalData">

            </CustomSelectBoolean>
        </div>
        <div v-if="!rowDisabled"
             style="margin-bottom: 40px; margin: auto" class="p-lg-9">
            <table class="tableizer-table">
                <thead>
                    <tr class="tableizer-firstrow">
                        <th>Pole wyboru</th>
                        <th>Stosowanie danego elementu</th>
                        <th>Limit kwotowy na 1 osobę w skali miesiąca</th>
                    </tr>
                </thead>
                <tbody style="text-align: left">
                    <tr>
                        <td>elementy motywacji finansowej (np. podwyżki, premie)</td>
                        <td>
                            <CustomSelectBoolean :disabled="rowDisabled"
                                                 name="isFinancialMotivation"
                                                 v-model="internalValue.isFinancialMotivation"/>
                        </td>
                        <td>
                            <CustomInputNumber :disabled="isDisabled(internalValue.isFinancialMotivation)
                                                   || rowDisabled"
                                               name="financialMotivationLimit"
                                               v-model="internalValue.financialMotivationLimit"
                                               mode="currency" locale="pl-PL" currency="PLN"/>
                        </td>
                    </tr>
                    <tr>
                        <td>elementy motywacji pozafinansowej
                            (np. dodatkowe ubezpieczenie, pakiet badań, dofinansowanie edukacji)</td>
                        <td>
                            <CustomSelectBoolean :disabled="rowDisabled"
                                                 name="isNonFinancialMotivation"
                                                 v-model="internalValue.isNonFinancialMotivation"/>
                        </td>
                        <td>
                            <CustomInputNumber :disabled="isDisabled(internalValue.isNonFinancialMotivation)
                                                   || rowDisabled"
                                               name="isNonFinancialMotivation"
                                               v-model="internalValue.nonFinancialMotivationLimit"
                                               mode="currency" locale="pl-PL" currency="PLN"/>
                        </td
                        ></tr>
                    <tr>
                        <td>elementy motywacji pozamaterialnej (np. empowerment, hygge)</td>
                        <td>
                            <CustomSelectBoolean :disabled="rowDisabled"
                                                 name="isNonFinancialMotivation"
                                                 v-model="internalValue.isNonMaterialMotivation"/>
                        </td>
                        <td>
                            <CustomInputNumber :disabled="isDisabled(internalValue.isNonMaterialMotivation)
                                                   || rowDisabled"
                                               name="isNonFinancialMotivation"
                                               v-model="internalValue.nonMaterialMotivationLimit"
                                               mode="currency" locale="pl-PL" currency="PLN"/>
                        </td>
                    </tr>
                </tbody></table>
        </div>
    </div>
</template>

<script>
    import CustomSelectBoolean from "@/views/user/components/CustomSelectBoolean";
    import CustomInputNumber from "@/components/form/CustomInputNumber";

    export default {
        name: "EfficiencyMotivationData",

        components: {
            CustomSelectBoolean,
            CustomInputNumber,
        },

        props: {
            modelValue: null,
            analysisVariant: {
                type: String,
                default: null,
            },
        },

        emits: ["update:modelValue"],

        computed: {
            internalValue: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },
            },
            rowDisabled() {
                if (!this.internalValue.plannedMotivationChanges) {
                    return true; // Czy planowane są zmiany w systemie motywacyjnym: NIE
                }
                if (this.analysisVariant === "dane statystyczne") {
                    return true;
                }
                if (this.analysisVariant === "dane mieszane" && this.internalValue.useStatisticalData === true) {
                    return true;
                }
                return false;
            },
        },

        methods: {

            getBooleanLabel(value) {
                switch (value) {
                    case true:
                        return "Tak";

                    case false:
                        return "Nie";

                    default:
                        return "";
                }
            },

            isDisabled(val) {
                return !(val === true);
            },
        },
    };

</script>

<style scoped>
table.tableizer-table {
    font-size: 1rem;
}
.tableizer-table td {
    padding: 0.3rem 0.5rem;
    margin: 10px;
    border-top: 1px solid #CCC;
}
.tableizer-table th {
    color: #495057;
    padding: 1rem;
    font-weight: bold;
}
</style>
