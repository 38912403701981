<template>
    <div>
        <h1 class="page-header">
            <i class="pi pi-list header-icon"></i>Trendy</h1>

        <Toolbar class="actions">
            <template v-slot:right>
                <Button label="Dodaj nowy post" icon="pi pi-plus" @click="showNewCommentDialog = true"/>
            </template>
        </Toolbar>
        <AddNewTrendCommentDialog v-model:visible ="showNewCommentDialog"
                                  @new-comment-added="updateSearch"></AddNewTrendCommentDialog>
        <TrendsSurveyDataView ref="trendsSurveyDataView"
                              v-model:search-criteria="searchCriteria"></TrendsSurveyDataView>
    </div>
</template>

<script>
    import TrendsSurveyDataView from "@/views/trends/TrendsSurveyDataView";
    import AddNewTrendCommentDialog from "@/views/trends/components/AddNewTrendCommentDialog";
    import Button from "primevue/button";
    import Toolbar from "primevue/toolbar";

    export default {
        name: "TrendsTable",
        components: {
            TrendsSurveyDataView, AddNewTrendCommentDialog, Toolbar, Button,
        },

        data() {
            return {
                searchCriteria: this.getClearSearchCriteria(),
                showNewCommentDialog: false,
            };
        },

        methods: {
            updateSearch() {
                this.$refs.trendsSurveyDataView.refreshResults();
            },

            getClearSearchCriteria() {
                return {
                    page: {
                        limit: 10,
                        offset: 0,
                        sortField: null,
                        sortOrder: null,
                    },
                };
            },
        },
    };
</script>

<style scoped>

</style>
