<template>
    <div>
        <h1 class="page-header">
            <i class="pi pi-user header-icon"></i>Moje kompetencje</h1>

        <CompetenceTable :own="true" ref="competenceTable"/>
        <div style="width: 100%; display: flex; align-items: flex-start; margin-top: 30px">
            <h3 style="display: inline;">Wymagane działania do dalszego rozwoju</h3>
        </div>
        <CompetenceActionsTable :own="true" ref="competenceActionsTable"/>
    </div>
</template>

<script>
    import CompetenceTable from "@/views/competence/CompetenceTable";
    import CompetenceActionsTable from "@/views/competence/CompetenceActionsTable";

    export default {
        name: "OwnCompetencesView",
        components: {
            CompetenceTable, CompetenceActionsTable,
        },
    };
</script>

<style scoped>

</style>
