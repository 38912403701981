<template>
    <div class="custom-checkbox">
        <div style="align-content: flex-start; display: flex;">
            <Checkbox :id="name" v-model="internalValue" :class="computedClasses"
                      @input="handleChange" @blur="handleBlur" :binary="true" :disabled="disabled"/>
            <label style="margin-left: 10px" :for="name">{{ label }}</label>
        </div>
        <span v-show="errorMessage" class="p-invalid">{{ errorMessage }}</span>
    </div>
</template>

<script>
    import Checkbox from "primevue/checkbox";
    import {useField} from "vee-validate";

    export default {
        name: "CustomCheckbox",

        props: {
            name: {
                type: String,
            },
            label: {
                type: String,
            },
            modelValue: null,
            class: {
                type: String,
            },
            rules: undefined,
            disabled: {
                type: Boolean,
            },
        },
        emits: ["update:modelValue"],

        watch: {
            modelValue(val) {
                this.handleChange(val);
            },
        },

        computed: {
            internalValue: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },

            },

            computedClasses() {
                const classes = this.class ? this.class : "";
                return classes + (this.errorMessage ? "p-invalid" : "");
            },

        },

        components: {Checkbox},

        setup(props) {
            const {
                value: inputValue,
                errorMessage,
                handleBlur,
                handleChange,
                meta,
            } = useField(props.name, props.rules, {
                initialValue: props.modelValue,
            });

            return {
                handleChange,
                handleBlur,
                errorMessage,
                inputValue,
                meta,
            };
        },
    };
</script>

<style scoped>
    .custom-checkbox {
        margin-bottom: 20px;

    }
</style>
