<template>
    <div v-if="Role.isAdmin() || Role.isEmployer()" style="margin: auto">
        <div class="post-title" style="padding-top: 30px">
            RAPORT Z ANALIZY TRENDÓW W BRANŻY MEBLOWEJ
        </div>
        <div>
            <h3>Dane dotyczące ankiety</h3>
        </div>
        <DataTable ref="trendsSurveyTable" :value="trendsTableData" :lazy="true">
            <Column field="title" header="Nazwa" style="width: 500px; text-align: center">
                <template #body="{data}">
                    <div style="width: 100%">
                        {{data.title}}
                    </div>
                </template>
            </Column>
            <Column field="data" header="Wartość" style="width: 500px; text-align: center">
                <template #body="{data}">
                    <div style="width: 100%">
                        {{data.data}}
                    </div>
                </template>
            </Column>
        </DataTable>
        <div v-if="post && chartExists" style="padding-top: 30px">
            <div class="p-col-12">
                <div>
                    <Chart class="p-col-12" type="doughnut" :data="counterChartData" :options="counterChartOptions"
                           :height="400" :width="400" style="padding: 20px; display: flex"></Chart>
                </div>
            </div>
            <h3>Uzyskane wyniki</h3>
        </div>
        <div v-if="post && chartExists" class="p-grid p-jc-evenly"
             style="padding-top: 10px; margin: auto">
            <div class="avoid-break-inside">
                <div class="p-col-12">
                    <!--                <div class="box">-->
                    <Chart class="p-col-12" type="doughnut" :data="firstQuestionChartData"
                           :options="firstQuestionChartOptions"
                           :height="400" :width="400" style="padding: 20px; display: flex"></Chart>
                    <!--                </div>-->
                    Zdecydowana większość respondentów - <b>{{firstQuestionChartData.highestValuePercentage}}%</b>
                    wskazała, że rozwiązanie z zakresu <b>{{keyword}}</b> jest stosowane
                    <b>{{firstQuestionChartData.labelOfHighestValue}}</b>.
                    <br>
                    {{firstQuestionChartData.labelDescription}}
                </div>
                <div class="p-col-12">
                    <div class="box">
                        <Chart class="p-col-12" type="doughnut" :data="secondQuestionChartData"
                               :options="secondQuestionChartOptions"
                               :height="400" :width="400" style="padding: 20px; display: flex"></Chart>
                    </div>
                    Zdecydowana większość respondentów - <b>{{secondQuestionChartData.highestValuePercentage}}%</b>
                    wskazała, że rozwiązanie z zakresu <b>{{keyword}}</b> jest planowane do wdrożenia
                    <b>{{secondQuestionChartData.labelOfHighestValue}}</b>.
                    <br>
                    {{secondQuestionChartData.labelDescription}}
                </div>
                <div class="p-col-12">
                    <div class="box">
                        <Chart class="p-col-12" type="doughnut" :data="thirdQuestionChartData"
                               :options="thirdQuestionChartOptions"
                               :height="400" :width="400" style="padding: 20px; display: flex"></Chart>
                    </div>
                    Zdecydowana większość respondentów - <b>{{thirdQuestionChartData.highestValuePercentage}}%</b>
                    wskazała, że rozwiązanie z zakresu <b>{{keyword}}</b> ma
                    <b>{{thirdQuestionChartData.labelOfHighestValue}}</b> rynkowy.
                    <br>
                    {{thirdQuestionChartData.labelDescription}}
                </div>
                <div class="p-col-12">
                    <div class="box">
                        <Chart class="p-col-12" type="doughnut" :data="fourthQuestionChartData"
                               :options="fourthQuestionChartOptions"
                               :height="400" :width="400" style="padding: 20px; display: flex"></Chart>
                    </div>
                    Zdecydowana większość respondentów - <b>{{fourthQuestionChartData.highestValuePercentage}}%</b>
                    wskazała, że rozwiązanie z zakresu <b>{{keyword}}</b> cechuje
                    <b>{{fourthQuestionChartData.labelOfHighestValue}}</b> trwałość zapotrzebowania.
                    <br>
                    {{fourthQuestionChartData.labelDescription}}
                </div>
                <div class="p-col-12">
                    <div class="box">
                        <Chart class="p-col-12" type="doughnut" :data="fifthQuestionChartData"
                               :options="fifthQuestionChartOptions"
                               :height="400" :width="400" style="padding: 20px; display: flex"></Chart>
                    </div>
                    Zdecydowana większość respondentów - <b>{{fifthQuestionChartData.highestValuePercentage}}%</b>
                    wskazała, że dla poszukiwania nowych rozwiązań / trendów w branży meblarskiej najlepszym źródłem są
                    <b>{{fifthQuestionChartData.labelOfHighestValue}}</b>.
                </div>
                <div class="p-col-12">
                    <div class="box">
                        <Chart class="p-col-12" type="doughnut" :data="sixthQuestionChartData"
                               :options="sixthQuestionChartOptions"
                               :height="400" :width="400" style="padding: 20px; display: flex"></Chart>
                    </div>
                    Zdecydowana większość respondentów - <b>{{sixthQuestionChartData.highestValuePercentage}}%</b>
                    wskazała, że dla działu <b>{{department}}</b> rozwiązanie z zakresu <b>{{keyword}}</b> odnosi się
                    <b>{{sixthQuestionChartData.labelOfHighestValue}}</b>.
                    <br>
                    {{sixthQuestionChartData.labelDescription}}
                </div>
            </div>
        </div>
    </div>
    <div style="padding-bottom: 20px; padding-top: 20px;">
        <span class="no-print">
            <Button @click="goBack()" class="p-button"
                    style="padding: 20px; margin-right: 40px;">
                <i class="pi pi-angle-double-left" style="padding-right: 10px;"></i>Powrót do poprzedniej strony
            </Button>
            <Button @click="print()" class="p-button-danger"
                    style="padding: 20px; background-color: #ff6161;">
                <i class="pi pi-print" style="padding-right: 10px;"></i>Drukuj</Button>
        </span>
    </div>
</template>

<script>
    import {
        searchTrendPostUsingPOST as searchTrendPost,
        // eslint-disable-next-line no-unused-vars
        getTrendStudyResultsUsingGET as getTrendStudyResults,
        countTrendQuizResultsUsingGET as countTrendQuizResults,
    } from "@/swagger/vue-api-client";
    import {DateUtils} from "@/utils/DateUtils";
    import Button from "primevue/button";
    import {SystemRole} from "@/utils/SystemRole";
    import Chart from "primevue/chart";
    import DataTable from "primevue/datatable";
    import Column from "primevue/column";
    import {Departments} from "../../utils/Departments";

    export default {
        name: "TrendsSurveyDataView",
        components: {
            Button,
            Chart,
            DataTable,
            Column,
        },

        data() {
            return {
                Role: SystemRole,
                editCommentDialog: false,
                comments: [],
                post: null,
                attemptCount: null,
                chartExists: false,
                quiz: null,
                results: null,
                searchCriteria: {
                    postId: this.$route.params.id,
                    page: {
                        limit: 1,
                        offset: 0,
                        sortField: null,
                        sortOrder: null,
                    },
                },
                currentCommentId: 0,
                currentCommentContent: "",
                moderationStatus: false,
                showEditPostDialog: false,
                globalAmount: 0,
                keyword: null,
                department: null,
                trendsTableData: [
                    {
                        title: "Okres przeprowadzenia ankiety",
                        data: null,
                    },
                    {
                        title: "Liczba respondentów, w tym:",
                        data: null,
                    },
                    {
                        title: "polskie firmy z branży meblarskiej",
                        data: null,
                    },
                    {
                        title: "zagraniczne firmy z branży meblarskiej",
                        data: null,
                    },
                    {
                        title: "firmy designerskie",
                        data: null,
                    },
                    {
                        title: "uczelnie i jednostki naukowe",
                        data: null,
                    },
                    {
                        title: "instytucje otoczenia biznesu",
                        data: null,
                    },
                    {
                        title: "organizatorzy targów",
                        data: null,
                    },
                    {
                        title: "Analizowana fraza",
                        data: null,
                    },
                ],
                counterChartData: {
                    labels: ["polskie firmy z branży meblarskiej", "zagraniczne firmy z branży meblarskiej",
                             "firmy designerskie", "uczelnie i jednostki naukowe",
                             "instytucje otoczenia biznesu", "organizatorzy targów"],
                    datasets: [
                        {
                            backgroundColor: ["#a7a5a5", "#33ccff", "#247ba0", "#ff6161", "#e60000", "#05112f"],
                            data: [],
                        },
                    ],
                },
                counterChartOptions: {
                    responsive: false,
                    maintainAspectRatio: false,
                    title: {
                        display: true,
                        text: null,
                    },
                    plugins: {
                        legend: {
                            labels: {
                                color: "#495057",
                            },
                        },
                    },
                },
                firstQuestionChartData: {
                    labels: [],
                    datasets: [
                        {
                            backgroundColor: ["#a7a5a5", "#33ccff", "#247ba0", "#ff6161", "#05112f"],
                            data: [],
                        },
                    ],
                    highestValuePercentage: null,
                    labelOfHighestValue: null,
                    labelDescription: null,
                },
                firstQuestionChartOptions: {
                    responsive: false,
                    maintainAspectRatio: false,
                    title: {
                        display: true,
                        text: null,
                    },
                    plugins: {
                        legend: {
                            labels: {
                                color: "#495057",
                            },
                        },
                    },
                    tooltips: {
                        callbacks: {
                            label(tooltipItems, data) {
                                let total = 0;
                                for (let i = 0; i < data.datasets[0].data.length; i += 1) {
                                    if (data.datasets[0].data[i] !== 0) {
                                        total += data.datasets[0].data[i];
                                    }
                                }
                                return " " + data.labels[tooltipItems.index] + ": "
                                    + Math.trunc((data.datasets[0].data[tooltipItems.index] * 100) / total) + "%";
                            },
                        },
                    },
                },
                secondQuestionChartData: {
                    labels: [],
                    datasets: [
                        {
                            backgroundColor: ["#a7a5a5", "#33ccff", "#247ba0", "#ff6161", "#05112f"],
                            data: [],
                        },
                    ],
                    highestValuePercentage: null,
                    labelOfHighestValue: null,
                    labelDescription: null,
                },
                secondQuestionChartOptions: {
                    responsive: false,
                    title: {
                        display: true,
                        text: null,
                    },
                    plugins: {
                        legend: {
                            labels: {
                                color: "#495057",
                            },
                        },
                    },
                    tooltips: {
                        callbacks: {
                            label(tooltipItems, data) {
                                let total = 0;
                                for (let i = 0; i < data.datasets[0].data.length; i += 1) {
                                    if (data.datasets[0].data[i] !== 0) {
                                        total += data.datasets[0].data[i];
                                    }
                                }
                                return " " + data.labels[tooltipItems.index] + ": "
                                    + Math.trunc((data.datasets[0].data[tooltipItems.index] * 100) / total) + "%";
                            },
                        },
                    },
                },
                thirdQuestionChartData: {
                    labels: [],
                    datasets: [
                        {
                            backgroundColor: ["#a7a5a5", "#33ccff", "#247ba0", "#ff6161", "#05112f"],
                            data: [],
                        },
                    ],
                    highestValuePercentage: null,
                    labelOfHighestValue: null,
                    labelDescription: null,
                },
                thirdQuestionChartOptions: {
                    responsive: false,
                    maintainAspectRatio: false,
                    title: {
                        display: true,
                        text: null,
                    },
                    plugins: {
                        legend: {
                            labels: {
                                color: "#495057",
                            },
                        },
                    },
                    tooltips: {
                        callbacks: {
                            label(tooltipItems, data) {
                                let total = 0;
                                for (let i = 0; i < data.datasets[0].data.length; i += 1) {
                                    if (data.datasets[0].data[i] !== 0) {
                                        total += data.datasets[0].data[i];
                                    }
                                }
                                return " " + data.labels[tooltipItems.index] + ": "
                                    + Math.trunc((data.datasets[0].data[tooltipItems.index] * 100) / total) + "%";
                            },
                        },
                    },
                },
                fourthQuestionChartData: {
                    labels: [],
                    datasets: [
                        {
                            backgroundColor: ["#a7a5a5", "#33ccff", "#247ba0", "#ff6161", "#05112f"],
                            data: [],
                        },
                    ],
                    highestValuePercentage: null,
                    labelOfHighestValue: null,
                    labelDescription: null,
                },
                fourthQuestionChartOptions: {
                    responsive: false,
                    maintainAspectRatio: false,
                    title: {
                        display: true,
                        text: null,
                    },
                    plugins: {
                        legend: {
                            labels: {
                                color: "#495057",
                            },
                        },
                    },
                    tooltips: {
                        callbacks: {
                            label(tooltipItems, data) {
                                let total = 0;
                                for (let i = 0; i < data.datasets[0].data.length; i += 1) {
                                    if (data.datasets[0].data[i] !== 0) {
                                        total += data.datasets[0].data[i];
                                    }
                                }
                                return " " + data.labels[tooltipItems.index] + ": "
                                    + Math.trunc((data.datasets[0].data[tooltipItems.index] * 100) / total) + "%";
                            },
                        },
                    },
                },
                fifthQuestionChartData: {
                    labels: [],
                    datasets: [
                        {
                            // eslint-disable-next-line max-len
                            backgroundColor: ["#a7a5a5", "#33cc33", "#33ccff", "#247ba0", "#ff6161", "#e60000", "#05112f"],
                            data: [],
                        },
                    ],
                    highestValuePercentage: null,
                    labelOfHighestValue: null,
                    labelDescription: null,
                },
                fifthQuestionChartOptions: {
                    responsive: false,
                    maintainAspectRatio: false,
                    title: {
                        display: true,
                        text: null,
                    },
                    plugins: {
                        legend: {
                            labels: {
                                color: "#495057",
                            },
                        },
                    },
                    tooltips: {
                        callbacks: {
                            label(tooltipItems, data) {
                                let total = 0;
                                for (let i = 0; i < data.datasets[0].data.length; i += 1) {
                                    if (data.datasets[0].data[i] !== 0) {
                                        total += data.datasets[0].data[i];
                                    }
                                }
                                return " " + data.labels[tooltipItems.index] + ": "
                                    + Math.trunc((data.datasets[0].data[tooltipItems.index] * 100) / total) + "%";
                            },
                        },
                    },
                },
                sixthQuestionChartData: {
                    labels: [],
                    datasets: [
                        {
                            backgroundColor: ["#a7a5a5", "#33ccff", "#247ba0", "#ff6161", "#05112f"],
                            data: [],
                        },
                    ],
                    highestValuePercentage: null,
                    labelOfHighestValue: null,
                    labelDescription: null,
                },
                sixthQuestionChartOptions: {
                    responsive: false,
                    maintainAspectRatio: false,
                    title: {
                        display: true,
                        text: null,
                    },
                    plugins: {
                        legend: {
                            labels: {
                                color: "#495057",
                            },
                        },
                    },
                    tooltips: {
                        callbacks: {
                            label(tooltipItems, data) {
                                let total = 0;
                                for (let i = 0; i < data.datasets[0].data.length; i += 1) {
                                    if (data.datasets[0].data[i] !== 0) {
                                        total += data.datasets[0].data[i];
                                    }
                                }
                                return " " + data.labels[tooltipItems.index] + ": "
                                    + Math.trunc((data.datasets[0].data[tooltipItems.index] * 100) / total) + "%";
                            },
                        },
                    },
                },
            };
        },

        created() {
            this.search();
            this.searchCriteria.page.sortField = "creationDate";
            this.searchCriteria.page.sortOrder = -1;
        },

        methods: {
            search() {
                searchTrendPost({trendPostSearchCriteria: this.searchCriteria}).then((response) => {
                    // eslint-disable-next-line prefer-destructuring
                    this.post = response.data[0];
                    this.trendsTableData[0].data = "od " + DateUtils.displayDateTime(this.post.creationDate);
                    countTrendQuizResults({trendQuizId: this.post.quiz.id}).then((resp) => {
                        this.globalAmount = resp.data.globalAmount;
                        this.trendsTableData[1].data = this.globalAmount;
                        this.trendsTableData[2].data = resp.data.polishFurnitureCompaniesAmount;
                        this.trendsTableData[3].data = resp.data.foreignFurnitureCompaniesAmount;
                        this.trendsTableData[4].data = resp.data.designCompaniesAmount;
                        this.trendsTableData[5].data = resp.data.universitiesAmount;
                        this.trendsTableData[6].data = resp.data.institutionsAssociatingEntrepreneursAmount;
                        this.trendsTableData[7].data = resp.data.fairOrganizersAmount;
                        this.keyword = this.post.title.substring(this.post.title.lastIndexOf("dziedzinie") + 11);
                        this.trendsTableData[8].data = this.keyword;

                        this.counterChartOptions.title.text = "Struktura respondentów badania";
                        this.counterChartData.datasets[0].data.push(resp.data.polishFurnitureCompaniesAmount);
                        this.counterChartData.datasets[0].data.push(resp.data.foreignFurnitureCompaniesAmount);
                        this.counterChartData.datasets[0].data.push(resp.data.designCompaniesAmount);
                        this.counterChartData.datasets[0].data.push(resp.data.universitiesAmount);
                        // eslint-disable-next-line max-len
                        this.counterChartData.datasets[0].data.push(resp.data.institutionsAssociatingEntrepreneursAmount);
                        this.counterChartData.datasets[0].data.push(resp.data.fairOrganizersAmount);
                    });

                    getTrendStudyResults({trendQuizId: this.post.quiz.id}).then((resp) => {
                        this.department = Departments.getDepartmentNameByDiscriminator(resp.data.department);
                        this.firstQuestionChartOptions.title.text = resp.data.questions[0].questionText;
                        this.firstQuestionChartData.labels.push(resp.data.questions[0].answers[0].answerText);
                        // eslint-disable-next-line max-len
                        this.firstQuestionChartData.datasets[0].data.push(resp.data.questions[0].answers[0].answerCount);
                        this.firstQuestionChartData.labels.push(resp.data.questions[0].answers[1].answerText);
                        // eslint-disable-next-line max-len
                        this.firstQuestionChartData.datasets[0].data.push(resp.data.questions[0].answers[1].answerCount);
                        this.firstQuestionChartData.labels.push(resp.data.questions[0].answers[2].answerText);
                        // eslint-disable-next-line max-len
                        this.firstQuestionChartData.datasets[0].data.push(resp.data.questions[0].answers[2].answerCount);
                        this.firstQuestionChartData.labels.push(resp.data.questions[0].answers[3].answerText);
                        // eslint-disable-next-line max-len
                        this.firstQuestionChartData.datasets[0].data.push(resp.data.questions[0].answers[3].answerCount);
                        this.firstQuestionChartData.labels.push(resp.data.questions[0].answers[4].answerText);
                        // eslint-disable-next-line max-len
                        this.firstQuestionChartData.datasets[0].data.push(resp.data.questions[0].answers[4].answerCount);
                        // eslint-disable-next-line max-len
                        this.firstQuestionChartData.highestValuePercentage = this.getPercentageMaxValueOfArray(this.firstQuestionChartData.datasets[0].data);
                        // eslint-disable-next-line max-len,prefer-spread
                        this.firstQuestionChartData.labelOfHighestValue = this.firstQuestionChartData.labels[this.firstQuestionChartData.datasets[0].data.indexOf(Math.max.apply(Math, this.firstQuestionChartData.datasets[0].data))];
                        switch (this.firstQuestionChartData.labelOfHighestValue) {
                            case "w ogóle":
                                // eslint-disable-next-line max-len
                                this.firstQuestionChartData.labelDescription = "Należy obserwować rozwiązanie. Potencjalnie może ono jeszcze nie być w ogóle znane na rynku, więc jest szansa na uzyskanie wysokiego stopnia przewagi konkurencyjnej, jeśli wykazuje się znaczącym potencjałem rynkowym.";
                                break;
                            case "w niewielkim zakresie":
                                // eslint-disable-next-line max-len
                                this.firstQuestionChartData.labelDescription = "Należy obserwować rozwiązanie. Potencjalnie może ono jeszcze nie być znane na rynku, więc jest szansa na uzyskanie wysokiego stopnia przewagi konkurencyjnej, jeśli wykazuje się znaczącym potencjałem rynkowym.";
                                break;
                            case "w umiarkowanym zakresie":
                                // eslint-disable-next-line max-len
                                this.firstQuestionChartData.labelDescription = "Rozwiązanie może mieć znaczący potencjał przewagi konkurencyjnej, nie jest jeszcze szeroko stosowane, ale część podmiotów już zidentyfikowała jego potencjał rozwojowy.";
                                break;
                            case "w znacznym zakresie":
                                // eslint-disable-next-line max-len
                                this.firstQuestionChartData.labelDescription = "Rozwiązanie jest już dość powszechnie stosowane, jego wdrożenie może nie wiązać się z przewagą konkurencyjną, a raczej z podążaniem za branżą, co nie znaczy, że rozwiązanie to nie może przynieść wymiernych korzyści ekonomicznych.";
                                break;
                            case "w dominującym zakresie":
                                // eslint-disable-next-line max-len
                                this.firstQuestionChartData.labelDescription = "Rozwiązanie jest już szeroko stosowane, jego wdrożenie może nie wiązać się z przewagą konkurencyjną, a raczej z podążaniem za branżą, co nie znaczy, że rozwiązanie to nie może przynieść wymiernych korzyści ekonomicznych.";
                                break;
                            default:
                                this.firstQuestionChartData.labelDescription = "";
                        }

                        this.secondQuestionChartOptions.title.text = resp.data.questions[1].questionText;
                        this.secondQuestionChartData.labels.push(resp.data.questions[1].answers[0].answerText);
                        // eslint-disable-next-line max-len
                        this.secondQuestionChartData.datasets[0].data.push(resp.data.questions[1].answers[0].answerCount);
                        this.secondQuestionChartData.labels.push(resp.data.questions[1].answers[1].answerText);
                        // eslint-disable-next-line max-len
                        this.secondQuestionChartData.datasets[0].data.push(resp.data.questions[1].answers[1].answerCount);
                        this.secondQuestionChartData.labels.push(resp.data.questions[1].answers[2].answerText);
                        // eslint-disable-next-line max-len
                        this.secondQuestionChartData.datasets[0].data.push(resp.data.questions[1].answers[2].answerCount);
                        this.secondQuestionChartData.labels.push(resp.data.questions[1].answers[3].answerText);
                        // eslint-disable-next-line max-len
                        this.secondQuestionChartData.datasets[0].data.push(resp.data.questions[1].answers[3].answerCount);
                        this.secondQuestionChartData.labels.push(resp.data.questions[1].answers[4].answerText);
                        // eslint-disable-next-line max-len
                        this.secondQuestionChartData.datasets[0].data.push(resp.data.questions[1].answers[4].answerCount);
                        // eslint-disable-next-line max-len
                        this.secondQuestionChartData.highestValuePercentage = this.getPercentageMaxValueOfArray(this.secondQuestionChartData.datasets[0].data);
                        // eslint-disable-next-line max-len,prefer-spread
                        this.secondQuestionChartData.labelOfHighestValue = this.secondQuestionChartData.labels[this.secondQuestionChartData.datasets[0].data.indexOf(Math.max.apply(Math, this.secondQuestionChartData.datasets[0].data))];
                        switch (this.secondQuestionChartData.labelOfHighestValue) {
                            case "w bardzo niewielkim stopniu":
                                // eslint-disable-next-line max-len
                                this.secondQuestionChartData.labelDescription = "Należy przeanalizować powody braku zainteresowania wdrożeniem rozwiązania, możliwe, że nie jest ono efektywne bądź ma niski potencjał rynkowy lub też wysokie bariery techniczne wdrożenia. Powodem może być również brak świadomości w tym zakresie.";
                                break;
                            case "w niewielkim stopniu":
                                // eslint-disable-next-line max-len
                                this.secondQuestionChartData.labelDescription = "Należy przeanalizować powody braku zainteresowania wdrożeniem rozwiązania, możliwe, że nie jest ono efektywne bądź ma niski potencjał rynkowy. Powodem może być również niski poziom świadomości w tym zakresie, jednak zidentyfikowane plany wdrożeniowe wskazują, że najprawdopodobniej jest technicznie możliwe do implementacji.";
                                break;
                            case "w umiarkowanym stopniu":
                                // eslint-disable-next-line max-len
                                this.secondQuestionChartData.labelDescription = "Należy śledzić wdrożenie rozwiązania – może mieć wpływ na sytuację konkurencyjną na rynku.";
                                break;
                            case "w dużym stopniu":
                                // eslint-disable-next-line max-len
                                this.secondQuestionChartData.labelDescription = "Należy uważnie śledzić wdrożenie rozwiązania – może mieć istotny wpływ na sytuację konkurencyjną na rynku.";
                                break;
                            case "w bardzo dużym stopniu":
                                // eslint-disable-next-line max-len
                                this.secondQuestionChartData.labelDescription = "Należy bardzo uważnie śledzić wdrożenie rozwiązania – prawdopodobnie będzie kluczowe dla sytuacji konkurencyjnej w branży.";
                                break;
                            default:
                                this.secondQuestionChartData.labelDescription = "";
                        }

                        this.thirdQuestionChartOptions.title.text = resp.data.questions[2].questionText;
                        this.thirdQuestionChartData.labels.push(resp.data.questions[2].answers[0].answerText);
                        // eslint-disable-next-line max-len
                        this.thirdQuestionChartData.datasets[0].data.push(resp.data.questions[2].answers[0].answerCount);
                        this.thirdQuestionChartData.labels.push(resp.data.questions[2].answers[1].answerText);
                        // eslint-disable-next-line max-len
                        this.thirdQuestionChartData.datasets[0].data.push(resp.data.questions[2].answers[1].answerCount);
                        this.thirdQuestionChartData.labels.push(resp.data.questions[2].answers[2].answerText);
                        // eslint-disable-next-line max-len
                        this.thirdQuestionChartData.datasets[0].data.push(resp.data.questions[2].answers[2].answerCount);
                        // eslint-disable-next-line max-len
                        this.thirdQuestionChartData.highestValuePercentage = this.getPercentageMaxValueOfArray(this.thirdQuestionChartData.datasets[0].data);
                        // eslint-disable-next-line max-len,prefer-spread
                        this.thirdQuestionChartData.labelOfHighestValue = this.thirdQuestionChartData.labels[this.thirdQuestionChartData.datasets[0].data.indexOf(Math.max.apply(Math, this.thirdQuestionChartData.datasets[0].data))];
                        switch (this.thirdQuestionChartData.labelOfHighestValue) {
                            case "niski potencjał":
                                // eslint-disable-next-line max-len
                                this.thirdQuestionChartData.labelDescription = "Prawdopodobnie rozwiązanie nie znajdzie znaczącej grupy odbiorców – może cechować je niszowość.";
                                break;
                            case "umiarkowany potencjał":
                                // eslint-disable-next-line max-len
                                this.thirdQuestionChartData.labelDescription = "Prawdopodobnie rozwiązanie nie będzie wiodącym na rynku, ale ma potencjał do uzyskania udziału w rynku.";
                                break;
                            case "wysoki potencjał":
                                // eslint-disable-next-line max-len
                                this.thirdQuestionChartData.labelDescription = "Prawdopodobnie rozwiązanie może być wiodącym na rynku.";
                                break;
                            default:
                                this.thirdQuestionChartData.labelDescription = "";
                        }

                        this.fourthQuestionChartOptions.title.text = resp.data.questions[3].questionText;
                        this.fourthQuestionChartData.labels.push(resp.data.questions[3].answers[0].answerText);
                        // eslint-disable-next-line max-len
                        this.fourthQuestionChartData.datasets[0].data.push(resp.data.questions[3].answers[0].answerCount);
                        this.fourthQuestionChartData.labels.push(resp.data.questions[3].answers[1].answerText);
                        // eslint-disable-next-line max-len
                        this.fourthQuestionChartData.datasets[0].data.push(resp.data.questions[3].answers[1].answerCount);
                        this.fourthQuestionChartData.labels.push(resp.data.questions[3].answers[2].answerText);
                        // eslint-disable-next-line max-len
                        this.fourthQuestionChartData.datasets[0].data.push(resp.data.questions[3].answers[2].answerCount);
                        // eslint-disable-next-line max-len
                        this.fourthQuestionChartData.highestValuePercentage = this.getPercentageMaxValueOfArray(this.fourthQuestionChartData.datasets[0].data);
                        // eslint-disable-next-line max-len,prefer-spread
                        this.fourthQuestionChartData.labelOfHighestValue = this.fourthQuestionChartData.labels[this.fourthQuestionChartData.datasets[0].data.indexOf(Math.max.apply(Math, this.fourthQuestionChartData.datasets[0].data))];
                        switch (this.fourthQuestionChartData.labelOfHighestValue) {
                            case "krótkookresowa":
                                // eslint-disable-next-line max-len
                                this.fourthQuestionChartData.labelDescription = "Prawdopodobnie rozwiązanie może być chwilową „modą”, inwestycja we wdrożenie może okazać się nieefektywna.";
                                break;
                            case "średniookresowa":
                                // eslint-disable-next-line max-len
                                this.fourthQuestionChartData.labelDescription = "Prawdopodobnie rozwiązanie może być „modne” przez dłuższy okres czasu, inwestycja we wdrożenie może okazać się efektywna.";
                                break;
                            case "długookresowa":
                                // eslint-disable-next-line max-len
                                this.fourthQuestionChartData.labelDescription = "Prawdopodobnie rozwiązanie może stać się trwałym elementem w branży meblowej, inwestycja we wdrożenie może okazać się efektywna.";
                                break;
                            default:
                                this.fourthQuestionChartData.labelDescription = "";
                        }

                        this.fifthQuestionChartOptions.title.text = resp.data.questions[4].questionText;
                        this.fifthQuestionChartData.labels.push(resp.data.questions[4].answers[0].answerText);
                        // eslint-disable-next-line max-len
                        this.fifthQuestionChartData.datasets[0].data.push(resp.data.questions[4].answers[0].answerCount);
                        this.fifthQuestionChartData.labels.push(resp.data.questions[4].answers[1].answerText);
                        // eslint-disable-next-line max-len
                        this.fifthQuestionChartData.datasets[0].data.push(resp.data.questions[4].answers[1].answerCount);
                        this.fifthQuestionChartData.labels.push(resp.data.questions[4].answers[2].answerText);
                        // eslint-disable-next-line max-len
                        this.fifthQuestionChartData.datasets[0].data.push(resp.data.questions[4].answers[2].answerCount);
                        this.fifthQuestionChartData.labels.push(resp.data.questions[4].answers[3].answerText);
                        // eslint-disable-next-line max-len
                        this.fifthQuestionChartData.datasets[0].data.push(resp.data.questions[4].answers[3].answerCount);
                        this.fifthQuestionChartData.labels.push(resp.data.questions[4].answers[4].answerText);
                        // eslint-disable-next-line max-len
                        this.fifthQuestionChartData.datasets[0].data.push(resp.data.questions[4].answers[4].answerCount);
                        this.fifthQuestionChartData.labels.push(resp.data.questions[4].answers[5].answerText);
                        // eslint-disable-next-line max-len
                        this.fifthQuestionChartData.datasets[0].data.push(resp.data.questions[4].answers[5].answerCount);
                        this.fifthQuestionChartData.labels.push(resp.data.questions[4].answers[6].answerText);
                        // eslint-disable-next-line max-len
                        this.fifthQuestionChartData.datasets[0].data.push(resp.data.questions[4].answers[6].answerCount);
                        // eslint-disable-next-line max-len
                        this.fifthQuestionChartData.highestValuePercentage = this.getPercentageMaxValueOfArray(this.fifthQuestionChartData.datasets[0].data);
                        // eslint-disable-next-line max-len,prefer-spread
                        this.fifthQuestionChartData.labelOfHighestValue = this.fifthQuestionChartData.labels[this.fifthQuestionChartData.datasets[0].data.indexOf(Math.max.apply(Math, this.fifthQuestionChartData.datasets[0].data))];

                        this.sixthQuestionChartOptions.title.text = resp.data.questions[5].questionText;
                        this.sixthQuestionChartData.labels.push(resp.data.questions[5].answers[0].answerText);
                        // eslint-disable-next-line max-len
                        this.sixthQuestionChartData.datasets[0].data.push(resp.data.questions[5].answers[0].answerCount);
                        this.sixthQuestionChartData.labels.push(resp.data.questions[5].answers[1].answerText);
                        // eslint-disable-next-line max-len
                        this.sixthQuestionChartData.datasets[0].data.push(resp.data.questions[5].answers[1].answerCount);
                        this.sixthQuestionChartData.labels.push(resp.data.questions[5].answers[2].answerText);
                        // eslint-disable-next-line max-len
                        this.sixthQuestionChartData.datasets[0].data.push(resp.data.questions[5].answers[2].answerCount);
                        // eslint-disable-next-line max-len
                        this.sixthQuestionChartData.highestValuePercentage = this.getPercentageMaxValueOfArray(this.sixthQuestionChartData.datasets[0].data);
                        // eslint-disable-next-line max-len,prefer-spread
                        this.sixthQuestionChartData.labelOfHighestValue = this.sixthQuestionChartData.labels[this.sixthQuestionChartData.datasets[0].data.indexOf(Math.max.apply(Math, this.sixthQuestionChartData.datasets[0].data))];
                        switch (this.sixthQuestionChartData.labelOfHighestValue) {
                            case "w małym stopniu":
                                // eslint-disable-next-line max-len
                                this.sixthQuestionChartData.labelDescription = "W przypadku podjęcia decyzji o wdrożeniu należy przeanalizować istotność pozostałych działów z punktu widzenia powodzenia implementacji trendu.";
                                break;
                            case "w umiarkowanym stopniu":
                                // eslint-disable-next-line max-len
                                this.sixthQuestionChartData.labelDescription = "W przypadku podjęcia decyzji o wdrożeniu należy brać pod uwagę implementację w tym dziale, niemniej należy również przeanalizować istotność innych działów w tym zakresie.";
                                break;
                            case "w dużym stopniu":
                                // eslint-disable-next-line max-len
                                this.sixthQuestionChartData.labelDescription = "W przypadku podjęcia decyzji o wdrożeniu należy skupić uwagę na implementacji w tym dziale.";
                                break;
                            default:
                                this.sixthQuestionChartData.labelDescription = "";
                        }

                        if (this.globalAmount > 0) {
                            this.chartExists = true;
                        }
                    });
                });
            },
            parseDate(date) {
                return DateUtils.displayDateTime(date);
            },

            refreshResults() {
                this.search();
            },
            isAdmin() {
                return localStorage.getItem("role") === this.ADMIN;
            },
            openEditCommentDialog(commentId, content) {
                this.currentCommentId = commentId;
                this.currentCommentContent = content;
                this.editCommentDialog = true;
            },
            // eslint-disable-next-line vue/no-dupe-keys
            showModerationStatus() {
                this.moderationStatus = false;
            },
            getPercentageMaxValueOfArray(val) {
                // eslint-disable-next-line prefer-spread
                return Math.trunc((Math.max.apply(Math, val) * 100) / this.globalAmount);
            },
            goBack() {
                window.history.back();
            },
            print() {
                window.print();
            },
        },
    };

</script>

<style scoped>
    @media print {
        .no-print, .no-print *
        {
            display: none !important;
        }

        .tableizer-table th {
            border: 1px solid black !important;
        }
        .tableizer-table td {
            border-bottom: 1px solid black !important;
            border-left: 1px dashed black !important;
            border-right: 1px dashed black !important;
        }
        .avoid-break-inside {
            break-inside: avoid;
            page-break-inside: avoid;
        }
    }
    table.tableizer-table {
        font-size: 1rem;
    }
    .tableizer-table td {
        padding: 0.5rem 0.8rem;
        margin: 10px;
        border-bottom: 1px solid #CCC;
        border-left: 1px dashed #CCC;
        border-right: 1px dashed #CCC;
    }
    .tableizer-table th {
        background-color: #D9D9D9;
        color: #495057;
        padding: 1rem;
        font-weight: bold;
        border: 1px solid #ffffff;
    }

    .post-content {
        white-space: pre-wrap;
    }
    .post-title {
        font-size: 2rem;
        font-weight: 900;
        text-align: center;
        padding-bottom: 30px;
    }

    .post-in-comments {
        padding: 30px 0 30px 30px;
        background-color: #05112f;
        border-bottom-width: 20px;
        border-top-width: 20px;
        border-radius: 10px;
        color: white;
    }

    .data-view-all-rows {
        padding: 30px 0 30px 30px;
        background-color: #247ba0;
        border-color: #ffffff;
        border-bottom-width: 20px;
        border-top-width: 20px;
        border-radius: 10px;
        color: white;
    }
    .p-dataview.p-dataview-list .p-dataview-content > .p-grid > div {
        border: solid rgba(0, 0, 0, 0);
        border-top-width: 20px;
    }
    .comment-author {
        font-size: 1.5rem;
        font-weight: 700;
        text-align: left;
        padding-bottom: 10px;
    }
    .comment-content {
        margin: 0 0 1rem 0;
        text-align: left;
        padding-right: 10px;
    }
    .comment-date {
        display: flex;
        flex-direction: column;
        text-align: right;
        padding-right: 10px;
        padding-top: 10px;
    }
    .moderation-button {
        text-align: left;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .moderated-info-class {
        text-align: left;
        color: #ff6161;
        font-size: 1.2rem;
        font-stretch: ultra-expanded;
        padding-top: 20px;
        background-color: white;
        margin-left: 70%;
        margin-top: 20px;
        margin-bottom: 10px;
        padding-bottom: 10px;
        border-bottom-left-radius: 10px;
        border-top-left-radius: 10px;
        padding-right: 15px;
    }
    .p-col-12 {
        display: block;
        text-align: center;
        align-content: center;
        justify-content: center;
        align-items: center;
    }
</style>
