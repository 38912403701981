<template>
    <h3 style="margin-bottom: 40px" v-if="analysisVariant !== 'dane statystyczne'">
        Planowane efekty wdrożenia trendów
    </h3>

    <div style="margin-bottom: 40px; margin: auto;" class="p-lg-9" v-if="analysisVariant !== 'dane statystyczne'">
        <div style="margin-bottom: 20px">
            <CustomSelectBoolean v-if="analysisVariant === 'dane mieszane'"
                                 class="p-lg-9" style="margin: auto"
                                 name="useStatisticalData"
                                 label="Chcę skorzystać z danych statystycznych?"
                                 v-model="internalValue.useStatisticalData">
            </CustomSelectBoolean>
        </div>

        <table class="tableizer-table">
            <thead>
                <tr class="tableizer-firstrow">
                    <th>Pole wyboru</th><th>Wystąpienie danego efektu</th><th>Wysokość efektu w skali roku [w %]</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>pozyskanie nowych grup klientów /
                        wejście na nowe rynki - ZMIANA POZIOMU PRZYCHODÓW (np. +10%, +150%)</td>
                    <td>
                        <CustomSelectBoolean name="isNewClients"
                                             :disabled="internalValue.useStatisticalData === true"
                                             v-model="internalValue.isNewClients"/>
                    </td>
                    <td>
                        <CustomSelectOne :disabled="isDisabled(internalValue.isNewClients)"
                                         v-model="internalValue.newClientsEffectPercentage"
                                         :items="effectImpactPercentages"
                                         item-label="label"
                                         item-value="value" label="Wysokość efektu w skali roku [w %]"/>
                    </td>
                </tr>
                <tr>
                    <td>budowanie marki w oparciu o innowacyjność /
                        pionierstwo we wdrażaniu trendów - ZMIANA POZIOMU PRZYCHODÓW</td>
                    <td>
                        <CustomSelectBoolean name="isInnovation"
                                             :disabled="internalValue.useStatisticalData === true"
                                             v-model="internalValue.isInnovation"/>
                    </td>
                    <td>
                        <CustomSelectOne
                            :disabled="isDisabled(internalValue.isInnovation)"
                            v-model="internalValue.innovationEffectPercentage"
                            :items="effectImpactPercentages"
                            item-label="label"
                            item-value="value" label="Wysokość efektu w skali roku [w %]"/>
                    </td>
                </tr>
                <tr>
                    <td>obniżenie kosztów funkcjonowania - ZMIANA POZIOMU KOSZTÓW (np. -10%, -50%)</td>
                    <td>
                        <CustomSelectBoolean name="isLessCosts"
                                             :disabled="internalValue.useStatisticalData === true"
                                             v-model="internalValue.isLessCosts"/>
                    </td>
                    <td>
                        <CustomSelectOne :disabled="isDisabled(internalValue.isLessCosts)"
                                         v-model="internalValue.lessCostsEffectPercentage"
                                         :items="negativeEffectImpactPercentages"
                                         item-label="label"
                                         item-value="value" label="Wysokość efektu w skali roku [w %]"/>
                    </td>
                </tr>
            </tbody></table>
    </div>
</template>

<script>
    import CustomSelectBoolean from "@/views/user/components/CustomSelectBoolean";
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne";

    export default {
        name: "EfficiencyPlannedTrendEffects",
        components: {
            CustomSelectOne,
            CustomSelectBoolean,
        },

        data() {
            return {
                effectImpactPercentages: [
                    {label: "1%", value: 0.01},
                    {label: "5%", value: 0.05},
                    {label: "10%", value: 0.1},
                    {label: "15%", value: 0.15},
                    {label: "20%", value: 0.2},
                    {label: "25%", value: 0.25},
                    {label: "30%", value: 0.3},
                    {label: "35%", value: 0.35},
                    {label: "40%", value: 0.4},
                    {label: "45%", value: 0.45},
                    {label: "50%", value: 0.50},
                    {label: "55%", value: 0.55},
                    {label: "60%", value: 0.60},
                    {label: "65%", value: 0.65},
                    {label: "70%", value: 0.70},
                    {label: "75%", value: 0.75},
                    {label: "80%", value: 0.80},
                    {label: "85%", value: 0.85},
                    {label: "90%", value: 0.90},
                    {label: "95%", value: 0.95},
                    {label: "100%", value: 1},
                    {label: "110%", value: 1.1},
                    {label: "125%", value: 1.25},
                    {label: "150%", value: 1.5},
                    {label: "175%", value: 1.75},
                    {label: "200%", value: 2},
                    {label: "250%", value: 2.5},
                    {label: "300%", value: 3},
                    {label: "400%", value: 4},
                    {label: "500%", value: 5},
                ],

                negativeEffectImpactPercentages: [
                    {label: "-1%", value: -0.01},
                    {label: "-5%", value: -0.05},
                    {label: "-10%", value: -0.1},
                    {label: "-15%", value: -0.15},
                    {label: "-20%", value: -0.2},
                    {label: "-25%", value: -0.25},
                    {label: "-30%", value: -0.3},
                    {label: "-35%", value: -0.35},
                    {label: "-40%", value: -0.4},
                    {label: "-45%", value: -0.45},
                    {label: "-50%", value: -0.50},
                    {label: "-55%", value: -0.55},
                    {label: "-60%", value: -0.60},
                    {label: "-65%", value: -0.65},
                    {label: "70%", value: 0.70},
                    {label: "-75%", value: -0.75},
                    {label: "-80%", value: -0.80},
                    {label: "-85%", value: -0.85},
                    {label: "-90%", value: -0.90},
                    {label: "-95%", value: -0.95},
                    {label: "-100%", value: -1},
                ],
            };
        },
        props: {
            modelValue: null,
            analysisVariant: {
                type: String,
                default: null,
            },
        },

        emits: ["update:modelValue"],

        computed: {
            internalValue: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },
            },
        },
        methods: {
            isDisabled(val) {
                return this.internalValue.useStatisticalData || val !== true;
            },
        },
    };
</script>

<style scoped>
    table.tableizer-table {
        font-size: 1rem;
    }
    .tableizer-table td {
        padding: 0.3rem 0.5rem;
        margin: 10px;
        border-top: 1px solid #CCC;
        text-align: left;
    }
    .tableizer-table th {
        color: #495057;
        padding: 1rem;
        font-weight: bold;
    }
</style>
