<template>
    <div v-if="loaded">
        <div style="width: 100%; display: flex; align-items: flex-start; margin-top: 30px">
            <Button label="Zapisz kompetencje" @click="saveCompetences" :disabled="disableControls || !loaded"
                    style="max-height: 40px; margin-right: 50px"
                    icon="pi pi-save" iconPos="left"/>
        </div>
        <DataTable ref="workersTable" :value="workers" :lazy="true" :paginator="true"
                   v-model:rows="searchParams.page.limit"
                   removableSort :totalRecords="totalRecords" :loading="loading" @page="onPage"
                   @sort="onPage" :rowsPerPageOptions="[5,10,20,50]" :autoLayout="true"
                   :rowHover="true">
            <template #empty>
                Nie znaleziono pracowników
            </template>
            <template #loading>
                Trwa wczytywanie pracowników. Proszę czekać
            </template>
            <Column field="firstName" header="Imię"></Column>
            <Column field="lastName" header="Nazwisko"></Column>
            <Column header="Kompetencje">
                <template #body="slotProps">
                    <div>
                        <!--TODO: -->
                        <CustomMultiselect
                            v-model="workerSelectedCompetenceMap[slotProps.data.id]"
                            :items="workerOptionsCompetenceMap"
                            item-label="competence" placeholder="Wybór kompetencji"
                            :disabled="isSelfEvaluationStartedOrCompleted(slotProps.data.selfEvaluationStatus)
                                || disableControls"
                            empty-message="Brak kompetencji do wyboru."/>
                    </div>
                </template>
            </Column>
            <Column field="email" header="Email" headerStyle="width: 12em">
                <template #body="{data}">
                    <div>
                        {{data.email}}
                        <Button
                            v-if="!data.emailVerified"
                            type="button"
                            icon="pi pi-envelope" class="p-button-info p-button-rounded p-button-outlined"
                            @click="sendVerificationEmail(data.id, $event)"
                            v-tooltip.top="'Wyślij link aktywacyjny'"
                            style="margin-left: 40%">
                        </Button>
                    </div>
                </template>
            </Column>
            <Column field="emailVerified" header="Status" style="width: 20px; text-align: center">
                <template #body="{data}">
                    <div>
                        <Avatar v-if="data.emailVerified" icon="pi pi-check" class="p-mr-2 email-verified"
                                v-tooltip.top="'Zweryfikowany'"
                                shape="circle"/>
                        <Avatar v-else icon="pi pi-check" class="p-mr-2 email-not-verified"
                                v-tooltip.top="'Oczekuje na weryfikację'"
                                shape="circle"/>
                    </div>
                </template>
            </Column>
            <Column field="selfEvaluationStatus" header="Samoocena"
                    style="width: 200px; text-align: center">
                <template #body="{data}">
                    <div style="white-space:nowrap; align-content: stretch;">
                        <div style="display: inline-block;">
                            <EvaluationStatusBadge :status="data.selfEvaluationStatus"/>
                        </div>
                        <br><br>
                        <div style="display: inline-block;">
                            <Button :disabled="isEvaluationStatusSet(data.selfEvaluationStatus)"
                                    type="button" style="float: right; margin-right: 10px"
                                    icon="pi pi-ticket"
                                    class="p-ml-2 p-button-rounded p-button-secondary p-button-sm p-button-danger"
                                    @click="assignSelfEvaluation(data.id)"
                                    v-tooltip.top="'Przypisz do samooceny'">
                            </Button>
                        </div>
                    </div>
                </template>
            </Column>
            <Column field="coworkerEvaluationStatus" header="Ocena współpracownika"
                    style="width: 200px; text-align: center">
                <template #body="{data}">
                    <div style="white-space:nowrap; align-content: stretch;">
                        <div style="display: inline-block;">
                            <EvaluationStatusBadge :status="data.coworkerEvaluationStatus"/>
                        </div>
                        <br><br>
                        <div style="display: inline-block;">
                            <Button :disabled="isEvaluationStatusSet(data.coworkerEvaluationStatus)"
                                    type="button" style="float: right; margin-right: 10px"
                                    icon="pi pi-ticket" class="p-ml-2 p-button-rounded pp-button-sm"
                                    @click="selectCoworker(data.id)"
                                    v-tooltip.top="'Przypisz do oceny współpracownika'">
                            </Button>
                        </div>
                    </div>
                </template>
            </Column>
            <Column field="supervisorEvaluationStatus" header="Ocena przełożonego"
                    style="width: 200px; text-align: center">
                <template #body="{data}">
                    <div style="white-space:nowrap; align-content: stretch;">
                        <div style="display: inline-block;">
                            <EvaluationStatusBadge :status="data.supervisorEvaluationStatus"/>
                        </div>
                        <br>
                        <br>
                        <div style="display: inline-block;">
                            <Button :disabled="isEvaluationStatusSet(data.supervisorEvaluationStatus)"
                                    type="button" style="float: right; margin-right: 10px"
                                    icon="pi pi-ticket" class="p-ml-2 p-button-rounded p-button-help p-button-sm"
                                    @click="selectSupervisor(data.id)"
                                    v-tooltip.top="'Przypisz do oceny przełożonego'">
                            </Button>
                        </div>
                    </div>
                </template>
            </Column>
            <Column header= "Kompetencje" headerStyle="width: 130px; text-align: center" style="text-align: center;">
                <template #body="{data}">
                    <Button :disabled="!data.hasSurveyMergingResult"
                            type="button"
                            icon="pi pi-eye" class="p-button-help p-ml-2
                                p-button-rounded p-button-outlined p-button-sm"
                            @click="redirectToCompetences(data.id)"
                            v-tooltip.top="'Wyświetl kompetencje pracownika'">
                    </Button>
                </template>
            </Column>
            <Column header= "Usuń" headerStyle="width: 130px; text-align: center" style="text-align: center;">
                <template #body="{data}">
                    <Button
                        type="button"
                        icon="pi pi-user-minus" class="p-button-danger
                        p-ml-2 p-button-rounded p-button-outlined p-button-sm"
                        @click="deleteUser(data.id, $event)"
                        v-tooltip.top="'Usuń pracownika'">
                    </Button>
                </template>
            </Column>
        </DataTable>
        <AddAssessorDialog v-model:visible ="showAddAssessorDialog" :workers="coworkers"
                           @assessor-added="assignCoworkerEvaluation(workerId, $event)" />
        <AddSupervisorDialog v-model:visible ="showAddSupervisorDialog" :supervisors="supervisors"
                             @supervisor-added="assignSupervisorEvaluation(supervisorId, $event)" />
    </div>
</template>

<script>
    import DataTable from "primevue/datatable";
    import Column from "primevue/column";
    import Avatar from "primevue/avatar";
    import Button from "primevue/button";
    import Tooltip from "primevue/tooltip";
    import {
        addCoworkerEvaluationUsingPOST as addCoworkerEvaluation,
        addSelfEvaluationUsingPOST as addSelfEvaluation,
        addSupervisorEvaluationUsingPOST as addSupervisorEvaluation,
        deleteWorkerUsingDELETE as deleteWorker,
        getCurrentAccountIdUsingGET as getCurrentAccountId,
        searchEmployerUsingPOST as searchEmployer,
        searchWorkerCountUsingPOST as searchWorkerCount,
        searchWorkerUsingPOST as searchWorker,
        sendVerificationEmailUsingPOST as sendVerificationEmail,
        saveCompetenceMapForWorkersUsingPOST as saveCompetenceMap,
    } from "@/swagger/vue-api-client";
    import {Departments} from "@/utils/Departments";
    import EvaluationStatusBadge from "@/components/EvaluationStatusBadge";
    import CustomMultiselect from "@/components/form/CustomMultiselect";
    import AddAssessorDialog from "./AddAssessorDialog";
    import AddSupervisorDialog from "./AddSupervisorDialog";

    export default {
        name: "WorkersTable",
        components: {
            EvaluationStatusBadge,
            AddAssessorDialog,
            DataTable,
            Column,
            Avatar,
            Button,
            AddSupervisorDialog,
            CustomMultiselect,
        },

        directives: {
            tooltip: Tooltip,
        },

        props: {
            searchCriteria: {
                type: Object,
            },
            optionsCompetenceMap: {
                type: Array,
            },
            selectedCompetenceMap: {
                type: Object,
            },
            disableControls: {
                type: Boolean,
                default: false,
            },
        },

        watch: {
            totalRecords(newVal) {
                this.$emit("total-records-changed", newVal);
            },
        },

        data() {
            return {
                searchParams: this.searchCriteria,
                loading: false,
                loaded: false,
                totalRecords: 0,
                workers: [],
                currentAccountId: 0,
                showAddAssessorDialog: false,
                workerId: 0,
                coworkers: [],
                coworkersConst: [],
                showAddSupervisorDialog: false,
                supervisorId: 0,
                supervisors: [],
                supervisorsConst: [],
                competences: [],
                userCompetences: [],
                competenceColumnVisible: false,
                workerOptionsCompetenceMap: this.optionsCompetenceMap,
                workerSelectedCompetenceMap: {...this.selectedCompetenceMap},
            };
        },
        emits: ["total-records-changed"],
        methods: {
            deleteUser(id, event) {
                this.$confirm.require({
                    target: event.currentTarget,
                    message: "Czy na pewno usunąć pracownika?",
                    icon: "pi pi-exclamation-triangle",
                    acceptLabel: "Tak",
                    rejectLabel: "Nie",
                    accept: () => {
                        deleteWorker({id})
                            .then(() => {
                                this.$toast.add({
                                    severity: "success",
                                    summary: "Sukces",
                                    detail: "Pomyślnie usunięto pracownika",
                                    life: 3000,
                                });
                                this.refreshResults();
                            })
                            .catch((error) => {
                                if (error.response.status === 403) {
                                    this.$toast.add({
                                        severity: "error",
                                        summary: "Błąd",
                                        detail: "Nie masz wystarczających uprawnień",
                                        life: 3000,
                                    });
                                } else if (error.response.status === 409) {
                                    this.$toast.add({
                                        severity: "error",
                                        summary: "Błąd",
                                        detail: "Pracownik posiada już przesłaną ankietę",
                                        life: 3000,
                                    });
                                } else {
                                    this.$toast.add({
                                        severity: "error",
                                        summary: "Błąd",
                                        detail: "Wystąpił nieoczekiwany błąd",
                                        life: 3000,
                                    });
                                }
                            });
                    },
                    reject: () => {
                        // callback to execute when user rejects the action
                    },
                });
            },

            sendVerificationEmail(id, event) {
                this.$confirm.require({
                    target: event.currentTarget,
                    message: "Czy na pewno wysłać email z linkiem aktywacyjnym?",
                    icon: "pi pi-exclamation-triangle",
                    acceptLabel: "Tak",
                    rejectLabel: "Nie",
                    accept: () => {
                        sendVerificationEmail({id})
                            .then(() => {
                                this.$toast.add({
                                    severity: "success",
                                    summary: "Sukces",
                                    detail: "Pomyślnie wysłano email z linkiem aktywacyjnym",
                                    life: 3000,
                                });
                                this.refreshResults();
                            })
                            .catch((error) => {
                                if (error.response.status === 403) {
                                    this.$toast.add({
                                        severity: "error",
                                        summary: "Błąd",
                                        detail: "Nie masz wystarczających uprawnień",
                                        life: 3000,
                                    });
                                } else {
                                    this.$toast.add({
                                        severity: "error",
                                        summary: "Błąd",
                                        detail: "Wystąpił nieoczekiwany błąd",
                                        life: 3000,
                                    });
                                }
                            });
                    },
                    reject: () => {
                        // callback to execute when user rejects the action
                    },
                });
            },

            assignSelfEvaluation(userId) {
                addSelfEvaluation({selfEvaluation: {assessedWorkerId: userId}})
                    .then(() => {
                        this.$toast.add({
                            severity: "success",
                            summary: "Sukces",
                            detail: "Pomyślnie dodano ocenę",
                            life: 3000,
                        });
                        this.refreshResults();
                    })
                    .catch(() => {
                        this.$toast.add({
                            severity: "error",
                            summary: "Błąd",
                            detail: "Nie udało się dodać oceny",
                            life: 3000,
                        });
                    });
            },

            assignCoworkerEvaluation(userId, workerAssessorId) {
                addCoworkerEvaluation({coworkerEvaluation: {assessorId: workerAssessorId, assessedWorkerId: userId}})
                    .then(() => {
                        this.$toast.add({
                            severity: "success",
                            summary: "Sukces",
                            detail: "Pomyślnie dodano ocenę",
                            life: 3000,
                        });
                        this.refreshResults();
                    })
                    .catch(() => {
                        this.$toast.add({
                            severity: "error",
                            summary: "Błąd",
                            detail: "Nie udało się dodać oceny",
                            life: 3000,
                        });
                    });
            },

            assignSupervisorEvaluation(userId, supervisorId) {
                addSupervisorEvaluation({
                    supervisorEvaluation: {
                        assessorId: supervisorId,
                        assessedWorkerId: userId,
                    },
                })
                    .then(() => {
                        this.$toast.add({
                            severity: "success",
                            summary: "Sukces",
                            detail: "Pomyślnie dodano ocenę",
                            life: 3000,
                        });
                        this.refreshResults();
                    })
                    .catch(() => {
                        this.$toast.add({
                            severity: "error",
                            summary: "Błąd",
                            detail: "Nie udało się dodać oceny",
                            life: 3000,
                        });
                    });
            },

            onPage(event) {
                this.loading = true;

                this.searchParams.page.offset = event.first;
                this.searchParams.page.limit = event.rows;
                this.searchParams.page.sortField = event.sortField;
                this.searchParams.page.sortOrder = event.sortOrder;

                this.search();
            },

            search() {
                searchWorker({searchCriteria: this.searchCriteria}).then((response) => {
                    this.workers = response.data;
                    this.loading = false;
                });
            },

            updateTotalRecords() {
                searchWorkerCount({searchCriteria: this.searchCriteria}).then((response) => {
                    this.totalRecords = response.data;
                });
            },

            getFirstPage() {
                return {
                    first: this.searchCriteria.page.offset,
                    rows: this.searchCriteria.page.limit,
                };
            },

            isEvaluationStatusSet(status) {
                switch (status) {
                    case "ASSIGNED":
                    case "STARTED":
                    case "SENT":
                        return true;
                    default:
                        return false;
                }
            },

            isSelfEvaluationStartedOrCompleted(status) {
                switch (status) {
                    case "SENT":
                    case "STARTED":
                        return true;
                    default:
                        return false;
                }
            },

            refreshResults() {
                this.updateTotalRecords();
                this.search();
                this.getAllWorkersFromDepartment();
                this.getUsersForSupervisorEvaluation();
            },

            getCurrentAccountId() {
                getCurrentAccountId({}).then((response) => {
                    this.currentAccountId = response.data;
                });
            },
            selectCoworker(userId) {
                this.coworkers = this.coworkersConst.filter((worker) => worker.id !== userId);
                this.workerId = userId;
                this.showAddAssessorDialog = true;
            },
            getAllWorkersFromDepartment() {
                searchWorker({searchCriteria: this.getWorkersSearchCriteria()}).then((response) => {
                    this.coworkers = response.data;
                    this.coworkersConst = response.data;
                });
            },
            getWorkersSearchCriteria() {
                return {
                    department: Departments.getDepartmentDiscriminatorByUrlParam(this.$route.params.department),
                    page: {
                        limit: 100,
                        offset: 0,
                        sortField: null,
                        sortOrder: null,
                    },
                };
            },
            selectSupervisor(userId) {
                this.supervisors = this.supervisorsConst.filter((worker) => worker.id !== userId);
                this.supervisorId = userId;
                this.showAddSupervisorDialog = true;
            },
            getUsersForSupervisorEvaluation() {
                setTimeout(() => searchEmployer({searchCriteria: this.getClearSearchCriteria()}).then((response) => {
                    this.supervisors = [].concat(response.data, this.coworkers);
                    this.supervisorsConst = [].concat(response.data, this.coworkers);
                }), 1000);
            },
            getClearSearchCriteria() {
                return {
                    page: {
                        limit: 10,
                        offset: 0,
                        sortField: null,
                        sortOrder: null,
                    },
                };
            },
            redirectToCompetences(id) {
                this.$router.push({
                    name: "competences",
                    params: {id},
                });
            },
            flattenCompetenceMap(givenMap) {
                const map = givenMap;
                // eslint-disable-next-line no-restricted-syntax
                for (const [key, value] of Object.entries(map)) {
                    if (value.length > 0) {
                        for (let i = 0; i < value.length; i += 1) {
                            map[key][i] = map[key][i].competence;
                        }
                    }
                }
                return map;
            },

            fetchData() {
                this.loaded = false;
                this.updateTotalRecords();
                this.onPage(this.getFirstPage());
                this.getAllWorkersFromDepartment();
                this.getUsersForSupervisorEvaluation();
                this.loaded = true;
            },

            saveCompetences() {
                const map = JSON.parse(JSON.stringify(this.workerSelectedCompetenceMap));
                saveCompetenceMap(
                    {
                        department: Departments.getDepartmentByUrlParam(this.$route.params.department).discriminator,
                        selectedCompetenceMap: this.flattenCompetenceMap(map),
                    },
                ).then(() => {
                    this.fetchData();
                });
            },
        },

        mounted() {
            this.fetchData();
        },
    };
</script>

<style lang="less" scoped>
    @import "src/views/assets/less/workers-view.less";
</style>
