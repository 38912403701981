<template>
    <div>
        <DataTable ref="competenceActionsTable" :value="actions" :lazy="true">
            <template #empty>
                Nie znaleziono akcji
            </template>
            <template #loading>
                Trwa wczytywanie akcji. Proszę czekać
            </template>
            <Column bodyStyle="background-color: rgba(38,162,147,0.5); border: none"
                    style="border: none"></Column>
            <Column field="activation" header="Aktywacja" style="text-align: center"></Column>
            <Column field="adaptation" header="Adaptacja" style="text-align: center"></Column>
            <Column field="action" header="Akcja" style="text-align: center"></Column>
            <Column field="autonomy" header="Autonomia" style="text-align: center"></Column>
            <Column></Column>
            <Column></Column>
        </DataTable>
    </div>
</template>

<script>
    import DataTable from "primevue/datatable";
    import Column from "primevue/column";
    import {
        getCompetenceActionsUsingGET as getCompetenceActions,
        getMyCompetenceActionsUsingGET as getOwnCompetenceActions,
    } from "@/swagger/vue-api-client";
    import Tooltip from "primevue/tooltip";

    export default {
        name: "CompetenceActionsTable",
        components: {
            DataTable, Column,
        },

        directives: {
            tooltip: Tooltip,
        },

        data() {
            return {
                actions: [],
                workerId: this.$route.params.id,
            };
        },
        props: {
            own: {
                type: Boolean,
                required: false,
                default: false,
            },
        },

        methods: {
            getWorkerCompetenceActions() {
                if (this.own) {
                    getOwnCompetenceActions().then((response) => {
                        this.actions = response.data;
                    });
                } else {
                    getCompetenceActions({workerId: this.workerId}).then((response) => {
                        this.actions = response.data;
                    });
                }
            },
        },

        mounted() {
            this.getWorkerCompetenceActions();
        },
    };
</script>

<style scoped>
</style>
