<template>
    <div>
        <DataTable ref="companyTable" :value="companies" :lazy="true" :paginator="true"
                   v-model:rows="searchParams.page.limit"
                   removableSort :totalRecords="totalRecords"
                   :loading="loading" @page="onPage"
                   @sort="onPage"
                   :rowsPerPageOptions="[5,10,20,50]" :autoLayout="true"
                   :rowHover="true">
            <template #empty>
                Nie znaleziono firm
            </template>
            <template #loading>
                Trwa wczytywanie firm. Proszę czekać
            </template>
            <Column field="name" header="Nazwa" icon="pi pi-check" headerStyle="width: 400px; text-align: left">
                <template #body="slotProps">
                    <div v-tooltip.bottom="slotProps.data.description">
                        {{ slotProps.data.name }}
                    </div>
                </template>
            </Column>
            <Column field="creationDate" header="Data rejestracji" headerStyle="width: 350px; text-align: left">
                <template #body="slotProps">
                    {{parseDate(slotProps.data.creationDate)}}
                </template>
            </Column>
            <Column field="phoneNumber" header="Numer telefonu" headerStyle="width: 200px; text-align: left">
                <template #body="slotProps">
                    {{slotProps.data.phoneNumber}}
                </template>
            </Column>
            <Column field="agreementForDataSharing" header="Zgoda na publikację" headerStyle="width: 12em"
                    bodyStyle="text-align:center">
                <template #body="slotProps">
                    <Avatar v-if="slotProps.data.agreementForDataSharing === true" icon="pi pi-check"
                            class="p-mr-2 agreement-given"
                            v-tooltip.top="'Udzielono zgody'"
                            shape="circle"/>
                    <Avatar v-else icon="pi pi-times" class="p-mr-2 agreement-not-given"
                            v-tooltip.top="'Nie udzielono zgody'"
                            shape="circle"/>
                </template>
            </Column>
            <Column field="publishedForPublicAccess" header="Opublikowano na stronie"
                    headerStyle="width: 12em" bodyStyle="text-align:center">
                <template #body="slotProps">
                    <Avatar v-if="slotProps.data.publishedForPublicAccess === true" icon="pi pi-check"
                            class="p-mr-2 agreement-given"
                            v-tooltip.top="'Opublikowano na stronie głównej'"
                            shape="circle"/>
                    <Avatar v-else icon="pi pi-times" class="p-mr-2 agreement-not-given"
                            v-tooltip.top="'Nie opublikowano'"
                            shape="circle"/>
                </template>
            </Column>
            <Column header= "Akcje" headerStyle="width: 190px; text-align: center"
                    bodyStyle="text-align:center">
                <template #body="slotProps">
                    <div>
                        <router-link :to="{name: 'companyExcelFiles', params: {'id': slotProps.data.id}}">
                            <Button type="button" icon="pi pi-file-excel"
                                    class="company-button-space p-ml-2 p-button-rounded p-button-outlined"
                                    style="color: #689F38"
                                    v-tooltip.top="'Pliki excel firmy'"/>
                        </router-link>
                        <Button :disabled="isDisabled(slotProps.data)"
                                icon="pi pi-eye"
                                v-if="slotProps.data.publishedForPublicAccess === false"
                                type="button" @click="changePublicationStatus(slotProps.data)"
                                class="company-button-space
                                       p-ml-2 p-button-rounded p-button-outlined"
                                style="color: #05112f"
                                v-tooltip.top="'Publikuj na stronie głównej'">
                        </Button>
                        <Button icon="pi pi-eye-slash"
                                v-if="slotProps.data.publishedForPublicAccess === true" type="button"
                                @click="changePublicationStatus(slotProps.data)"
                                class="company-button-space
                                       p-ml-2 p-button-rounded p-button-outlined"
                                style="color: #ff6161"
                                v-tooltip.top="'Odpublikuj'">
                        </Button>
                        <router-link :to="{name: 'companyEdit', params: {'id': slotProps.data.id}}">
                            <Button type="button" icon="pi pi-pencil"
                                    class="company-button-space
                                           p-button-rounded p-button-outlined"
                                    style="color: #247ba0"
                                    v-tooltip.top="'Edytuj firmę'"/>
                        </router-link>
                    </div>
                </template>
            </Column>
        </DataTable>
    </div>
</template>

<script>
    import DataTable from "primevue/datatable";
    import Column from "primevue/column";
    import Button from "primevue/button";
    import Tooltip from "primevue/tooltip";
    import Avatar from "primevue/avatar";
    import router from "@/router";
    import {
        changePublicationStatusUsingPOST as changePublicationStatus,
        searchCompanyUsingPOST as searchCompany,
        searchCompanyCountUsingPOST as searchCompanyCount,
    } from "@/swagger/vue-api-client";
    import {DateUtils} from "@/utils/DateUtils";

    export default {
        name: "CompanyTable",
        components: {
            // eslint-disable-next-line vue/no-unused-components
            DataTable, Column, Avatar, Button, Tooltip, router,
        },

        props: {
            searchCriteria: {
                type: Object,
            },
        },

        emits: ["update:searchCriteria"],

        directives: {
            tooltip: Tooltip,
        },
        data() {
            return {
                searchParams: this.searchCriteria,
                loading: false,
                totalRecords: 0,
                companies: [],
            };
        },

        mounted() {
            this.updateTotalRecords();
            this.onPage(this.getFirstPage());
        },

        methods: {
            search() {
                this.onPage(this.getFirstPage());
                this.updateTotalRecords();
            },
            getFirstPage() {
                return {
                    first: this.searchCriteria.page.offset,
                    rows: this.searchCriteria.page.limit,
                };
            },

            changePublicationStatus(company) {
                changePublicationStatus({companyIdRequest: {id: company.id}}).then(() => {
                    if (company.publishedForPublicAccess === false) {
                        company.publishedForPublicAccess = true;
                    } else {
                        company.publishedForPublicAccess = false;
                    }
                    this.$toast.add({
                        severity: "success",
                        summary: "Sukces",
                        detail: "Zmieniono widoczność firmy",
                        life: 3000,
                    });
                }).catch(() => {
                    this.$toast.add({
                        severity: "error",
                        summary: "Błąd",
                        detail: "Nastąpił nieoczekiwany błąd",
                        life: 3000,
                    });
                });
            },

            isDisabled(company) {
                if (company.agreementForDataSharing === false) {
                    return true;
                }
                return false;
            },

            updateTotalRecords() {
                searchCompanyCount({searchCriteria: this.searchCriteria}).then((response) => {
                    this.totalRecords = response.data;
                });
            },

            onPage(event) {
                this.loading = true;

                this.searchParams.page.offset = event.first;
                this.searchParams.page.limit = event.rows;
                this.searchParams.page.sortField = event.sortField;
                this.searchParams.page.sortOrder = event.sortOrder;

                searchCompany({searchCriteria: this.searchCriteria}).then((response) => {
                    this.companies = response.data;
                    this.loading = false;
                });
            },

            parseDate(date) {
                return DateUtils.displayDateTime(date);
            },

        },

    };
</script>

<style scoped>
.agreement-not-given {
    background-color:#a7a5a5;
    color: #ffffff
}
.agreement-given {
    background-color:#ff6161;
    color: #ffffff
}
.company-button-space {
    margin: 0.2rem;
}
</style>
