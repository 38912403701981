<template>
    <div class="p-grid">
        <div class="p-col-4">
            <CustomInputText name="firstName" label="Imię" v-model="internalValue.firstName"/>
            <CustomSelectOneSystemRole name="systemRole" label="Rola" v-model="internalValue.systemRole"/>
        </div>
        <div class="p-col-4">
            <CustomInputText name="lastName" label="Nazwisko" v-model="internalValue.lastName"/>
        </div>
        <div class="p-col-4">
            <CustomInputText name="email" label="e-mail" v-model="internalValue.email"/>
        </div>
    </div>
</template>

<script>
    import CustomInputText from "../../../components/form/CustomInputText";
    import CustomSelectOneSystemRole from "../../../components/form/CustomSelectOneSystemRole";

    export default {
        name: "UserSearchCriteria",

        props: {
            modelValue: null,
        },

        emits: ["update:modelValue"],

        computed: {
            internalValue: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },
            },
        },

        components: {CustomInputText, CustomSelectOneSystemRole},
    };
</script>

<style scoped>

</style>
