<template>
    <div>
        <div class="top-panel">
            <div class="code">
                401
            </div>
        </div>
        <div class="bottom-panel">
            <div class="icon">
                <i class="pi pi-lock"/>
            </div>
            <div class="content">
                <h3>Dostęp zabroniony</h3>
                <span>Prosimy skontaktować się z administratorem systemu</span>
                <br><br>
                <div class="p-lg-12 p-col-12" style="align-items: center">
                    <Button class="p-lg-12 p-col-12"
                            icon="pi pi-undo"
                            label="Powrót na stronę główną"
                            @click="redirectToLogin"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Button from "primevue/button";

    export default {
        name: "ForbiddenView",
        components: {Button},

        methods: {
            redirectToLogin() {
                this.$router.push({name: "login"});
            },
        },
    };
</script>

<style lang="less" scoped>
    @import "assets/less/status-page.less";
</style>
