<template>
    <div>
        <CustomSelectOne :name="name" :items="items" :empty-value="emptyValue" v-model="internalValue"
                         item-label="label" item-value="value" :label="label" v-if="loading"/>
    </div>
</template>

<script>
    import {getAllDepartmentsUsingGET as getAllDepartments} from "../../swagger/vue-api-client";
    import CustomSelectOne from "./inner/CustomSelectOne";

    export default {
        name: "CustomSelectOneDepartment",
        props: {
            name: {
                type: String,
            },
            label: {
                type: String,
            },
            emptyValue: {
                type: Boolean,
                default: true,
            },
            modelValue: null,
        },

        emits: ["update:modelValue"],

        computed: {
            internalValue: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },
            },
        },

        data() {
            return {
                items: [],
                loading: false,
            };
        },

        beforeMount() {
            getAllDepartments().then((response) => {
                this.items = response.data;
                this.loading = true;
            });
        },

        components: {CustomSelectOne},
    };
</script>

<style scoped>

</style>
