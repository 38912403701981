<template>
    <div>
        <h1 class="page-header"><i class="pi pi-file header-icon"></i>Baza materiałów dla pracowników</h1>

        <div style="width: 100%; display: flex; align-items: flex-start; margin-top: 30px">
            <Button label="Dodaj materiał" @click="showAddFileDialog = true"
                    style="max-height: 40px; margin-right: 50px" v-if="isEmployer"
                    icon="pi pi-plus" iconPos="left"/>
        </div>
        <FileFormDialog :editing="false" v-model:visible="showAddFileDialog" @file-added="updateSearch"/>
        <ElearningFilesTable ref="filesTable" v-model:search-criteria="searchCriteria"/>
    </div>
</template>

<script>

    import ElearningFilesTable from "@/views/elearning/components/ElearningFilesTable";
    import FileFormDialog from "@/views/elearning/components/FileFormDialog";
    import Button from "primevue/button";
    import {SystemRole} from "../../utils/SystemRole";

    export default {
        name: "ElearningMainView",

        components: {FileFormDialog, Button, ElearningFilesTable},

        data() {
            return {
                showAddFileDialog: false,
                searchCriteria: this.getClearSearchCriteria(),
                isEmployer: SystemRole.isEmployer(),
            };
        },

        methods: {
            updateSearch() {
                this.$refs.filesTable.refreshResults();
            },

            getClearSearchCriteria() {
                return {
                    page: {
                        limit: 10,
                        offset: 0,
                        sortField: null,
                        sortOrder: null,
                    },
                };
            },
        },
    };
</script>
