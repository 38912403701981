<template>
    <div>
        <DataTable ref="competenceDepartmentTable" :value="competences" :lazy="true" :loading="loading">
            <template #empty>
                Nie znaleziono kompetencji
            </template>
            <template #loading>
                Trwa wczytywanie kompetencji. Proszę czekać
            </template>
            <Column field="competence" header="Kompetencje" style="text-align: center"></Column>
            <Column field="dominantLevel" header="Dominujący poziom" style="text-align: center"></Column>
            <Column field="averageCompetencyLevel" header="Średni poziom kompetencji" style="text-align: center">
                <template #body="{data}">
                    {{data.averageCompetencyLevel}}%
                    <ProgressBar :value="data.averageCompetencyLevel"></ProgressBar>
                </template>
            </Column>
            <Column field="debutant" style="text-align: center;">
                <template #header>
                    <span v-tooltip="'Pracownik znajduje się na podstawowym poziomie  badanej kompetencji.' +
                        ' Potrzebuje wsparcia w zakresie wykorzystywania jej podczas realizacji większości' +
                        ' obowiązków zawodowych. \n W takiej sytuacji zalecane jest korzystanie z wiedzy' +
                        ' i doświadczenia pozostałych współpracowników, u których wspomniana kompetencja' +
                        ' jest lepiej rozwinięta i którzy skutecznie wykorzystują ją w codziennej pracy.' +
                        ' Działania reskillingowe, poza poszerzaniem wiedzy i umiejętności pracownika,' +
                        ' powinny zmierzać do zwiększania jego motywacji \n w zakresie rozwijania badanej' +
                        ' umiejętności.'" style="margin-right: 14%">
                        Debiutant ⓘ
                    </span>
                </template>
                <template #body="{data}">
                    {{data.debutant}}%
                </template>
            </Column>
            <Column field="assistant" style="text-align: center;">
                <template #header>
                    <span v-tooltip="'Pracownik jest na dobrej drodze do stopniowego osiągania' +
                        ' samodzielności w zakresie badanej kompetencji. Niekiedy potrafi skutecznie' +
                        ' wykorzystywać ją podczas realizacji zadań. W dalszym ciągu wskazane jest' +
                        ' rozwijanie umiejętności np. dzięki obserwowaniu pracy współpracowników,' +
                        ' którzy wspomnianą kompetencję mają lepiej rozwiniętą, bądź wykorzystują' +
                        ' ją w bardziej złożony sposób. Stwarzanie okazji do wymiany doświadczeń' +
                        ' jest w tej sytuacji niezbędne do pomyślnego przejścia przez proces reskillingu.' +
                        ' Pracownik potrzebuje także wzmocnienia motywacji do rozwoju.'" style="margin-right: 14%">
                        Asystent ⓘ
                    </span>
                </template>
                <template #body="{data}">
                    {{data.assistant}}%
                </template>
            </Column>
            <Column field="specialist" style="text-align: center;">
                <template #header>
                    <span v-tooltip="'Pracownik przejawia wysoki poziom w zakresie badanej kompetencji.' +
                        ' Zwykle potrafi skutecznie wykorzystywać ją w ramach wykonywania obowiązków zawodowych.' +
                        ' Niekiedy może potrzebować wsparcia ze strony bardziej doświadczonych współpracowników.' +
                        ' Stwarzanie okazji do uczestnictwa w rekomendowanych poniżej działaniach rozwojowych,' +
                        ' może znacząco przyczynić się do wzmocnienia kompetencji i osiągnięcia pełnej' +
                        ' biegłości w posługiwaniu się nią. Wpłynie to na pomyślne przejście procesu' +
                        ' reskillingu i zwiększenie samodzielności' +
                        ' w wykonywaniu zadań zawodowych.'" style="margin-right: 14%">
                        Specjalista ⓘ
                    </span>
                </template>
                <template #body="{data}">
                    {{data.specialist}}%
                </template>
            </Column>
            <Column field="professional" style="text-align: center;">
                <template #header>
                    <span v-tooltip="'Pracownik znajduje się na najwyższym poziomie rozwoju w zakresie' +
                        ' badanej kompetencji. Potrafi biegle wykorzystywać ją podczas realizowania' +
                        ' obowiązków zawodowych. Najprawdopodobniej z powodzeniem wykorzysta ją podczas' +
                        ' wdrażania procesu reskillingu w organizacji. Należy zaznaczyć, iż może on pełnić' +
                        ' rolę lidera zmiany i być aktywnie włączany w działania kształtujące tę' +
                        ' umiejętność u pozostałych członków zespołu. Kluczowe dla dalszego rozwoju' +
                        ' badanej kompetencji jest dla niego utrwalenie posiadanych zasobów oraz' +
                        ' wzbogacanie ich w oparciu o najnowsze trendy branżowe i rozwojowe.'">
                        Profesjonalista ⓘ
                    </span>
                </template>
                <template #body="{data}">
                    {{data.professional}}%
                </template>
            </Column>
            <Column field="averageCompetencyLevelGap" header="Średni poziom luki kompetencyjnej"
                    style="text-align: center">
                <template #body="{data}">
                    {{data.averageCompetencyLevelGap}}%
                    <ProgressBar :value="data.averageCompetencyLevelGap"></ProgressBar>
                </template>
            </Column>
        </DataTable>
    </div>
</template>

<script>
    import DataTable from "primevue/datatable";
    import Column from "primevue/column";
    import ProgressBar from "primevue/progressbar";
    import {Departments} from "@/utils/Departments";
    import {getCompetenceDepartmentsUsingGET as getCompetenceDepartments} from "@/swagger/vue-api-client";
    import Tooltip from "primevue/tooltip";

    export default {
        name: "CompetenceDepartmentTable",
        components: {
            DataTable, Column, ProgressBar,
        },
        emits: ["loading-finished, merging-not-completed"],

        directives: {
            tooltip: Tooltip,
        },

        data() {
            return {
                competences: [],
                department: Departments.getDepartmentDiscriminatorByUrlParam(this.$route.params.department),
                loading: false,
            };
        },

        methods: {
            getCompetenceDepartments() {
                getCompetenceDepartments({department: this.department}).then((response) => {
                    this.competences = response.data;
                    this.loading = false;
                    this.$emit("loading-finished");
                }).catch((error) => {
                    if (error.response.status === 409) {
                        this.$emit("merging-not-completed");
                    }
                    this.$emit("loading-finished");
                    this.loading = false;
                });
            },
        },

        mounted() {
            this.loading = true;
            this.getCompetenceDepartments();
        },
    };
</script>

<style lang="less" scoped>
    @import "src/views/assets/less/competence-view.less";
</style>
