<template>
    <div>
        <h1 class="page-header">
            <i class="pi pi-comments header-icon"></i>Forum</h1>

        <Toolbar class="actions">
            <template v-slot:right>
                <Button label="Dodaj nowy temat" icon="pi pi-plus" @click="showNewPostDialog = true"/>
            </template>
        </Toolbar>
        <AddNewPostDialog v-model:visible ="showNewPostDialog" @new-post-added="updateSearch"/>
        <ForumTable ref="forumTable" v-model:search-criteria="searchCriteria"></ForumTable>
    </div>
</template>

<script>
    import ForumTable from "@/views/forum/ForumTable";
    import Toolbar from "primevue/toolbar";
    import Button from "primevue/button";
    import AddNewPostDialog from "@/views/forum/components/AddNewPostDialog";
    import {SystemRole} from "../../utils/SystemRole";

    export default {
        name: "ForumPage",
        components: {
            ForumTable, Toolbar, Button, AddNewPostDialog,
        },

        data() {
            return {
                searchCriteria: this.getClearSearchCriteria(),
                showNewPostDialog: false,
                showSearchPagesDialog: false,
                isAdmin: SystemRole.isAdmin(),
                isEmployer: SystemRole.isEmployer(),
            };
        },
        methods: {

            getClearSearchCriteria() {
                return {
                    page: {
                        limit: 10,
                        offset: 0,
                        sortField: null,
                        sortOrder: null,
                    },
                };
            },

            updateSearch() {
                this.$refs.forumTable.refreshResults();
            },
        },
    };
</script>

<style scoped>

</style>
