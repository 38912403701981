<template>
    <div class="p-grid">
        <div class="p-col-4">
            <CustomSelectOneWorker name="fullName" label="Pracownik" v-model="internalValue.workerId"/>
        </div>
        <div class="p-col-4">
            <CustomSelectOneDepartment name="department" label="Dział" v-model="internalValue.department"/>
        </div>
        <div class="p-col-4">
            <CustomSelectOneEvaluationType name="evaluationType" label="Typ oceny"
                                           v-model="internalValue.evaluationType"/>
        </div>
        <div class="p-field p-col-12 p-md-4 p-fluid">
            <span class="p-float-label" :class="icon ? 'p-input-icon-right' : ''">
                <i v-if="icon" :class="icon" />
                <Calendar class="p-float-label" id="range" v-model="internalValue.dates" selectionMode="range"
                          :manualInput="false" dateFormat="dd-mm-yy" :showButtonBar="true"/>
                <label class="p-float-label" for="range">Data przesłania od-do</label>
                <span v-show="errorMessage" class="p-invalid">{{ errorMessage }}</span>
            </span>
        </div>
    </div>
</template>

<script>
    import CustomSelectOneDepartment from "@/components/form/CustomSelectOneDepartment";
    import Calendar from "primevue/calendar";
    import CustomSelectOneEvaluationType from "@/components/form/CustomSelectOneEvaluationType";
    import CustomSelectOneWorker from "@/components/form/CustomSelectOneWorker";

    export default {
        name: "SurveySearchCriteria",

        props: {
            modelValue: null,
        },

        emits: ["update:modelValue"],

        computed: {
            internalValue: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },
            },
        },

        components: {
            CustomSelectOneWorker,
            CustomSelectOneEvaluationType,
            CustomSelectOneDepartment,
            Calendar,
        },
    };
</script>

<style scoped>

</style>
