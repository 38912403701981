<template>
    <div>
        <DataTable ref="elearningFilesTable" :value="files" :lazy="true" :paginator="true"
                   v-model:rows="searchParams.page.limit"
                   removableSort :totalRecords="totalRecords" :loading="loading" @page="onPage"
                   @sort="onPage" :rowsPerPageOptions="[5,10,20,50]" :autoLayout="true"
                   :rowHover="true">
            <template #empty>
                Nie znaleziono materiałów
            </template>
            <template #loading>
                Trwa wczytywanie materiałów. Proszę czekać
            </template>
            <Column field="name" header="Nazwa"></Column>
            <Column field="filename" header="Nazwa pliku"></Column>
            <Column field="description" header="Opis pliku"></Column>
            <Column header="Akcje" headerStyle="width: 200px; text-align: center">
                <template #body="{data}">
                    <span style="display: flex; justify-content: center">
                        <Button
                            type="button"
                            icon="pi pi-download"
                            class="p-button-success p-ml-2 p-button-rounded p-button-outlined"
                            @click="downloadEmployerFile(data)"
                            v-tooltip.top="'Pobierz materiał'">
                        </Button>
                        <Button
                            type="button"
                            icon="pi pi-pencil"
                            v-if="isEmployer"
                            class="p-button-info p-ml-2 p-button-rounded p-button-outlined"
                            @click="editEmployerFile(data.id)"
                            v-tooltip.top="'Edytuj materiał'">
                        </Button>
                        <Button
                            type="button"
                            icon="pi pi-trash"
                            v-if="isEmployer"
                            class="p-button-danger p-ml-2 p-button-rounded p-button-outlined"
                            @click="deleteEmployerFile(data.id, $event)"
                            v-tooltip.top="'Usuń materiał'">
                        </Button>
                    </span>
                </template>
            </Column>
        </DataTable>
        <FileFormDialog :editing="true" v-model:visible="showEditFileDialog"
                        :edited-id="editedId" @file-added="refreshResults"/>
    </div>
</template>

<script>
    import DataTable from "primevue/datatable";
    import Column from "primevue/column";
    import Button from "primevue/button";
    import Tooltip from "primevue/tooltip";
    import {
        downloadEmployerFileUsingGETURL as downloadFileUrl,
        deleteEmployerFileUsingDELETE as deleteFile,
        searchEmployerFilesUsingPOST as searchEmployerFiles,
        searchEmployerFilesCountUsingPOST as searchEmployerFilesCount,
    } from "@/swagger/vue-api-client";
    import FileFormDialog from "@/views/elearning/components/FileFormDialog";
    import {FileUtils} from "@/utils/FileUtils";
    import {SystemRole} from "../../../utils/SystemRole";

    export default {
        name: "ElearningFilesTable",
        components: {
            FileFormDialog,
            DataTable,
            Column,
            Button,
        },
        mixins: [FileUtils],
        directives: {
            tooltip: Tooltip,
        },

        props: {
            searchCriteria: {
                type: Object,
            },
        },

        data() {
            return {
                searchParams: this.searchCriteria,
                loading: false,
                totalRecords: 0,
                files: [],
                showEditFileDialog: false,
                editedId: undefined,
                isEmployer: SystemRole.isEmployer(),
            };
        },
        methods: {

            onPage(event) {
                this.loading = true;

                this.searchParams.page.offset = event.first;
                this.searchParams.page.limit = event.rows;
                this.searchParams.page.sortField = event.sortField;
                this.searchParams.page.sortOrder = event.sortOrder;

                this.search();
            },

            search() {
                searchEmployerFiles({searchCriteria: this.searchCriteria}).then((response) => {
                    this.files = response.data;
                    this.loading = false;
                });
            },

            updateTotalRecords() {
                searchEmployerFilesCount({searchCriteria: this.searchCriteria}).then((response) => {
                    this.totalRecords = response.data;
                });
            },

            getFirstPage() {
                return {
                    first: this.searchCriteria.page.offset,
                    rows: this.searchCriteria.page.limit,
                };
            },

            refreshResults() {
                this.editedId = undefined;
                this.updateTotalRecords();
                this.search();
            },

            deleteEmployerFile(id, event) {
                this.$confirm.require({
                    target: event.currentTarget,
                    message: "Czy na pewno usunąć materiał?",
                    icon: "pi pi-exclamation-triangle",
                    acceptLabel: "Tak",
                    rejectLabel: "Nie",
                    accept: () => {
                        deleteFile({id})
                            .then(() => {
                                this.$toast.add({
                                    severity: "success",
                                    summary: "Sukces",
                                    detail: "Pomyślnie usunięto materiał",
                                    life: 3000,
                                });
                                this.refreshResults();
                            })
                            .catch((error) => {
                                if (error.response.status === 403) {
                                    this.$toast.add({
                                        severity: "error",
                                        summary: "Błąd",
                                        detail: "Nie masz wystarczających uprawnień",
                                        life: 3000,
                                    });
                                } else {
                                    this.$toast.add({
                                        severity: "error",
                                        summary: "Błąd",
                                        detail: "Wystąpił nieoczekiwany błąd",
                                        life: 3000,
                                    });
                                }
                            });
                    },
                    reject: () => {
                        // callback to execute when user rejects the action
                    },
                });
            },

            async downloadEmployerFile(file) {
                await this.downloadFileAsBlob(this, downloadFileUrl({id: file.id}), file.filename);
            },

            editEmployerFile(id) {
                this.editedId = id;
                this.showEditFileDialog = true;
            },

        },

        mounted() {
            this.updateTotalRecords();
            this.onPage(this.getFirstPage());
        },
    };
</script>

<style lang="less" scoped>
@import "src/views/assets/less/files-view.less";
</style>
