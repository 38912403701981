<template>
    <div class="simple-layout-wrapper">
        <header>
            <img src="@/assets/ue.png" alt="ue-logo" class="eu-logo">
        </header>
        <div class="main p-fluid">
            <div style="align-items: center; margin-top: -80px;">
                <img src="@/assets/logo_full.png" width="300" height="300">
            </div>
            <Toast/>
            <ConfirmDialog/>
            <router-view />
        </div>
    </div>
</template>

<script>
    import Toast from "primevue/toast";
    import ConfirmDialog from "primevue/confirmdialog";

    export default {
        name: "LayoutSimpleLogo",
        components: {
            Toast, ConfirmDialog,
        },

    };
</script>

<style lang="less">
    @import "../assets/less/layout-simple.less";
</style>
