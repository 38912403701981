<template>
    <div>
        <CustomSelectOne :disabled="disabled"
                         :name="name" :items="items" :empty-value="emptyValue"
                         v-model="internalValue"
                         item-label="label" item-value="value" :label="label" v-if="loading"/>
    </div>
</template>

<script>
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne";

    export default {
        name: "CustomSelectOneVoivodeship",
        props: {
            name: {
                type: String,
            },
            label: {
                type: String,
            },
            emptyValue: {
                type: Boolean,
                default: true,
            },
            modelValue: null,
            disabled: {
                type: Boolean,
                default: false,
                required: false,

            },
        },

        emits: ["update:modelValue"],

        computed: {
            internalValue: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },
            },
        },

        data() {
            return {
                items: [
                    {label: "dolnośląskie", value: "dolnośląskie"},
                    {label: "kujawsko-pomorskie", value: "kujawsko-pomorskie"},
                    {label: "lubelskie", value: "lubelskie"},
                    {label: "lubuskie", value: "lubuskie"},
                    {label: "łódzkie", value: "łódzkie"},
                    {label: "małopolskie", value: "małopolskie"},
                    {label: "mazowieckie", value: "mazowieckie"},
                    {label: "opolskie", value: "opolskie"},
                    {label: "podkarpackie", value: "podkarpackie"},
                    {label: "podlaskie", value: "podlaskie"},
                    {label: "kujawsko-pomorskie", value: "kujawsko-pomorskie"},
                    {label: "śląskie", value: "śląskie"},
                    {label: "świętokrzyskie", value: "świętokrzyskie"},
                    {label: "warmińsko-mazurskie", value: "warmińsko-mazurskie"},
                    {label: "wielkopolskie", value: "wielkopolskie"},
                    {label: "zachodniopomorskie", value: "zachodniopomorskie"},
                ],
                loading: false,
            };
        },

        beforeMount() {
            this.loading = true;
        },

        components: {CustomSelectOne},
    };
</script>

<style scoped>

</style>
