<template>
    <div v-if="post" class="post-title">
        {{post.title}}
    </div>
    <div v-if="post" class="post-in-comments">
        <div class="comment-author">
            {{post.author.firstName}} {{post.author.lastName}}
        </div>
        <div class="comment-content">
            {{post.content}}
        </div>
        <div class="comment-date">
            {{parseDate(post.creationDate)}}
        </div>
        <div v-if="post.version > 0" class="moderated-info-class" style="text-align: right;">
            <i class="pi pi-user-edit"></i>
            Zmoderowano
        </div>
        <div class="moderation-button">
            <Button v-if="Role.isAdmin()" label="Moderuj post"
                    class="p-button-raised"
                    style="background-color: #ff6161"
                    icon="pi pi-cog" @click="showEditPostDialog = true"/>
        </div>
    </div>
    <div v-if="post">
        <EditCommentDialog v-model:commentId="currentCommentId" v-model:visible ="editCommentDialog"
                           v-model:content="currentCommentContent" @comment-edited="refreshResults">

        </EditCommentDialog>
        <EditPostDialog v-model:visible="showEditPostDialog" v-model:content="post.content"
                        v-model:title="post.title"
                        @post-edited="refreshResults">

        </EditPostDialog>
        <DataView :paginator="true" :rows="9" :value="post.comments" sortField="creationDate"
                  sortOrder="1">
            <template #header>
            </template>
            <template #list="slotProps">
                <div class="p-col-12">
                    <div class="data-view-all-rows">
                        <div class="comment-author">
                            {{ slotProps.data.author.firstName }} {{ slotProps.data.author.lastName }}
                        </div>
                        <div class="comment-content">
                            <div>
                                <span class="post-content">
                                    {{ slotProps.data.content }}
                                </span>
                            </div>
                        </div>
                        <div class="comment-date">
                            <div>{{ parseDate(slotProps.data.creationDate) }}</div>
                        </div>
                        <div v-if="slotProps.data.version > 0"
                             class="moderated-info-class"
                             style="text-align: right;">
                            <i class="pi pi-user-edit"></i>
                            Zmoderowano
                        </div>
                        <div class="moderation-button">
                            <Button v-if="Role.isAdmin()" label="Moderuj post"
                                    class="p-button-raised"
                                    style="background-color: #ff6161"
                                    icon="pi pi-cog"
                                    @click="openEditCommentDialog(slotProps.data.id, slotProps.data.content)"/>
                        </div>
                    </div>
                </div>
            </template>
        </DataView>
    </div>
</template>

<script>
    import DataView from "primevue/dataview";
    import {searchPostUsingPOST as searchPost} from "@/swagger/vue-api-client";
    import {DateUtils} from "@/utils/DateUtils";
    import Button from "primevue/button";
    import {SystemRole} from "@/utils/SystemRole";
    import EditCommentDialog from "@/views/forum/components/EditCommentDialog";
    import EditPostDialog from "@/views/forum/components/EditPostDialog";

    export default {
        name: "ForumPostDataView",
        components: {
            EditCommentDialog,
            DataView,
            Button,
            EditPostDialog,
        },

        data() {
            return {
                Role: SystemRole,
                editCommentDialog: false,
                comments: [],
                post: null,
                searchCriteria: {
                    postId: this.$route.params.id,
                    page: {
                        limit: 1,
                        offset: 0,
                        sortField: null,
                        sortOrder: null,
                    },
                },
                currentCommentId: 0,
                currentCommentContent: "",
                moderationStatus: false,
                showEditPostDialog: false,
            };
        },

        created() {
            this.search();
            this.searchCriteria.page.sortField = "creationDate";
            this.searchCriteria.page.sortOrder = -1;
        },

        methods: {
            search() {
                searchPost({postSearchCriteria: this.searchCriteria}).then((response) => {
                    // eslint-disable-next-line prefer-destructuring
                    this.post = response.data[0];
                });
            },
            parseDate(date) {
                return DateUtils.displayDateTime(date);
            },

            refreshResults() {
                this.search();
            },
            isAdmin() {
                return localStorage.getItem("role") === this.ADMIN;
            },
            openEditCommentDialog(commentId, content) {
                this.currentCommentId = commentId;
                this.currentCommentContent = content;
                this.editCommentDialog = true;
            },
            // eslint-disable-next-line vue/no-dupe-keys
            showModerationStatus() {
                this.moderationStatus = false;
            },
        },
    };

</script>

<style scoped>

    .post-content {
        white-space: pre-wrap;
    }
    .post-title {
        font-size: 2rem;
        font-weight: 900;
        text-align: center;
        padding-bottom: 30px;
    }

    .post-in-comments {
        padding: 30px 0 30px 30px;
        background-color: #05112f;
        border-bottom-width: 20px;
        border-top-width: 20px;
        border-radius: 10px;
        color: white;
    }

    .data-view-all-rows {
        padding: 30px 0 30px 30px;
        background-color: #247ba0;
        border-color: #ffffff;
        border-bottom-width: 20px;
        border-top-width: 20px;
        border-radius: 10px;
        color: white;
    }
    .p-dataview.p-dataview-list .p-dataview-content > .p-grid > div {
        border: solid rgba(0, 0, 0, 0);
        border-top-width: 20px;
    }
    .comment-author {
        font-size: 1.5rem;
        font-weight: 700;
        text-align: left;
        padding-bottom: 10px;
    }
    .comment-content {
        margin: 0 0 1rem 0;
        text-align: left;
        padding-right: 10px;
    }
    .comment-date {
        display: flex;
        flex-direction: column;
        text-align: right;
        padding-right: 10px;
        padding-top: 10px;
    }
    .moderation-button {
        text-align: left;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .moderated-info-class {
        text-align: left;
        color: #ff6161;
        font-size: 1.2rem;
        font-stretch: ultra-expanded;
        padding-top: 20px;
        background-color: white;
        margin-left: 70%;
        margin-top: 20px;
        margin-bottom: 10px;
        padding-bottom: 10px;
        border-bottom-left-radius: 10px;
        border-top-left-radius: 10px;
        padding-right: 15px;
    }
</style>
