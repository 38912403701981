<template>
    <div class="popup">
        <div>
            <ProgressSpinner/>
        </div>
        <div>
            <span class="label" v-if="label">{{label}}</span>
        </div>
    </div>
</template>

<script>

    import ProgressSpinner from "primevue/progressspinner";

    export default {
        name: "PopupProgressSpinner",
        components: {
            ProgressSpinner,
        },
        props: {
            label: String,
        },
    };
</script>

<style scoped>
    .popup {position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 50%;
    }
    .label {
        font-weight: bold;
        font-size: 20px;
    }
</style>
