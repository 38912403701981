<template>
    <div>
        <DataTable ref="resultTable" :value="result" :lazy="false" :rows="2" :rowHover="true">
            <Column field="page" header="Strona" style="width: 50%; word-wrap: break-word;" :sortable="true"></Column>
            <Column field="appearances" header="Ilość wystąpień" style="width: 50%; text-align: center;"
                    :sortable="true"></Column>
            <Column field="word" header="Fraza" style="width: 50%; text-align: center;"
                    :sortable="true"></Column>
        </DataTable>
    </div>
</template>

<script>
    import Button from "primevue/button";
    import DataTable from "primevue/datatable";
    import Column from "primevue/column";
    import Tooltip from "primevue/tooltip";
    import CustomBadge from "../../../components/CustomBadge";

    export default {
        name: "ResultTable",
        components: {
            // eslint-disable-next-line vue/no-unused-components
            CustomBadge, Button, Column, DataTable, Tooltip,
        },

        props: {
            result: {
                type: Object,
            },
        },
    };
</script>

<style scoped>

</style>
