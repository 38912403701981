<template>
    <div>
        <h1 class="page-header">
            <i class="pi pi-desktop header-icon"></i>
            E-Learning <span v-if="title">"{{title}}"</span>
        </h1>
        <Steps :model="steps">
            <template #item="{item}">
                <a :href="item.url">{{item.label}}</a>
            </template>
        </Steps>
        <router-view :data="stepData" @next="goForward($event)" @prev="goPrevious($event)"></router-view>
    </div>
</template>

<script>
    import Steps from "primevue/steps";
    import {
        getElearningStepsNumberUsingGET,
        getELearningStepUsingPUT,
        getElearningUsingGET,
    } from "@/swagger/vue-api-client";

    export default {
        name: "MyElearningTemplateView",

        components: {
            Steps,
        },

        data() {
            return {
                steps: [],
                stepData: null,
                eLearningId: null,
                title: null,
                quizId: null,
            };
        },

        watch: {
            "$route.params.stepId": function (newVal, oldVal) {
                if (newVal !== oldVal) {
                    this.setCurrentStep();
                    this.getELearningData();
                }
            },
            "$route.params.id": function (newVal, oldVal) {
                if (newVal !== oldVal) {
                    this.setCurrentElearningId();
                    this.buildSteps();
                    this.getElearningTitle();
                }
            },
        },

        methods: {
            goForward(page) {
                this.$router.push({name: "ElearningStep", params: {id: this.eLearningId, stepId: page}});
            },

            goPrevious(page) {
                this.$router.push({name: "ElearningStep", params: {id: this.eLearningId, stepId: page}});
            },

            buildSteps() {
                getElearningStepsNumberUsingGET({eLearningId: this.eLearningId})
                    .then((response) => {
                        const steps = [];
                        // eslint-disable-next-line no-plusplus
                        for (let i = 1; i <= response.data; i++) {
                            steps.push({to: "/my-elearning/" + this.eLearningId + "/" + i});
                        }
                        this.steps = steps;
                    });
            },

            getELearningData() {
                getELearningStepUsingPUT(
                    {eLearningAccessId: 0, eLearningId: this.eLearningId, stepId: this.currentStep},
                ).then((response) => {
                    this.stepData = response.data;
                });
            },
            getElearningTitle() {
                getElearningUsingGET({id: this.eLearningId}).then((response) => {
                    this.title = response.data.name;
                });
            },
            setCurrentStep() {
                // eslint-disable-next-line radix
                this.currentStep = parseInt(this.$route.params.stepId);
            },
            setCurrentElearningId() {
                // eslint-disable-next-line radix
                this.eLearningId = parseInt(this.$route.params.id);
            },
        },

        mounted() {
            this.setCurrentElearningId();
            this.buildSteps();
            this.getElearningTitle();
            if (this.$route.params.stepId) {
                this.setCurrentStep();
                this.getELearningData();
            } else {
                this.$router.push({name: "notFound"});
            }
        },

    };
</script>

<style scoped>
a:link, a:visited {
    background-color: #f44336;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
}

a:hover, a:active {
    background-color: red;
}

</style>
